/* eslint-disable react/no-unknown-property */
/** @jsxImportSource theme-ui */
import React from 'react';

import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Flex, Grid } from 'theme-ui';

import { actionCreators } from '../../../store/ActionCreators';
import {
  selectContent,
  selectImageProcessorContainer,
  selectImageProcessorUrl,
  selectRemoveConcessionModalData,
} from '../../../store/Selectors';
import FandBItemImage from '../kiosk/FandBItemImage';

const RemoveConcessionModal: React.FC = () => {
  const dispatch = useDispatch();

  const content = useSelector(selectContent);
  const imageProcessorContainer = useSelector(selectImageProcessorContainer);
  const imageProcessorUrl = useSelector(selectImageProcessorUrl);
  const removeConcessionModalData = useSelector(
    selectRemoveConcessionModalData
  );

  if (
    !removeConcessionModalData?.concession ||
    removeConcessionModalData?.concessionIndex === null ||
    !removeConcessionModalData?.onRemove ||
    !removeConcessionModalData?.showModal
  )
    return null;

  const { concession, concessionIndex, onRemove, showModal } =
    removeConcessionModalData;

  const imagePath = concession.image
    ? concession.image.replace('https://', '')
    : null;

  const imageUrl: string = imagePath
    ? `${imageProcessorUrl}/${imageProcessorContainer}/${imagePath}?height=200`
    : '/images/concession-fallback.png';

  const onRemoveConcession = () => {
    onRemove('remove', concession, concessionIndex);
    dispatch(actionCreators.removeRemoveConcessionModalData());
  };

  return (
    <Modal
      show={showModal}
      centered
      className='layout-modal small-buttons'
      backdrop='static'
      keyboard={false}
      sx={{
        '.modal-content': {
          alignItems: 'center',
          maxHeight: '500px',
          overflow: 'scroll',
          padding: 3,
        },
      }}
    >
      <Flex
        sx={{
          alignItems: 'center',
          flexDirection: 'column',
          textAlign: 'center',
        }}
      >
        <Box>
          <b>{concession.description}</b>
          <Box mt={3}>
            <FandBItemImage imageUrl={imageUrl} />
          </Box>
        </Box>
        <Box sx={{ mt: 3, textWrap: 'balance' }}>
          {content.kiosk.kioskModalRemoveMessage}
        </Box>
        <Grid columns={2} mt={3}>
          <Button
            variant='secondary'
            onClick={() =>
              dispatch(actionCreators.removeRemoveConcessionModalData())
            }
          >
            {content.goBackText}
          </Button>
          <Button variant='primary' onClick={() => onRemoveConcession()}>
            {content.kiosk.kioskModalRemove}
          </Button>
        </Grid>
      </Flex>
    </Modal>
  );
};

export default RemoveConcessionModal;
