import { isMobileOnly } from 'react-device-detect';
import { createSelector } from 'reselect';

import { CurrencyConfig } from '../@types/configTypes';
import {
  BookingFeeType,
  DeliveryPreference,
  DisplayBookingFee,
  FoodAndBeverageJourneyDeliveryType,
  TicketExclusionStrategyType,
  OrderType,
  FoodAndBeverageDeliveryWindowsDisplayOptionEnum,
  GiftCardDeliveryStateTypeEnum,
} from '../@types/enums';
import {
  Concession,
  ConcessionGrouping,
  GiftCard,
  GiftCardWithAmount,
  GlobalState,
  MakePaymentRequestModel,
  PaymentGiftCard,
  SeasonPassTicketType,
  SeatMapSeat,
  SeatsLayoutModel,
  TicketCredit,
  TicketTypeModel,
  TicketTypes,
} from '../@types/modelTypes';
import {
  CURRENCY_ISO_CODES,
  JOURNEY_TYPES,
  REFUND_SERVICE_CHARGE,
} from '../constants';
import {
  calculateAmountToPayByBankCard,
  getCartCostInCents,
  getTotalFabItemsSelected,
  getRequiresDeliveryOptions,
  getOrderToSeatOnly,
  getProductsTax,
  displayPrice,
  getTotalGiftCardsSelected,
  combineConcessions,
  getButtonText,
  calculateBookingFeeAndTax,
  getCollectFromKioskOnly,
  calculateBookingFeeForSeasonPasses,
} from '../services/Helpers';
import { journeyTypeConfigs } from '../services/journeyTypeConfigs';
import { getSeatsAvailableInArea } from '../services/SeatMapHelpers';

export const selectState = (state: GlobalState) => state;
export const isIosApp = (state: GlobalState) =>
  state.source === 'app' && state.device === 'ios';
export const isFabEnabledTicketing = (state: GlobalState) =>
  state.config.currentCinema.foodAndBeverageTicketingJourney !==
  FoodAndBeverageJourneyDeliveryType.Disabled;

export const selectAppliedDeals = (state: GlobalState) => state.appliedDeals;
export const selectAppliedGiftCards = (state: GlobalState) =>
  state.appliedGiftCards;
export const selectAppliedVouchers = (state: GlobalState) =>
  state.appliedVouchers;
export const selectBookingData = (state: GlobalState) => state.bookingData;
export const selectBookingFee = (state: GlobalState) => state.bookingFee;
export const selectBookingFeeStrategy = (state: GlobalState) =>
  state.bookingFeeStrategy;
export const selectBookingFeeTax = (state: GlobalState) => state.bookingFeeTax;
export const selectCartSummary = (state: GlobalState) => state.cartSummary;
export const selectAllConcessions = (state: GlobalState) => state.concessions;
export const selectFaBConcessions = (state: GlobalState) => {
  return (
    state.concessions?.filter(
      (x: ConcessionGrouping) => x.title !== 'egiftcards'
    ) ?? null
  );
};
export const selectConcessionsAdded = (state: GlobalState) =>
  state.concessionsAdded;
export const selectConfig = (state: GlobalState) => state.config;
export const selectConfigDonationValues = (state: GlobalState) =>
  state.config.payment.donationValues;
export const selectConfirmationData = (state: GlobalState) =>
  state.confirmationData;
export const selectConsent = (state: GlobalState) => state.consent;

export const selectContent = (state: GlobalState) => state.content;
export const selectContentSeats = (state: GlobalState) => state.content.seats;
export const selectContentPhysicalGiftcards = (state: GlobalState) =>
  state.content?.physicalGiftCards;
export const selectContentCartSummary = (state: GlobalState) =>
  state.content.cartSummary;
export const selectContentVouchers = (state: GlobalState) =>
  state.content?.vouchers;
export const selectCountdownEndTime = (state: GlobalState) =>
  state.countdownEndTime;
export const selectCountdownExpired = (state: GlobalState) =>
  state.countdownExpired;
export const selectCurrencyConfig = (state: GlobalState) => {
  return state.config
    ? ({
        currency: state.config.currency,
        culture: state.config.languageCulture,
      } as CurrencyConfig)
    : undefined;
};
export const selectCurrentCinema = (state: GlobalState) =>
  state.config?.currentCinema;
export const selectCustomer = (state: GlobalState) => state.customer;
export const selectDazzlerConfig = (state: GlobalState) => {
  return { dazzler: state.dazzler, dazzlerSteps: state.dazzlerSteps };
};
export const selectDazzlerContent = (state: GlobalState) =>
  state.dazzlerContent;
export const selectDeals = (state: GlobalState) => state.deals;
export const selectDealsInTicketsStep = (state: GlobalState) =>
  state.dealsInTicketsStep;
export const selectDeliveryItemId = (state: GlobalState) =>
  state.deliveryItemId;
export const selectDevice = (state: GlobalState) => state.device;
export const selectDeliveryPreference = (state: GlobalState) =>
  state.deliveryPreference;
export const selectDidomiReady = (state: GlobalState) => state.didomiReady;
export const selectDonation = (state: GlobalState) => state.donation;
export const selectEmbargoData = (state: GlobalState) => state.embargoData;
export const selectError = (state: GlobalState) => state.error;
export const selectFandBItemModalData = (state: GlobalState) =>
  state.fandbItemModalData;
export const selectGiftCardRecipient = (state: GlobalState) =>
  state.giftCardRecipient;
export const selectGiftCardsAdded = (state: GlobalState) =>
  state.giftCardsAdded;
export const selectGratuityInCents = (state: GlobalState) =>
  state.gratuityInCents;
export const selectGuestMarketing = (state: GlobalState) =>
  state.guestMarketing;
export const selectGuestNewsletterSignUp = (state: GlobalState) =>
  state.guestNewsletterSignUp;
export const selectHasVoucherApplied = (state: GlobalState) =>
  state.hasVoucherApplied;
export const selectHideTax = (state: GlobalState) =>
  state.config.currentCinema.hideTax;
export const selectHostedPayments = (state: GlobalState) =>
  state.hostedPayments;
export const selectImageProcessorUrl = (state: GlobalState) =>
  state.imageProcessorUrl;
export const selectImageProcessorContainer = (state: GlobalState) =>
  state.imageProcessorContainer;
export const selectJourneyType = (state: GlobalState) => state.journeyType;
export const selectJourneyTypeConfig = (state: GlobalState) =>
  journeyTypeConfigs[state.journeyType];
export const selectKioskSubStep = (state: GlobalState) => state.kioskSubStep;
export const selectKioskActiveCategory = (state: GlobalState) =>
  state.kioskActiveCategory;
export const selectLoading = (state: GlobalState) => state.loading;
export const selectLoyaltyRecognitionNumber = (state: GlobalState) =>
  state.loyaltyRecognitionNumber;
export const selectLoyaltyRedemptionRewards = (state: GlobalState) =>
  state.loyaltyRedemptionRewards;
export const selectMaxRetriesExceeded = (state: GlobalState) =>
  state.maxRetriesExceeded;
export const selectPhysicalGiftCardRecipient = (state: GlobalState) =>
  state.physicalGiftCardRecipient;
export const selectOrderExists = (state: GlobalState) => !!state.orderExists;
export const selectPayment = (state: GlobalState) => state.payment;
export const selectPaymentProviders = (state: GlobalState) =>
  state.config?.currentCinema?.paymentProviders.map((pp) => pp.paymentProvider);
export const selectQueryString = (state: GlobalState) => state.queryString;
export const selectRemoveConcessionModalData = (state: GlobalState) =>
  state.removeConcessionModalData;
export const selectRequestData = (state: GlobalState) => state.requestData;
export const selectRefundData = (state: GlobalState) => state.refundData;
export const selectSeatsModel = (state: GlobalState) => state.seatsModel;
export const selectShowtimes = (state: GlobalState) => state.showtimes;
export const selectSelectedLanguageCulture = (state: GlobalState) =>
  state.selectedLanguageCulture;
export const selectDazzlerLocale = (state: GlobalState) =>
  state.selectedLanguageCulture.replace('-', '_');
export const selectSelectedFaBConcessions = (state: GlobalState) =>
  state.selectedConcessions;
export const selectSelectedGiftCards = (state: GlobalState) =>
  state.selectedGiftCards;
export const selectSelectedDeliveryWindow = (state: GlobalState) =>
  state.selectedDeliveryWindow;
export const selectSelectedDonation = (state: GlobalState) =>
  state.selectedDonation;
export const selectSelectedGratuity = (state: GlobalState) =>
  state.selectedGratuity;
export const selectSelectedPaymentProvider = (state: GlobalState) =>
  state.selectedPaymentProvider;
export const selectSelectedSeats = (state: GlobalState) => state.selectedSeats;
export const selectSingleSeatRuleFired = (state: GlobalState) =>
  state.singleSeatRuleFired;
export const selectSource = (state: GlobalState) => state.source;
export const selectStep = (state: GlobalState) => state.step;
export const selectTicketTypes = (state: GlobalState) => state.ticketTypes;
export const selectTicketCredits = (state: GlobalState) => state.ticketCredits;
export const selectTicketCreditsAvailable = (state: GlobalState) =>
  state.ticketCreditsAvailable;
export const selectToken = (state: GlobalState) => state.token;
export const selectTotalDiscount = (state: GlobalState) => state.totalDiscount;

export const selectCurrentCinemaHomeUrl = createSelector(
  selectCurrentCinema,
  selectRequestData,
  (currentCinema, requestData) => {
    if (!currentCinema) return '';

    let requestDataQueryString = '';

    if (requestData) {
      requestDataQueryString = 'token=' + requestData;

      if (currentCinema.cinemaHomeUrl.includes('?'))
        requestDataQueryString = '&' + requestDataQueryString;
      else requestDataQueryString = '?' + requestDataQueryString;
    }

    return currentCinema.cinemaHomeUrl + requestDataQueryString;
  }
);

export const selectIsSeatsFirstJourney = createSelector(
  selectConfig,
  selectJourneyType,
  (config, journeyType) => {
    return (
      config?.currentCinema.enableSeatsFirst &&
      journeyType !== JOURNEY_TYPES.SEASON_PASS
    );
  }
);

export const selectDynamicSeatingEnabled = createSelector(
  selectConfig,
  selectIsSeatsFirstJourney,
  (config, isSeatsFirstJourney) => {
    return config?.useDynamicSeating && isSeatsFirstJourney;
  }
);

export const selectAppliedCeaCards = (state: GlobalState) =>
  state.appliedCeaCards;
export const selectCeaCardTicketTypeModel = (state: GlobalState) =>
  state.ticketTypes?.ticketTypeModels?.find(
    (x) =>
      x.ticketExclusionStrategy?.isExcluded &&
      x.ticketExclusionStrategy.type === TicketExclusionStrategyType.CEA
  );

export const selectAppliedMemberCards = (state: GlobalState) =>
  state.appliedMemberCards;

export const selectVoucherRecipient = (state: GlobalState) =>
  state.voucherRecipient;

export const selectRegisterForCarParkingEncryptedToken = (state: GlobalState) =>
  state.registerForCarParkingEncryptedToken;

export const selectShouldUseTurnstile = (state: GlobalState) =>
  !!state.turnstileConfig?.enabled && !!state.turnstileConfig?.siteKey;
export const selectTurnstileSiteKey = (state: GlobalState) =>
  state.turnstileConfig?.siteKey ?? '';
export const selectShouldUseRecaptcha = (state: GlobalState) =>
  !!state.recaptchaConfig?.enabled && !!state.recaptchaConfig?.siteKey;
export const selectEnableContentSecurityPolicy = (state: GlobalState) =>
  state.enableContentSecurityPolicy;
export const selectContentSecurityPolicyMetaTagContent = (state: GlobalState) =>
  state.contentSecurityPolicyMetaTagContent;
export const selectRecaptchaSiteKey = (state: GlobalState) =>
  state.recaptchaConfig?.siteKey ?? '';

export const selectConcessionsJourneyParentBookingRef = (state: GlobalState) =>
  state.concessionsJourneyParentBookingRef;

export const selectPayWithStoredCardEnabled = (state: GlobalState) => {
  return (
    state.bookingData?.isUserValidated &&
    state.config.currentCinema?.enablePayWithStoredCard
  );
};

export const shouldDisplayBookingFeeOnTicketsStep = (state: GlobalState) => {
  return (
    state.config?.currentCinema.displayBookingFeesOnTicketsStep ===
      DisplayBookingFee.INFOBOXANDTICKETS ||
    state.config?.currentCinema.displayBookingFeesOnTicketsStep ===
      DisplayBookingFee.TICKETS ||
    state.config?.currentCinema.displayBookingFeesOnTicketsStep ===
      DisplayBookingFee.TICKETSWITHTAX
  );
};

export const shouldDisplayTaxInTicketsPricing = (state: GlobalState) => {
  return (
    state.config?.currentCinema.displayBookingFeesOnTicketsStep ===
    DisplayBookingFee.TICKETSWITHTAX
  );
};

export const selectUseBookingFeeStrategyInTickets = (state: GlobalState) => {
  const perItemFee =
    state.bookingFeeStrategy?.bookingFeeType === BookingFeeType.PERITEM
      ? state.bookingFeeStrategy?.bookingFeeValue
      : 0;
  const perItemTax =
    state.bookingFeeStrategy?.bookingFeeType === BookingFeeType.PERITEM
      ? state.bookingFeeStrategy?.bookingFeeTaxValue
      : 0;
  const shouldDisplayInTicketConfig =
    state.config?.currentCinema.displayBookingFeesOnTicketsStep ===
      DisplayBookingFee.INFOBOXANDTICKETS ||
    state.config?.currentCinema.displayBookingFeesOnTicketsStep ===
      DisplayBookingFee.TICKETS ||
    state.config?.currentCinema.displayBookingFeesOnTicketsStep ===
      DisplayBookingFee.TICKETSWITHTAX;
  const useBookingFeeStrategy = perItemFee > 0 && shouldDisplayInTicketConfig;

  return { useBookingFeeStrategy, perItemFee, perItemTax };
};

export const selectShowBookingFeeInInfoBox = (state: GlobalState) => {
  let showInfoBox = false;
  const bookingFeeType = state.bookingFeeStrategy?.bookingFeeType;
  const feeValue = state.bookingFeeStrategy?.bookingFeeValue ?? 0;
  if (bookingFeeType === BookingFeeType.PERITEM) {
    showInfoBox =
      feeValue > 0 &&
      (state.config?.currentCinema.displayBookingFeesOnTicketsStep ===
        DisplayBookingFee.INFOBOXANDTICKETS ||
        state.config?.currentCinema.displayBookingFeesOnTicketsStep ===
          DisplayBookingFee.INFOBOX);
  } else if (bookingFeeType === BookingFeeType.PERSALE) {
    showInfoBox =
      feeValue > 0 &&
      state.config?.currentCinema.displayBookingFeesOnTicketsStep !==
        DisplayBookingFee.DISABLED;
  }

  return showInfoBox;
};

export const selectSeasonPasses = (state: GlobalState) => state.seasonPasses;
export const selectSelectedSeasonPass = (state: GlobalState) =>
  state.selectedSeasonPass;
export const selectSelectedSeasonPassItem = (state: GlobalState) =>
  state.selectedSeasonPass?.seasonPassItem;
export const selectSeasonPassMovies = (state: GlobalState) =>
  state.seasonPassMovies;
export const selectSelectedSeasonPassSessions = (state: GlobalState) =>
  state.selectedSeasonPass?.sessions;

export const selectUseCalculatedBookingFeeStrategy = createSelector(
  selectState,
  selectSelectedSeasonPass,
  (state, selectedSeasonPass) => {
    let bookingFee = 0;
    let bookingFeeTax = 0;
    if (state.bookingFeeStrategy && state.ticketTypes) {
      const { calculatedBookingFee, calculatedBookingFeeTax } =
        calculateBookingFeeAndTax(
          state.ticketTypes.ticketTypeModels,
          state.selectedConcessions,
          state.bookingFeeStrategy
        );
      bookingFee = calculatedBookingFee + calculatedBookingFeeTax;
      bookingFeeTax = calculatedBookingFeeTax;
    }

    if (state.bookingFeeStrategy && selectedSeasonPass?.seasonPassItem) {
      const { calculatedBookingFee, calculatedBookingFeeTax } =
        calculateBookingFeeForSeasonPasses(
          selectedSeasonPass.seasonPassItem.ticketTypes,
          selectedSeasonPass.sessions,
          state.bookingFeeStrategy
        );
      bookingFee = calculatedBookingFee;
      bookingFeeTax = calculatedBookingFeeTax;
    }

    const useBookingFeeStrategy =
      state.config?.currentCinema.calculateBookingFeesOnTicketsStep &&
      state.bookingFeeStrategy;
    return {
      useBookingFeeStrategy,
      calculatedBookingFee: bookingFee,
      calculatedBookingFeeTax: bookingFeeTax,
    };
  }
);

export const selectSubTotal = createSelector(
  selectState,
  selectSelectedSeasonPass,
  (state, selectedSeasonPass) => {
    const { selectedConcessions, ticketTypes, selectedGiftCards } = state;
    let subTotal = getCartCostInCents(
      combineConcessions(selectedConcessions, selectedGiftCards),
      ticketTypes?.ticketTypeModels
    );

    if (selectedSeasonPass?.seasonPassItem) {
      if (selectedSeasonPass.sessions.length > 0) {
        subTotal = selectedSeasonPass.seasonPassItem.ticketTypes.reduce(
          (acc: number, ticket: SeasonPassTicketType) =>
            acc +
            ticket.priceInCents *
              selectedSeasonPass.sessions.length *
              ticket.quantity,
          0
        );
      } else {
        subTotal = selectedSeasonPass.seasonPassItem.ticketTypes.reduce(
          (acc: number, ticket: SeasonPassTicketType) =>
            acc + ticket.priceInCents * ticket.quantity,
          0
        );
      }
    }

    return subTotal;
  }
);

export const getProductTax = (state: GlobalState) => {
  const { selectedConcessions, ticketTypes } = state;
  return getProductsTax(selectedConcessions, ticketTypes);
};

export const selectTandCsAccepted = (state: GlobalState) => {
  return state.config?.payment.showTermsAndConditionsCheckbox
    ? state.termsAndConditionsChecked
    : true;
};

export const selectTandCsAcceptedSignup = (state: GlobalState) => {
  return state.termsAndConditionsChecked;
};

export const selectIsCartSummeryOpen = (state: GlobalState) => {
  return state.isCartSummaryOpen;
};

export const selectCombinedConcessionsList = (state: GlobalState) => {
  return combineConcessions(state.selectedConcessions, state.selectedGiftCards);
};

export const selectCalculatedGratuityInCents = (state: GlobalState) => {
  const {
    selectedGratuity,
    selectedConcessions,
    selectedGiftCards,
    ticketTypes,
  } = state;
  let gratuityInCents: number;

  if (selectedGratuity?.percentage > 0) {
    const cartCostInCents = getCartCostInCents(
      combineConcessions(selectedConcessions, selectedGiftCards),
      ticketTypes?.ticketTypeModels
    );

    gratuityInCents = Math.round(
      (cartCostInCents * selectedGratuity.percentage) / 100
    );
  } else {
    gratuityInCents = selectedGratuity?.amount ?? 0;
  }

  return gratuityInCents;
};

export const selectIsCustomerReadyForPayment = createSelector(
  selectCustomer,
  selectTandCsAccepted,
  (customer, hasAcceptedTandCs) => {
    return customer.isValid && hasAcceptedTandCs;
  }
);

export const selectRefundIncludesBookingFee = (state: GlobalState) => {
  return (
    state.config.currentCinema?.refundServiceCharge ===
      REFUND_SERVICE_CHARGE.YES ||
    (state.bookingData?.isUserValidated &&
      state.config.currentCinema?.refundServiceCharge ===
        REFUND_SERVICE_CHARGE.YES_FOR_LOYALTY)
  );
};

export const selectGrandTotalBeforeDiscountInCents = createSelector(
  selectBookingFee,
  selectSubTotal,
  selectCalculatedGratuityInCents,
  selectDonation,
  (bookingFee, subTotal, gratuityInCents, donation) => {
    return subTotal + gratuityInCents + donation + bookingFee;
  }
);

export const selectTicketCreditDiscount = createSelector(
  selectTicketCredits,
  (ticketCredits) =>
    ticketCredits?.length
      ? ticketCredits.reduce(
          (a: number, b: TicketCredit) => a + b.price * b.used,
          0
        )
      : 0
);

const selectCalculatedGrandTotalWithDiscount = createSelector(
  selectTotalDiscount,
  selectGrandTotalBeforeDiscountInCents,
  selectTicketCreditDiscount,
  (totalDiscount, grandTotalBeforeDiscountInCents, ticketCreditDiscount) => {
    return (
      grandTotalBeforeDiscountInCents -
      (totalDiscount ?? 0) -
      ticketCreditDiscount
    );
  }
);

export const selectGrandTotalAfterDiscountsInCents = createSelector(
  selectCalculatedGrandTotalWithDiscount,
  (calculatedGrandTotalWithDiscount) => calculatedGrandTotalWithDiscount
);

export const selectMaxPayableByLoyaltyInCents = createSelector(
  selectCalculatedGrandTotalWithDiscount,
  selectDonation,
  (calculatedGrandTotalWithDiscount, donation) => {
    return calculatedGrandTotalWithDiscount - (donation ?? 0);
  }
);

export const selectGrandTotalWithoutDonationInCents = createSelector(
  selectBookingFee,
  selectSubTotal,
  selectCalculatedGratuityInCents,
  selectTotalDiscount,
  (bookingFee, subTotal, gratuityInCents, totalDiscount) => {
    return subTotal + gratuityInCents + bookingFee - (totalDiscount ?? 0);
  }
);

export const selectGrandTotalWithoutDonationAndRewards = createSelector(
  selectGrandTotalWithoutDonationInCents,
  selectLoyaltyRedemptionRewards,
  selectTicketCreditDiscount,
  (
    grandTotalWithoutDonationInCents,
    loyaltyRedemptionRewards,
    ticketCreditDiscount
  ) => {
    return (
      grandTotalWithoutDonationInCents -
      loyaltyRedemptionRewards -
      ticketCreditDiscount
    );
  }
);

export const selectRefundTotalInCents = createSelector(
  selectBookingFee,
  selectRefundIncludesBookingFee,
  selectGrandTotalAfterDiscountsInCents,
  (bookingFee, refundIncludesBookingFee, grandTotalAfterDiscount) => {
    return Math.max(
      grandTotalAfterDiscount - (refundIncludesBookingFee ? 0 : bookingFee),
      0
    );
  }
);

export const selectGiftCardsWithAmounts = createSelector(
  selectAppliedGiftCards,
  selectCalculatedGrandTotalWithDiscount,
  (appliedGiftCards, grandTotalAfterDiscountInCents) => {
    let stillToPay = grandTotalAfterDiscountInCents;
    const giftCardsWithAmounts: GiftCardWithAmount[] = appliedGiftCards?.map(
      (giftCard) => {
        let amount = 0;

        if (giftCard.giftCardBalanceInCents < stillToPay) {
          amount = giftCard.giftCardBalanceInCents;
          stillToPay -= amount;
        } else {
          amount = stillToPay;
          stillToPay = 0;
        }

        return {
          giftCardAmount: amount,
          giftCardNumber: giftCard.giftCardNumber,
          giftCardPin: giftCard.giftCardPin,
          giftCardBalanceInCents: giftCard.giftCardBalanceInCents,
        };
      }
    );

    return giftCardsWithAmounts;
  }
);

export const selectGiftCardsForPayment = createSelector(
  selectGiftCardsWithAmounts,
  (giftCardsWithAmounts) => {
    let giftCardsForPayment: PaymentGiftCard[] = [];
    if (!giftCardsWithAmounts) {
      return giftCardsForPayment;
    }
    const giftCardsToBeCharged = giftCardsWithAmounts.filter(
      (giftCard) => giftCard.giftCardAmount > 0
    );

    giftCardsForPayment = giftCardsToBeCharged.map((giftCardToBeCharged, i) => {
      const isFinalCardInArray = i === giftCardsToBeCharged.length - 1;
      return {
        number: giftCardToBeCharged.giftCardNumber,
        pin: giftCardToBeCharged.giftCardPin,
        balance: giftCardToBeCharged.giftCardBalanceInCents,
        isPartialPayment: isFinalCardInArray,
      };
    });

    return giftCardsForPayment;
  }
);

export const selectMakePaymentForHostedRequest = createSelector(
  selectToken,
  selectCustomer,
  selectConfig,
  selectSource,
  selectLoyaltyRecognitionNumber,
  selectGuestMarketing,
  selectGuestNewsletterSignUp,
  selectLoyaltyRedemptionRewards,
  selectDonation,
  selectCalculatedGrandTotalWithDiscount,
  selectGiftCardsForPayment,
  selectCalculatedGratuityInCents,
  selectQueryString,
  selectTicketCredits,
  (
    token,
    customer,
    config,
    source,
    loyaltyRecognitionNumber,
    guestMarketing,
    guestNewsletterSignUp,
    loyaltyRedemptionRewards,
    donation,
    grandTotalAfterDiscountInCents,
    giftCardsForPayment,
    gratuityInCents,
    queryString,
    ticketCredits
  ) => {
    return {
      dataToken: token,
      fullName: customer?.name,
      email: customer?.email,
      emailConfirm: customer?.confirmEmail,
      telephone: customer?.telephone,
      currencySymbol: CURRENCY_ISO_CODES[config.currency],
      totalCharge: grandTotalAfterDiscountInCents,
      source: source,
      queryString: queryString,
      deviceType: isMobileOnly ? 1 : 0,
      giftCards: giftCardsForPayment,
      isPaymentWithGiftcardEnabled: true,
      loyaltyCardNumber: loyaltyRecognitionNumber,
      gratuityInCents: gratuityInCents,
      sendNewsletter: guestMarketing?.sendNewsletter ?? false,
      guestNewsletterSignUp: guestNewsletterSignUp,
      contactByThirdParty: guestMarketing?.contactByThirdParty ?? false,
      payWithLoyaltyDollars: loyaltyRedemptionRewards > 0,
      loyaltyPaymentAmountInCents: loyaltyRedemptionRewards,
      donationInCents: donation,
      zipCode: customer.zipCode ?? null,
      ticketCredits: ticketCredits,
    };
  }
);

export const selectCurrencySymbol = (state: GlobalState) =>
  state?.config ? CURRENCY_ISO_CODES[state.config.currency] : '';

export const selectMakePaymentRequestModel = createSelector(
  selectToken,
  selectCustomer,
  selectSource,
  selectGiftCardsForPayment,
  selectCalculatedGrandTotalWithDiscount,
  selectCalculatedGratuityInCents,
  selectLoyaltyRedemptionRewards,
  selectGuestMarketing,
  selectGuestNewsletterSignUp,
  selectDonation,
  selectCurrencySymbol,
  selectSelectedPaymentProvider,
  selectPaymentProviders,
  selectQueryString,
  selectTicketCredits,
  (
    token,
    customer,
    source,
    giftCardsForPayment,
    grandTotalAfterDiscountInCents,
    gratuityInCents,
    loyaltyRedemptionRewards,
    guestMarketing,
    guestNewsletterSignUp,
    donation,
    currencySymbol,
    paymentProvider,
    paymentProviders,
    queryString,
    ticketCredits
  ) => {
    // if none selected, default to first valid provider, this ensures we pass MVC validation
    //when we are paying by 100% GC or Loyalty, or ZeroPayment
    const paymentProviderToUse = paymentProvider ?? paymentProviders[0];

    const makePaymentModel: MakePaymentRequestModel = {
      dataToken: token,
      fullName: customer?.name,
      email: customer?.email ?? null,
      emailConfirm: customer?.confirmEmail ?? null,
      telephone: customer?.telephone ?? null,
      currencySymbol: currencySymbol,
      giftCards: giftCardsForPayment,
      totalCharge: grandTotalAfterDiscountInCents,
      source: source,
      queryString: queryString,
      paymentProvider: paymentProviderToUse,
      gratuityInCents: gratuityInCents,
      billingPostal: null,
      billingAddress: null,
      cardNumber: null,
      cardType: null,
      cardToken: null,
      storedCardTokenIdentifier: null,
      cvv: null,
      expiryDateMonth: null,
      expiryDateYear: null,
      loyaltyCardNumber: null,
      nameOnCard: null,
      payWithLoyaltyDollars: (loyaltyRedemptionRewards || 0) > 0,
      ticketCredits: ticketCredits,
      loyaltyPaymentAmountInCents: loyaltyRedemptionRewards,
      stripeCheckoutToken: null,
      spreedlyCybersourceDeviceFingerprintId: null,
      sendNewsletter: guestMarketing?.sendNewsletter ?? false,
      guestNewsletterSignUp: guestNewsletterSignUp,
      contactByThirdParty: guestMarketing?.contactByThirdParty ?? false,
      shouldStoreCardToken: false,
      donationInCents: donation,
      recaptchaToken: null,
      completedPaymentProviderTransactionIdentifier: null,
      amazonPayOrderReferenceId: null,
      zipCode: null,
      journeyType: null,
    };

    return makePaymentModel;
  }
);

export const selectDisplayPrice = createSelector(
  [
    selectCurrencyConfig,
    (_state: GlobalState, amount: number | null | undefined) => amount,
  ],
  (currencyConfig, amount) => {
    return currencyConfig && (amount || amount === 0)
      ? displayPrice(amount, currencyConfig)
      : '';
  }
);

export const selectBankCardAmount = createSelector(
  selectCalculatedGrandTotalWithDiscount,
  selectAppliedGiftCards,
  selectDonation,
  selectLoyaltyRedemptionRewards,
  (
    grandTotal: number,
    appliedGiftCards: GiftCard[],
    donation: number,
    loyaltyRedemptionRewards: number
  ) => {
    return calculateAmountToPayByBankCard(
      appliedGiftCards,
      grandTotal,
      donation,
      loyaltyRedemptionRewards
    );
  }
);

export const selectBankCardAmountDisplayPrice = createSelector(
  selectCurrencyConfig,
  selectBankCardAmount,
  (currencyConfig, bankCardAmount) => {
    return currencyConfig ? displayPrice(bankCardAmount, currencyConfig) : '';
  }
);

export const selectProductsTax = createSelector(
  selectSelectedFaBConcessions,
  selectTicketTypes,
  (selectedConcessions, ticketTypes) => {
    return getProductsTax(selectedConcessions, ticketTypes);
  }
);

export const selectTotalFaBItemsSelected = createSelector(
  selectSelectedFaBConcessions,
  (selectedConcessions) => {
    return getTotalFabItemsSelected(selectedConcessions);
  }
);

export const selectTotalGiftCardsSelected = createSelector(
  selectSelectedGiftCards,
  (selectedGiftCards) => {
    return getTotalGiftCardsSelected(selectedGiftCards);
  }
);

export const selectSelectedSeatNames = createSelector(
  selectSelectedSeats,
  (selectedSeats) => {
    const seatsToDisplay: string[] = [];

    if (selectedSeats) {
      selectedSeats.forEach((seat) => {
        if (!seatsToDisplay.includes(seat.seatName)) {
          seatsToDisplay.push(seat.seatName);
        }
      });
    }

    return seatsToDisplay;
  }
);

export const selectIsInSeatDeliveryEnabled = createSelector(
  (state: GlobalState) => state.seatsModel?.seatsLayoutModel?.areaCategories,
  (areaCategories) =>
    areaCategories?.some((ac) => ac.isInSeatDeliveryEnabled === true)
);

export const selectHasSeatsWithSeatDeliveryAvailable = createSelector(
  [
    selectSelectedSeats,
    (state: GlobalState) => state.seatsModel?.seatsLayoutModel?.areaCategories,
    selectIsInSeatDeliveryEnabled,
  ],
  (selectedSeats, areaCategories, isInSeatDeliveryEnabled) => {
    if (!isInSeatDeliveryEnabled) return false;

    const seatDeliveryEnabledAreaCodes = areaCategories
      ?.filter((ac) => ac.isInSeatDeliveryEnabled)
      ?.map((ac) => ac.areaCategoryCode);

    return selectedSeats?.some((seat: SeatMapSeat) =>
      seatDeliveryEnabledAreaCodes?.includes(seat.areaCategoryCode)
    );
  }
);

export const selectDeliveryWindows = (state: GlobalState) =>
  state.deliveryWindows;

export const selectFoodAndBeverageDeliveryWindowsDisplayOption = (
  state: GlobalState
) => state.config.currentCinema.foodAndBeverageDeliveryWindowsDisplayOption;

export const selectDisplayCommentsBoxForDevliveryWindows = createSelector(
  selectConfig,
  (config) => {
    return config.currentCinema
      .foodAndBeverageDeliveryWindowsDisplayCommentsBox;
  }
);
export const selectIsDeliveryOptionBasedOnSeatingArea = (state: GlobalState) =>
  state?.deliveryOptionBasedOnSeatingArea;

export const selectRequiresDeliveryOptions = createSelector(
  [
    selectJourneyType,
    selectConfig,
    selectDeliveryWindows,
    selectHasSeatsWithSeatDeliveryAvailable,
    selectIsDeliveryOptionBasedOnSeatingArea,
    selectFoodAndBeverageDeliveryWindowsDisplayOption,
  ],
  (
    journeyType,
    config,
    deliveryWindows,
    hasSeatsWithSeatDeliveryAvailable,
    isDeliveryOptionBasedOnSeatingArea,
    foodAndBeverageDeliveryWindowsDisplayOption
  ) => {
    if (isDeliveryOptionBasedOnSeatingArea) {
      if (
        hasSeatsWithSeatDeliveryAvailable &&
        foodAndBeverageDeliveryWindowsDisplayOption !==
          FoodAndBeverageDeliveryWindowsDisplayOptionEnum.DoNotDisplayDeliveryWindows
      ) {
        return !!deliveryWindows?.length;
      } else {
        return false;
      }
    } else {
      return getRequiresDeliveryOptions(
        journeyType,
        config.currentCinema.foodAndBeverageConcessionsOnlyJourney,
        config.currentCinema.foodAndBeverageTicketingJourney,
        deliveryWindows,
        foodAndBeverageDeliveryWindowsDisplayOption
      );
    }
  }
);

export const selectDeliveryOptionWhenNoChoices = createSelector(
  selectJourneyTypeConfig,
  selectCurrentCinema,
  selectHasSeatsWithSeatDeliveryAvailable,
  (journeyTypeConfig, currentCinema, hasSeatsWithSeatDeliveryAvailable) => {
    const deliveryPreferenceForConcessionsOnly =
      currentCinema.foodAndBeverageConcessionsOnlyJourney ===
      FoodAndBeverageJourneyDeliveryType.OrderToSeatOnly
        ? DeliveryPreference.ORDER_TO_SEAT
        : DeliveryPreference.COLLECT_FROM_KIOSK;

    const deliveryPreferenceForTicketingJourney =
      currentCinema.foodAndBeverageTicketingJourney ===
        FoodAndBeverageJourneyDeliveryType.OrderToSeatOnly ||
      hasSeatsWithSeatDeliveryAvailable
        ? DeliveryPreference.ORDER_TO_SEAT
        : DeliveryPreference.COLLECT_FROM_KIOSK;

    return journeyTypeConfig.isConcessionsOnlyJourney
      ? deliveryPreferenceForConcessionsOnly
      : deliveryPreferenceForTicketingJourney;
  }
);

export const selectOrderToSeatOnly = createSelector(
  selectJourneyType,
  selectConfig,
  (journeyType, config) => {
    return getOrderToSeatOnly(
      journeyType,
      config.currentCinema.foodAndBeverageConcessionsOnlyJourney,
      config.currentCinema.foodAndBeverageTicketingJourney
    );
  }
);

export const selectCollectFromKioskOnly = createSelector(
  selectJourneyType,
  selectConfig,
  (journeyType, config) => {
    return getCollectFromKioskOnly(
      journeyType,
      config.currentCinema.foodAndBeverageConcessionsOnlyJourney,
      config.currentCinema.foodAndBeverageTicketingJourney
    );
  }
);

export const selectNumberOfSeatsToSelect = createSelector(
  selectSeatsModel,
  selectConfig,
  selectIsSeatsFirstJourney,
  (seatsModel, config, isSeatsFirstJourney) => {
    const getTotalSeats = (
      areaCategories: SeatsLayoutModel['areaCategories']
    ) => {
      return areaCategories.reduce((a, b) => a + (b.seatsToAllocate || 0), 0);
    };

    let numberOfSeatsToSelect;

    if (isSeatsFirstJourney) {
      numberOfSeatsToSelect = config.tickets.maxTicketsPerOrder
        ? config.tickets.maxTicketsPerOrder
        : seatsModel?.seatsLayoutModel?.maxSeatsToSelect ?? 0;
    } else if (seatsModel) {
      numberOfSeatsToSelect = getTotalSeats(
        seatsModel.seatsLayoutModel.areaCategories
      );
    } else {
      return 0;
    }

    return numberOfSeatsToSelect;
  }
);

export const selectSeatsAvailableInArea = createSelector(
  selectConfig,
  selectSeatsModel,
  selectIsSeatsFirstJourney,
  (_state: GlobalState, seatAreaCode: string) => seatAreaCode,
  (config, seatsModel, isSeatsFirstJourney, seatAreaCode) => {
    return getSeatsAvailableInArea(
      seatAreaCode,
      config,
      seatsModel,
      isSeatsFirstJourney
    );
  }
);

export const selectForgottenPasswordUrl = (state: GlobalState) => {
  if (!state.config) return '';
  if (
    state.source === 'app' &&
    state.config.signIn.forgottenPasswordUrlMobileApp
  ) {
    return state.config.signIn.forgottenPasswordUrlMobileApp;
  } else {
    return state.config.signIn.forgottenPasswordUrl;
  }
};

export const selectRegisterUrl = (state: GlobalState) => {
  if (!state.config) return '';
  if (state.source === 'app' && state.config.signIn.registerUrlMobileApp) {
    return state.config.signIn.registerUrlMobileApp;
  } else {
    return state.config.signIn.registerUrl;
  }
};

export const selectLoginUrl = (state: GlobalState) => {
  if (!state.config) return '';
  if (state.source === 'app' && state.config.signIn.loginUrlMobileApp) {
    return state.config.signIn.loginUrlMobileApp;
  } else {
    return state.config.signIn.loginUrl;
  }
};

export const selectIsHostedPaymentInProgress = (state: GlobalState) =>
  state.isHostedPaymentInProgress;

export const selectEGiftCardGroup = (state: GlobalState) => {
  return state.concessions?.find(
    (cg: ConcessionGrouping) => cg.title === 'egiftcards'
  );
};

export const selectPhysicalGiftCardGroup = (state: GlobalState) => {
  return state.concessions?.find(
    (cg: ConcessionGrouping) => cg.title === 'giftcards'
  );
};

export const selectMemberGiftCardGroup = (state: GlobalState) => {
  return state.concessions?.find(
    (cg: ConcessionGrouping) => cg.title === 'membergiftcards'
  );
};

export const selectEVouchersGroup = (state: GlobalState) => {
  return state.concessions?.find(
    (cg: ConcessionGrouping) => cg.title === 'eVouchers'
  );
};

export const selectEGiftCardImage = createSelector(
  selectEGiftCardGroup,
  selectSelectedGiftCards,
  selectContent,
  (eGiftCardGroup, selectedGiftCards, content) => {
    const onlyOneGiftCardOption =
      eGiftCardGroup?.items.length === 1 ? eGiftCardGroup.items[0] : null;
    const firstSelectedGiftCard: Concession | undefined =
      selectedGiftCards?.list.find((x) => x.quantity > 0);

    if (onlyOneGiftCardOption?.image) {
      return onlyOneGiftCardOption.image;
    }

    return firstSelectedGiftCard?.image
      ? firstSelectedGiftCard.image
      : content?.giftCards.defaultImage ?? '';
  }
);

export const selectContinueButtonText = createSelector(
  selectJourneyType,
  selectStep,
  selectContent,
  selectConfig,
  selectBookingData,
  (journeyType, step, content, config, bookingData) => {
    return getButtonText(journeyType, step, content, config, bookingData);
  }
);

export const selectUseDynamicTicket = createSelector(
  selectConfig,
  selectJourneyTypeConfig,
  (config, journeyTypeConfig) => {
    return config.useDynamicBasket && !journeyTypeConfig.isSeatsFirst;
  }
);

export const selectIsMultipleMemberCardTicketEnabled = (state: GlobalState) =>
  state.config.tickets?.enableMultipleLoyaltyCardTicketPurchase;

export const selectGuestSessionToken = (state: GlobalState) =>
  state.guestSessionToken;

export const selectShouldShowGuestMarketingOptions = createSelector(
  selectConfig,
  selectBookingData,
  (config, bookingData) => {
    return (
      config?.payment.enableGuestMarketing &&
      !(
        config.payment.hideGuestMarketingWhenLoggedIn &&
        bookingData.isUserValidated
      )
    );
  }
);

export const selectShowBookingInfo = createSelector(
  selectConfirmationData,
  selectJourneyTypeConfig,
  selectConcessionsJourneyParentBookingRef,
  (confirmationData, journeyTypeConfig, concessionsJourneyParentBookingRef) => {
    const showBookingInfoConfirmationData =
      confirmationData &&
      (confirmationData.orderType !== OrderType.Concessions ||
        (confirmationData.orderType === OrderType.Concessions &&
          !!concessionsJourneyParentBookingRef));

    const showBookingInfoNoConfirmationData =
      (confirmationData === null &&
        journeyTypeConfig.type !== JOURNEY_TYPES.CONCESSIONS_ONLY) ||
      (journeyTypeConfig.type === JOURNEY_TYPES.CONCESSIONS_ONLY &&
        !!concessionsJourneyParentBookingRef);

    return showBookingInfoConfirmationData || showBookingInfoNoConfirmationData;
  }
);

export const selectRecipientStateStrategy = (state: GlobalState) => {
  const showStateInput =
    state.config.giftCardsPurchase.useStateInDeliveryAddress ===
    GiftCardDeliveryStateTypeEnum.Enabled;
  const lookupState =
    state.config.giftCardsPurchase.useStateInDeliveryAddress ===
    GiftCardDeliveryStateTypeEnum.EnabledWithUsLookup;

  return { showStateInput, lookupState };
};

export const selectTicketBookingFeeInTicketsStep = createSelector(
  selectHideTax,
  selectUseBookingFeeStrategyInTickets,
  (_state: GlobalState, ticket: TicketTypeModel) => ticket,
  (hideTax, useBookingFeeStrategyInTickets, ticket) => {
    const { useBookingFeeStrategy, perItemFee } =
      useBookingFeeStrategyInTickets;

    let fee = 0;
    if (useBookingFeeStrategy) {
      fee = perItemFee;
    } else if (ticket.bookingFee) {
      fee = ticket.bookingFee;
      if (!hideTax) {
        fee -= ticket.bookingFeeTax;
      }
    }
    return fee;
  }
);

const selectPriceToDisplayInTicketsStep = createSelector(
  selectHideTax,
  shouldDisplayBookingFeeOnTicketsStep,
  (_state: GlobalState, price: number, tax: number, bookingFee: number) => {
    return { price, tax, bookingFee };
  },
  (hideTax, shouldDisplayAdditionalPrices, ticketWithBookingFee) => {
    const { price, tax, bookingFee } = ticketWithBookingFee;

    const priceWithHideTaxConfigAdjustment = hideTax ? price : price - tax;

    const ticketPriceIncludingFeeAndTax =
      priceWithHideTaxConfigAdjustment + bookingFee;

    const totalPriceToDisplay =
      bookingFee > 0 && shouldDisplayAdditionalPrices
        ? ticketPriceIncludingFeeAndTax
        : priceWithHideTaxConfigAdjustment;

    return totalPriceToDisplay;
  }
);

export const selectTotalPriceToDisplayInTicketsStep = createSelector(
  selectState,
  (_state: GlobalState, ticket: TicketTypeModel, bookingFee: number) => {
    return { ticket, bookingFee };
  },
  (state, ticketWithBookingFee) => {
    const { ticket, bookingFee } = ticketWithBookingFee;

    return selectPriceToDisplayInTicketsStep(
      state,
      ticket.price,
      ticket.tax,
      bookingFee
    );
  }
);

export const selectDisplayOriginalPrice = createSelector(
  selectConfig,
  (_state: GlobalState, ticket: TicketTypeModel) => ticket,
  (config, ticket) => {
    return (
      !!config?.tickets.displayOriginalPrice &&
      !!ticket.originalPrice &&
      ticket.price !== ticket.originalPrice
    );
  }
);

export const selectOriginalPriceToDisplayInTicketsStep = createSelector(
  selectState,
  (_state: GlobalState, ticket: TicketTypeModel, bookingFee: number) => {
    return { ticket, bookingFee };
  },
  (state, ticketWithBookingFee) => {
    const { ticket, bookingFee } = ticketWithBookingFee;

    if (!ticket.originalPrice) {
      return ticket.originalPrice;
    }

    return selectPriceToDisplayInTicketsStep(
      state,
      ticket.originalPrice,
      ticket.tax,
      bookingFee
    );
  }
);

export const selectHasCardPaymentPromoTickets = createSelector(
  selectTicketTypes,
  (ticketTypes: TicketTypes | null) => {
    if (!ticketTypes?.ticketTypeModels) return false;
    return ticketTypes.ticketTypeModels.some(
      (t) => t.isCardPaymentPromotionTicket === true
    );
  }
);

export const selectAppliedCardPaymentPromoBankCards = (state: GlobalState) =>
  state?.validatedCardPaymentPromoBankCards;

export const selectHasCardPaymentPromoTicketsInCart = createSelector(
  selectTicketTypes,
  (ticketTypes: TicketTypes | null) => {
    if (!ticketTypes?.ticketTypeModels) return false;
    return ticketTypes.ticketTypeModels.some(
      (t) => t.isCardPaymentPromotionTicket === true && t.quantity > 0
    );
  }
);

export const selectThirdPartyMembershipNames = (state: GlobalState) =>
  state?.thirdPartyMembershipNames;
