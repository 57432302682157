/** @jsxImportSource theme-ui */
import React from 'react';

import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { Box } from 'theme-ui';

import CartSummaryDiscount from './CartSummaryDiscount';
import CartSummaryFilmInfo from './CartSummaryFilmInfo';
import CartSummaryImage from './CartSummaryImage';
import CartSummaryItems from './CartSummaryItems';
import CartSummaryItemsRefund from './CartSummaryItemsRefund';
import CartSummaryLoyaltyRecognition from './CartSummaryLoyaltyRecognition';
import CartSummarySubtitle from './CartSummarySubtitle';
import CartSummaryTotal from './CartSummaryTotal';

import { JOURNEY_TYPES } from '../../../constants';
import { useScreenWidth } from '../../../contextProviders/screenWidthContext';
import {
  selectCartSummary,
  selectContent,
  selectEGiftCardImage,
  selectJourneyTypeConfig,
} from '../../../store/Selectors';
import SeasonPassCartSummaryImage from '../seasonpass/SeasonPassCartSummaryImage';

interface Props {
  hideTotal: boolean;
}

const CartSummary: React.FC<Props> = ({ hideTotal }) => {
  const { pathname } = useLocation();
  const { isLargeScreenWidth } = useScreenWidth();

  const cartSummary = useSelector(selectCartSummary);
  const content = useSelector(selectContent);
  const journeyTypeConfig = useSelector(selectJourneyTypeConfig);
  const eGiftCardImage = useSelector(selectEGiftCardImage);

  if (!cartSummary) return null;

  const hasShowtime = !!cartSummary.sessionDate;

  const isRefund = pathname.startsWith('/refund/');

  return (
    <Box
      className='sidebar'
      sx={{
        textAlign: 'center',
        px: 3,
        pt: isLargeScreenWidth ? '100px' : '40px',
        backgroundColor: 'sidebarBackground',
        color: 'mostReadableOnSidebarBackground',
        '.bottom-border': {
          borderBottom: 'sidebarSeparator',
        },
        '.cart-summary-row': {
          borderTop: 'sidebarSeparator',
        },
        '.deal-icon': {
          '.a': {
            fill: 'primary',
          },
          '.b': {
            fill: 'sidebarBackground',
          },
        },
        '.edit-col': {
          '.icon': {
            '.a': {
              fill: 'mostReadableOnSidebarBackground',
            },
          },
          '&:hover': {
            '.icon': {
              '.a': {
                fill: 'primary',
              },
            },
          },
        },
      }}
    >
      <Box className='cart-summary contained' data-testid='cart-summary'>
        <CartSummarySubtitle />
        <Box mt={5}>
          <div>
            {hasShowtime && <CartSummaryFilmInfo />}
            {journeyTypeConfig.type === JOURNEY_TYPES.GIFTCARDS_ONLY && (
              <CartSummaryImage
                image={eGiftCardImage}
                heading={content.giftCards.summaryGiftCardHeading}
              />
            )}
            {journeyTypeConfig.type ===
              JOURNEY_TYPES.PHYSICAL_GIFTCARDS_ONLY && (
              <CartSummaryImage
                image={content.physicalGiftCards.cartSummaryDefaultImage}
                heading={
                  content.physicalGiftCards.cartSummaryPhysicalGiftCardHeading
                }
              />
            )}
            {journeyTypeConfig.type === JOURNEY_TYPES.GIFTMEMBERSHIP_ONLY && (
              <CartSummaryImage
                image={content.giftMembership.cartSummaryDefaultImage}
                heading={
                  content.giftMembership.cartSummaryGiftMembershipHeading
                }
              />
            )}
            {journeyTypeConfig.type === JOURNEY_TYPES.LOYALTY_SIGNUP_TIERED && (
              <CartSummaryImage
                image={content.loyalty.defaultImage}
                heading={content.loyalty.signUpCartHeading}
              />
            )}
            {journeyTypeConfig.type === JOURNEY_TYPES.VOUCHERS_ONLY && (
              <CartSummaryImage
                image={content.vouchers.defaultImage}
                heading={content.vouchers.cartSummaryHeading}
              />
            )}

            {journeyTypeConfig.type === JOURNEY_TYPES.SEASON_PASS && (
              <SeasonPassCartSummaryImage />
            )}

            {isRefund ? (
              <CartSummaryItemsRefund />
            ) : (
              <CartSummaryItems hideTotal={hideTotal} />
            )}
          </div>
        </Box>
        <CartSummaryDiscount />
        <CartSummaryLoyaltyRecognition />
        <CartSummaryTotal hideTotal={hideTotal} isRefund={isRefund} />
      </Box>
    </Box>
  );
};

export default CartSummary;
