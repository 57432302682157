/** @jsxImportSource theme-ui */
import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Box, Flex } from 'theme-ui';

import { ChangeGiftCardByIdContext } from '../../../../@types/actionTypes';
import { Concession, ConcessionPricing } from '../../../../@types/modelTypes';
import { useScreenWidth } from '../../../../contextProviders/screenWidthContext';
import { isVariableCostInvalid } from '../../../../services/GiftCardHelpers';
import {
  displayPrice,
  getMatchingConcessionDeal,
  getMatchingConcessionDealDetails,
} from '../../../../services/Helpers';
import { findModifiedConcessionItemCost } from '../../../../services/KioskHelpers';
import { actionCreators } from '../../../../store/ActionCreators';
import {
  selectConfig,
  selectContent,
  selectCurrencyConfig,
  selectDeals,
  selectSelectedGiftCards,
} from '../../../../store/Selectors';
import { ReactComponent as DealSvg } from '../../../../svgs/deal.svg';
import ConcessionVariableInput from '../../concessionVariableInput/ConcessionVariableInput';
import QuantityButton from '../../quantitybutton/QuantityButton';
import RichText from '../../richtext/RichText';
import GiftCardImage from '../GiftCardImage';

interface Props extends Concession {
  defaultImage: string;
  totalCardsSelected: number;
}

const PhysicalConcessionItem: React.FC<Props> = ({
  id,
  code,
  description,
  extendedDescription,
  image,
  taxInCents,
  cost,
  quantity,
  modifierGroups,
  isVariablePriceItem,
  minVariableCost,
  maxVariableCost,
  defaultImage,
  totalCardsSelected,
}) => {
  const concession: Concession = {
    id,
    code,
    description,
    extendedDescription,
    image,
    taxInCents,
    cost,
    quantity,
    modifierGroups,
    modifiers: [],
    isVariablePriceItem,
    minVariableCost,
    maxVariableCost,
    hidden: false,
    variablePriceInCents: 0,
    orderDelivery: null,
    isSoldOut: false,
  };
  const dispatch = useDispatch();
  const { isExtraSmallScreenWidth, isMobileScreenWidth } = useScreenWidth();

  const config = useSelector(selectConfig);
  const content = useSelector(selectContent);
  const selectedConcessions = useSelector(selectSelectedGiftCards);
  const deals = useSelector(selectDeals);

  const [currentValue, setCurrentValue] = useState(
    concession?.isVariablePriceItem && concession.cost > 0
      ? (concession.cost / 100).toString()
      : ''
  );

  const currencyConfig = useSelector(selectCurrencyConfig);

  const hideTax: boolean = config.currentCinema.hideTax;

  const imagePath: string = concession.image
    ? concession.image.replace('https://', '')
    : defaultImage;

  const newValueInCents: number = Number(currentValue) * 100;

  const selectedConcession: Concession | undefined =
    concession.isVariablePriceItem
      ? selectedConcessions.list.find(
          (x) => x.id === concession.id && x.cost === newValueInCents
        )
      : selectedConcessions.list.find((x) => x.id === concession.id);

  const quantityInCart: number = selectedConcession
    ? selectedConcession.quantity
    : 0;

  const itemPricing: ConcessionPricing =
    findModifiedConcessionItemCost(concession);

  const isAddDisabled =
    totalCardsSelected >=
      config.giftCardsPurchase.maxQuantityOfPhysicalGiftCards ||
    (concession.isVariablePriceItem &&
      isVariableCostInvalid(concession, newValueInCents));

  const handleConcessionChange = (
    context: ChangeGiftCardByIdContext,
    concession: Concession
  ) => {
    if (concession.isVariablePriceItem) {
      concession.cost = newValueInCents;
      concession.variablePriceInCents = newValueInCents;
    }
    dispatch(actionCreators.changeGiftCardById(concession, context));
  };

  const renderButton = (
    context: ChangeGiftCardByIdContext,
    disabled: boolean
  ) => {
    return (
      <QuantityButton
        context={context}
        disabled={disabled}
        onClick={() => handleConcessionChange(context, concession)}
      />
    );
  };

  const renderQuantityButtons = () => {
    return (
      <Flex className='quantity-selector' sx={{ justifyContent: 'center' }}>
        <div className='quantity-button-container'>
          {renderButton('remove', quantityInCart === 0)}
        </div>
        <Flex
          className='quantity-col'
          sx={{
            px: 2,
            alignItems: 'center',
            justifyContent: 'center',
            fontWeight: 'bold',
          }}
        >
          {quantityInCart}
        </Flex>
        <div className='quantity-button-container'>
          {renderButton('add', !!isAddDisabled)}
        </div>
      </Flex>
    );
  };

  const concessionQuantitySelector = () => {
    const priceToDisplay = hideTax
      ? itemPricing.costIncTax
      : itemPricing.costMinusTax;
    const subTotal = (quantityInCart > 0 ? quantityInCart : 1) * priceToDisplay;
    return (
      <Box
        className='options-selector-container'
        sx={{ position: 'relative', width: '100%', textAlign: 'center' }}
      >
        <Box className='options-selector' sx={{ pr: [0, 0, '100px'] }}>
          {renderQuantityButtons()}
        </Box>
        <Box
          sx={{
            textAlign: 'right',
            alignItems: 'center',
            minWidth: [0, 0, '80px'],
            position: 'absolute',
            right: 2,
            top: '15px',
          }}
        >
          <span>
            {itemPricing.costIncTax > 0 &&
              displayPrice(subTotal, currencyConfig)}
          </span>
        </Box>
      </Box>
    );
  };

  useEffect(() => {
    const calculateDiscount = () => {
      let amountSaved = 0;
      selectedConcessions.list.forEach((c: Concession) => {
        const matchedConcessionDeal = getMatchingConcessionDealDetails(
          c.id,
          deals
        );
        if (matchedConcessionDeal && matchedConcessionDeal.amount) {
          const saving = c.cost - matchedConcessionDeal.amount;
          amountSaved += c.quantity * saving;
        }
      });
      return amountSaved;
    };
    if (selectedConcessions.list.length) {
      const dealsDiscountTotal = calculateDiscount();
      dispatch(actionCreators.setTotalDiscount(dealsDiscountTotal));
    }
  }, [deals, dispatch, selectedConcessions]);

  const matchingDeal = getMatchingConcessionDeal(id, deals);

  return (
    <Box
      className='concession'
      sx={{
        justifyContent: 'space-between',
        display: isMobileScreenWidth ? 'block' : 'flex',
        alignItems: 'center',
        py: 6,
        textAlign: 'center',
      }}
    >
      <Box>
        <Flex sx={{ justifyContent: 'flex-start' }}>
          <Box className='image-container' sx={{ pr: 4, position: 'relative' }}>
            {matchingDeal && <DealSvg className='deal-icon' />}
            <GiftCardImage
              imageUrl={imagePath || content.giftCards.defaultImage}
              isPhysicalGc
            />
          </Box>
          <Box className='details' sx={{ textAlign: 'left' }}>
            <div>
              {matchingDeal && (
                <Box
                  className='deal-text'
                  sx={{ mb: 2, textTransform: 'uppercase', fontWeight: 'bold' }}
                >
                  {matchingDeal.description}
                </Box>
              )}
              <span className='h3'>{concession.description}</span>
              {concession.extendedDescription && (
                <RichText
                  className='tiny'
                  styles={{ mt: 2, width: '100%', ul: { mb: 0 } }}
                  text={concession.extendedDescription}
                />
              )}
            </div>
            {concession.isVariablePriceItem && !isExtraSmallScreenWidth && (
              <ConcessionVariableInput
                concession={concession}
                currentValue={currentValue}
                setCurrentValue={setCurrentValue}
              />
            )}
          </Box>
        </Flex>
      </Box>

      {concession.isVariablePriceItem && isExtraSmallScreenWidth && (
        <Box>
          <ConcessionVariableInput
            concession={concession}
            currentValue={currentValue}
            setCurrentValue={setCurrentValue}
          />
        </Box>
      )}
      <Flex
        sx={{
          minWidth: 'unset',
          mt: isMobileScreenWidth ? 4 : 0,
          alignItems: concession.isVariablePriceItem ? 'flex-end' : 'center',
          justifyContent: 'center',
        }}
      >
        {concessionQuantitySelector()}
      </Flex>
    </Box>
  );
};

export default PhysicalConcessionItem;
