import React from 'react';

import { useSelector } from 'react-redux';
import { Flex } from 'theme-ui';

import { selectContent } from '../../../store/Selectors';

const ActionButtonSpinner: React.FC = () => {
  const content = useSelector(selectContent);

  return (
    <Flex sx={{ alignItems: 'center', justifyContent: 'center' }}>
      <span
        className='spinner-grow spinner-grow-sm spinner-icon'
        role='status'
        aria-hidden='true'
      />
      <span>{content.checkingButtonText}</span>
    </Flex>
  );
};

export default ActionButtonSpinner;
