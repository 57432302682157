import React from 'react';

import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { ThemeUIStyleObject } from 'theme-ui';

import { useScreenWidth } from '../../../contextProviders/screenWidthContext';
import { selectConfig } from '../../../store/Selectors';
import ContainedRow from '../../common/layout/ContainedRow';
import RichText from '../../common/richtext/RichText';
import messages from '../intl';

interface Props {
  styles?: ThemeUIStyleObject;
}

const CaptchaText: React.FC<Props> = ({ styles }) => {
  const { formatMessage } = useIntl();
  const { isMobileScreenWidth } = useScreenWidth();
  const config = useSelector(selectConfig);

  if (!config || !config.enableCaptcha || !isMobileScreenWidth) return null;

  return (
    <ContainedRow dataTestId='captcha-text' styles={styles}>
      <RichText
        className='rich-text-tiny'
        text={formatMessage(messages.captchaRichText)}
        styles={{ textAlign: 'center' }}
      />
    </ContainedRow>
  );
};

export default CaptchaText;
