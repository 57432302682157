import { useCallback, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { PaymentProvidersEnum } from '../@types/enums';
import { IntializePaymentProviderResponse } from '../@types/modelTypes';
import { PEACH_CODES } from '../constants';
import backend from '../services/RestUtilities';
import { actionCreators } from '../store/ActionCreators';
import {
  selectContent,
  selectToken,
  selectSelectedPaymentProvider,
} from '../store/Selectors';

type InitializeProviderResponses = {
  [key: string]: IntializePaymentProviderResponse | undefined;
};

export interface HookResponse {
  initializeProviderResponses: InitializeProviderResponses;
  requiresInitialization: boolean;
}
const providersRequiringInitPaymentProviderCall: PaymentProvidersEnum[] = [
  PaymentProvidersEnum.STRIPEPOPUP,
  PaymentProvidersEnum.SPREEDLY,
  PaymentProvidersEnum.PAYRIX,
  PaymentProvidersEnum.RTSHEARTLAND,
];

const defaultIntializePaymentProviderResponse: InitializeProviderResponses = {
  [PaymentProvidersEnum.STRIPEPOPUP]: undefined,
  [PaymentProvidersEnum.SPREEDLY]: undefined,
  [PaymentProvidersEnum.PAYRIX]: undefined,
  [PaymentProvidersEnum.RTSHEARTLAND]: undefined,
};

export const useInitializePaymentProvider = (
  paymentProvider: PaymentProvidersEnum,
  grandTotal: number
) => {
  const dispatch = useDispatch();
  const content = useSelector(selectContent);
  const token = useSelector(selectToken);
  const selectedPaymentProvider = useSelector(selectSelectedPaymentProvider);
  const [initializeProviderResponses, setInitializeProviderResponses] =
    useState<InitializeProviderResponses>(
      defaultIntializePaymentProviderResponse
    );

  const shouldCallInitPaymentProvider: boolean =
    grandTotal > 0 &&
    providersRequiringInitPaymentProviderCall.includes(paymentProvider);

  const requiresInitialization: boolean =
    shouldCallInitPaymentProvider &&
    !initializeProviderResponses[paymentProvider];

  const initializePaymentProvider = useCallback(async () => {
    const response = await backend.post(
      'api/Payment/InitializePaymentProvider',
      { dataToken: token, paymentProvider }
    );
    if (response.ok && response.content.peachCode === PEACH_CODES.noError) {
      setInitializeProviderResponses({
        ...initializeProviderResponses,
        [paymentProvider]: response.content.postValues,
      });
    } else {
      dispatch(actionCreators.setError(content.error.paymentDeclinedRichText));
    }
  }, [
    content.error.paymentDeclinedRichText,
    dispatch,
    initializeProviderResponses,
    paymentProvider,
    token,
  ]);

  useEffect(() => {
    if (selectedPaymentProvider === paymentProvider && requiresInitialization) {
      initializePaymentProvider();
    }
  }, [
    initializePaymentProvider,
    paymentProvider,
    requiresInitialization,
    selectedPaymentProvider,
  ]);

  return {
    initializeProviderResponses,
    requiresInitialization,
  } as HookResponse;
};
