/** @jsxImportSource theme-ui */
import React, { useEffect, useCallback } from 'react';

import { useCookies } from 'react-cookie';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';

import { DeliveryPreference } from '../../../@types/enums';
import { ConfirmationRequestModel } from '../../../@types/modelTypes';
import { BARCODE_FORMATS } from '../../../constants';
import { useScreenWidth } from '../../../contextProviders/screenWidthContext';
import { useConfirmationTracking } from '../../../hooks/useConfirmationTracking';
import {
  createConfirmation,
  displaySelectedSeatsNames,
  getConfirmationShouldShowBarcode,
} from '../../../services/Helpers';
import { getStepNumberForDisplay } from '../../../services/JourneyService';
import { buildQueryString } from '../../../services/QueryStringHelper';
import backend from '../../../services/RestUtilities';
import { actionCreators } from '../../../store/ActionCreators';
import {
  selectConfig,
  selectConfirmationData,
  selectContent,
  selectCustomer,
  selectDeliveryPreference,
  selectGuestSessionToken,
  selectHasSeatsWithSeatDeliveryAvailable,
  selectJourneyType,
  selectOrderToSeatOnly,
  selectSelectedDeliveryWindow,
  selectSelectedSeatNames,
  selectShowBookingInfo,
  selectStep,
  selectToken,
} from '../../../store/Selectors';
import ContainedRow from '../../common/layout/ContainedRow';
import RichText from '../../common/richtext/RichText';
import ShowtimeHeroConcessions from '../../common/showtimehero/ShowtimeHeroConcessions';

const ConcessionsConfirmation: React.FC = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const { orderId } = useParams();
  const [cookies] = useCookies();
  const { isLargeScreenWidth } = useScreenWidth();

  const config = useSelector(selectConfig);
  const confirmationData = useSelector(selectConfirmationData);
  const content = useSelector(selectContent);
  const customer = useSelector(selectCustomer);
  const selectedDeliveryWindow = useSelector(selectSelectedDeliveryWindow);
  const step = useSelector(selectStep);
  const selectedSeatNames = useSelector(selectSelectedSeatNames);
  const guestSessionToken = useSelector(selectGuestSessionToken);
  const token = useSelector(selectToken);
  const showBookingInfo = useSelector(selectShowBookingInfo);
  const journeyType = useSelector(selectJourneyType);
  const deliveryPreference = useSelector(selectDeliveryPreference);
  const isOrderToSeatOnly = useSelector(selectOrderToSeatOnly);
  const hasSeatsWithSeatDeliveryAvailable = useSelector(
    selectHasSeatsWithSeatDeliveryAvailable
  );

  useConfirmationTracking('Kiosk');

  const fetchData = useCallback(async () => {
    dispatch(actionCreators.setLoading(true));
    const qs = buildQueryString({
      circuitId: searchParams.get('circuitId') ?? undefined,
      languageCulture: cookies.preferredLanguageCulture,
    });
    const url = `api/Confirmation/${orderId}${qs}`;

    let body: ConfirmationRequestModel | undefined;

    if (guestSessionToken) {
      body = {
        DataToken: token,
        GuestSessionToken: guestSessionToken,
      };
    }

    const response = await backend.post(url, body);

    if (response.ok) {
      const data = response.content;
      const confirmation = createConfirmation(data, customer, journeyType);
      dispatch(actionCreators.setConfirmation(confirmation));
    }

    dispatch(actionCreators.setGuestSessionToken(undefined));
    dispatch(actionCreators.setLoading(false));
  }, [
    dispatch,
    searchParams,
    cookies.preferredLanguageCulture,
    orderId,
    guestSessionToken,
    token,
    customer,
    journeyType,
  ]);

  useEffect(() => {
    if (!confirmationData || confirmationData.externalOrderId !== orderId) {
      fetchData();
    }
  }, [confirmationData, orderId, fetchData]);

  if (!config || !content || !confirmationData) return null;

  const knownBarcodeFormat =
    BARCODE_FORMATS[config.currentCinema.barcodeFormat];
  const barcodeHeight = knownBarcodeFormat === 'QR' ? 150 : 60;
  const barcodeWidth = knownBarcodeFormat === 'QR' ? 150 : 264;
  const barcodeUrl = `/Image/GetBarcode/${confirmationData.externalOrderId}/${barcodeHeight}/${barcodeWidth}?circuitId=${config.circuitId}`;
  const shouldShowBarcode =
    knownBarcodeFormat !== 'None' &&
    knownBarcodeFormat !== undefined &&
    confirmationData.codeUsedInBarcode &&
    getConfirmationShouldShowBarcode(
      config.currentCinema.foodAndBeverageConcessionsOnlyJourneyShowBarcode,
      !!selectedDeliveryWindow
    );

  const showSummaryInShowtimeHero = isLargeScreenWidth;

  const shouldShowConfirmationNumber =
    config.currentCinema.showConfirmationId &&
    confirmationData.bookingConfirmationId !==
      confirmationData.codeUsedInBarcode &&
    !!confirmationData.bookingConfirmationId;

  const useDeliveryToSeatLabel =
    deliveryPreference === DeliveryPreference.ORDER_TO_SEAT ||
    isOrderToSeatOnly ||
    hasSeatsWithSeatDeliveryAvailable;

  return (
    <div
      className='confirmation'
      data-testid='concessions-confirmation'
      sx={{ textAlign: 'center' }}
    >
      <ContainedRow>
        <h1>
          {getStepNumberForDisplay(step)}
          {content.confirmation.title}
        </h1>
      </ContainedRow>

      <ContainedRow>
        <ShowtimeHeroConcessions
          barcodeUrl={barcodeUrl}
          displayBarcode={
            !selectedDeliveryWindow &&
            shouldShowBarcode &&
            showSummaryInShowtimeHero
          }
          displayDateTime={showBookingInfo}
        />
      </ContainedRow>

      {!selectedDeliveryWindow &&
        shouldShowBarcode &&
        !showSummaryInShowtimeHero && (
          <ContainedRow styles={{ mt: 6 }}>
            <img src={barcodeUrl} alt='concessions barcode' />
          </ContainedRow>
        )}

      <ContainedRow>
        <h2 sx={{ mt: 7 }}>
          {content.concessionsOnlyJourney.confirmationOrderDetailsHeading}
        </h2>
        <p>
          {confirmationData.codeUsedInBarcode && (
            <>
              {content.concessionsOnlyJourney.confirmationOrderNumberLabel}{' '}
              {confirmationData.codeUsedInBarcode}
            </>
          )}
          {config.currentCinema.showConfirmationId &&
            confirmationData.bookingConfirmationId !==
              confirmationData.codeUsedInBarcode && (
              <>
                <h2 sx={{ mt: 7 }}>
                  {content.confirmation.confirmationIdSubTitle}
                </h2>
                {shouldShowConfirmationNumber && (
                  <p>{confirmationData.bookingConfirmationId}</p>
                )}
              </>
            )}
          <br />
          {customer.email && (
            <>
              {content.concessionsOnlyJourney.confirmationOrderEmailLabel}{' '}
              {customer.email}
            </>
          )}
        </p>
      </ContainedRow>

      {selectedDeliveryWindow ? (
        <>
          <ContainedRow>
            <h2 sx={{ mt: 7 }}>{content.cartSummary.deliveryOptionLabel}</h2>
            <p>
              <span>
                {useDeliveryToSeatLabel
                  ? content.concessionsOnlyJourney.deliveryToSeatLabel
                  : content.concessionsOnlyJourney.collectFromKioskLabel}
                <span className='dot-separator'>·</span>
                {selectedDeliveryWindow.deliveryWindowDescription}
              </span>
              {showBookingInfo && (
                <>
                  <br />
                  <>
                    {content.cartSummary.yourSeatsLabel}{' '}
                    {selectedSeatNames &&
                      displaySelectedSeatsNames(selectedSeatNames)}
                  </>
                </>
              )}
            </p>
          </ContainedRow>

          <ContainedRow>
            <h2 sx={{ mt: 7 }}>
              {useDeliveryToSeatLabel
                ? content.concessionsOnlyJourney
                    .confirmationOrderToSeatDetailsHeading
                : content.concessionsOnlyJourney
                    .confirmationCollectFromKioskDetailsHeading}
            </h2>
            <RichText
              text={
                useDeliveryToSeatLabel
                  ? content.concessionsOnlyJourney
                      .confirmationOrderToSeatDetailsRichText
                  : content.concessionsOnlyJourney
                      .confirmationCollectFromKioskDetailsRichText
              }
            />
          </ContainedRow>
        </>
      ) : (
        <>
          <ContainedRow>
            <h2 sx={{ mt: 7 }}>
              {
                content.concessionsOnlyJourney
                  .confirmationCollectFromKioskHeading
              }
            </h2>
            <p>
              {content.concessionsOnlyJourney.confirmationCollectFromKioskText}
            </p>
            <p>
              {config.currentCinema.title}
              <br />
              {config.currentCinema.address1}
              <br />
              {config.currentCinema.city}
              {', '}
              {config.currentCinema.stateName} {config.currentCinema.zipCode}
            </p>
          </ContainedRow>

          <ContainedRow>
            <h2 sx={{ mt: 7 }}>
              {
                content.concessionsOnlyJourney
                  .confirmationCollectFromKioskDetailsHeading
              }
            </h2>
            <RichText
              text={
                content.concessionsOnlyJourney
                  .confirmationCollectFromKioskDetailsRichText
              }
            />
          </ContainedRow>
        </>
      )}

      <ContainedRow>
        <h2 sx={{ mt: 7 }}>
          {content.concessionsOnlyJourney.confirmationAdditionalContentHeading}
        </h2>
        <RichText
          text={
            content.concessionsOnlyJourney.confirmationAdditionalContentRichText
          }
        />
      </ContainedRow>

      {selectedDeliveryWindow && shouldShowBarcode && (
        <>
          <ContainedRow>
            <h2 sx={{ mt: 7 }}>
              {
                content.concessionsOnlyJourney
                  .confirmationOptionalCollectionHeading
              }
            </h2>
            <RichText
              text={
                content.concessionsOnlyJourney
                  .confirmationOptionalCollectionRichText
              }
            />
          </ContainedRow>

          <ContainedRow styles={{ mt: 6 }}>
            <img src={barcodeUrl} alt='concessions barcode' />
          </ContainedRow>
        </>
      )}
    </div>
  );
};

export default ConcessionsConfirmation;
