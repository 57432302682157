import { PayrixPayFields } from './PayrixPaymentTypes';

export const GetPayrixPayFieldStyle: () => PayrixPayFields['customizations']['style'] =
  () => ({
    '.input': {
      padding: '5px',
      marginBottom: '5px',
      width: '-webkit-fill-available',
      borderColor: '#666',
      borderStyle: 'solid',
      borderWidth: '1px',
    },
    '.card-icon': {
      top: '5px',
    },
    '.form-error': {
      color: 'red',
    },
    '.address1': {
      display: 'none',
    },
    '.address1-error': {
      display: 'none',
    },
    '.city': {
      display: 'none',
    },
    '.city-error': {
      display: 'none',
    },
    '.state': {
      display: 'none',
    },
    '.state-error': {
      display: 'none',
    },
    '.email': {
      display: 'none',
    },
    '.email-error': {
      display: 'none',
    },
    '.phone': {
      display: 'none',
    },
    '.phone-error': {
      display: 'none',
    },
  });
