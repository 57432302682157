/* eslint-disable no-console */
// docs at: https://github.com/globalpayments/globalpayments-js/tree/master/packages/globalpayments-js
// more help at: https://developer.globalpay.com/docs/javascript
// if we use this new version, the Heartlkand token object will change and we'll need to adjust Boost and Cypher!
// RTS example used 'https://api2.heartlandportico.com/SecureSubmit.v1/token/gp-1.3.0/globalpayments.js';

const loadHeartlandRts = (callback) => {
  const existingScript = document.getElementById('heartlandRts');
  if (!existingScript) {
    const script = document.createElement('script');
    script.src = 'https://js.globalpay.com/4.0.13/globalpayments.js';
    script.id = 'heartlandRts';

    document.body.appendChild(script);
    script.onload = () => {
      if (callback) callback();
    };
  } else {
    if (callback) callback();
  }
};
export default loadHeartlandRts;
