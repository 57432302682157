import { LanguageCulture } from '../@types/configTypes';

export const CURRENCY_LEFT = 'LEFT';
export const CURRENCY_RIGHT = 'RIGHT';
export const CURRENCY_ISO_CODES = {
  USD: '$',
  GBP: '£',
  EUR: '€',
  MXN: '$',
  ARS: '$',
  DKK: 'kr',
  AED: 'AED',
  JOD: 'JOD',
  BRL: 'R$',
};

type CurrencyCulture = {
  decimalSeperator: string;
  position: 'LEFT' | 'RIGHT';
  spaceBetween: boolean;
};

export type CurrencyCultures = {
  [key in LanguageCulture]: CurrencyCulture;
};
export const CURRENCY_CULTURES: CurrencyCultures = {
  'en-US': {
    decimalSeperator: '.',
    position: CURRENCY_LEFT,
    spaceBetween: false,
  },
  'en-GB': {
    decimalSeperator: '.',
    position: CURRENCY_LEFT,
    spaceBetween: false,
  },
  'fr-FR': {
    decimalSeperator: ',',
    position: CURRENCY_LEFT,
    spaceBetween: false,
  },
  'es-MX': {
    decimalSeperator: ',',
    position: CURRENCY_LEFT,
    spaceBetween: false,
  },
  'da-DK': {
    decimalSeperator: ',',
    position: CURRENCY_RIGHT,
    spaceBetween: true,
  },
  'pt-BR': {
    decimalSeperator: ',',
    position: CURRENCY_LEFT,
    spaceBetween: false,
  },
};
