/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import AdyenCheckout from '@adyen/adyen-web';
import '@adyen/adyen-web/dist/adyen.css';
import '../../../../../../scss/components/views/_adyenpayment.scss';
import { CardElementProps } from '@adyen/adyen-web/dist/types/components/Card/types';
import DropinElement from '@adyen/adyen-web/dist/types/components/Dropin';
import { CoreOptions } from '@adyen/adyen-web/dist/types/core/types';
import { Accordion } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { ValidateCardPaymentPromoBankCardNumberRequestModel } from '../../../../../../@types/modelTypes';
import { PEACH_CODES } from '../../../../../../constants';
import { useTurnstile } from '../../../../../../contextProviders/turnstileContext';
import useConfirmationPageRedirect from '../../../../../../hooks/useConfirmationPageRedirect';
import { getContentForError } from '../../../../../../services/PeachErrorResolver';
import backend from '../../../../../../services/RestUtilities';
import { actionCreators } from '../../../../../../store/ActionCreators';
import {
  selectBankCardAmount,
  selectConfig,
  selectContent,
  selectCustomer,
  selectHasCardPaymentPromoTicketsInCart,
  selectToken,
} from '../../../../../../store/Selectors';

interface Props {
  adyenConfig: CoreOptions | undefined;
  setAdyenConfig: Dispatch<SetStateAction<CoreOptions | undefined>>;
}

const Adyen: React.FC<Props> = ({ adyenConfig, setAdyenConfig }) => {
  const dispatch = useDispatch();
  useConfirmationPageRedirect();
  const turnstile = useTurnstile();

  const dataToken = useSelector(selectToken);
  const content = useSelector(selectContent);
  const config = useSelector(selectConfig);
  const customer = useSelector(selectCustomer);
  const bankCardAmount = useSelector(selectBankCardAmount);
  const hasCardPaymentPromotionTicketInCart = useSelector(
    selectHasCardPaymentPromoTicketsInCart
  );

  const [dropinElement, setDropinElement] = useState<
    DropinElement | undefined
  >();
  const paymentContainerRef =
    useRef() as React.MutableRefObject<HTMLInputElement>;

  const enteredBinRef = useRef<string | null>(null);

  const validateBin = useCallback(
    async (bin: string | null): Promise<boolean> => {
      if (!bin || bin.length < 6) {
        return false;
      }
      const turnstileToken = await turnstile?.getToken();
      const data: ValidateCardPaymentPromoBankCardNumberRequestModel = {
        dataToken,
        creditCardBin: bin,
      };

      try {
        const response = await backend.post(
          'api/Tickets/ValidateCardPaymentPromoBinForPaymentStep',
          data,
          turnstileToken
        );

        if (response.ok && response.content.peachCode === PEACH_CODES.noError) {
          return true;
        } else {
          return false;
        }
      } catch (error) {
        return false;
      } finally {
        turnstile?.resetToken();
      }
    },
    [turnstile, dataToken]
  );

  const handleSubmission = useCallback(
    async (state: any, component: any) => {
      if (!state.isValid || !adyenConfig) {
        return;
      }

      if (hasCardPaymentPromotionTicketInCart) {
        const isValidBin = await validateBin(enteredBinRef.current);
        if (!isValidBin) {
          dispatch(
            actionCreators.setError(
              getContentForError(
                PEACH_CODES.invalidCardPaymentPromoBankNumber,
                content
              ),
              PEACH_CODES.invalidCardPaymentPromoBankNumber
            )
          );
          component?.update();
          return;
        }
      }

      dispatch(
        actionCreators.adyenInitiatePayment({
          adyenStateData: state.data,
          adyenComponent: component,
          setAdyenConfig,
        })
      );
    },
    [
      adyenConfig,
      hasCardPaymentPromotionTicketInCart,
      dispatch,
      setAdyenConfig,
      validateBin,
      content,
    ]
  );

  const handleAdditionalDetails = useCallback(
    (state: any, component: any) => {
      dispatch(
        actionCreators.adyenSubmitAdditionalDetails({
          adyenStateData: state.data,
          adyenComponent: component,
          setAdyenConfig,
        })
      );
    },
    [dispatch, setAdyenConfig]
  );

  const createCheckout = useCallback(async () => {
    if (!adyenConfig) return;
    const adyenConfigToUse: CoreOptions = JSON.parse(
      JSON.stringify(adyenConfig)
    );

    const cardConfiguration: CardElementProps = {
      ...(adyenConfigToUse.paymentMethodsConfiguration?.card || {}),
      onBinValue: (binData: { binValue: string }) => {
        if (binData.binValue && binData.binValue.length >= 6) {
          enteredBinRef.current = binData.binValue;
        }
      },
    };

    const checkout = await AdyenCheckout({
      ...adyenConfigToUse,
      onSubmit: (state: any, component: any) => {
        void handleSubmission(state, component);
      },
      onAdditionalDetails: handleAdditionalDetails,
      amount: {
        value: bankCardAmount,
        currency: config.currency,
      },
      paymentMethodsConfiguration: {
        ...(adyenConfigToUse.paymentMethodsConfiguration ?? {}),
        card: cardConfiguration,
      },
    });

    if (paymentContainerRef.current) {
      const newDropin: DropinElement = checkout
        .create('dropin', {
          openFirstPaymentMethod: true,
          openFirstStoredPaymentMethod: false,
        })
        .mount(paymentContainerRef.current);
      setDropinElement(newDropin);
    }
  }, [
    adyenConfig,
    bankCardAmount,
    config.currency,
    handleAdditionalDetails,
    handleSubmission,
  ]);

  useEffect(() => {
    if (adyenConfig && customer.isValid && customer.email) {
      createCheckout();
    }
  }, [adyenConfig, createCheckout, customer]);

  return (
    <Accordion.Collapse in={dropinElement !== undefined} eventKey='0'>
      <div ref={paymentContainerRef} />
    </Accordion.Collapse>
  );
};

export default Adyen;
