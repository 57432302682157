/** @jsxImportSource theme-ui */
import React, { useState, useCallback, useEffect } from 'react';

import { Accordion } from 'react-bootstrap';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useDispatch, useSelector } from 'react-redux';

import { PaymentProvidersEnum } from '../../../../../../@types/enums';
import { HostedPayment } from '../../../../../../@types/modelTypes';
import { PEACH_CODES } from '../../../../../../constants';
import { useRecaptcha } from '../../../../../../contextProviders/recaptchaContext';
import { useTurnstile } from '../../../../../../contextProviders/turnstileContext';
import { createConfirmation } from '../../../../../../services/Helpers';
import { getContentForError } from '../../../../../../services/PeachErrorResolver';
import backend from '../../../../../../services/RestUtilities';
import { actionCreators } from '../../../../../../store/ActionCreators';
import {
  selectBankCardAmount,
  selectBankCardAmountDisplayPrice,
  selectConfig,
  selectContent,
  selectCountdownExpired,
  selectCustomer,
  selectGrandTotalAfterDiscountsInCents,
  selectHostedPayments,
  selectJourneyType,
  selectMakePaymentForHostedRequest,
  selectMaxRetriesExceeded,
  selectToken,
} from '../../../../../../store/Selectors';
import ActionButton from '../../../../actionbutton/ActionButton';
import StoredCardPayment from '../../storedcard/StoredCardPayment';

const iframeHeightInclError = 360;

const VantivIframeStoredCard: React.FC = () => {
  const dispatch = useDispatch();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const recaptcha = useRecaptcha();
  const turnstile = useTurnstile();
  const bankCardAmount = useSelector(selectBankCardAmount);
  const priceToDisplay = useSelector(selectBankCardAmountDisplayPrice);
  const dataToken = useSelector(selectToken);
  const content = useSelector(selectContent);
  const config = useSelector(selectConfig);
  const countdownExpired = useSelector(selectCountdownExpired);
  const customer = useSelector(selectCustomer);
  const [shouldStoreCardToken, setShouldStoreCardToken] =
    useState<boolean>(false);
  const maxRetriesExceeded = useSelector(selectMaxRetriesExceeded);
  const [
    selectedStoredCardTokenIdentifier,
    setSelectedStoredCardTokenIdentifier,
  ] = useState<string | null>(null);
  const hostedPayments = useSelector(selectHostedPayments);
  const makePaymentModel = useSelector(selectMakePaymentForHostedRequest);
  const grandTotal = useSelector(selectGrandTotalAfterDiscountsInCents);
  const journeyType = useSelector(selectJourneyType);
  const hostedPayment = hostedPayments[PaymentProvidersEnum.VANTIVHPIFRAME];
  const showExternalPaymentForm = hostedPayment !== null;

  // Stores the token which should be re-used when toggling the Should Store Card checkbox
  const [newStoredCardTokenIdentifier, setNewStoredCardTokenIdentifier] =
    useState<string | null>(null);

  // Disables the stored card checkbox while waiting for the API.
  const [
    shouldStoreCardTokenCheckboxDisabled,
    setShouldStoreCardTokenCheckboxDisabled,
  ] = useState<boolean>(false);

  const getPaymentProvider = useCallback(
    async (storedCardToken: string | null) => {
      if (!executeRecaptcha) return;

      const recaptchaToken = await recaptcha?.getRecaptchaToken(
        'HostedPaymentRequest',
        executeRecaptcha
      );

      const turnstileToken = await turnstile?.getToken();

      const response = await backend.post(
        'api/Payment/Hosted',
        {
          ...makePaymentModel,
          recaptchaToken: recaptchaToken ?? null,
          storedCardTokenIdentifier: storedCardToken,
          paymentProvider: PaymentProvidersEnum.VANTIVHPIFRAME,
        },
        turnstileToken
      );

      if (response.ok) {
        const responseContent = response.content;
        if (responseContent.peachCode === PEACH_CODES.noError) {
          const transactionId = responseContent.transactionId;
          const newHostedPayment: HostedPayment = {
            actionUrl: responseContent.actionUrl,
            transactionId,
            grandTotal,
            bankCardAmount,
            publishKey: response.content.postValues.PublishKey,
          };
          dispatch(
            actionCreators.setHostedPayment(
              PaymentProvidersEnum.VANTIVHPIFRAME,
              newHostedPayment,
              responseContent.guestSessionToken
            )
          );

          dispatch(actionCreators.setHostedPaymentInProgress(true));
          dispatch(actionCreators.setSendAnalytics(true));
          if (config.enableCountDown) {
            dispatch(
              actionCreators.setCountDown(responseContent.secondsToExpiration)
            );
          }
        } else {
          dispatch(
            actionCreators.setError(
              getContentForError(responseContent.peachCode, content),
              responseContent.peachCode
            )
          );
          dispatch(
            actionCreators.setHostedPayment(
              PaymentProvidersEnum.VANTIVHPIFRAME,
              null
            )
          );
        }
      } else {
        const getErrorMessage = (code: number) => {
          switch (code) {
            case PEACH_CODES.seatingCapacityExceeded:
            case PEACH_CODES.sessionSoldOut:
              return content.error.sessionSoldOutRichText;
            case PEACH_CODES.sessionNotBookable:
              return content.error.sessionNotBookableRichText;
            case PEACH_CODES.orderMaxRetriesReached:
              dispatch(actionCreators.setMaxRetriesExceeded(true));
              return content.error.invalidOrderErrorRichText;
            default:
              return content.error.unexpectedErrorRichText;
          }
        };
        dispatch(
          actionCreators.setHostedPayment(
            PaymentProvidersEnum.VANTIVHPIFRAME,
            null
          )
        );
        dispatch(actionCreators.setHostedPaymentInProgress(false));
        const errorMessage = getErrorMessage(response.content.peachCode);
        dispatch(
          actionCreators.setError(errorMessage, response.content.peachCode)
        );
      }

      turnstile?.resetToken();
    },
    [
      bankCardAmount,
      config.enableCountDown,
      content,
      dispatch,
      executeRecaptcha,
      grandTotal,
      makePaymentModel,
      recaptcha,
      turnstile,
    ]
  );

  const onPayWithStoredCardClick = async () => {
    dispatch(actionCreators.setLoading(true));
    await getPaymentProvider(selectedStoredCardTokenIdentifier);
    const postResponse = await backend.post('api/Payment/VHPStoredCard', {
      dataToken: dataToken,
    });

    const postResponseContent = postResponse.content;

    if (postResponseContent.peachCode === PEACH_CODES.noError) {
      const confirmation = createConfirmation(
        postResponseContent,
        customer,
        journeyType
      );
      dispatch(actionCreators.setSendAnalytics(true));
      dispatch(actionCreators.setConfirmation(confirmation));
    } else {
      dispatch(
        actionCreators.setError(
          getContentForError(postResponseContent.peachCode, content),
          postResponseContent.peachCode
        )
      );
    }
    dispatch(actionCreators.setLoading(false));
  };

  const refreshHostedPayment = useCallback(async () => {
    dispatch(actionCreators.setLoading(true));
    await getPaymentProvider(null);
    dispatch(actionCreators.setLoading(false));
  }, [dispatch, getPaymentProvider]);

  // clears hosted payment when required
  useEffect(() => {
    const amountToPayChanged =
      bankCardAmount !== hostedPayment?.bankCardAmount ||
      grandTotal !== hostedPayment?.grandTotal;

    if (hostedPayment && amountToPayChanged) {
      refreshHostedPayment();
    }
  }, [bankCardAmount, grandTotal, hostedPayment, refreshHostedPayment]);

  const handleNewPaymentFormShow = async () => {
    if (!hostedPayment) {
      await refreshHostedPayment();
    }
  };

  const createHostedPaymentTokenAsync = async (
    newShouldStoreCardToken: boolean
  ) => {
    setShouldStoreCardTokenCheckboxDisabled(true);

    const postResponse = await backend.post(
      'api/Payment/CreateHostedPaymentToken',
      {
        paymentProvider: PaymentProvidersEnum.VANTIVHPIFRAME,
        dataToken: dataToken,
        tokenIdentifier: newStoredCardTokenIdentifier,
        shouldStoreCardToken: newShouldStoreCardToken,
      }
    );

    setShouldStoreCardTokenCheckboxDisabled(false);

    const postResponseContent = postResponse.content;
    if (
      postResponseContent.peachCode === PEACH_CODES.noError &&
      postResponseContent.tokenIdentifier
    ) {
      setNewStoredCardTokenIdentifier(postResponseContent.tokenIdentifier);
    }
  };

  const handleShouldStoreCardTokenChanged = async (
    newShouldStoreCardToken: boolean
  ) => {
    if (!hostedPayment) {
      return;
    }

    await createHostedPaymentTokenAsync(newShouldStoreCardToken);
  };

  return (
    <div className='hosted-payment' data-testid='vantiv-hosted-payment'>
      <StoredCardPayment
        setSelectedStoredCardTokenIdentifier={
          setSelectedStoredCardTokenIdentifier
        }
        shouldStoreCardToken={shouldStoreCardToken}
        setShouldStoreCardToken={setShouldStoreCardToken}
        paymentProvider={PaymentProvidersEnum.VANTIVHPIFRAME}
        handleNewPaymentFormShow={handleNewPaymentFormShow}
        handleShouldStoreCardTokenChanged={handleShouldStoreCardTokenChanged}
        shouldStoreCardTokenCheckboxDisabled={
          shouldStoreCardTokenCheckboxDisabled
        }
      >
        {hostedPayment?.transactionId && !countdownExpired && (
          <Accordion.Collapse
            data-testid='vantiv-payment-iframe'
            eventKey='0'
            in={showExternalPaymentForm}
            className='vantiv-iframe'
          >
            <div sx={{ p: 4 }}>
              <iframe
                src={`${hostedPayment.actionUrl}?TransactionSetupID=${hostedPayment.transactionId}`}
                width='100%'
                scrolling='auto'
                frameBorder='0'
                style={{
                  minHeight: `${iframeHeightInclError}px`,
                }}
                title='WorldPay'
              />
            </div>
          </Accordion.Collapse>
        )}
      </StoredCardPayment>
      {selectedStoredCardTokenIdentifier && (
        <ActionButton
          onClick={onPayWithStoredCardClick}
          disabled={maxRetriesExceeded || !selectedStoredCardTokenIdentifier}
          showIcon
          mb={0}
          mt={4}
          variant='primary'
        >
          {`${content.payment.submitText} ${priceToDisplay}`}
        </ActionButton>
      )}
    </div>
  );
};

export default VantivIframeStoredCard;
