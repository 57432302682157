import React from 'react';

import { useSelector } from 'react-redux';
import { Box, Flex } from 'theme-ui';

import {
  concessionItemSxProp,
  descriptionSxProp,
  priceSxProp,
  quantitySxProp,
} from './constants';

import {
  Concession,
  ConcessionPricing,
  Modifier,
} from '../../../@types/modelTypes';
import { splitDeliveryItemFromConcessions } from '../../../services/GiftCardHelpers';
import { displayPrice } from '../../../services/Helpers';
import { findModifiedConcessionItemCost } from '../../../services/KioskHelpers';
import {
  selectCurrencyConfig,
  selectAllConcessions,
  selectSelectedGiftCards,
} from '../../../store/Selectors';

interface Props {
  hideTax: boolean;
}

const CartSummaryDeliveryItems: React.FC<Props> = ({ hideTax }) => {
  const currencyConfig = useSelector(selectCurrencyConfig);
  const concessions = useSelector(selectAllConcessions);
  const selectedGCConcessions = useSelector(selectSelectedGiftCards);

  const concessionWithModifiers = (
    c: Concession,
    hideTax: boolean,
    ci: number
  ) => {
    const itemPricing: ConcessionPricing = findModifiedConcessionItemCost(c);

    return (
      <>
        <Flex
          className='concession-item deliveryItem'
          sx={concessionItemSxProp}
        >
          <Flex sx={descriptionSxProp}>{c.description}</Flex>
          <Flex sx={{ alignItems: 'center' }}>
            <Box className='tickets-quantity' sx={quantitySxProp}>
              {c.quantity}
            </Box>
            <Box className='price' sx={priceSxProp}>
              {displayPrice(
                c.quantity *
                  (hideTax ? itemPricing.costIncTax : itemPricing.costMinusTax),
                currencyConfig
              )}
            </Box>
          </Flex>
        </Flex>
        {c.modifiers &&
          c.modifiers.map((m: Modifier, mi: number) => (
            <Box
              key={`c_${c.id}_${ci}_m_${m.id}_${mi}`}
              sx={{ textAlign: 'left' }}
            >
              {'- '}
              {m.description}
              {m.quantity > 1 && <span> x {m.quantity}</span>}
            </Box>
          ))}
      </>
    );
  };

  if (!selectedGCConcessions) {
    return null;
  }

  const { deliveryItem } = splitDeliveryItemFromConcessions(
    concessions,
    selectedGCConcessions
  );

  if (!deliveryItem) return null;

  return (
    <div className='cart-summary-row'>
      <div className='concessions-item-list' data-testid='cs-concessions'>
        {concessionWithModifiers(deliveryItem, hideTax, 0)}
      </div>
    </div>
  );
};

export default CartSummaryDeliveryItems;
