/** @jsxImportSource theme-ui */
import React, { useCallback } from 'react';

import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Flex } from 'theme-ui';

import messages from './intl';

import { actionCreators } from '../../../store/ActionCreators';
import {
  selectConfig,
  selectDazzlerLocale,
  selectGuestNewsletterSignUp,
} from '../../../store/Selectors';
import CheckBoxButton from '../../common/checkboxbutton/CheckBoxButton';
import ContainedRow from '../../common/layout/ContainedRow';
import { resolveLocalisedStringOrDefault } from '../helpers';
import { WidgetData } from '../types';

interface Props {
  widget: WidgetData<'TicketingCMSJourneyPurchaserDetailsWidget'>;
}

const GuestNewsletterSignUpCheckbox: React.FC<Props> = ({ widget }) => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const configPayment = useSelector(selectConfig)?.payment;
  const guestNewsletterSignUp = useSelector(selectGuestNewsletterSignUp);
  const locale = useSelector(selectDazzlerLocale);

  const handleNewsLetterClick = useCallback(() => {
    dispatch(actionCreators.setGuestNewsletterSignUp(!guestNewsletterSignUp));
  }, [dispatch, guestNewsletterSignUp]);

  if (!configPayment) return null;

  return (
    <ContainedRow classNameWrapper='guest-marketing-wrapper'>
      <Flex
        className='checkbox-with-text'
        sx={{
          justifyContent: 'space-between',
          alignItems: 'center',
          mt: 4,
          mx: 2,
        }}
      >
        <Flex sx={{ alignItems: 'center', mr: 2 }}>
          <CheckBoxButton
            checked={guestNewsletterSignUp}
            onClick={handleNewsLetterClick}
          />
        </Flex>
        <Flex sx={{ alignItems: 'center', flexGrow: 1 }}>
          <p
            className='tiny'
            data-testid='crm-newsletter'
            sx={{
              textAlign: 'left',
              m: 0,
              fontWeight: guestNewsletterSignUp ? 'bold' : 'normal',
            }}
          >
            {resolveLocalisedStringOrDefault(
              formatMessage(messages.guestNewsletterSignUpCheckboxText),
              locale,
              widget.shape?.guestNewsletterSignUpCheckboxText
            )}
          </p>
        </Flex>
      </Flex>
    </ContainedRow>
  );
};

export default GuestNewsletterSignUpCheckbox;
