/** @jsxImportSource theme-ui */
import React, { useEffect, useState } from 'react';

import moment from 'moment';
import { Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Grid } from 'theme-ui';
import { isLength } from 'validator';

import { Customer } from '../../../@types/modelTypes';
import { isBansheeSafeEmail, validateZipCode } from '../../../services/Helpers';
import { actionCreators } from '../../../store/ActionCreators';
import {
  selectBookingData,
  selectConfig,
  selectContent,
  selectCustomer,
} from '../../../store/Selectors';
import ContainedRow from '../layout/ContainedRow';
import ConfirmPasswordInput from '../login/ConfirmPasswordInput';
import PasswordInput from '../login/PasswordInput';
import RichText from '../richtext/RichText';
import DateOfBirthOptions from '../signup/DateOfBirthOptions';
import TermsAndConditions from '../termsandconditions/TermsAndConditions';

const CustomerDetailsSignup: React.FC = () => {
  const dispatch = useDispatch();

  const bookingData = useSelector(selectBookingData);
  const { currentCinema } = useSelector(selectConfig);
  const config = useSelector(selectConfig);
  const content = useSelector(selectContent);
  const customer = useSelector(selectCustomer);

  const [customerState, setCustomerState] = useState<Customer>({
    name: customer.name ?? '',
    nameIsValid: !!customer.nameIsValid,
    nameIsValidated: false,
    email: customer.email ?? '',
    emailIsValid: !!customer.emailIsValid,
    emailIsValidated: false,
    confirmEmail: customer.confirmEmail ?? '',
    confirmEmailIsValid: !!customer.confirmEmailIsValid,
    confirmEmailIsValidated: false,
    telephone: customer.telephone ?? '',
    telephoneIsValid: !!customer.telephoneIsValid,
    telephoneIsValidated: false,
    phoneNumber: customer.telephone ?? '',
    zipCode: customer.zipCode ?? '',
    zipCodeIsValid: !!customer.zipCodeIsValid,
    zipCodeIsValidated: false,
    dateOfBirth: customer.dateOfBirth ?? '',
    dateOfBirthIsValid: !!customer.dateOfBirthIsValid,
    dateOfBirthIsValidated: false,
    password: customer.password ?? '',
    passwordIsValid: !!customer.passwordIsValid,
    passwordIsValidated: false,
    confirmPassword: customer.confirmPassword ?? '',
    confirmPasswordIsValid: !!customer.confirmPasswordIsValid,
    confirmPasswordIsValidated: false,
    day: customer.day ?? '',
    dayIsValidated: false,
    month: customer.month ?? '',
    monthIsValidated: false,
    year: customer.year ?? '',
    yearIsValidated: false,
    isValid: false,
  });

  const zipCodeMin = currentCinema.isZipCodeRequired ? 1 : 0;

  // if user is logged in sets validation
  useEffect(() => {
    if (bookingData?.isUserValidated) {
      setCustomerState({
        name: customer.name ?? '',
        nameIsValid: !!customer.nameIsValid,
        nameIsValidated: false,
        email: customer.email ?? '',
        emailIsValid: !!customer.emailIsValid,
        emailIsValidated: false,
        confirmEmail: customer.confirmEmail ?? '',
        confirmEmailIsValid: !!customer.confirmEmailIsValid,
        confirmEmailIsValidated: false,
        telephone: customer.telephone ?? '',
        telephoneIsValid: !!customer.telephoneIsValid,
        telephoneIsValidated: false,
        phoneNumber: customer.telephone ?? '',
        zipCode: customer.zipCode ?? '',
        zipCodeIsValid: !!customer.zipCodeIsValid,
        zipCodeIsValidated: false,
        dateOfBirth: customer.dateOfBirth ?? '',
        dateOfBirthIsValid: !!customer.dateOfBirthIsValid,
        dateOfBirthIsValidated: false,
        password: customer.password ?? '',
        passwordIsValid: !!customer.passwordIsValid,
        passwordIsValidated: false,
        confirmPassword: customer.confirmPassword ?? '',
        confirmPasswordIsValid: !!customer.confirmPasswordIsValid,
        confirmPasswordIsValidated: false,
        day: customer.day ?? '',
        dayIsValidated: false,
        month: customer.month ?? '',
        monthIsValidated: false,
        year: customer.year ?? '',
        yearIsValidated: false,
        isValid: false,
      });
    }
  }, [
    bookingData?.isUserValidated,
    customer.confirmEmail,
    customer.confirmEmailIsValid,
    customer.email,
    customer.emailIsValid,
    customer.name,
    customer.nameIsValid,
    customer.telephone,
    customer.telephoneIsValid,
    customer.zipCode,
    customer.zipCodeIsValid,
    customer.dateOfBirth,
    customer.dateOfBirthIsValid,
    customer.password,
    customer.passwordIsValid,
    customer.confirmPassword,
    customer.confirmPasswordIsValid,
    customer.day,
    customer.month,
    customer.year,
  ]);

  const handleCustomerChange = (nextCustomerState: Customer) => {
    const isValid =
      nextCustomerState.nameIsValid &&
      nextCustomerState.confirmEmailIsValid &&
      nextCustomerState.emailIsValid &&
      nextCustomerState.telephoneIsValid &&
      nextCustomerState.dateOfBirthIsValid &&
      nextCustomerState.passwordIsValid &&
      nextCustomerState.confirmPasswordIsValid &&
      (!currentCinema.captureZipCode || nextCustomerState.zipCodeIsValid);

    setCustomerState({
      ...nextCustomerState,
      isValid,
    });
    dispatch(
      actionCreators.setCustomerDetails({
        ...nextCustomerState,
        isValid,
      })
    );
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.currentTarget.value;
    const nameIsValid = isLength(name, { min: 1, max: 50 });

    handleCustomerChange({
      ...customerState,
      name,
      nameIsValid,
    });
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const email = e.currentTarget.value.trim().toLowerCase();
    const emailIsValid = isBansheeSafeEmail(email);

    handleCustomerChange({
      ...customerState,
      email,
      emailIsValid,
    });
  };

  const handleConfirmEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const confirmEmail = e.currentTarget.value.trim().toLowerCase();
    const confirmEmailIsValid =
      isBansheeSafeEmail(confirmEmail) &&
      confirmEmail === customerState.email &&
      confirmEmail !== '';

    handleCustomerChange({
      ...customerState,
      confirmEmail,
      confirmEmailIsValid,
    });
  };
  const handleMonthChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const month = e.currentTarget.value;
    const dateOfBirthIsValid =
      moment().diff(
        moment(
          `${customer.day}-${month.padStart(2, '0')}-${customer.year}`,
          'DD-MM-YYYY'
        ),
        'years'
      ) >= config.loyalty.signupMinimumAge;

    handleCustomerChange({
      ...customerState,
      month,
      dateOfBirthIsValid,
      dateOfBirth: `${customerState.year}-${month.padStart(
        2,
        '0'
      )}-${customerState.day?.padStart(2, '0')}T00:00:00`,
    });
  };
  const handleDayChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const day = e.currentTarget.value;
    const dateOfBirthIsValid =
      moment().diff(
        moment(
          `${day.padStart(2, '0')}-${customer.month}-${customer.year}`,
          'DD-MM-YYYY'
        ),
        'years'
      ) >= config.loyalty.signupMinimumAge;

    handleCustomerChange({
      ...customerState,
      day,
      dateOfBirthIsValid,
      dateOfBirth: `${customerState.year}-${customerState.month?.padStart(
        2,
        '0'
      )}-${day.padStart(2, '0')}T00:00:00`,
    });
  };

  const handleYearChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const year = e.currentTarget.value;
    const dateOfBirthIsValid =
      moment().diff(
        moment(`${customer.day}-${customer.month}-${year}`, 'DD-MM-YYYY'),
        'years'
      ) >= config.loyalty.signupMinimumAge;

    handleCustomerChange({
      ...customerState,
      year,
      dateOfBirthIsValid,
      dateOfBirth: `${year}-${customerState.month?.padStart(
        2,
        '0'
      )}-${customerState.day?.padStart(2, '0')}T00:00:00`,
    });
  };
  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const password = e.currentTarget.value;
    const passwordIsValid = isLength(password, { min: 1, max: 50 });

    handleCustomerChange({
      ...customerState,
      password,
      passwordIsValid,
    });
  };
  const handleConfirmPasswordChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const confirmPassword = e.currentTarget.value;
    const confirmPasswordIsValid =
      confirmPassword === customerState.password && confirmPassword !== '';

    handleCustomerChange({
      ...customerState,
      confirmPassword,
      confirmPasswordIsValid,
    });
  };
  const handleTelephoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const telephone = e.currentTarget.value;
    const telephoneIsValid = isLength(telephone, { min: 1, max: 50 });

    handleCustomerChange({
      ...customerState,
      telephone,
      telephoneIsValid,
      phoneNumber: telephone,
    });
  };

  const handleZipCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const zipCode = e.currentTarget.value;
    const zipCodeIsValid = validateZipCode(zipCode, zipCodeMin);

    handleCustomerChange({
      ...customerState,
      zipCode,
      zipCodeIsValid,
    });
  };

  if (!content) {
    return null;
  }

  return (
    <div className='customer-details' data-testid='customer-details'>
      <Form noValidate sx={{ mt: 2, textAlign: 'left' }}>
        <Form.Group sx={{ mb: 4 }}>
          <Form.Label htmlFor='fullName'>
            {content.payment.fullNameLabel}
          </Form.Label>
          <Form.Control
            type='text'
            placeholder={content.payment.fullNamePlaceholder}
            onChange={handleNameChange}
            required
            maxLength={50}
            value={customerState.name}
            isInvalid={
              customerState.nameIsValidated && !customerState.nameIsValid
            }
            isValid={customerState.nameIsValidated && customerState.nameIsValid}
            onBlur={() =>
              setCustomerState({ ...customerState, nameIsValidated: true })
            }
            id='fullName'
            name='fullName'
          />
          <Form.Control.Feedback type='invalid'>
            {content.payment.fullNameValidationText}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group sx={{ mb: 4 }}>
          <Form.Label htmlFor='email'>{content.payment.emailLabel}</Form.Label>
          <Form.Control
            type='email'
            placeholder={content.payment.emailPlaceholder}
            onChange={handleEmailChange}
            value={customerState.email}
            required
            isInvalid={
              customerState.emailIsValidated && !customerState.emailIsValid
            }
            isValid={
              customerState.emailIsValidated && customerState.emailIsValid
            }
            onBlur={() =>
              setCustomerState({ ...customerState, emailIsValidated: true })
            }
            id='email'
            name='email'
          />
          <Form.Control.Feedback type='invalid'>
            {content.payment.emailValidationText}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group sx={{ mb: 4 }}>
          <Form.Label htmlFor='confirmEmail'>
            {content.payment.confirmEmailLabel}
          </Form.Label>
          <Form.Control
            required
            type='email'
            placeholder={content.payment.confirmEmailPlaceholder}
            onChange={handleConfirmEmailChange}
            value={customerState.confirmEmail}
            isInvalid={
              customerState.confirmEmailIsValidated &&
              !customerState.confirmEmailIsValid
            }
            isValid={
              customerState.confirmEmailIsValidated &&
              customerState.confirmEmailIsValid
            }
            onBlur={() =>
              setCustomerState({
                ...customerState,
                confirmEmailIsValidated: true,
              })
            }
            onPaste={(e: React.ClipboardEvent<HTMLInputElement>) =>
              e.preventDefault()
            }
            id='confirmEmail'
            name='confirmEmail'
          />
          <Form.Control.Feedback type='invalid'>
            {content.payment.confirmEmailValidationText}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group sx={{ mb: 4 }} data-testid='telephone'>
          <Form.Label htmlFor='telephone'>
            {content.payment.telephoneLabel}
          </Form.Label>
          <Form.Control
            type='text'
            placeholder={content.payment.telephonePlaceholder}
            onChange={handleTelephoneChange}
            required
            maxLength={50}
            value={customerState.telephone}
            isInvalid={
              customerState.telephoneIsValidated &&
              !customerState.telephoneIsValid
            }
            isValid={
              customerState.telephoneIsValidated &&
              customerState.telephoneIsValid
            }
            onBlur={() =>
              setCustomerState({
                ...customerState,
                telephoneIsValidated: true,
              })
            }
            id='telephone'
            name='telephone'
          />
          <Form.Control.Feedback type='invalid'>
            {content.payment.telephoneValidationText}
          </Form.Control.Feedback>
        </Form.Group>

        {currentCinema.captureZipCode && (
          <Form.Group sx={{ mb: 4 }} data-testid='zipCode'>
            <Form.Label htmlFor='zipCode'>
              {content.payment.zipCodeLabel}
            </Form.Label>
            <Form.Control
              type='text'
              placeholder={content.payment.zipCodePlaceholder}
              onChange={handleZipCodeChange}
              maxLength={8}
              value={customerState.zipCode}
              isInvalid={
                customerState.zipCodeIsValidated &&
                !customerState.zipCodeIsValid
              }
              isValid={
                customerState.zipCodeIsValidated && customerState.zipCodeIsValid
              }
              onBlur={() =>
                setCustomerState({
                  ...customerState,
                  zipCodeIsValidated: true,
                })
              }
              id='zipCode'
              name='zipCode'
              required
            />
            <Form.Control.Feedback type='invalid'>
              {content.payment.zipCodeValidationText}
            </Form.Control.Feedback>
          </Form.Group>
        )}

        <Form.Group sx={{ mb: 4 }}>
          <Form.Label>{content.loyalty.signupDateOfBirthLabel}</Form.Label>
          <Grid columns={3}>
            <Form.Select
              value={customerState.month}
              required
              className={'form-control'}
              onChange={handleMonthChange}
              isInvalid={
                customerState.monthIsValidated &&
                customerState.dayIsValidated &&
                customerState.yearIsValidated &&
                !customerState.dateOfBirthIsValid
              }
              isValid={
                customerState.monthIsValidated &&
                customerState.dateOfBirthIsValid
              }
              onBlur={() =>
                setCustomerState({
                  ...customerState,
                  monthIsValidated: true,
                })
              }
              id='dateOfBirthMonth'
              name='dateOfBirthMonth'
            >
              <DateOfBirthOptions type={'month'} />
            </Form.Select>
            <Form.Select
              value={customerState.day}
              className={'form-control'}
              onChange={handleDayChange}
              required
              isInvalid={
                customerState.monthIsValidated &&
                customerState.dayIsValidated &&
                customerState.yearIsValidated &&
                !customerState.dateOfBirthIsValid
              }
              isValid={
                customerState.dayIsValidated && customerState.dateOfBirthIsValid
              }
              onBlur={() =>
                setCustomerState({
                  ...customerState,
                  dayIsValidated: true,
                })
              }
              id='dateOfBirthDay'
              name='dateOfBirthDay'
            >
              <DateOfBirthOptions type={'day'} />
            </Form.Select>
            <Form.Select
              onChange={handleYearChange}
              className={'form-control'}
              value={customerState.year}
              required
              isInvalid={
                customerState.monthIsValidated &&
                customerState.dayIsValidated &&
                customerState.yearIsValidated &&
                !customerState.dateOfBirthIsValid
              }
              isValid={
                customerState.yearIsValidated &&
                customerState.dateOfBirthIsValid
              }
              onBlur={() =>
                setCustomerState({
                  ...customerState,
                  yearIsValidated: true,
                })
              }
              id='dateOfBirthYear'
              name='dateOfBirthYear'
            >
              <DateOfBirthOptions type={'year'} />
            </Form.Select>
          </Grid>
          {!customer.dateOfBirthIsValid &&
            customer.monthIsValidated &&
            customer.dayIsValidated &&
            customer.yearIsValidated && (
              <Form.Group sx={{ mb: 4 }}>
                <div className='is-invalid' />
                <div className='invalid-feedback'>
                  {content.loyalty.signupDateOfBirthInvalid}
                </div>
              </Form.Group>
            )}
        </Form.Group>
        <ContainedRow styles={{ mt: 5 }}>
          <h2 sx={{ textAlign: 'center' }}>
            {content.loyalty.signUpPasswordHeading}
          </h2>
          <RichText text={content.loyalty.signupPasswordRichText} />
        </ContainedRow>
        <RichText
          className='tiny'
          styles={{ mt: 4 }}
          text={content.signIn.signUpPasswordRequirementsRichText}
        />
        <PasswordInput
          handlePasswordChange={handlePasswordChange}
          value={customerState.password ?? ''}
          isInvalid={
            !!(
              customerState.passwordIsValidated &&
              !customerState.passwordIsValid
            )
          }
          isValid={
            !!(
              customerState.passwordIsValidated && customerState.passwordIsValid
            )
          }
          onBlur={() =>
            setCustomerState({
              ...customerState,
              passwordIsValidated: true,
            })
          }
        />
        <ConfirmPasswordInput
          handlePasswordChange={handleConfirmPasswordChange}
          value={customerState.confirmPassword ?? ''}
          isInvalid={
            !!(
              customerState.confirmPasswordIsValidated &&
              !customerState.confirmPasswordIsValid
            )
          }
          isValid={
            !!(
              customerState.confirmPasswordIsValidated &&
              customerState.confirmPasswordIsValid
            )
          }
          onBlur={() =>
            setCustomerState({
              ...customerState,
              confirmPasswordIsValidated: true,
            })
          }
        />

        <Box className='separator' mt={6} />
        <TermsAndConditions
          isSignUp
          isPageValidated={!!customerState.isValid}
        />
      </Form>
    </div>
  );
};

export default CustomerDetailsSignup;
