import React from 'react';

import Image from 'react-bootstrap/Image';
import { useSelector } from 'react-redux';

import {
  selectImageProcessorUrl,
  selectImageProcessorContainer,
} from '../../../store/Selectors';
import { ReactComponent as GiftCardLargeSvg } from '../../../svgs/giftcardLarge.svg';

interface Props {
  imageUrl: string | null | undefined;
}
const TierImage: React.FC<Props> = ({ imageUrl }) => {
  const imageProcessorUrl = useSelector(selectImageProcessorUrl);
  const imageProcessorContainer = useSelector(selectImageProcessorContainer);

  const blobImage: string | null = imageUrl
    ? `${imageProcessorUrl}/${imageProcessorContainer}/${imageUrl.replace(
        'https://',
        ''
      )}?width=200`
    : '';

  return (
    <>
      {imageUrl ? (
        <Image src={blobImage} alt={'GiftCard'} rounded />
      ) : (
        <GiftCardLargeSvg className='fallback' />
      )}
    </>
  );
};

export default TierImage;
