/** @jsxImportSource theme-ui */
import React, { useState } from 'react';

import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Box } from 'theme-ui';

import { Concession } from '../../../@types/modelTypes';
import useConfirmationPageRedirect from '../../../hooks/useConfirmationPageRedirect';
import { useValidateConcessionsJourney } from '../../../hooks/useValidateConcessionsJourney';
import {
  selectBookingData,
  selectConfig,
  selectCustomer,
  selectSelectedFaBConcessions,
  selectVoucherRecipient,
  selectContentVouchers,
  selectEVouchersGroup,
} from '../../../store/Selectors';
import ActionButton from '../../common/actionbutton/ActionButton';
import ContainedRow from '../../common/layout/ContainedRow';
import SplitPaymentDesign from '../../common/payment/design/SplitPaymentDesign';
import RichText from '../../common/richtext/RichText';
import TermsAndConditions from '../../common/termsandconditions/TermsAndConditions';
import PaymentSummaryRow from '../../common/voucherstore/PaymentSummaryRow';

const VouchersPayment: React.FC = () => {
  const navigate = useNavigate();
  useValidateConcessionsJourney();
  const bookingData = useSelector(selectBookingData);
  const config = useSelector(selectConfig);
  const contentVouchers = useSelector(selectContentVouchers);
  const customer = useSelector(selectCustomer);
  const voucherRecipient = useSelector(selectVoucherRecipient);

  const selectedConcessions = useSelector(selectSelectedFaBConcessions);
  const eVoucherGroup = useSelector(selectEVouchersGroup);
  const [validateForms, setValidateForms] = useState(false);
  const [creditCardType, setCreditCardType] = useState('');

  useConfirmationPageRedirect();

  const setPaymentFormVisibility = (nextShowPaymentForm: boolean) => {
    setValidateForms(!nextShowPaymentForm);
  };

  const getVoucherRows = () => {
    if (eVoucherGroup === undefined) return null;

    return selectedConcessions.list.map((c: Concession, i: number) => (
      <PaymentSummaryRow selectedConcessionIndex={i} key={`voucherrow_${i}`} />
    ));
  };

  if (!contentVouchers || !config || !bookingData) return null;

  return (
    <div className='payment' data-testid='payment' sx={{ textAlign: 'center' }}>
      <div className='giftcards'>
        <ContainedRow>
          <h1 sx={{ textAlign: 'center' }}>{contentVouchers.paymentHeading}</h1>
          <RichText text={contentVouchers.paymentRichText} />
        </ContainedRow>

        <Box px={[4, 4, 0]}>
          <Box className='position-relative' sx={{ mt: 6 }}>
            <div className='separator' />
            <div className='summary-rows' sx={{ mt: 5 }}>
              {getVoucherRows()}
            </div>
            <div
              className='recipient-details summary tiny'
              sx={{ pb: 5, textAlign: 'left' }}
            >
              <div className='contained detail' sx={{ mt: 4, px: [0, 0, 5] }}>
                <div>
                  <span sx={{ fontWeight: 'bold' }}>
                    {contentVouchers.paymentRecipientNameLabel}
                  </span>{' '}
                  <span>{voucherRecipient.name}</span>
                </div>
                <div>
                  <span sx={{ fontWeight: 'bold' }}>
                    {contentVouchers.paymentRecipientEmailLabel}
                  </span>{' '}
                  <span>{voucherRecipient.email}</span>
                </div>

                <div sx={{ mt: 2 }}>
                  <span sx={{ fontWeight: 'bold' }}>
                    {contentVouchers.paymentBillingNameLabel}
                  </span>{' '}
                  <span>{customer.name}</span>
                </div>

                <div>
                  <span sx={{ fontWeight: 'bold' }}>
                    {contentVouchers.paymentBillingEmailLabel}
                  </span>{' '}
                  <span>{customer.email}</span>
                </div>
                {voucherRecipient.message && (
                  <div sx={{ mt: 2 }}>
                    <span sx={{ fontWeight: 'bold' }}>
                      {contentVouchers.paymentRecipientMessageLabel}
                    </span>{' '}
                    <span>{voucherRecipient.message}</span>
                  </div>
                )}
              </div>
              <ActionButton
                onClick={() => navigate(-1)}
                sticky={false}
                showIcon={false}
                contained
                variant={'outline'}
              >
                {contentVouchers.paymentEditText}
              </ActionButton>
            </div>
          </Box>
        </Box>
      </div>

      <ContainedRow textAlign='left'>
        <TermsAndConditions isPageValidated={validateForms} />
      </ContainedRow>

      <SplitPaymentDesign
        creditCardType={creditCardType}
        showPaymentForm={true}
        showGiftCardForm={false}
        isPageValidated={validateForms}
        handleValidatePage={() => setValidateForms(true)}
        setPaymentFormVisibility={setPaymentFormVisibility}
        setCreditCardType={setCreditCardType}
      />
    </div>
  );
};

export default VouchersPayment;
