import React from 'react';

import { useSelector } from 'react-redux';
import { Flex } from 'theme-ui';

import { GlobalState } from '../../../@types/modelTypes';
import {
  selectContentCartSummary,
  selectDisplayPrice,
} from '../../../store/Selectors';

interface Props {
  hideTax: boolean;
  productsTax: number;
  bookingFeeTax: number;
}

const CartSummaryTax: React.FC<Props> = ({
  bookingFeeTax,
  hideTax,
  productsTax,
}) => {
  const contentCartSummary = useSelector(selectContentCartSummary);
  const totalTax = productsTax + bookingFeeTax;
  const priceToDisplay = useSelector((state: GlobalState) =>
    selectDisplayPrice(state, totalTax)
  );
  if (hideTax || totalTax === 0) return null;

  return (
    <Flex
      className='cart-summary-row-flex'
      data-testid='cs-tax'
      sx={{ justifyContent: 'space-between' }}
    >
      <span>{contentCartSummary.taxLabel}</span>
      <span>{priceToDisplay}</span>
    </Flex>
  );
};

export default CartSummaryTax;
