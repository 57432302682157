import React from 'react';

import { useSelector } from 'react-redux';

import { SeatMapSeat } from '../../../../@types/modelTypes';
import {
  getDisplayDateAndTime,
  displaySelectedSeatsNames,
} from '../../../../services/Helpers';
import {
  selectCartSummary,
  selectContentCartSummary,
  selectSelectedLanguageCulture,
} from '../../../../store/Selectors';
import CartSummaryFilmInfoPoster from '../../cartsummary/CartSummaryFilmInfoPoster';
import CartSummaryFilmInfoStillImage from '../../cartsummary/CartSummaryFilmInfoStillImage';
import ContainedRow from '../../layout/ContainedRow';

interface Props {
  reservedSeats?: SeatMapSeat[];
  useStillImageInCartSummary: boolean;
  bookingRef: string;
}

const BookingInfo: React.FC<Props> = ({
  reservedSeats,
  useStillImageInCartSummary,
  bookingRef,
}) => {
  const selectedLanguageCulture = useSelector(selectSelectedLanguageCulture);
  const cartSummary = useSelector(selectCartSummary);
  const contentCartSummary = useSelector(selectContentCartSummary);

  if (!cartSummary?.sessionDate) return null;
  const seatsToDisplay = reservedSeats?.map((seat) => seat.seatName);

  const dateAndTime =
    cartSummary?.sessionDate &&
    getDisplayDateAndTime(cartSummary.sessionDate, selectedLanguageCulture);

  return (
    <>
      {bookingRef && (
        <ContainedRow styles={{ mt: 5, textAlign: 'center' }}>
          <h1>Booking: {bookingRef}</h1>
        </ContainedRow>
      )}
      <ContainedRow styles={{ mt: 5, textAlign: 'left' }}>
        <div className='concessions-booking-info contained'>
          <div
            className='booking-info-row'
            sx={{ display: 'flex', justifyContent: 'flex-start' }}
          >
            <div className='booking-info-left'>
              {useStillImageInCartSummary ? (
                <CartSummaryFilmInfoStillImage />
              ) : (
                <CartSummaryFilmInfoPoster />
              )}
            </div>

            <div className='booking-info-right'>
              <h2>{cartSummary.title}</h2>

              <div>
                <p className='tiny'>
                  {cartSummary.rated && (
                    <span data-testid='cs-rated'>{cartSummary.rated}</span>
                  )}
                  {cartSummary.formattedDuration && cartSummary.rated && (
                    <span className='dot-separator'>·</span>
                  )}
                  {cartSummary.formattedDuration && (
                    <span>{cartSummary.formattedDuration}</span>
                  )}
                </p>
              </div>

              <div className='bottom'>
                <p>
                  <b>{contentCartSummary.atLabel} </b>
                  {cartSummary.theaterLocationName} - {cartSummary.screen}
                </p>
                <p>
                  <b>{contentCartSummary.onLabel} </b>
                  {dateAndTime?.displayDate} - {dateAndTime?.displayTime}
                </p>
              </div>
              {reservedSeats && (
                <div className='bottom'>
                  <p>
                    <b>{contentCartSummary.yourSeatsLabel} </b>
                    {seatsToDisplay &&
                      displaySelectedSeatsNames(seatsToDisplay)}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </ContainedRow>
    </>
  );
};

export default BookingInfo;
