/** @jsxImportSource theme-ui */
import React, { useState } from 'react';

import { useSelector } from 'react-redux';
import { Box, Flex } from 'theme-ui';

import { RefundServiceCharge } from '../../../@types/enums';
import {
  selectBookingData,
  selectConfig,
  selectContent,
  selectRefundData,
} from '../../../store/Selectors';
import ActionButton from '../actionbutton/ActionButton';
import CheckBoxButton from '../checkboxbutton/CheckBoxButton';
import ContainedRow from '../layout/ContainedRow';
import LoginSection from '../login/LoginSection';
import RichText from '../richtext/RichText';

interface Props {
  handleLogInClick: () => void;
  handleSubmitRefund: (
    isLoyaltyRefund: boolean,
    refundIncludesServiceCharge: boolean
  ) => void;
}

const RefundRequest: React.FC<Props> = ({
  handleLogInClick,
  handleSubmitRefund,
}) => {
  const content = useSelector(selectContent);
  const config = useSelector(selectConfig);
  const bookingData = useSelector(selectBookingData);
  const refundData = useSelector(selectRefundData);
  const [checked, setChecked] = useState(false);

  const handleCheckboxChange = () => {
    setChecked(!checked);
  };

  if (
    refundData?.isRefundAllowedForMemberLevelId &&
    !bookingData.isUserValidated &&
    config.currentCinema.refundServiceCharge === RefundServiceCharge.NO
  ) {
    return (
      <ContainedRow dataTestId='refund-request-is-allowed-for-member-level'>
        <RichText text={content.refund.loyaltyLoginRefundRequestMessage} />
        <LoginSection />
      </ContainedRow>
    );
  }

  return (
    <>
      {config.currentCinema.refundServiceCharge ===
        RefundServiceCharge.LOYALTY_ONLY &&
        !bookingData.isUserValidated && (
          <ContainedRow dataTestId='refund-request-loyalty-service-charge'>
            <RichText text={content.refund.loyaltyLoginRefundRequestMessage} />
            <ActionButton
              onClick={handleLogInClick}
              showIcon
              contained
              mb={0}
              variant='primary'
            >
              {content.refund.logInButtonText}
            </ActionButton>

            <Box mt={6}>
              <RichText text={content.refund.continueAsGuestMessage} />
            </Box>
            <Flex mt={5}>
              <Flex sx={{ alignItems: 'center', mr: 5 }}>
                <CheckBoxButton
                  checked={checked}
                  onClick={handleCheckboxChange}
                />
              </Flex>
              <RichText
                text={content.refund.refundAgreeTermsText}
                styles={{ textAlign: 'left' }}
              />
            </Flex>
            <ActionButton
              onClick={() => handleSubmitRefund(false, false)}
              showIcon
              contained
              disabled={!checked}
              mb={0}
              variant='primary'
            >
              {content.refund.continueAsGuestButtonText}
            </ActionButton>
          </ContainedRow>
        )}

      {config.currentCinema.refundServiceCharge ===
        RefundServiceCharge.LOYALTY_ONLY &&
        bookingData.isUserValidated && (
          <ContainedRow dataTestId='refund-signed-in'>
            <RichText text={content.refund.loyaltyRefundRequestConfirmation} />
            <ActionButton
              onClick={() => handleSubmitRefund(true, true)}
              showIcon
              contained
              variant='primary'
            >
              {content.refund.confirmButtonText}
            </ActionButton>
          </ContainedRow>
        )}

      {config.currentCinema.refundServiceCharge === RefundServiceCharge.YES && (
        <ContainedRow dataTestId='refund-request-yes-service-charge'>
          <RichText text={content.refund.genericRefundRequestMessage} />
          <ActionButton
            onClick={() => handleSubmitRefund(false, true)}
            showIcon
            contained
            mb={0}
            variant='primary'
          >
            {content.refund.confirmButtonText}
          </ActionButton>
        </ContainedRow>
      )}

      {config.currentCinema.refundServiceCharge === RefundServiceCharge.NO && (
        <ContainedRow dataTestId='refund-request-no-service-charge'>
          <RichText text={content.refund.nonLoyaltyRefundRequestConfirmation} />
          <Flex mt={5}>
            <Flex sx={{ alignItems: 'center', mr: 5 }}>
              <CheckBoxButton
                checked={checked}
                onClick={handleCheckboxChange}
              />
            </Flex>
            <RichText
              text={content.refund.refundAgreeTermsText}
              styles={{ textAlign: 'left' }}
            />
          </Flex>
          <ActionButton
            onClick={() => handleSubmitRefund(false, false)}
            showIcon
            contained
            disabled={!checked}
            mb={0}
            variant='primary'
          >
            {content.refund.confirmButtonText}
          </ActionButton>
        </ContainedRow>
      )}
    </>
  );
};

export default RefundRequest;
