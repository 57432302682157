/** @jsxImportSource theme-ui */
import React, { useEffect, useState } from 'react';

import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from 'theme-ui';

import { GlobalState } from '../../../@types/modelTypes';
import { useRecaptcha } from '../../../contextProviders/recaptchaContext';
import { useScreenWidth } from '../../../contextProviders/screenWidthContext';
import { useTurnstile } from '../../../contextProviders/turnstileContext';
import { useBoostNavigate } from '../../../hooks/useBoostNavigate';
import { useUpdateExpectedBookingFeesAndTax } from '../../../hooks/useUpdateExpectedBookingFeesAndTax';
import { useValidateJourney } from '../../../hooks/useValidateJourney';
import { getTotalNumberOfSeatsWithinSelectedTickets } from '../../../services/Helpers';
import { getStepNumberForDisplay } from '../../../services/JourneyService';
import { actionCreators } from '../../../store/ActionCreators';
import {
  selectBookingData,
  selectConfig,
  selectContent,
  selectContinueButtonText,
  selectHasCardPaymentPromoTickets,
  selectIsSeatsFirstJourney,
  selectJourneyTypeConfig,
  selectSelectedSeats,
  selectShowBookingFeeInInfoBox,
  selectStep,
  selectTicketTypes,
} from '../../../store/Selectors';
import ActionButton from '../../common/actionbutton/ActionButton';
import CustomHtmlDiv from '../../common/customHtmlDiv/CustomHtmlDiv';
import ContainedRow from '../../common/layout/ContainedRow';
import RecaptchaText from '../../common/recaptchatext/RecaptchaText';
import RichText from '../../common/richtext/RichText';
import SelectedHeading from '../../common/selectedHeading/SelectedHeading';
import ShowtimeHero from '../../common/showtimehero/ShowtimeHero';
import CardPaymentPromoTicketSelector from '../../common/tickets/CardPaymentPromoTicketSelector';
import CeaCardsSelector from '../../common/tickets/CeaCardsSelector';
import CeaThirdPartyVoucherSelector from '../../common/tickets/CeaThirdPartyVoucherSelector';
import DealsIntroduction from '../../common/tickets/DealsIntroduction';
import LoyaltyTicketSelector from '../../common/tickets/LoyaltyTicketSelector';
import ThirdPartyVoucherSelector from '../../common/tickets/ThirdPartyVoucherSelector';
import TicketSelectorContainer from '../../common/tickets/TicketSelectorContainer';
import VoucherSelector from '../../common/tickets/VoucherSelector';
import TotalWrapper from '../../common/totalwrapper/TotalWrapper';
import DazzlerWrapper from '../../dazzlercommon/DazzlerWrapper';
import LegacyWrapper from '../../dazzlercommon/LegacyWrapper';

const Tickets: React.FC = () => {
  const dispatch = useDispatch();
  const boostNavigate = useBoostNavigate();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const recaptcha = useRecaptcha();
  const turnstile = useTurnstile();
  const { isLargeScreenWidth } = useScreenWidth();

  const bookingData = useSelector(selectBookingData);
  const config = useSelector(selectConfig);
  const content = useSelector(selectContent);
  const journeyTypeConfig = useSelector(selectJourneyTypeConfig);
  const selectedSeats = useSelector(selectSelectedSeats);
  const step = useSelector(selectStep);
  const availablePosTickets = useSelector(
    (state: GlobalState) => state.availablePosTickets
  );
  const hasThirdPartyMemberTickets = useSelector(
    (state: GlobalState) => state.hasThirdPartyMemberTickets
  );

  const showInfoBox = useSelector(selectShowBookingFeeInInfoBox);
  const ticketTypes = useSelector(selectTicketTypes);
  const continueButtonText = useSelector(selectContinueButtonText);
  const isSeatsFirstJourney = useSelector(selectIsSeatsFirstJourney);
  const hasPromoTickets = useSelector(selectHasCardPaymentPromoTickets);

  const hasMemberTickets = ticketTypes?.ticketTypeModels.find(
    (t) => t.isMemberTicket
  );
  const [feedback, setFeedback] = useState<string | undefined>();

  useValidateJourney();

  // Show error when no tickets available
  useEffect(() => {
    if (
      !!content &&
      !!availablePosTickets &&
      !availablePosTickets.groupedTicketTypes
    ) {
      dispatch(actionCreators.setError(content.error.sessionExpiredRichText));
    }
  }, [content, availablePosTickets, dispatch]);

  const navigateToNextStep = () => {
    boostNavigate.navigateToNextStep({
      appendCinemaAndSessionIdsFromUrl: true,
    });
  };

  useUpdateExpectedBookingFeesAndTax();

  const validateDynamicBasket = async () => {
    setFeedback(undefined);

    dispatch(
      actionCreators.validateDynamicBasket({
        executeRecaptcha,
        setCeaTicketInsufficientFeedbackFunc: setFeedback,
        navigateToNextStepFunc: navigateToNextStep,
        turnstile,
        recaptcha,
      })
    );
  };

  const handleContinueClick = async () => {
    if (config.useDynamicBasket && !journeyTypeConfig.isSeatsFirst) {
      await validateDynamicBasket();
    } else if (ticketTypes) {
      dispatch(
        actionCreators.addBasketTickets({
          executeRecaptcha,
          setCeaTicketInsufficientFeedbackFunc: setFeedback,
          navigateToNextStepFunc: navigateToNextStep,
          turnstile,
          recaptcha,
        })
      );
    }
  };

  if (!config || !content) return null;

  const numberOfTicketsSelected = ticketTypes
    ? getTotalNumberOfSeatsWithinSelectedTickets(ticketTypes.ticketTypeModels)
    : 0;

  const isSeatsFirst =
    isSeatsFirstJourney &&
    bookingData.isReservedSeating &&
    journeyTypeConfig.isSeatsFirst;
  const ticketSelectedForEverySeat =
    selectedSeats.length === numberOfTicketsSelected;
  const orderHasMaxTickets =
    (config.tickets.maxTicketsPerOrder &&
      numberOfTicketsSelected >= config.tickets.maxTicketsPerOrder) ||
    (isSeatsFirst && ticketSelectedForEverySeat);

  const continueDisabled = isSeatsFirst
    ? !ticketSelectedForEverySeat
    : !numberOfTicketsSelected;

  return (
    <>
      <DazzlerWrapper step='tickets' />
      <LegacyWrapper step='tickets'>
        <Box
          className='tickets'
          data-testid='tickets'
          sx={{
            '.ticket-group-highlight': {
              borderColor: 'primary',
              boxShadow: 'primaryBlurryShadow',
            },
          }}
        >
          <ContainedRow textAlign='center'>
            <h1>
              {getStepNumberForDisplay(step)}
              {content.tickets.title}
            </h1>
          </ContainedRow>

          <ContainedRow>
            <ShowtimeHero />
          </ContainedRow>

          {isLargeScreenWidth && isSeatsFirst && <TotalWrapper />}

          {(content.tickets.subTitle ||
            content.tickets.introductionRichText) && (
            <ContainedRow styles={{ mt: 5 }}>
              <h2 sx={{ textAlign: 'center' }}>{content.tickets.subTitle}</h2>
              <RichText text={content.tickets.introductionRichText} />
            </ContainedRow>
          )}

          {showInfoBox && (
            <ContainedRow styles={{ mt: 5 }}>
              <Box className='info-container' sx={{ p: 5, textAlign: 'left' }}>
                <RichText text={content.tickets.bookingFeeInfoBoxRichText} />
              </Box>
            </ContainedRow>
          )}

          {isSeatsFirst && (
            <SelectedHeading
              allSelected={ticketSelectedForEverySeat}
              numberSelected={numberOfTicketsSelected}
              numberToSelect={selectedSeats.length}
            />
          )}

          {config.tickets.enableTopVoucherBox && (
            <>
              <RecaptchaText />
              <ContainedRow styles={{ mt: 5, textAlign: 'left' }}>
                <VoucherSelector orderHasMaxTickets={orderHasMaxTickets} />
              </ContainedRow>
            </>
          )}
          {config.tickets.enableThirdPartyMemberTicketsVoucherBox &&
            hasThirdPartyMemberTickets && (
              <>
                <RecaptchaText />
                <ContainedRow styles={{ mt: 5, textAlign: 'left' }}>
                  <ThirdPartyVoucherSelector
                    orderHasMaxTickets={orderHasMaxTickets}
                  />
                </ContainedRow>
              </>
            )}
          {config.tickets.enableCeaCardBox &&
            !config.tickets.useLoginValidationForCea && (
              <>
                <RecaptchaText />
                <ContainedRow styles={{ mt: 5, textAlign: 'left' }}>
                  <CeaThirdPartyVoucherSelector
                    orderHasMaxTickets={orderHasMaxTickets}
                  />
                </ContainedRow>
              </>
            )}
          {config.tickets.enableCeaCardBox &&
            config.tickets.useLoginValidationForCea && (
              <>
                <RecaptchaText />
                <ContainedRow styles={{ mt: 5, textAlign: 'left' }}>
                  <CeaCardsSelector orderHasMaxTickets={orderHasMaxTickets} />
                </ContainedRow>
              </>
            )}

          {config.tickets.enableMultipleLoyaltyCardTicketPurchase &&
            hasMemberTickets && (
              <>
                <RecaptchaText />
                <ContainedRow styles={{ mt: 5, textAlign: 'left' }}>
                  <LoyaltyTicketSelector
                    orderHasMaxTickets={orderHasMaxTickets}
                  />
                </ContainedRow>
              </>
            )}

          <DealsIntroduction />

          {config.tickets.customHtmlDivId && (
            <CustomHtmlDiv id={config.tickets.customHtmlDivId} />
          )}

          <TicketSelectorContainer
            ticketTypeGroup={
              config.tickets.enableMultipleLoyaltyCardTicketPurchase
                ? 'non-member-tickets'
                : 'all-tickets'
            }
            orderHasMaxTickets={orderHasMaxTickets}
          />

          {hasPromoTickets && (
            <CardPaymentPromoTicketSelector
              orderHasMaxTickets={orderHasMaxTickets}
            />
          )}

          <ActionButton
            onClick={handleContinueClick}
            disabled={continueDisabled}
            sticky={config.tickets.useStickyButton}
            showIcon
            contained
            hasMarginX
            showWarningMessage={!!feedback}
            warningMessage={feedback}
            variant='primary'
            showCartSummaryButtonOnMobile
          >
            {continueButtonText}
          </ActionButton>
          {(content.tickets.additionalSubTitle ||
            content.tickets.additionalRichText) && (
            <ContainedRow styles={{ mt: 2 }}>
              <h2 sx={{ textAlign: 'center' }}>
                {content.tickets.additionalSubTitle}
              </h2>
              <RichText text={content.tickets.additionalRichText} />
            </ContainedRow>
          )}
        </Box>
      </LegacyWrapper>
    </>
  );
};

export default Tickets;
