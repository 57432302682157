import React from 'react';

export interface Props {
  createSession: () => void;
  language: string;
}

const ApplePayButton: React.FC<Props> = ({ createSession, language }) => {
  const supportsSetup = 'openPaymentSetup' in ApplePaySession;
  const cssClass = supportsSetup
    ? 'apple-pay apple-pay-button-with-text apple-pay-button-black-with-text'
    : 'apple-pay apple-pay-button apple-pay-button-black';
  return (
    <button
      onClick={() => createSession()}
      style={{ width: '100%', height: '50px' }}
      className={cssClass}
      lang={language}
    />
  );
};

export default ApplePayButton;
