import React from 'react';

import { AddToCalendarButton } from 'add-to-calendar-button-react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { Box } from 'theme-ui';

import { TicketTypeModel } from '../../../@types/modelTypes';
import {
  selectCartSummary,
  selectConfirmationData,
  selectContent,
  selectSelectedSeatNames,
  selectSelectedSeats,
  selectTicketTypes,
} from '../../../store/Selectors';

interface Props {
  displayConfirmationNumber: boolean;
  locationCinemaName: string;
}

const AddToCalendar: React.FC<Props> = ({
  displayConfirmationNumber,
  locationCinemaName,
}) => {
  const cartSummary = useSelector(selectCartSummary);
  const content = useSelector(selectContent);
  const confirmationData = useSelector(selectConfirmationData);
  const ticketTypes = useSelector(selectTicketTypes);
  const selectedSeats = useSelector(selectSelectedSeats);
  const selectedSeatNames = useSelector(selectSelectedSeatNames);
  if (
    !cartSummary ||
    !cartSummary.sessionDate ||
    !cartSummary.duration ||
    !cartSummary.title
  ) {
    return null;
  }

  const sessionDateStart = moment(cartSummary.sessionDate);
  const sessionDateEnd = moment(cartSummary.sessionDate).add(
    cartSummary.duration,
    'minutes'
  );
  const calendarData = {
    startDate: sessionDateStart.format('yyyy-MM-DD'),
    endDate: sessionDateEnd.format('yyyy-MM-DD'),
    startTime: sessionDateStart.format('HH:mm'),
    endTime: sessionDateEnd.format('HH:mm'),
    tz: cartSummary.ianaTimeZone,
  };

  const getCalendarDescription = () => {
    let calDesc = `[ul][li][strong]${cartSummary.title}[/strong] ${content.cartSummary.atLabel} ${cartSummary.theaterLocationName}, ${cartSummary.screen}[/li]`;
    calDesc += `[li]${content.confirmation.bookingReferenceSubTitle} ${confirmationData?.codeUsedInBarcode}[/li]`;
    if (displayConfirmationNumber) {
      calDesc += `[li]${content.confirmation.confirmationIdSubTitle}: ${confirmationData?.bookingConfirmationId}[/li]`;
    }
    ticketTypes?.ticketTypeModels.forEach((t: TicketTypeModel) => {
      calDesc += `[li]${t.displayName} (${t.quantity})[/li]`;
    });
    if (selectedSeats.length) {
      calDesc += `[li]${content.cartSummary.yourSeatsLabel}: ${selectedSeatNames}[/li]`;
    }
    calDesc += `[/ul]`;
    return calDesc;
  };

  const getCalendarFileName = () => {
    let fileName = cartSummary.title.replace(/[^a-z0-9]/gi, '');
    fileName += '-' + sessionDateStart.format('YYMMDD-HHmm');

    return fileName;
  };

  return (
    <Box
      sx={{
        mt: 5,
        mb: [3, 3, 3, 0],
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <AddToCalendarButton
        name={`${cartSummary.title} ${content.cartSummary.atLabel} ${cartSummary.theaterLocationName}`}
        description={getCalendarDescription()}
        startDate={calendarData.startDate}
        endDate={calendarData.endDate}
        startTime={calendarData.startTime}
        endTime={calendarData.endTime}
        timeZone={calendarData.tz}
        location={locationCinemaName}
        options={['Apple', 'Google', 'Outlook.com', 'iCal']}
        size='3|3|1'
        trigger='click'
        buttonStyle='flat'
        hideCheckmark
        lightMode='bodyScheme'
        iCalFileName={getCalendarFileName()}
      />
    </Box>
  );
};

export default AddToCalendar;
