import React, { FC, memo } from 'react';

import { useSelector } from 'react-redux';

import { selectBookingData } from '../../../store/Selectors';
import Widget from '../index';
import { WidgetData } from '../types';
type Props = {
  widget: WidgetData<'TicketingCMSJourneyConditionalWidget'>;
};

export const Conditional: FC<Props> = ({ widget }) => {
  // TODO - currently is hardcoded to spport isLoggedIn
  const bookingData = useSelector(selectBookingData);
  return (
    <div>
      {widget.shape?.isUserAuthenticated === bookingData.isUserValidated &&
        widget.shape?.widgets?.map((widget) => {
          return <Widget key={widget?.id} widget={widget} />;
        })}
    </div>
  );
};

export default memo(Conditional);
