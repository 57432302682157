/* eslint-disable @typescript-eslint/no-explicit-any */
/** @jsxImportSource theme-ui */
import React, { useState, useEffect, useCallback } from 'react';

import { CoreOptions } from '@adyen/adyen-web/dist/types/core/types';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from 'theme-ui';

import Adyen from './Adyen';

import { PaymentProvidersEnum } from '../../../../../../@types/enums';
import { HostedPaymentResponse } from '../../../../../../@types/modelTypes';
import { useRecaptcha } from '../../../../../../contextProviders/recaptchaContext';
import { useTurnstile } from '../../../../../../contextProviders/turnstileContext';
import { actionCreators } from '../../../../../../store/ActionCreators';
import {
  selectBankCardAmount,
  selectBookingData,
  selectConfig,
  selectGrandTotalAfterDiscountsInCents,
  selectSelectedPaymentProvider,
  selectShouldUseTurnstile,
} from '../../../../../../store/Selectors';

const AdyenDropinPayment: React.FC = () => {
  const dispatch = useDispatch();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const recaptcha = useRecaptcha();
  const turnstile = useTurnstile();

  const config = useSelector(selectConfig);
  const bookingData = useSelector(selectBookingData);
  const selectedPaymentProvider = useSelector(selectSelectedPaymentProvider);
  const bankCardAmount = useSelector(selectBankCardAmount);
  const grandTotal = useSelector(selectGrandTotalAfterDiscountsInCents);
  const shouldUseTurnstile = useSelector(selectShouldUseTurnstile);
  const [adyenConfig, setAdyenConfig] = useState<CoreOptions | undefined>(
    undefined
  );

  const shouldShowGiftCardWrapper =
    bookingData.isPaymentWithGiftCardEnabled && grandTotal > 0;

  useEffect(() => {
    dispatch(actionCreators.setHostedPayment(PaymentProvidersEnum.ADYEN, null));
    setAdyenConfig(undefined);
  }, [dispatch, bankCardAmount]);

  const turnstileCheckValid = !shouldUseTurnstile || turnstile;

  const hostedPaymentCallBack = useCallback(
    (getHostedResponse: any) => {
      const hostedPaymentResponse: HostedPaymentResponse =
        getHostedResponse.content;
      const paymentMethodsResponse = JSON.parse(
        hostedPaymentResponse.postValues.PaymentMethods
      );

      const adyenConfig: CoreOptions = {
        paymentMethodsResponse: paymentMethodsResponse,
        clientKey: hostedPaymentResponse.postValues.ClientKey,
        locale: config.languageCulture,
        environment: hostedPaymentResponse.postValues.Environment,
        paymentMethodsConfiguration: {
          card: {
            hasHolderName: true,
            holderNameRequired: true,
          },
          applepay: { buttonType: 'buy' },
          threeDS2: {
            challengeWindowSize: '05',
          },
        },
        amount: {
          value: bankCardAmount,
          currency: config.currency,
        },
      };
      setAdyenConfig(adyenConfig);
    },
    [bankCardAmount, config.currency, config.languageCulture]
  );

  const callHostedPayment = useCallback(async () => {
    if (!executeRecaptcha || !turnstileCheckValid) return;

    dispatch(
      actionCreators.adyenDropinHostedPayment({
        executeRecaptcha,
        recaptcha,
        turnstile,
        callBackFunction: hostedPaymentCallBack,
      })
    );
  }, [
    dispatch,
    executeRecaptcha,
    hostedPaymentCallBack,
    recaptcha,
    turnstile,
    turnstileCheckValid,
  ]);

  useEffect(() => {
    if (selectedPaymentProvider !== PaymentProvidersEnum.ADYEN || adyenConfig)
      return;

    if (!adyenConfig) {
      callHostedPayment();
    }
  }, [adyenConfig, callHostedPayment, selectedPaymentProvider]);

  return (
    <div
      className='everyman-payment-design payment'
      data-testid='em-custom-payment-design'
      sx={{ textAlign: 'left' }}
    >
      <Box
        className='payment-details-wrapper'
        sx={{ mt: shouldShowGiftCardWrapper ? 2 : 5, px: 0 }}
      >
        {grandTotal && adyenConfig ? (
          <Adyen adyenConfig={adyenConfig} setAdyenConfig={setAdyenConfig} />
        ) : null}
      </Box>
    </div>
  );
};

export default AdyenDropinPayment;
