/** @jsxImportSource theme-ui */
import React from 'react';

import { useSelector } from 'react-redux';
import { Box, Flex } from 'theme-ui';

import { QuantitySelectorContext } from '../../../@types/actionTypes';
import { GlobalState, TicketTypeModel } from '../../../@types/modelTypes';
import { getPackageTicketsDescription } from '../../../services/TicketHelpers';
import { selectDisplayPrice } from '../../../store/Selectors';
import QuantityButton from '../quantitybutton/QuantityButton';

interface Props {
  ticket: TicketTypeModel;
  onClick: (
    ticketTypeId: TicketTypeModel['id'],
    voucherCode: TicketTypeModel['voucherCode'],
    context: QuantitySelectorContext
  ) => void;
  disableAdd: boolean;
  disableRemove: boolean;
  packageTicketsIncludesLabel: string;
  hideTax: boolean;
}

const VoucherTicketType: React.FC<Props> = ({
  ticket,
  onClick,
  disableAdd,
  disableRemove,
  packageTicketsIncludesLabel,
  hideTax,
}) => {
  const priceWithTaxAdjustment = hideTax
    ? ticket.price
    : ticket.price - ticket.tax;
  const priceToDisplay = useSelector((state: GlobalState) =>
    selectDisplayPrice(state, priceWithTaxAdjustment)
  );

  return (
    <Flex
      className='voucher-ticket-type'
      data-testid='voucher-ticket-type'
      sx={{
        borderTop: 'boxSeparator',
      }}
    >
      <Box className='btn-container' sx={{ textAlign: 'left' }}>
        <QuantityButton
          context={'remove'}
          disabled={disableRemove}
          onClick={() => onClick(ticket.id, ticket.voucherCode, 'remove')}
        />
      </Box>
      <Flex
        sx={{
          flexGrow: 1,
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
        }}
      >
        <div className={ticket.quantity > 0 ? 'selected' : ''}>
          <p>{ticket.voucherDisplayName}</p>
          {ticket.isPackageTicket && (
            <p className='tiny'>
              {packageTicketsIncludesLabel}{' '}
              {getPackageTicketsDescription(ticket)}
            </p>
          )}
          <p>
            {ticket.quantity > 0 && (
              <>
                {priceToDisplay}
                <span className='quantity'> x {ticket.quantity}</span>
              </>
            )}
          </p>
        </div>
      </Flex>
      <Box className='btn-container' sx={{ textAlign: 'right' }}>
        <QuantityButton
          context={'add'}
          disabled={disableAdd}
          onClick={() => onClick(ticket.id, ticket.voucherCode, 'add')}
        />
      </Box>
    </Flex>
  );
};

export default VoucherTicketType;
