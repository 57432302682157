/** @jsxImportSource theme-ui */

import React, { PropsWithChildren } from 'react';

import classnames from 'classnames';
import { Box, Button, Flex } from 'theme-ui';

import ActionButtonCartSummary from './ActionButtonCartSummary';

import { useScreenWidth } from '../../../contextProviders/screenWidthContext';
import { ReactComponent as IconArrowSvg } from '../../../svgs/iconArrow.svg';
import WarningMessage from '../warningmessage/WarningMessage';

interface Props {
  contained?: boolean;
  disabled?: boolean;
  modal?: boolean;
  hasMarginX?: boolean;
  mt?: number;
  mb?: number;
  onClick?: React.MouseEventHandler;
  showIcon?: boolean;
  showWarningMessage?: boolean;
  sticky?: boolean;
  stickyMobileDesktop?: boolean;
  warningMessage?: string;
  warningTitle?: string;
  variant: string;
  iconReversed?: boolean;
  showCartSummaryButtonOnMobile?: boolean;
}

const ActionButton: React.FC<PropsWithChildren<Props>> = ({
  children,
  contained,
  disabled,
  modal,
  hasMarginX,
  mt,
  mb,
  onClick,
  showIcon,
  showWarningMessage,
  sticky,
  stickyMobileDesktop,
  warningMessage,
  warningTitle,
  variant,
  iconReversed,
  showCartSummaryButtonOnMobile,
}) => {
  const { isLargeScreenWidth, isMobileScreenWidth } = useScreenWidth();

  const shouldAlwaysBeSticky =
    showCartSummaryButtonOnMobile && !isLargeScreenWidth;

  return (
    <Flex
      className={classnames(
        (shouldAlwaysBeSticky || sticky) && 'sticky-button',
        (shouldAlwaysBeSticky || stickyMobileDesktop) &&
          'sticky-button-mobile-desktop'
      )}
      sx={{
        '&.sticky-button, &.sticky-button-mobile-desktop': {
          backgroundColor: 'websiteBackground',
          '> div': {
            borderTop: 'bodySeparator',
          },
        },
        width: '100%',
      }}
    >
      <Box
        className={classnames(contained && 'contained')}
        sx={{ flexGrow: 1 }}
      >
        <div className='action-button-container'>
          {showWarningMessage && (
            <WarningMessage
              warningTitle={warningTitle}
              warningMessage={warningMessage}
            />
          )}
          <Flex
            sx={{
              justifyContent: 'center',
              mx: !isMobileScreenWidth && hasMarginX ? 5 : 0,
              mt: mt === undefined ? 4 : mt,
              mb: mb === undefined ? 4 : mb,
            }}
          >
            {showCartSummaryButtonOnMobile && !isLargeScreenWidth && (
              <ActionButtonCartSummary variant={variant} />
            )}
            <Button
              data-testid='action-button'
              className={classnames(
                'action-button',
                modal && 'btn-modal',
                showIcon && 'btn-large'
              )}
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: showIcon ? 'space-between' : 'center',
              }}
              onClick={onClick}
              disabled={disabled}
              type='button'
              variant={variant}
            >
              {children}
              {showIcon && (
                <IconArrowSvg
                  className={classnames(
                    iconReversed ? 'icon-arrow-reverse' : 'icon-arrow'
                  )}
                  data-testid='action-button-icon'
                  sx={{ display: 'flex', alignItems: 'center' }}
                />
              )}
            </Button>
          </Flex>
        </div>
      </Box>
    </Flex>
  );
};

export default ActionButton;
