import React from 'react';

import { useSelector } from 'react-redux';
import { Box, Flex } from 'theme-ui';

import { Concession, GlobalState } from '../../../../@types/modelTypes';
import {
  selectContentPhysicalGiftcards,
  selectDisplayPrice,
} from '../../../../store/Selectors';
import GiftCardImage from '../GiftCardImage';

interface GiftCardsRowProps {
  concession: Concession;
}

const PaymentSummaryRowPhysical: React.FC<GiftCardsRowProps> = ({
  concession,
}) => {
  const contentPhysicalGiftCards = useSelector(selectContentPhysicalGiftcards);

  const priceToDisplay = useSelector((state: GlobalState) =>
    selectDisplayPrice(state, concession.cost * concession.quantity)
  );

  const imagePath = concession?.image
    ? concession.image.replace('https://', '')
    : contentPhysicalGiftCards.physicalGiftCardDefaultImage;

  if (!concession || concession.quantity < 1) return null;

  const getDescription = () => {
    return `${concession.description} x ${concession.quantity}`;
  };

  return (
    <div className='giftcards-row'>
      <Flex
        sx={{ py: 5, alignItems: 'center', justifyContent: 'space-between' }}
      >
        <Flex sx={{ alignItems: 'center' }}>
          <Box className='image-container' mr={4}>
            <GiftCardImage imageUrl={imagePath} isPhysicalGc />
          </Box>
          <Box
            className='summary-item'
            sx={{ textAlign: 'left', fontWeight: 'bold' }}
          >
            {getDescription()}
          </Box>
        </Flex>
        <Box className='summary-item' sx={{ fontWeight: 'bold' }}>
          <span>{priceToDisplay}</span>
        </Box>
      </Flex>
    </div>
  );
};

export default PaymentSummaryRowPhysical;
