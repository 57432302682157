import React from 'react';

import { useSelector } from 'react-redux';

import { selectAppliedGiftCards } from '../../../store/Selectors';
import GiftCardBreakDown from '../payment/giftcardcomponents/GiftCardBreakDown';

interface Props {
  isConfirmation: boolean;
}

const CartSummaryGiftCards: React.FC<Props> = ({ isConfirmation }) => {
  const appliedGiftCards = useSelector(selectAppliedGiftCards);

  if (!appliedGiftCards?.length) return null;

  return (
    <div className='cart-summary-row' data-testid='cs-selected-seats'>
      <GiftCardBreakDown
        pricingStrategy={isConfirmation ? 'confirmation' : 'journey'}
        variant='cartSummary'
      />
    </div>
  );
};

export default CartSummaryGiftCards;
