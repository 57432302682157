import { useEffect, useRef } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { useBoostNavigate } from './useBoostNavigate';

import { PEACH_CODES } from '../constants';
import backend from '../services/RestUtilities';
import { actionCreators } from '../store/ActionCreators';
import {
  selectBookingData,
  selectContent,
  selectSeasonPassMovies,
  selectSelectedSeasonPassItem,
} from '../store/Selectors';

export const useGetSeasonPassShowtimes = () => {
  const dispatch = useDispatch();

  const boostNavigate = useBoostNavigate();
  const bookingData = useSelector(selectBookingData);
  const content = useSelector(selectContent);
  const selectedSeasonPassTicket = useSelector(selectSelectedSeasonPassItem);
  const promotionId = selectedSeasonPassTicket?.id;
  const seasonPassMovies = useSelector(selectSeasonPassMovies);

  const getSeasonPassShowtimesCalledRef = useRef(false);

  useEffect(() => {
    const getSeasonPassShowtimes = async () => {
      dispatch(actionCreators.setLoading(true));

      const { cinemaId, circuitId } = bookingData;

      const path = `api/SeasonPass/${promotionId}?cinemaId=${cinemaId}&circuitId=${circuitId}`;
      const response = await backend.get(path);

      let result;
      if (
        response.ok &&
        (response.content.peachCode == PEACH_CODES.ok ||
          response.content.peachCode == PEACH_CODES.noError)
      ) {
        result = response.content;
        dispatch(actionCreators.setSeasonPassMovies(result.films));
      } else {
        dispatch(
          actionCreators.setError(
            content.error.unexpectedErrorRichText,
            response.content.peachCode
          )
        );
      }
      dispatch(actionCreators.setLoading(false));
    };

    const shouldFetchSeasonPassShowtimes = () => {
      if (
        !content ||
        !bookingData?.cinemaId ||
        !promotionId ||
        seasonPassMovies?.length
      )
        return false;

      if (!getSeasonPassShowtimesCalledRef.current) {
        getSeasonPassShowtimesCalledRef.current = true;
        return true;
      }

      return false;
    };

    if (shouldFetchSeasonPassShowtimes()) {
      getSeasonPassShowtimes();
    }
  }, [
    bookingData,
    boostNavigate,
    content,
    dispatch,
    promotionId,
    seasonPassMovies?.length,
  ]);
};
