import { JOURNEY_TYPES } from '.';

export const SEASON_PASS_STEPS = {
  START: 'start',
  LOGIN: 0,
  SELECT: 1,
  TICKETS: 2,
  SHOWTIMES: 3,
  SEATS: 4,
  PAYMENT: 5,
  CONFIRMATION: 6,
};

export const JOURNEY_STEPS = {
  [JOURNEY_TYPES.SEASON_PASS]: {
    ...SEASON_PASS_STEPS,
  },
};
