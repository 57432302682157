/** @jsxImportSource theme-ui */
import React from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { Box, Flex } from 'theme-ui';

import { costWidth, quantityWidth } from './Constants';
import VoucherImage from './VoucherImage';

import { QuantitySelectorContext } from '../../../@types/actionTypes';
import { Concession, ConcessionPricing } from '../../../@types/modelTypes';
import { displayPrice } from '../../../services/Helpers';
import {
  findModifiedConcessionMatch,
  findModifiedConcessionItemCost,
} from '../../../services/KioskHelpers';
import { actionCreators } from '../../../store/ActionCreators';
import {
  selectCurrencyConfig,
  selectCurrentCinema,
  selectSelectedGiftCards,
} from '../../../store/Selectors';
import QuantityButton from '../quantitybutton/QuantityButton';
import RichText from '../richtext/RichText';

interface Props extends Concession {
  defaultImage: string;
}

const VoucherItem: React.FC<Props> = ({
  id,
  description,
  extendedDescription,
  image,
  defaultImage,
  taxInCents,
  cost,
  quantity,
  modifierGroups,
}) => {
  const dispatch = useDispatch();

  const currentCinema = useSelector(selectCurrentCinema);
  const selectedConcessions = useSelector(selectSelectedGiftCards);

  const concession: Concession = {
    id,
    description,
    extendedDescription,
    image,
    taxInCents,
    cost,
    quantity,
    modifierGroups,
    modifiers: [],
    isVariablePriceItem: false,
    minVariableCost: 0,
    maxVariableCost: 0,
    hidden: false,
    variablePriceInCents: 0,
    orderDelivery: null,
    code: '',
    isSoldOut: false,
    //deals: [],
  };

  //TODO add this to config
  const maxQuantityOfVouchers = 10;

  const currencyConfig = useSelector(selectCurrencyConfig);

  const imagePath: string = concession.image
    ? concession.image.replace('https://', '')
    : defaultImage;

  const selectedConcession: Concession | undefined =
    findModifiedConcessionMatch(concession, selectedConcessions);

  const quantityInCart: number = selectedConcession
    ? selectedConcession.quantity
    : 0;

  const itemPricing: ConcessionPricing =
    findModifiedConcessionItemCost(concession);

  const handleConcessionChange = (context: QuantitySelectorContext) => {
    const concessionToChange: Concession = JSON.parse(
      JSON.stringify(concession)
    );
    concessionToChange.quantity = 1;
    if (context === 'add') {
      dispatch(actionCreators.addConcessionWithMods(concessionToChange, false));
    } else {
      dispatch(
        actionCreators.removeConcessionWithMods(concessionToChange, false)
      );
    }
  };

  const renderButton = (
    context: QuantitySelectorContext,
    disabled: boolean
  ) => {
    return (
      <QuantityButton
        context={context}
        disabled={disabled}
        onClick={() => handleConcessionChange(context)}
      />
    );
  };

  const renderQuantityButtons = () => {
    return (
      <Flex
        className='quantity-selector'
        sx={{ justifyContent: ['center', 'center', 'flex-end'] }}
      >
        <div className='quantity-button-container'>
          {renderButton('remove', quantityInCart === 0)}
        </div>
        <Flex
          className='quantity-col'
          sx={{
            alignItems: 'center',
            justifyContent: 'center',
            fontWeight: 'bold',
          }}
        >
          {quantityInCart}
        </Flex>
        <div className='quantity-button-container'>
          {renderButton('add', maxQuantityOfVouchers <= quantityInCart)}
        </div>
      </Flex>
    );
  };

  const concessionQuantitySelector = () => {
    const priceToDisplay = currentCinema.hideTax
      ? itemPricing.costIncTax
      : itemPricing.costMinusTax;

    const subTotal = (quantityInCart > 0 ? quantityInCart : 1) * priceToDisplay;

    return (
      <Flex
        className='options-selector-container'
        sx={{
          textAlign: 'center',
          justifyContent: 'center',
          minWidth: 'unset',
        }}
      >
        <Box className='options-selector' sx={{ width: quantityWidth }}>
          {renderQuantityButtons()}
        </Box>
        {itemPricing.costIncTax > 0 && (
          <Flex
            sx={{
              width: costWidth,
              textAlign: 'right',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            {displayPrice(subTotal, currencyConfig)}
          </Flex>
        )}
      </Flex>
    );
  };

  return (
    <Box
      className='concession'
      sx={{
        alignItems: 'center',
        py: 4,
        px: 3,
        textAlign: 'center',
        display: ['block', 'flex'],
      }}
    >
      <Flex sx={{ flexGrow: 1, width: '100%', alignItems: 'center' }}>
        <Box className='image-container' sx={{ mr: 4, height: '100%' }}>
          <VoucherImage imageUrl={imagePath} />
        </Box>

        <Box
          className='details'
          sx={{
            alignItems: 'center',
            flexWrap: 'wrap',
            textAlign: 'left',
            flexGrow: 1,
          }}
        >
          <span className='h3' sx={{ width: '100%' }}>
            {concession.description}
          </span>
          {concession.extendedDescription && (
            <RichText
              className='tiny'
              styles={{ mt: 2, width: '100%' }}
              text={concession.extendedDescription}
            />
          )}
        </Box>
      </Flex>
      {concessionQuantitySelector()}
    </Box>
  );
};

export default VoucherItem;
