/** @jsxImportSource theme-ui */
import React from 'react';

import Button from 'react-bootstrap/Button';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { selectContent } from '../../../../store/Selectors';
import { ReactComponent as IconArrowSvg } from '../../../../svgs/iconArrow.svg';
import ActionButton from '../../actionbutton/ActionButton';
import ContainedRow from '../../layout/ContainedRow';

interface Props {
  resetBookingRef: () => void;
  bookingRef: string;
  pathname: string;
}

const ContinueButtons: React.FC<Props> = ({
  pathname,
  bookingRef,
  resetBookingRef,
}) => {
  const navigate = useNavigate();
  const content = useSelector(selectContent);
  return (
    <ContainedRow textAlign='center'>
      <ActionButton
        onClick={() => navigate(pathname)}
        disabled={!bookingRef}
        stickyMobileDesktop={false}
        showIcon
        contained
        hasMarginX
        variant='primary'
      >
        {content.concessionsOnlyJourney.landingStartConcessionsButtonText}
      </ActionButton>

      <Button
        onClick={resetBookingRef}
        className={'back'}
        variant='link'
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          px: 6,
          mt: 2,
          width: '100%',
        }}
      >
        {content.concessionsOnlyJourney.landingGoBackButtonText}
        <IconArrowSvg
          className='icon-arrow'
          sx={{ display: 'flex', alignItems: 'center' }}
        />
      </Button>
    </ContainedRow>
  );
};

export default ContinueButtons;
