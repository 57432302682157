/** @jsxImportSource theme-ui */
import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Box, Flex } from 'theme-ui';

import { displayPrice } from '../../../services/Helpers';
import { actionCreators } from '../../../store/ActionCreators';
import {
  selectConfig,
  selectConfigDonationValues,
  selectContent,
  selectCurrencyConfig,
  selectDonation,
  selectGrandTotalWithoutDonationInCents,
  selectSelectedDonation,
} from '../../../store/Selectors';
import AmountSelector from '../amountselector/AmountSelector';
import CheckBoxButton from '../checkboxbutton/CheckBoxButton';
import ContainedRow from '../layout/ContainedRow';

export const maxDonationValue = 100;

const Donations: React.FC = () => {
  const dispatch = useDispatch();

  const content = useSelector(selectContent);
  const donation = useSelector(selectDonation);
  const donationValues = useSelector(selectConfigDonationValues);
  const config = useSelector(selectConfig);
  const currencyConfig = useSelector(selectCurrencyConfig);
  const selectedDonation = useSelector(selectSelectedDonation);
  const grandTotal = useSelector(selectGrandTotalWithoutDonationInCents);

  let roundUpValue = 0;
  const grandTotalCeil = Math.ceil(grandTotal / 100) * 100;
  if (grandTotalCeil === grandTotal) {
    roundUpValue = 100;
  } else {
    roundUpValue = grandTotalCeil - grandTotal;
  }

  const [roundUpAmount, setRoundUpAmount] = useState(
    donation - selectedDonation.amount
  );
  const [roundUpChecked, setRoundUpChecked] = useState(roundUpAmount > 0);

  useEffect(() => {
    let roundUpValue = 0;
    if (roundUpChecked) {
      let grandTotalCeil = Math.ceil(grandTotal / 100) * 100;
      if (grandTotalCeil === grandTotal) {
        grandTotalCeil = grandTotalCeil + 100;
      }

      roundUpValue = grandTotalCeil - grandTotal;
    }
    setRoundUpAmount(roundUpValue);
  }, [grandTotal, roundUpChecked]);

  useEffect(() => {
    const donationValue = selectedDonation ? selectedDonation.amount : 0;

    dispatch(actionCreators.setDonation(donationValue + roundUpAmount));
  }, [dispatch, roundUpAmount, selectedDonation]);

  return (
    <ContainedRow
      classNameWrapper='donations-wrapper'
      styles={{
        backgroundColor: 'primary',
        mt: 5,
        mx: -3,
      }}
    >
      <Box
        className='donations'
        data-testid='donations'
        sx={{
          textAlign: 'center',
          color: 'mostReadableOnPrimaryBackground',
          py: 5,
        }}
      >
        <h2 sx={{ color: 'mostReadableOnPrimaryBackground', mt: 0 }}>
          {content.payment.donationSubTitle}
        </h2>
        {content.payment.donationText && <p>{content.payment.donationText}</p>}
        {config.payment.enableDonationRoundUpField && (
          <Flex
            className='donation-roundup-toggle-button'
            sx={{
              justifyContent: 'flex-start',
              mt: 4,
              '& .checkbox-button .selectable-button-svg': {
                '& .a': { fill: 'primary' },
                '& .b': { fill: 'mostReadableOnPrimaryBackground' },
                '& .c': { fill: 'mostReadableOnPrimaryBackground' },
              },
            }}
          >
            <Flex sx={{ alignItems: 'center', textAlign: 'left' }}>
              <CheckBoxButton
                checked={roundUpChecked}
                onClick={() => setRoundUpChecked(!roundUpChecked)}
              />
              {content.payment.donationRoundUpRichText && (
                <span
                  sx={{ ml: 2 }}
                  dangerouslySetInnerHTML={{
                    __html: content.payment.donationRoundUpRichText.replace(
                      '##DonationRoundUpValue##',
                      displayPrice(roundUpValue, currencyConfig)
                    ),
                  }}
                />
              )}
            </Flex>
          </Flex>
        )}
        <Box mt={5}>
          <AmountSelector
            maxAmount={maxDonationValue}
            setAmount={actionCreators.setSelectedDonation}
            values={donationValues}
            variant={'donation'}
          />
        </Box>
      </Box>
    </ContainedRow>
  );
};

export default Donations;
