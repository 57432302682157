import React from 'react';

import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import CartSummaryBookingFee from './CartSummaryBookingFee';
import CartSummaryConcessions from './CartSummaryFaBConcessions';
import CartSummaryTickets from './CartSummaryTickets';

import {
  selectBookingFee,
  selectBookingFeeTax,
  selectCurrentCinema,
  selectRefundIncludesBookingFee,
} from '../../../store/Selectors';

const CartSummaryItemsRefund: React.FC = () => {
  const { pathname } = useLocation();

  const bookingFee = useSelector(selectBookingFee);
  const bookingFeeTax = useSelector(selectBookingFeeTax);
  const currentCinema = useSelector(selectCurrentCinema);
  const refundIncludesBookingFee = useSelector(selectRefundIncludesBookingFee);
  const hideTax = currentCinema?.hideTax;

  return (
    <>
      <div className='cart-items' data-testid='cart-summary-items-refund'>
        <CartSummaryTickets hideTax={hideTax} />

        <CartSummaryConcessions hideTax={hideTax} pathname={pathname} />
      </div>

      {refundIncludesBookingFee && (
        <CartSummaryBookingFee
          bookingFee={bookingFee}
          bookingFeeTax={bookingFeeTax}
          hideTax={hideTax}
          isInsideSubtotal={false}
        />
      )}
    </>
  );
};

export default CartSummaryItemsRefund;
