import { shouldShowFoodAndBeverage } from './Helpers';

import { Config } from '../@types/configTypes';
import { Content } from '../@types/contentTypes';
import { GlobalState } from '../@types/modelTypes';

export const getContinueButtonTextForKioskEnabledJourney: (
  step: GlobalState['step'],
  content: Content,
  config: Config,
  isUserValidated: boolean
) => string = (step, content, config, isUserValidated) => {
  const foodAndBeverageEnabled = shouldShowFoodAndBeverage(
    config.currentCinema.foodAndBeverageTicketingJourney,
    config.isFoodAndBeverageLoyaltyOnly,
    isUserValidated
  );

  const isGiftCardUpsellEnabled = config.enableGiftCardUpsellOnTicketingJourney;

  if (step === 1 || step === 2) {
    if (foodAndBeverageEnabled) {
      return content.continueToFoodText;
    } else if (isGiftCardUpsellEnabled) {
      return content.continueToGiftCardsText;
    } else return content.continueToPaymentText;
  } else {
    return isGiftCardUpsellEnabled
      ? content.continueToGiftCardsText
      : content.continueToPaymentText;
  }
};
