import React from 'react';

interface SeasonPassImageProps {
  imageUrl: string;
  title: string;
}

const SeasonPassImage: React.FC<SeasonPassImageProps> = ({
  imageUrl,
  title,
}) => {
  return (
    <div>
      <img className='season-pass-img' src={imageUrl} alt={title} />
    </div>
  );
};

export default SeasonPassImage;
