import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';

import {
  getRouteFromStep,
  getStartJourneyRouteUsingGlobalState,
  getStartJourneyRouteUsingLocation,
} from '../services/JourneyService';
import { buildQueryString } from '../services/QueryStringHelper';
import {
  selectBookingData,
  selectConfig,
  selectCurrentCinemaHomeUrl,
  selectJourneyTypeConfig,
  selectQueryString,
  selectStep,
} from '../store/Selectors';

interface NavigateOptions {
  appendCinemaAndSessionIdsFromUrl?: boolean;
  appendCinemaAndSessionIdsFromBookingData?: boolean;
  appendQueryString?: boolean;
  search?: string;
}

export const useBoostNavigate = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const {
    externalCinemaId: externalCinemaIdFromUrl,
    externalSessionId: externalSessionIdFromUrl,
  } = useParams();
  const config = useSelector(selectConfig);
  const bookingData = useSelector(selectBookingData);
  let queryString = useSelector(selectQueryString);
  const [searchParams] = useSearchParams();
  const journeyTypeConfig = useSelector(selectJourneyTypeConfig);
  const step = useSelector(selectStep);
  const currentCinemaHomeUrl = useSelector(selectCurrentCinemaHomeUrl);

  const navigateToStep = (step: number, options?: NavigateOptions) => {
    const path = getRouteFromStep(journeyTypeConfig, step);

    navigateToPath(path, options);
  };

  const navigateToNextStep = (options?: NavigateOptions) => {
    const path = getRouteFromStep(journeyTypeConfig, step + 1);

    navigateToPath(path, options);
  };

  const navigateToPreviousStep = (options?: NavigateOptions) => {
    const path = getRouteFromStep(journeyTypeConfig, step - 1);

    navigateToPath(path, options);
  };

  const navigateToPath = (path: string, options?: NavigateOptions) => {
    let fullPath = path;
    if (
      options?.appendCinemaAndSessionIdsFromUrl &&
      externalCinemaIdFromUrl &&
      externalSessionIdFromUrl
    ) {
      fullPath += `/${externalCinemaIdFromUrl}/${externalSessionIdFromUrl}`;
    } else if (
      options?.appendCinemaAndSessionIdsFromUrl &&
      externalCinemaIdFromUrl
    ) {
      fullPath += `/${externalCinemaIdFromUrl}`;
    } else if (
      options?.appendCinemaAndSessionIdsFromBookingData &&
      bookingData
    ) {
      const { externalCinemaId, externalSessionId } = bookingData;
      fullPath += `/${externalCinemaId}/${externalSessionId}`;
    }

    if (!fullPath.startsWith('/')) fullPath = `/${fullPath}`;
    options?.appendQueryString || options?.search
      ? navigate({
          pathname: fullPath,
          search: options?.search ?? queryString,
        })
      : navigate(fullPath);
  };

  const navigateToStartJourneyUsingGlobalState = (search?: string) => {
    let route = getStartJourneyRouteUsingGlobalState(
      journeyTypeConfig,
      config,
      bookingData
    );
    const hasTicketingJourneyParams =
      !!externalCinemaIdFromUrl && !!externalSessionIdFromUrl;
    if (route === '' && hasTicketingJourneyParams) {
      route = `/startticketing/${externalCinemaIdFromUrl}/${externalSessionIdFromUrl}`;
    }
    if (!queryString) {
      queryString = buildQueryString({
        circuitId: searchParams.get('circuitId') ?? undefined,
      });
    }
    if (route) {
      navigate({
        pathname: route,
        search: search ?? queryString,
      });
    } else if (currentCinemaHomeUrl) {
      window.location.replace(currentCinemaHomeUrl);
    } else {
      navigate('/error');
    }
  };

  const navigateToStartJourneyUsingLocation = () => {
    const route = getStartJourneyRouteUsingLocation(
      location,
      externalCinemaIdFromUrl,
      externalSessionIdFromUrl
    );
    if (route) {
      if (!queryString) {
        if (externalCinemaIdFromUrl && !externalSessionIdFromUrl) {
          queryString = buildQueryString({
            cinemaId: externalCinemaIdFromUrl,
            circuitId: searchParams.get('circuitId') ?? undefined,
          });
        } else {
          queryString = buildQueryString({
            circuitId: searchParams.get('circuitId') ?? undefined,
          });
        }
      }
      navigate({
        pathname: route,
        search: queryString,
      });
    } else {
      navigate('/error');
    }
  };

  return {
    navigateToStartJourneyUsingGlobalState,
    navigateToStartJourneyUsingLocation,
    navigateToPath,
    navigateToStep,
    navigateToNextStep,
    navigateToPreviousStep,
  };
};
