/** @jsxImportSource theme-ui */
import React, { useEffect, useState } from 'react';

import { Button, Collapse } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import messages from './intl';
import PurchaserDetailsForm from './PurchaserDetailsForm';

import { selectCustomer } from '../../../store/Selectors';
import ContainedRow from '../../common/layout/ContainedRow';
import RecaptchaText from '../../common/recaptchatext/RecaptchaText';
import RichText from '../../common/richtext/RichText';
import { WidgetData } from '../types';

interface Props {
  widget: WidgetData<'TicketingCMSJourneyPurchaserDetailsWidget'>;
  isPageValidated: boolean;
  onEditCustomer?: () => void;
}

const PurchaserDetailsCollapse: React.FC<Props> = ({
  widget,
  isPageValidated,
  onEditCustomer,
}) => {
  const { formatMessage } = useIntl();

  const customer = useSelector(selectCustomer);
  const [isOpen, setIsOpen] = useState(!customer.isValid);

  const onEditClick = () => {
    !!onEditCustomer && onEditCustomer();
    setIsOpen(true);
  };

  // used to collapse the box
  useEffect(() => {
    if (isPageValidated && customer.isValid) {
      setIsOpen(false);
    } else if (isPageValidated && !isOpen) {
      setIsOpen(true);
    }
  }, [customer.isValid, isOpen, isPageValidated]);

  return (
    <ContainedRow classNameWrapper='customer-wrapper' styles={{ mt: 4 }}>
      <Collapse in={!isOpen} key={'closed'}>
        <div>
          <div sx={{ mt: 4, textAlign: 'center' }}>
            <p className='tiny'>
              {formatMessage(
                messages.purchaserDetailsCollapsedSummaryEditButtonText
              )}
            </p>
            <p className='h2'>
              {customer.name}
              <span className='dot-separator'>·</span>
              {customer.email}
            </p>
          </div>
          <div
            className='collapse-btn-wrapper'
            sx={{ my: 5, textAlign: 'center' }}
          >
            <Button
              className='collapse-btn tiny'
              onClick={onEditClick}
              variant='link'
              sx={{
                backgroundColor: 'websiteBackground',
                borderColor: 'mostReadableOnWebsiteBackground',
                color: 'mostReadableOnWebsiteBackground',
                textAlign: 'center',
                '&:hover, &:focus, &.btn:first-child:active': {
                  backgroundColor: 'mostReadableOnWebsiteBackground',
                  borderColor: 'mostReadableOnWebsiteBackground',
                  color: 'leastReadableOnWebsiteBackground',
                },
              }}
            >
              {formatMessage(
                messages.purchaserDetailsCollapsedSummaryEditButtonText
              )}
            </Button>
            <div
              className='collapse-btn-divider'
              sx={{
                borderTopColor: 'mostReadableOnWebsiteBackground',
                width: '100%',
              }}
            />
          </div>
        </div>
      </Collapse>
      <Collapse in={isOpen} key={'open'}>
        <div>
          <h2 sx={{ mt: 6, textAlign: 'center' }}>
            {formatMessage(messages.purchaserDetailsFormSubtitle)}
          </h2>
          <RichText
            text={formatMessage(messages.purchaserDetailsFormRichText)}
          />
          <RecaptchaText styles={{ mt: 3 }} />
          <PurchaserDetailsForm
            widget={widget}
            isPageValidated={isPageValidated}
          />
        </div>
      </Collapse>
    </ContainedRow>
  );
};

export default PurchaserDetailsCollapse;
