import React from 'react';

import { useSelector } from 'react-redux';
import { Box, Flex } from 'theme-ui';

import {
  concessionItemSxProp,
  descriptionSxProp,
  detailSxProp,
  priceSxProp,
  quantitySxProp,
} from './constants';

import { Concession, ConcessionPricing } from '../../../@types/modelTypes';
import { displayPrice } from '../../../services/Helpers';
import { findModifiedConcessionItemCost } from '../../../services/KioskHelpers';
import {
  selectCurrencyConfig,
  selectSelectedFaBConcessions,
} from '../../../store/Selectors';

interface Props {
  hideTax: boolean;
}

const CartSummaryLoyaltyConcessions: React.FC<Props> = ({ hideTax }) => {
  const currencyConfig = useSelector(selectCurrencyConfig);
  const selectedConcessions = useSelector(selectSelectedFaBConcessions);

  const loyaltyConcession = (c: Concession, hideTax: boolean) => {
    const itemPricing: ConcessionPricing = findModifiedConcessionItemCost(c);
    return (
      <Flex className='concession-item' sx={concessionItemSxProp}>
        <Flex sx={descriptionSxProp}>{c.description}</Flex>
        <Flex sx={detailSxProp}>
          <Box className='tickets-quantity' sx={quantitySxProp}>
            x {c.quantity}
          </Box>
          {itemPricing.costMinusTax > 100 && (
            <Box className='price' sx={priceSxProp}>
              {displayPrice(
                c.quantity *
                  (hideTax ? itemPricing.costIncTax : itemPricing.costMinusTax),
                currencyConfig
              )}
            </Box>
          )}
        </Flex>
      </Flex>
    );
  };

  if (
    selectedConcessions === null ||
    selectedConcessions?.list === null ||
    !selectedConcessions?.list?.some((x: Concession) => x.quantity > 0)
  ) {
    return null;
  }

  return (
    <div className='cart-summary-row'>
      {selectedConcessions.list
        .filter((c: Concession) => c.quantity > 0)
        .map((c: Concession, ci: number) => (
          <div
            className='concessions-item-list'
            data-testid='cs-concessions'
            key={`c_${c.id}_${ci}`}
          >
            {loyaltyConcession(c, hideTax)}
          </div>
        ))}
    </div>
  );
};

export default CartSummaryLoyaltyConcessions;
