/** @jsxImportSource theme-ui */
import React from 'react';

import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import messages from './intl';
import NewsletterCheckbox from './NewsletterCheckbox';
import ThirdPartyCheckbox from './ThirdPartyCheckbox';

import { selectDazzlerLocale } from '../../../store/Selectors';
import RichText from '../../common/richtext/RichText';
import { resolveLocalisedStringOrDefault } from '../helpers';
import { WidgetData } from '../types';

interface Props {
  widget: WidgetData<'TicketingCMSJourneyPurchaserDetailsWidget'>;
  showNewsletterCheckbox: boolean;
  showThirdPartyCheckbox: boolean;
  onEdit?: () => void;
}

const GuestMarketing: React.FC<Props> = ({
  widget,
  showNewsletterCheckbox,
  showThirdPartyCheckbox,
  onEdit,
}) => {
  const { formatMessage } = useIntl();

  const locale = useSelector(selectDazzlerLocale);

  return (
    <>
      <h2 sx={{ mt: 5, textAlign: 'center' }}>
        {resolveLocalisedStringOrDefault(
          formatMessage(messages.guestMarketingSubtitle),
          locale,
          widget.shape?.guestMarketingSubtitle
        )}
      </h2>
      <RichText
        text={resolveLocalisedStringOrDefault(
          formatMessage(messages.guestMarketingRichText),
          locale,
          widget.shape?.guestMarketingRichText
        )}
        styles={{ textAlign: 'left' }}
      />
      {showNewsletterCheckbox && (
        <NewsletterCheckbox widget={widget} onEdit={onEdit} />
      )}
      {showThirdPartyCheckbox && (
        <ThirdPartyCheckbox widget={widget} onEdit={onEdit} />
      )}
    </>
  );
};

export default GuestMarketing;
