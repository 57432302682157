/** @jsxImportSource theme-ui */
import React, { useEffect, useRef, useState } from 'react';

import classnames from 'classnames';
import { Overlay, Tooltip } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { isFloat } from 'validator';

import { Concession } from '../../../../../@types/modelTypes';
import {
  getToolTipText,
  getValueLimitedToTwoDecimalPlaces,
} from '../../../../../services/GiftCardHelpers';
import {
  selectContent,
  selectCurrencySymbol,
} from '../../../../../store/Selectors';
import { ReactComponent as RadioSelectedSvg } from '../../../../../svgs/radioSelected.svg';

interface VariableCostConcession extends Concession {
  onChangeCostHandler: (concession: Concession) => void;
  handleConcessionSelect: (concession: Concession, isVariable: boolean) => void;
  selectedConcessionIndex: number;
  selectedGiftCard: Concession | undefined;
}

const VariableCostGiftCardButton: React.FC<VariableCostConcession> = ({
  id,
  code,
  description,
  extendedDescription,
  image,
  taxInCents,
  quantity,
  modifierGroups,
  isVariablePriceItem,
  minVariableCost,
  maxVariableCost,
  orderDelivery,
  onChangeCostHandler,
  handleConcessionSelect,
  selectedConcessionIndex,
  selectedGiftCard,
}) => {
  const concession: Concession = {
    id,
    code,
    description,
    extendedDescription,
    image,
    taxInCents,
    quantity,
    modifierGroups,
    isVariablePriceItem,
    minVariableCost,
    maxVariableCost,
    orderDelivery,
    modifiers: [],
    cost: 0,
    variablePriceInCents: 0,
    hidden: false,
    isSoldOut: false,
  };

  const content = useSelector(selectContent);

  const currentVariableCost =
    selectedGiftCard?.isVariablePriceItem && selectedGiftCard.cost > 0
      ? (selectedGiftCard.cost / 100).toString()
      : '';

  const [currentValue, setCurrentValue] = useState(currentVariableCost);
  const [showVariableCostTooltip, setShowVariableCostTooltip] = useState(false);
  const currencySymbol = useSelector(selectCurrencySymbol);
  const variableCostRef = useRef(null);

  const isSelected = selectedGiftCard && selectedGiftCard.id === concession.id;

  useEffect(() => {
    if (
      currentValue === '' ||
      (concession.minVariableCost &&
        concession.minVariableCost <= Number(currentValue) * 100)
    ) {
      setShowVariableCostTooltip(false);
    } else {
      setShowVariableCostTooltip(true);
    }
  }, [concession.minVariableCost, currentValue]);

  const handleChangeCost = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = getValueLimitedToTwoDecimalPlaces(e.target.value);
    const newValueInCents: number = Number(value) * 100;
    if (value === '' || value === '.' || isFloat(value)) {
      if (
        concession.maxVariableCost !== null &&
        concession.maxVariableCost < newValueInCents
      ) {
        setShowVariableCostTooltip(true);
        setTimeout(() => {
          setShowVariableCostTooltip(false);
        }, 4000);
        return;
      }
      setCurrentValue(value);
      concession.cost = newValueInCents;
      concession.variablePriceInCents = newValueInCents;
      onChangeCostHandler(concession);
    }
  };

  const getVariableCostGiftCardSelectedTitle = (
    currencySymbol: string,
    otherLabel: string,
    otherValue: string,
    selectedText: string
  ) => {
    return otherValue
      ? `${currencySymbol}${otherValue} ${selectedText}`
      : `${otherLabel} ${selectedText}`;
  };

  const otherLabel = content?.amountSelectorCustomAmountLabel;
  const selectedText = content?.amountSelectorSelectedText;
  const variableCostGiftCardTitle = isSelected
    ? getVariableCostGiftCardSelectedTitle(
        currencySymbol,
        otherLabel,
        currentValue,
        selectedText
      )
    : otherLabel;

  return (
    <div className='giftcard-container variable-price multi'>
      <div className='variable-price-giftcard'>
        <Overlay
          placement='top'
          show={showVariableCostTooltip}
          target={variableCostRef}
        >
          <Tooltip
            data-testid='other-giftcard-tooltip'
            id='eGiftCardVariableLimit'
          >
            {getToolTipText(
              content.giftCards.variableCostToolTip,
              concession.minVariableCost,
              concession.maxVariableCost,
              currencySymbol
            )}
          </Tooltip>
        </Overlay>
        <button
          className={classnames('giftcard-button', isSelected && 'selected')}
          data-testid='other-giftcard-button'
          onClick={() => {
            concession.cost = Number(currentValue) * 100;
            concession.variablePriceInCents = concession.cost;
            handleConcessionSelect(concession, true);
          }}
          title={variableCostGiftCardTitle}
          type='button'
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: isSelected ? 'flex-start' : 'center',
            input: {
              border: 0,
              borderBottom: 'mostReadableOnWebsiteBackgroundBorder',
              color: 'mostReadableOnWebsiteBackground',
            },
          }}
        >
          <label
            ref={variableCostRef}
            className='item-cost'
            sx={{
              fontWeight: 'bold',
              display: 'flex',
              alignItems: 'center',
              justifyContent: isSelected ? 'flex-start' : 'center',
            }}
          >
            {isSelected && <RadioSelectedSvg className='check' />}
            {isSelected ? '' : content.amountSelectorCustomAmountLabel}{' '}
            {currencySymbol}
          </label>
          {isSelected && (
            <input
              id={`${'variableCostInput' + selectedConcessionIndex}`}
              data-testid='other-amount-input'
              placeholder={'0'}
              value={currentValue}
              onChange={handleChangeCost}
              type='tel'
              min='1'
              autoFocus
            />
          )}
        </button>
      </div>
    </div>
  );
};

export default VariableCostGiftCardButton;
