/** @jsxImportSource theme-ui */
import React, { useState } from 'react';

import Button from 'react-bootstrap/Button';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Box } from 'theme-ui';

import { Concession } from '../../../@types/modelTypes';
import useConfirmationPageRedirect from '../../../hooks/useConfirmationPageRedirect';
import { useValidateConcessionsJourney } from '../../../hooks/useValidateConcessionsJourney';
import {
  selectBookingData,
  selectConfig,
  selectContent,
  selectCustomer,
  selectSelectedFaBConcessions,
} from '../../../store/Selectors';
import ContainedRow from '../../common/layout/ContainedRow';
import SummaryTierItem from '../../common/loyalty/SummaryTierItem';
import PaymentDesignWrapper from '../../common/payment/design/PaymentDesignWrapper';
import RecaptchaText from '../../common/recaptchatext/RecaptchaText';
import RichText from '../../common/richtext/RichText';
import TermsAndConditions from '../../common/termsandconditions/TermsAndConditions';

const LoyaltyUpgradePayment: React.FC = () => {
  const navigate = useNavigate();
  useValidateConcessionsJourney();
  const bookingData = useSelector(selectBookingData);
  const config = useSelector(selectConfig);
  const content = useSelector(selectContent);
  const customer = useSelector(selectCustomer);
  const selectedConcessions = useSelector(selectSelectedFaBConcessions);

  const [validateForms, setValidateForms] = useState(false);
  const [creditCardType, setCreditCardType] = useState('');

  const setPaymentFormVisibility = (nextShowPaymentForm: boolean) => {
    setValidateForms(!nextShowPaymentForm);
  };

  useConfirmationPageRedirect();

  const concession: Concession | undefined = selectedConcessions.list.find(
    (c: Concession) => c.quantity === 1
  );

  if (!content || !config || !bookingData) return null;

  return (
    <div
      className='loyalty payment'
      data-testid='loyalty-payment'
      sx={{ textAlign: 'center' }}
    >
      <div className='giftcards'>
        <ContainedRow>
          <h1 sx={{ textAlign: 'center' }}>
            {content.loyalty.upgradeMainTitle}
          </h1>
        </ContainedRow>
        <ContainedRow styles={{ mt: 4 }}>
          <h2 sx={{ textAlign: 'center' }}>
            3. {content.loyalty.paymentHeading}
          </h2>
          <RichText
            text={content.loyalty.upgradePaymentRichText}
            styles={{ textAlign: 'left' }}
          />
        </ContainedRow>
        <RecaptchaText />
        <Box
          className='loyalty-rows-container'
          sx={{ maxWidth: '800px', marginX: 'auto', mt: 5 }}
        >
          <Box className='summary-row'>
            {concession ? (
              <SummaryTierItem
                {...concession}
                key={concession.id}
                defaultImage={
                  content.physicalGiftCards.physicalGiftCardDefaultImage
                }
              />
            ) : null}
          </Box>
          <ContainedRow
            classNameWrapper='tiny'
            styles={{ pb: 5, textAlign: 'left' }}
          >
            <Box className='detail' mt={1}>
              <div sx={{ mt: 2 }}>
                <span sx={{ fontWeight: 'bold' }}>
                  {content.loyalty.paymentBillingNameLabel}
                </span>{' '}
                <span>{customer.name}</span>
              </div>

              <div>
                <span sx={{ fontWeight: 'bold' }}>
                  {content.loyalty.paymentBillingEmailLabel}
                </span>{' '}
                <span>{customer.email}</span>
              </div>
            </Box>
            <Box mt={6}>
              <Button
                onClick={() => navigate(-1)}
                variant='link'
                className='btn-edit'
                sx={{
                  textAlign: 'center',
                  textTransform: 'uppercase',
                  fontWeight: 'bold',
                }}
              >
                {content.loyalty.paymentEditText}
              </Button>
            </Box>
          </ContainedRow>
          <div className='separator' />
        </Box>
      </div>

      <ContainedRow textAlign='left'>
        <TermsAndConditions isPageValidated={validateForms} />
      </ContainedRow>

      <PaymentDesignWrapper
        giftcardEnabled={false}
        loyaltyPaymentEnabled={false}
        creditCardType={creditCardType}
        showPaymentForm={true}
        showGiftCardForm={false}
        isPageValidated={validateForms}
        handleValidatePage={() => {
          setValidateForms(true);
        }}
        setPaymentFormVisibility={setPaymentFormVisibility}
        setGiftCardFormVisibility={undefined}
        setCreditCardType={setCreditCardType}
      />
    </div>
  );
};

export default LoyaltyUpgradePayment;
