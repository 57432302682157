import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { Box } from 'theme-ui';

import {
  AttributeWarning,
  MovieRatingWarning,
  ScreenWarning,
} from '../../../@types/contentTypes';
import { useBoostNavigate } from '../../../hooks/useBoostNavigate';
import { getDisplayDateAndTime } from '../../../services/Helpers';
import { actionCreators } from '../../../store/ActionCreators';
import {
  selectBookingData,
  selectCartSummary,
  selectConfig,
  selectContent,
  selectSelectedLanguageCulture,
  selectStep,
} from '../../../store/Selectors';
import ActionButton from '../actionbutton/ActionButton';
import ContainedRow from '../layout/ContainedRow';
import RichText from '../richtext/RichText';

interface Props {
  setShowShowtimes: (showShowtimes: boolean) => void;
  attributeWarnings: AttributeWarning[];
  screenWarning: ScreenWarning | undefined;
  movieRatingWarnings: MovieRatingWarning[];
}

const ShowtimeWarning: React.FC<Props> = ({
  setShowShowtimes,
  attributeWarnings,
  screenWarning,
  movieRatingWarnings,
}) => {
  const dispatch = useDispatch();
  const boostNavigate = useBoostNavigate();
  const location = useLocation();

  const step = useSelector(selectStep);
  const bookingData = useSelector(selectBookingData);
  const config = useSelector(selectConfig);
  const content = useSelector(selectContent);
  const cartSummary = useSelector(selectCartSummary);
  const selectedLanguageCulture = useSelector(selectSelectedLanguageCulture);

  const handleContinueClick = () => {
    dispatch(actionCreators.setLoading(true));

    const nextStep =
      config?.currentCinema?.allowSignIn &&
      bookingData &&
      !bookingData.isUserValidated
        ? step + 1
        : step + 2;

    boostNavigate.navigateToStep(nextStep, {
      appendCinemaAndSessionIdsFromBookingData: true,
      search: location.search,
    });

    dispatch(actionCreators.setLoading(false));
  };

  const handleShowSessions = () => {
    setShowShowtimes(true);
  };

  const hasAttributeWarning = !!attributeWarnings.length;
  const hasMovieRatingWarnings = !!movieRatingWarnings.length;
  if (!hasAttributeWarning && !screenWarning && !hasMovieRatingWarnings)
    return null;
  const dateAndTime =
    cartSummary.sessionDate &&
    getDisplayDateAndTime(cartSummary.sessionDate, selectedLanguageCulture);

  return (
    <>
      <ContainedRow
        classNameWrapper='showtime-attribute-warning'
        styles={{ mt: 4 }}
      >
        <Box className='info-container' sx={{ p: 4, mx: 2 }}>
          {screenWarning && (
            <RichText text={screenWarning.screenWarningRichText} />
          )}
          {hasAttributeWarning &&
            attributeWarnings.map((aw: AttributeWarning, i: number) => (
              <RichText
                key={aw.attributeName}
                text={aw.attributeWarningRichText
                  .replace('##AttributeName##', aw.attributeName)
                  .replace(
                    '##DateTime##',
                    `${dateAndTime?.displayDate} - ${dateAndTime?.displayTime}`
                  )}
                styles={{ mt: screenWarning || i > 0 ? 5 : 0 }}
              />
            ))}
          {hasMovieRatingWarnings &&
            movieRatingWarnings.map((warning, i) => (
              <RichText
                key={warning.movieRatingName}
                text={warning.movieRatingWarningRichText}
                styles={{
                  mt: screenWarning || hasAttributeWarning || i > 0 ? 5 : 0,
                }}
              />
            ))}
        </Box>
      </ContainedRow>

      <ContainedRow textAlign='center'>
        <ActionButton
          onClick={handleContinueClick}
          showIcon
          contained
          hasMarginX
          mb={0}
          variant='primary'
        >
          {content.continueButtonText}
        </ActionButton>

        <ActionButton
          onClick={handleShowSessions}
          showIcon
          contained
          variant='secondary'
          hasMarginX
        >
          {content.selectANewShowtimeText}
        </ActionButton>
      </ContainedRow>
    </>
  );
};

export default ShowtimeWarning;
