import { useDispatch, useSelector } from 'react-redux';

import { actionCreators } from '../store/ActionCreators';
import { selectSelectedSeasonPass, selectToken } from '../store/Selectors';

export const useCancelExistingSeasonPassOrder = () => {
  const disptach = useDispatch();
  const dataToken = useSelector(selectToken);
  const selectedSeasonPass = useSelector(selectSelectedSeasonPass);

  const cancelExistingSeasonPassOrder = () => {
    const cancelOrderRequest = {
      dataToken: dataToken,
    };
    const cancelOrderHeaders = {
      type: 'application/json',
    };
    const blob = new Blob(
      [JSON.stringify(cancelOrderRequest)],
      cancelOrderHeaders
    );
    navigator.sendBeacon('api/order/cancel', blob);

    selectedSeasonPass.sessions = selectedSeasonPass.sessions.map((s) => ({
      ...s,
      seats: [],
    }));

    disptach(actionCreators.setSelectedSeasonPass(selectedSeasonPass));
    disptach(actionCreators.setCountDown(0));
  };

  return cancelExistingSeasonPassOrder;
};
