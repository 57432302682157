import React from 'react';

import { useSelector } from 'react-redux';
import { Box, Flex } from 'theme-ui';

import VoucherImage from './VoucherImage';

import { Concession, GlobalState } from '../../../@types/modelTypes';
import {
  selectSelectedFaBConcessions,
  selectContentVouchers,
  selectDisplayPrice,
} from '../../../store/Selectors';

interface VouchersRowProps {
  selectedConcessionIndex: number;
}

const PaymentSummaryRow: React.FC<VouchersRowProps> = ({
  selectedConcessionIndex,
}) => {
  const contentVouchers = useSelector(selectContentVouchers);
  const selectedConcessions = useSelector(selectSelectedFaBConcessions);
  const selectedVoucher: Concession | undefined =
    selectedConcessions.list.length > 0
      ? selectedConcessions.list[selectedConcessionIndex]
      : undefined;
  const selectedVoucherCostToDisplay = useSelector((state: GlobalState) =>
    selectDisplayPrice(state, selectedVoucher?.cost)
  );
  if (!selectedVoucher || selectedVoucher.quantity < 1) return null;

  const getDescription = () => {
    return `${selectedVoucher.description} x ${selectedVoucher.quantity}`;
  };

  return (
    <div className='giftcards-row summary'>
      <Flex
        sx={{ py: 5, alignItems: 'center', justifyContent: 'space-between' }}
      >
        <Flex sx={{ alignItems: 'center' }}>
          <Box className='image-container' mr={4}>
            <VoucherImage
              imageUrl={selectedVoucher.image || contentVouchers.defaultImage}
            />
          </Box>
          <Box
            className='summary-item'
            sx={{ textAlign: 'left', fontWeight: 'bold' }}
          >
            <span>{getDescription()}</span>
          </Box>
        </Flex>
        <Box className='summary-item' sx={{ fontWeight: 'bold' }}>
          <span>{selectedVoucherCostToDisplay}</span>
        </Box>
      </Flex>
    </div>
  );
};

export default PaymentSummaryRow;
