/** @jsxImportSource theme-ui */
import React from 'react';

import { Box } from 'theme-ui';

interface Props {
  warningTitle?: string;
  warningMessage?: string;
}

const WarningMessage: React.FC<Props> = ({ warningTitle, warningMessage }) => {
  if (!warningMessage) {
    return null;
  }

  return (
    <Box
      className='warning-container'
      data-testid='warning-message'
      sx={{ my: 4, p: 4 }}
    >
      <h3 sx={{ mt: 0, mb: 1, textAlign: 'center' }}>{warningTitle}</h3>
      {warningMessage && <p sx={{ m: 0 }}>{warningMessage}</p>}
    </Box>
  );
};

export default WarningMessage;
