import React from 'react';

import classnames from 'classnames';
import Image from 'react-bootstrap/Image';
import { useSelector } from 'react-redux';
import { Flex } from 'theme-ui';

import {
  selectImageProcessorContainer,
  selectImageProcessorUrl,
} from '../../../store/Selectors';
import { ReactComponent as GiftCardLargeSvg } from '../../../svgs/giftcardLarge.svg';

interface Props {
  imageUrl: string | null | undefined;
  isPhysicalGc?: boolean;
}
const GiftCardImage: React.FC<Props> = ({ imageUrl, isPhysicalGc }) => {
  const imageProcessorUrl = useSelector(selectImageProcessorUrl);
  const imageProcessorContainer = useSelector(selectImageProcessorContainer);

  const imageWidth = isPhysicalGc ? 200 : 100;

  const blobImage: string | null = imageUrl
    ? `${imageProcessorUrl}/${imageProcessorContainer}/${imageUrl.replace(
        'https://',
        ''
      )}?width=${imageWidth}`
    : '';

  return (
    <Flex
      className={classnames(isPhysicalGc ?? 'gift-card-image-container')}
      sx={{
        justifyContent: 'flex-start',
        alignItems: 'center',
        overflow: 'hidden',
      }}
    >
      {imageUrl ? (
        <Image src={blobImage} alt={'Gift Card'} rounded />
      ) : (
        <GiftCardLargeSvg className='fallback' />
      )}
    </Flex>
  );
};

export default GiftCardImage;
