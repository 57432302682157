import React from 'react';

import { Button } from 'react-bootstrap'; // Assuming you're using react-bootstrap
import { useDispatch, useSelector } from 'react-redux';
import { Box, Flex } from 'theme-ui';

import { QuantitySelectorContext } from '../../../@types/actionTypes';
import {
  TicketTypeModel,
  ValidatedMemberCard,
} from '../../../@types/modelTypes';
import { displayPrice } from '../../../services/Helpers';
import { actionCreators } from '../../../store/ActionCreators';
import {
  selectAppliedMemberCards,
  selectContent,
  selectCurrencyConfig,
} from '../../../store/Selectors';

interface AppliedMemberCardsProps {
  handleTicketButtonClick: (
    ticketTypeId: TicketTypeModel['id'],
    context: QuantitySelectorContext
  ) => Promise<void>;
  tickets: TicketTypeModel[];
}

const AppliedMemberCards: React.FC<AppliedMemberCardsProps> = ({
  handleTicketButtonClick,
  tickets,
}) => {
  const dispatch = useDispatch();

  const content = useSelector(selectContent);
  const appliedMemberCards: ValidatedMemberCard[] = useSelector(
    selectAppliedMemberCards
  );
  const currencyConfig = useSelector(selectCurrencyConfig);

  const handleRemoveMemberCard = (memberCardNo: string) => {
    const appliedMemberCard = appliedMemberCards?.find(
      (usedMemberCard) => usedMemberCard.memberCardNumber === memberCardNo
    );
    const ticketIdToRemove = appliedMemberCard?.usedTicket.id;
    if (ticketIdToRemove) {
      const memberTicket = tickets.find((t) => t.id === ticketIdToRemove);
      if (memberTicket?.validatedVouchers) {
        memberTicket.validatedVouchers = memberTicket.validatedVouchers.filter(
          (card) => card !== memberCardNo
        );
      }
      dispatch(actionCreators.removeMemberCard(memberCardNo));
      handleTicketButtonClick(ticketIdToRemove, 'remove');
    }
  };

  return (
    <div
      className='applied-loyalty-cards-container'
      data-testid='applied-loyalty-cards'
    >
      <Box
        className='h3'
        sx={{
          borderTop: 'bodySeparator',
          borderBottom: 'bodySeparator',
          pt: 6,
          pb: 4,
          textAlign: 'center',
        }}
      >
        {content.tickets.memberTicketsAdditionalTicketHeading}
      </Box>
      {appliedMemberCards?.map((usedMemberCard: ValidatedMemberCard) => (
        <Flex
          className='applied-loyalty-cards-item'
          key={usedMemberCard?.memberCardNumber}
          sx={{
            borderTop: 'bodySeparator',
            py: 4,
          }}
        >
          <Box sx={{ flexGrow: 1, pr: 2, textAlign: 'left' }}>
            <span>
              {content.tickets.memberTicketsAddedMemberCardText}
              {usedMemberCard?.memberCardNumber}
            </span>
          </Box>
          <Flex
            sx={{
              alignItems: 'center',
              justifyContent: 'flex-end',
              flexShrink: 1,
              minWidth: '130px',
            }}
          >
            <Flex
              className='small'
              sx={{
                alignItems: 'center',
                justifyContent: 'center',
                mr: 3,
              }}
            >
              <Button
                onClick={() =>
                  handleRemoveMemberCard(usedMemberCard?.memberCardNumber)
                }
                variant='link'
                size='sm'
              >
                <span className='tiny'>
                  {content.payment.removeButtonText.toUpperCase()}
                </span>
              </Button>
            </Flex>
            <Flex
              sx={{
                alignItems: 'center',
                justifyContent: 'center',
                fontWeight: 'bold',
              }}
            >
              <span>
                {displayPrice(usedMemberCard.usedTicket.price, currencyConfig)}
              </span>
            </Flex>
          </Flex>
        </Flex>
      ))}
    </div>
  );
};

export default AppliedMemberCards;
