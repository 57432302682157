import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { PaymentProvidersEnum } from '../../@types/enums';
import { actionCreators } from '../../store/ActionCreators';
import {
  selectHostedPayments,
  selectIsHostedPaymentInProgress,
} from '../../store/Selectors';

export const useHostedPaymentUpdater = (pathname: string) => {
  const dispatch = useDispatch();
  const hostedPayments = useSelector(selectHostedPayments);
  const isHostedPaymentInProgress = useSelector(
    selectIsHostedPaymentInProgress
  );
  useEffect(() => {
    if (isHostedPaymentInProgress && !pathname.includes('/payment')) {
      dispatch(actionCreators.setHostedPaymentInProgress(false));
    } else if (
      !!hostedPayments[PaymentProvidersEnum.VANTIVHPIFRAME] &&
      pathname.includes('/payment') &&
      !isHostedPaymentInProgress
    ) {
      dispatch(actionCreators.setHostedPaymentInProgress(true));
    }
  }, [dispatch, hostedPayments, isHostedPaymentInProgress, pathname]);
};
