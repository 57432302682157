import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';

import { actionCreators } from '../store/ActionCreators';
import {
  selectConfig,
  selectSelectedLanguageCulture,
} from '../store/Selectors';

export const useInitializeLanguageCulture = () => {
  const [cookies] = useCookies();
  const config = useSelector(selectConfig);
  const selectedLanguageCulture = useSelector(selectSelectedLanguageCulture);
  const dispatch = useDispatch();

  if (cookies.preferredLanguageCulture) {
    if (cookies.preferredLanguageCulture !== selectedLanguageCulture) {
      dispatch(
        actionCreators.setSelectedLanguageCulture(
          cookies.preferredLanguageCulture
        )
      );
    }
    return;
  }

  if (
    config?.languageCulture &&
    config?.languageCulture !== selectedLanguageCulture
  ) {
    dispatch(actionCreators.setSelectedLanguageCulture(config.languageCulture));
  }
};
