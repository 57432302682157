import { expiration } from 'creditcards';

import { Config } from '../@types/configTypes';
import { PAYMENT_FEEDBACK_MESSAGES } from '../constants';

export const getCardNumberInvalidMessageKey = (state: {
  nameOnCard?: string;
  nameOnCardIsValid?: boolean;
  nameOnCardIsValidated?: boolean;
  cardNumberForDisplay?: string;
  cardNumber: string;
  cardNumberIsValid: boolean;
  cardNumberIsValidated?: boolean;
  cardType?: string;
  cardTypeIsValid: boolean;
  cardTypeIsValidated?: boolean;
  setCreditCardType?: string;
  month?: string;
  monthIsValid?: boolean;
  monthIsValidated?: boolean;
  year?: string;
  yearIsValid?: boolean;
  yearIsValidated?: boolean;
  expiryDateIsValid?: boolean;
  cvvCode?: string;
  cvvCodeIsValid?: boolean;
  cvvCodeIsValidated?: boolean;
  zipCode?: string;
  zipCodeIsValid?: boolean;
  zipCodeIsValidated?: boolean;
  address?: string;
  addressIsValid?: boolean;
  addressIsValidated?: boolean;
}) => {
  if (!state.cardNumber) {
    return PAYMENT_FEEDBACK_MESSAGES.CARD_NUMBER_REQUIRED;
  } else if (!state.cardNumberIsValid) {
    return PAYMENT_FEEDBACK_MESSAGES.CARD_NUMBER_INVALID;
  } else if (state.cardNumberIsValid && !state.cardTypeIsValid) {
    return PAYMENT_FEEDBACK_MESSAGES.CARD_TYPE_NOT_SUPPORTED;
  } else if (state.cardNumberIsValid && state.cardTypeIsValid) {
    return PAYMENT_FEEDBACK_MESSAGES.VALID;
  }
  return '';
};

export const getCvvInvalidMessageKey = (state: {
  nameOnCard?: string;
  nameOnCardIsValid?: boolean;
  nameOnCardIsValidated?: boolean;
  cardNumberForDisplay?: string;
  cardNumber?: string;
  cardNumberIsValid?: boolean;
  cardNumberIsValidated?: boolean;
  cardType?: string;
  cardTypeIsValid?: boolean;
  cardTypeIsValidated?: boolean;
  setCreditCardType?: string;
  month?: string;
  monthIsValid?: boolean;
  monthIsValidated?: boolean;
  year?: string;
  yearIsValid?: boolean;
  yearIsValidated?: boolean;
  expiryDateIsValid?: boolean;
  cvvCode: string;
  cvvCodeIsValid: boolean;
  cvvCodeIsValidated?: boolean;
  zipCode?: string;
  zipCodeIsValid?: boolean;
  zipCodeIsValidated?: boolean;
  address?: string;
  addressIsValid?: boolean;
  addressIsValidated?: boolean;
}) => {
  if (!state.cvvCode) {
    return PAYMENT_FEEDBACK_MESSAGES.CVV_CODE_REQUIRED;
  } else if (!state.cvvCodeIsValid) {
    return PAYMENT_FEEDBACK_MESSAGES.CVV_CODE_INVALID;
  } else if (state.cvvCodeIsValid) {
    return PAYMENT_FEEDBACK_MESSAGES.VALID;
  }
  return '';
};

export const getExpDateInvalidMessageKey = (
  state: {
    nameOnCard?: string;
    nameOnCardIsValid?: boolean;
    nameOnCardIsValidated?: boolean;
    cardNumberForDisplay?: string;
    cardNumber?: string;
    cardNumberIsValid?: boolean;
    cardNumberIsValidated?: boolean;
    cardType?: string;
    cardTypeIsValid?: boolean;
    cardTypeIsValidated?: boolean;
    setCreditCardType?: string;
    month: string;
    monthIsValid: boolean;
    monthIsValidated?: boolean;
    year: string;
    yearIsValid: boolean;
    yearIsValidated?: boolean;
    expiryDateIsValid: boolean;
    cvvCode?: string;
    cvvCodeIsValid?: boolean;
    cvvCodeIsValidated?: boolean;
    zipCode?: string;
    zipCodeIsValid?: boolean;
    zipCodeIsValidated?: boolean;
    address?: string;
    addressIsValid?: boolean;
    addressIsValidated?: boolean;
  },
  type: string
) => {
  if (type === 'month') {
    if (!state.month) {
      return PAYMENT_FEEDBACK_MESSAGES.EXP_MONTH_REQUIRED;
    } else if (!state.expiryDateIsValid) {
      return PAYMENT_FEEDBACK_MESSAGES.DATE_IN_PAST;
    } else if (state.monthIsValid) {
      return PAYMENT_FEEDBACK_MESSAGES.VALID;
    }
  } else {
    if (!state.year) {
      return PAYMENT_FEEDBACK_MESSAGES.EXP_YEAR_REQUIRED;
    } else if (!state.expiryDateIsValid) {
      return PAYMENT_FEEDBACK_MESSAGES.DATE_IN_PAST;
    } else if (state.yearIsValid) {
      return PAYMENT_FEEDBACK_MESSAGES.VALID;
    }
  }
  return '';
};

export const isExpiryDateValid = (month: string, year: string) => {
  const monthInt = parseInt(month, 10);
  const yearInt = parseInt(year, 10);

  if (isNaN(monthInt) || isNaN(yearInt)) {
    return false;
  }

  return !expiration.isPast(monthInt, yearInt);
};

export const isCardTypeValid = (cardType: string, config: Config) => {
  switch (cardType) {
    case 'American Express':
      return !config.payment.hideAmex;
    case 'Discover':
      return !config.payment.hideDiscover;
    case 'Maestro':
      return !config.payment.hideMaestro;
    case 'Mastercard':
      return !config.payment.hideMastercard;
    case 'Visa':
      return !config.payment.hideVisa;
    default:
      return false;
  }
};

export const tryGetTestCard = (cardNumber: string) => {
  return testCards.find((card) => card.number === cardNumber);
};

const testCards = [
  { number: '1111222233334444', type: 'Visa' },
  { number: '4215176886320100', type: 'Visa' },
];
