import { ThemeUIStyleObject } from 'theme-ui';

export const commonContainerSx: ThemeUIStyleObject = {
  padding: [2, 5],
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '0 auto',
  borderRadius: '8px',
};
