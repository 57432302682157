import * as JourneyService from './JourneyService';

import { Content } from '../@types/contentTypes';
import { JourneyTypeConfig } from '../@types/modelTypes';
import { PEACH_CODES as pc } from '../constants';
type Lookup = {
  code: number;
  text: string;
};

export function getContentForError(code: number, content: Content) {
  const e = content.error;
  const dictionary: Lookup[] = [
    { code: pc.noError, text: '' },
    { code: pc.ok, text: '' },
    { code: pc.unknownError, text: e.unexpectedErrorRichText },
    { code: pc.providerInternalError, text: e.unexpectedErrorRichText },
    { code: pc.generalError, text: e.unexpectedErrorRichText },
    { code: pc.invalidExternalData, text: e.invalidOrderErrorRichText },
    { code: pc.invalidOrder, text: e.invalidOrderErrorRichText },
    { code: pc.orderHasBeenProcessed, text: e.invalidOrderErrorRichText },
    { code: pc.orderMaxRetriesReached, text: e.invalidOrderErrorRichText },
    { code: pc.securityIssue, text: e.securityIssueRichText },
    { code: pc.splitPaymentFailed, text: e.paymentDeclinedRichText },
    { code: pc.paymentError, text: e.paymentDeclinedRichText },
    {
      code: pc.hostedPaymentPaymentDeclined,
      text: e.paymentDeclinedRichText,
    },
    { code: pc.paymentInvalid, text: e.paymentDeclinedRichText },
    { code: pc.paymentDeclined, text: e.paymentDeclinedRichText },
    { code: pc.orderHasTimeout, text: e.orderTimedOutRichText },
    {
      code: pc.paymentMightHaveBeenTaken,
      text: e.paymentTakenInErrorRichText,
    },
    { code: pc.paymentTakenWithError, text: e.paymentTakenInErrorRichText },
    { code: pc.refundFailed, text: e.refundFailedRichText },
    { code: pc.tooLateForRefund, text: e.tooLateForRefundRichText },
    { code: pc.orderAlreadyRefunded, text: e.orderAlreadyRefundedRichText },
    { code: pc.refunded, text: e.paymentRefundedErrorRichText },
    {
      code: pc.giftCardPaymentBalanceInSufficient,
      text: e.giftCardInsufficientErrorRichText,
    },
    {
      code: pc.needComplementaryPayment,
      text: e.giftCardInsufficientErrorRichText,
    },
    { code: pc.seatsUnavailable, text: e.seatsUnavailableRichText },
    { code: pc.chooseOtherSeats, text: e.seatsAlreadyTakenRichText },
    {
      code: pc.seatsUnavailableRefreshLayout,
      text: e.seatsAlreadyTakenRichText,
    },
    {
      code: pc.problemGiftCardProvider,
      text: e.giftCardBalanceErrorRichText,
    },
    {
      code: pc.hostedPaymentCardTypeNotAccepted,
      text: e.paymentCardTypeNotAcceptedRichText,
    },
    { code: pc.sessionSoldOut, text: e.sessionSoldOutRichText },
    { code: pc.seatingCapacityExceeded, text: e.sessionSoldOutRichText },
    {
      code: pc.concessionsCouldNotBeAdded,
      text: e.concessionsCouldNotBeAddedRichText,
    },
    { code: pc.gratuityNotAccepted, text: e.gratuityNotAcceptedRichText },
    { code: pc.recaptchaFailed, text: e.captchaFailedRichText },
    { code: pc.turnstileFailed, text: e.captchaFailedRichText },
    { code: pc.orderNotFound, text: e.unableToRetrieveBookingRichText },
    {
      code: pc.unexpectedConfigurationFound,
      text: e.foodAndBeverageConfigurationNotValidRichText,
    },
    {
      code: pc.tooEarlyToOrderFoodAndBeverage,
      text: e.tooEarlyToOrderFoodAndBeverageRichText,
    },
    {
      code: pc.tooLateToOrderFoodAndBeverage,
      text: e.tooLateToOrderFoodAndBeverageRichText,
    },
    {
      code: pc.foodAndBeverageDisabled,
      text: e.foodAndBeverageOrderingIsDisabledRichText,
    },
    { code: pc.sessionNotBookable, text: e.sessionNotBookableRichText },
    {
      code: pc.memberDetailsIncorrect,
      text: e.loginDetailsIncorrectRichText,
    },
    {
      code: pc.loyaltyMemberAlreadyExists,
      text: e.loyaltyMemberAlreadyExistsRichText,
    },
    {
      code: pc.unavailablePromotionTicket,
      text: e.promotionTicketsNotAvailableRichText,
    },
    {
      code: pc.invalidMemberCard,
      text: content.tickets.memberTicketsMemberCardInvalidErrorText,
    },
    {
      code: pc.loyaltyMemberAlreadyExists,
      text: e.loyaltyMemberAlreadyExistsRichText,
    },
    {
      code: pc.loyaltyMemberBlocked,
      text: e.loyaltyMemberBlockedRichText,
    },
    {
      code: pc.refundNotPermitted,
      text: content.refund.refundNotPermittedText,
    },
    {
      code: pc.invalidCardPaymentPromoBankNumber,
      text: content.tickets.cardPaymentPromoTicketsInvalidCardNumberErrorText,
    },
  ];
  const errorConfig = dictionary.find((x) => x.code === code);
  return errorConfig ? errorConfig.text : content.error.networkErrorRichText;
}

export function shouldRestartJourney(
  code: number,
  journeyTypeConfig: JourneyTypeConfig
) {
  switch (code) {
    case pc.orderHasTimeout:
    case pc.paymentMightHaveBeenTaken:
    case pc.paymentTakenWithError:
    case pc.invalidExternalData:
    case pc.orderHasBeenProcessed:
    case pc.orderMaxRetriesReached:
    case pc.invalidOrder:
      return true;
  }
  if (code === pc.orderNotFound) {
    return !journeyTypeConfig.isConcessionsOnlyJourney;
  }
}

export const shouldTryGoBack = (
  pathname: string,
  modalContext: number,
  journeyTypeConfig: JourneyTypeConfig
) => {
  if (pathname.startsWith('/startticketing/')) return true;
  switch (modalContext) {
    case pc.seatingCapacityExceeded:
    case pc.seatsUnavailableRefreshLayout:
    case pc.chooseOtherSeats:
    case pc.tooEarlyToOrderFoodAndBeverage:
    case pc.tooLateToOrderFoodAndBeverage:
    case pc.posError:
    case pc.orderNotFound:
    case pc.loyaltyMemberBlocked:
      return false;
    case pc.sessionExpired:
    case pc.sessionSoldOut:
    case pc.unexpectedConfigurationFound:
    case pc.foodAndBeverageDisabled:
    case pc.sessionNotBookable:
      return true;
  }
  const firstRoute = JourneyService.getRouteFromStep(journeyTypeConfig, 1);
  return pathname.startsWith(`/${firstRoute}/`);
};
