import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { actionCreators } from '../store/ActionCreators';
import {
  selectTicketTypes,
  selectUseBookingFeeStrategyInTickets,
  shouldDisplayBookingFeeOnTicketsStep,
} from '../store/Selectors';

export const useSetBookingFeeAndTax = () => {
  const dispatch = useDispatch();
  const ticketTypes = useSelector(selectTicketTypes);
  const { useBookingFeeStrategy } = useSelector(
    selectUseBookingFeeStrategyInTickets
  );
  const shouldDisplayInTicketConfig = useSelector(
    shouldDisplayBookingFeeOnTicketsStep
  );

  useEffect(() => {
    if (!ticketTypes || useBookingFeeStrategy || !shouldDisplayInTicketConfig) {
      return;
    }

    const { ticketTypeModels } = ticketTypes;

    if (!ticketTypeModels.some((t) => t.bookingFee > 0)) {
      return;
    }

    const bookingFee = ticketTypeModels.reduce((total, ticketTypeModel) => {
      return total + ticketTypeModel.bookingFee * ticketTypeModel.quantity;
    }, 0);

    const bookingFeeTax = ticketTypeModels.reduce((total, ticketTypeModel) => {
      return total + ticketTypeModel.bookingFeeTax * ticketTypeModel.quantity;
    }, 0);

    dispatch(actionCreators.setBookingFeeAndTax(bookingFee, bookingFeeTax));
  }, [
    dispatch,
    shouldDisplayInTicketConfig,
    ticketTypes,
    useBookingFeeStrategy,
  ]);
};
