import React from 'react';

import { useSelector } from 'react-redux';
import { Box } from 'theme-ui';

import VoucherItem from './VoucherItem';

import { Concession } from '../../../@types/modelTypes';
import { selectContent, selectEVouchersGroup } from '../../../store/Selectors';

const VoucherKiosk: React.FC = () => {
  const content = useSelector(selectContent);
  const concessionGrouping = useSelector(selectEVouchersGroup);

  return (
    <Box className='voucher-items'>
      {concessionGrouping?.items?.map((concession: Concession) => (
        <VoucherItem
          {...concession}
          defaultImage={content.vouchers.defaultImage}
          key={concession.id}
        />
      ))}
    </Box>
  );
};

export default VoucherKiosk;
