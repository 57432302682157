import React from 'react';

import { useSelector } from 'react-redux';
import { Box, Flex } from 'theme-ui';

import TierImage from './TierImage';

import {
  Concession,
  ConcessionPricing,
  GlobalState,
} from '../../../@types/modelTypes';
import { findModifiedConcessionItemCost } from '../../../services/KioskHelpers';
import {
  selectContent,
  selectCurrentCinema,
  selectDisplayPrice,
  selectSelectedFaBConcessions,
} from '../../../store/Selectors';

interface Props extends Concession {
  defaultImage: string;
}

const SummaryTierItem: React.FC<Props> = ({
  id,
  code,
  description,
  extendedDescription,
  image,
  taxInCents,
  cost,
  quantity,
  modifierGroups,
  isVariablePriceItem,
  minVariableCost,
  maxVariableCost,
  defaultImage,
}) => {
  const concession: Concession = {
    id,
    code,
    description,
    extendedDescription,
    image,
    taxInCents,
    cost,
    quantity,
    modifierGroups,
    modifiers: [],
    isVariablePriceItem,
    minVariableCost,
    maxVariableCost,
    hidden: false,
    variablePriceInCents: 0,
    orderDelivery: null,
    isSoldOut: false,
  };
  const currentCinema = useSelector(selectCurrentCinema);
  const content = useSelector(selectContent);
  const selectedConcessions = useSelector(selectSelectedFaBConcessions);

  const hideTax: boolean = currentCinema.hideTax;

  const imagePath: string = concession.image
    ? concession.image.replace('https://', '')
    : defaultImage;

  const selectedConcession: Concession | undefined =
    selectedConcessions.list.find((x) => x.id === concession.id);

  const quantityInCart: number = selectedConcession
    ? selectedConcession.quantity
    : 0;

  const itemPricing: ConcessionPricing =
    findModifiedConcessionItemCost(concession);

  const displayPrice = hideTax
    ? itemPricing.costIncTax
    : itemPricing.costMinusTax;
  const subTotal = (quantityInCart > 0 ? quantityInCart : 1) * displayPrice;

  const priceToDisplay = useSelector((state: GlobalState) =>
    selectDisplayPrice(state, subTotal)
  );
  const selected = quantityInCart >= 1;

  return (
    <Box
      sx={{
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'space-between',
        display: ['block', 'block', 'flex'],
      }}
    >
      <Flex sx={{ alignItems: 'center' }}>
        <Box className='image-container' py={4}>
          <TierImage imageUrl={imagePath || content.giftCards.defaultImage} />
        </Box>
        <Box className='details' sx={{ textAlign: 'left', pl: 4 }}>
          <span className='h3'>{concession.description}</span>
        </Box>
      </Flex>
      <Box sx={{ fontWeight: selected ? 'bold' : 'normal' }}>
        {priceToDisplay}
      </Box>
    </Box>
  );
};

export default SummaryTierItem;
