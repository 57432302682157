/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-empty-function */

import { extractPurchaseProperties } from './analyticsHelpers';

import { TrackingEvent } from '../@types/trackingTypes';
import { getTotalNumberActualTickets } from '../services/Helpers';

export default function tbcoReferralPlugin(tbcoConfig) {
  return {
    name: 'tbcoReferralTracking',
    config: { ...tbcoConfig },
    initialize: ({ config }) => {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = config.referralTrackingUri;
      script.setAttribute('data-tracking-id', config.referralTrackingId);
      document.head.appendChild(script);
    },
    page: () => {},
    track: ({ payload }) => {
      const { properties, event } = payload;
      if (event === TrackingEvent.PURCHASE) {
        const purchaseProperties = extractPurchaseProperties(properties);
        processPurchaseEvent(Date.now(), purchaseProperties);
      }
    },
    identify: () => {},
    loaded: ({ config }) => {
      const loaded = scriptLoaded(config.referralTrackingUri);
      return loaded;
    },
  };
}

function scriptLoaded(scriptSrc) {
  const scripts = document.querySelectorAll('script[src]');
  const regex = new RegExp(`^${scriptSrc}`);
  return Boolean(
    Object.values(scripts).filter((value) => regex.test(value.src)).length
  );
}

const processPurchaseEvent = (startTime, properties) => {
  if (!properties) {
    return;
  }

  const { confirmationData, ticketTypes, config, bookingData, cartSummary } =
    properties;
  const scriptLoadingMsTimeout = 10000;
  const elapsedTime = Date.now() - startTime;
  if (elapsedTime >= scriptLoadingMsTimeout) {
    // eslint-disable-next-line no-console
    console.error('tbco script exceed the timeout defined to load');
    return;
  }

  if (window.tbco) {
    const totalPriceAsDecimal = parseFloat(
      (confirmationData?.totalPrice / 100).toFixed(2)
    );
    const conversionEvent = new CustomEvent('tbco.conversion', {
      detail: {
        tracking_id: config.referralTrackingId,
        order_number: confirmationData?.externalOrderId,
        amount: totalPriceAsDecimal,
        quantity: getTotalNumberActualTickets(ticketTypes.ticketTypeModels),
        product_name: cartSummary.title,
        location_id: config.currentCinema?.title,
        loyalty_member: bookingData && bookingData.isUserValidated,
      },
    });
    console.log('dispatching tbco conversion');
    document.dispatchEvent(conversionEvent);
  } else {
    console.log('no window.tbco, deferring');
    setTimeout(processPurchaseEvent, 50, startTime);
  }
};
