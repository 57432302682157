import React, { FC, memo } from 'react';

import { WidgetData } from '../types';
type Props = {
  widget: WidgetData<'TicketingCMSJourneyImageWidget'>;
};
// TODO - use widget.shape?.width to determine the size of the image
// FULL = full width
// ORIGNAL = original size/max width of full?
export const Image: FC<Props> = ({ widget }) => {
  return (
    <div className='widget-row'>
      <img
        src={widget.shape?.src}
        alt={widget.shape?.alt}
        style={{ maxWidth: '100%' }}
      />
    </div>
  );
};

export default memo(Image);
