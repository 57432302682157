/** @jsxImportSource theme-ui */
import React from 'react';

import ContainedRow from '../../layout/ContainedRow';

const RoktWidget = () => {
  return (
    <ContainedRow>
      <div sx={{ mt: 6, textAlign: 'center' }}>
        <div id='rokt-above-payments' />
      </div>
    </ContainedRow>
  );
};

export default RoktWidget;
