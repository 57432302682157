export const resolveLocalisedStringOrDefault = (
  defaultContent: string,
  locale: string,
  field:
    | ReadonlyArray<{
        readonly __typename?: 'LocalizedString';
        readonly locale: string;
        readonly value: string;
      }>
    | undefined
) => {
  // do not replace || with ?? as per sonarcloud's advice. It will return empty string instead of falling back
  return field?.find((x) => x.locale === locale)?.value || defaultContent;
};
