export type DidomiConsentResult = boolean | null | undefined;

export type ConsentData = {
  analytics: DidomiConsentResult;
  marketing: DidomiConsentResult;
};

export type DidomiRegulation =
  | 'gdpr' // European law
  | 'ccpa' // California law (legacy)
  | 'cpra' // California law
  | 'cpa' // Colorado law
  | 'ctdpa' // Connecticut law
  | 'vcdpa'; // Virginia law

export type DidomiInstance = {
  isConsentRequired?: () => DidomiConsentResult;
  getUserConsentStatusForVendor?: (vendorId: string) => DidomiConsentResult;
  isRegulationApplied?: (
    regulation: DidomiRegulation
  ) => boolean | null | undefined;
  CCPA?: {
    getDoNotSellStatus?: () => DidomiConsentResult;
  };
  on?: (eventName: 'consent.changed', callback: () => void) => void;
  preferences?: {
    show?: () => void;
  };
  isUserConsentStatusPartial?: () => boolean;
  setUserAgreeToAll?: () => void;
};

export const REGULATIONS: DidomiRegulation[] = [
  'gdpr',
  'ccpa',
  'cpra',
  'cpa',
  'ctdpa',
  'vcdpa',
];

export const getRegulation = (
  didomiInstance: DidomiInstance | null | undefined
): DidomiRegulation | null => {
  for (let index = 0; index < REGULATIONS.length; index++) {
    const regulation = REGULATIONS[index];
    if (didomiInstance?.isRegulationApplied?.(regulation)) {
      return regulation;
    }
  }

  return null;
};
