import {
  defaultConfig,
  defaultGtagConf,
  gtagScriptSource,
} from './googleAnalyticsHelpers';

import { TrackingEvent, TrackingStrategy } from '../@types/trackingTypes';
import { TrackingStrategyResolver } from '../services/TrackingStrategyResolver';
let loadedInstances = {};

export const gaName = 'gaWithTracking';

export function googleAnalyticsWithTracking(pluginConfig = {}) {
  let pageCallCount = 0;
  let measurementIds = getIds(pluginConfig.measurementIds);

  const initConfig = {
    ...defaultConfig,
    ...pluginConfig,
  };

  return {
    name: gaName,
    config: initConfig,
    // Load gtag.js and define gtag
    initialize: ({ config, instance }) => {
      const { dataLayerName, customScriptSrc, gtagName, gtagConfig, debug } =
        config;
      /* Inject google gtag.js script if not found */
      /* If other gtags are loaded already, add ours anyway */
      const customLayerName = dataLayerName ? `&l=${dataLayerName}` : '';
      const src =
        customScriptSrc ||
        `${gtagScriptSource}?id=${measurementIds[0]}${customLayerName}`;
      if (!scriptLoaded(src)) {
        const script = document.createElement('script');
        script.async = true;
        script.src = src;
        document.body.appendChild(script);
      }
      /* Set up gtag and datalayer */
      if (!window[dataLayerName]) {
        window[dataLayerName] = window[dataLayerName] || [];
      }
      if (!window[gtagName]) {
        window[gtagName] = function () {
          window[dataLayerName].push(arguments);
        };
      }
      window[gtagName]('js', new Date());

      // Initialize tracker instances on page
      let gtagConf = {
        ...defaultGtagConf,
        ...(gtagConfig || {}),
      };
      // You must explicitly delete the debug_mode parameter or all sessions will fire in debug more. Setting it false is not enough.
      // https://support.google.com/analytics/answer/7201382?hl=en&ref_topic=9303319#zippy=%2Cgoogle-tag-websites:~:text=To%20disable%20debug%20mode%2C%20exclude%20the%20%27debug_mode%27%20parameter%3B%20setting%20the%20parameter%20to%20false%20doesn%27t%20disable%20debug%20mode.
      if (debug === true) {
        gtagConf.debug_mode = true;
      } else {
        delete gtagConf.debug_mode;
      }
      /* set custom dimensions from user traits */
      const user = instance.user() || {};
      const traits = user.traits || {};
      if (Object.keys(traits).length) {
        window[gtagName]('set', 'user_properties', traits);
      }
      /* Initialize all measurementIds */
      for (const measurementId of measurementIds) {
        if (!loadedInstances[measurementId]) {
          window[gtagName]('config', measurementId, gtagConf);
          loadedInstances[measurementId] = true;
        }
      }
    },
    identify: ({ payload, config }) => {
      const { gtagName } = config;
      if (!window[gtagName] || !measurementIds.length) return;
      if (payload.userId) {
        // https://developers.google.com/analytics/devguides/collection/ga4/user-id?platform=websites#send_user_ids
        window[gtagName]('set', { user_id: payload.userId });
      }
      // https://developers.google.com/analytics/devguides/collection/ga4/user-properties?technology=websites
      if (Object.keys(payload.traits).length) {
        window[gtagName]('set', 'user_properties', payload.traits);
      }
    },
    page: ({ payload, config, instance }) => {
      const { gtagName, gtagConfig, dataLayerName } = config;
      if (!window[gtagName] || !measurementIds.length) return;
      const { properties } = payload;
      const { send_to } = properties;
      const campaign = instance.getState('context.campaign');
      if (
        properties.globalState?.config?.trackingStrategy === null ||
        properties.globalState?.config?.trackingStrategy === undefined
      ) {
        return;
      }
      if (
        properties.globalState?.config.trackingStrategy !=
        TrackingStrategy.DEFAULT
      ) {
        const globalState = properties.globalState;
        const trackingStrategy = TrackingStrategyResolver.resolve(
          globalState?.config.trackingStrategy
        );
        trackingStrategy.processEvent(TrackingEvent.PAGE_VIEW, globalState, {
          dataLayerName,
        });
      }
      /* Create pageview-related properties */
      const pageView = {
        page_title: properties.title,
        page_location: properties.url,
        page_path: properties.path || document.location.pathname,
        page_hash: properties.hash,
        page_search: properties.search,
        page_referrer: properties.referrer,
      };
      const campaignData = addCampaignData(campaign);
      const userId = instance.user('userId');
      const isLoggedIn =
        properties?.globalState?.bookingData?.isUserValidated ?? false;
      const finalPayload = {
        ...(send_to ? { send_to } : {}),
        ...pageView,
        ...campaignData,
        ...(userId ? { user_id: userId } : {}),
        isLoggedIn,
      };
      /* If send_page_view true, ignore first analytics.page call */
      if (gtagConfig?.send_page_view && pageCallCount === 0) {
        pageCallCount++;
        return;
      }
      window[gtagName]('event', 'page_view', finalPayload);
      pageCallCount++;
    },
    track: ({ payload, config, instance }) => {
      const { properties = {}, event } = payload;
      const campaign = instance.getState('context.campaign');
      const { gtagName, dataLayerName } = config;
      if (!window[gtagName] || !measurementIds.length) return;
      const campaignData = addCampaignData(campaign);
      const userId = instance.user('userId');
      if (trackedEvents.includes(event)) {
        const globalState = properties.globalState;
        const totalTax = properties.options?.totalTax;
        const trackingCategory = properties.options?.trackingCategory;
        if (
          properties.globalState?.config?.trackingStrategy === null ||
          properties.globalState?.config?.trackingStrategy === undefined
        ) {
          return;
        }
        const trackingStrategy = TrackingStrategyResolver.resolve(
          globalState?.config.trackingStrategy
        );
        trackingStrategy.processEvent(event, globalState, {
          ...properties.options,
          campaignData,
          dataLayerName,
          ...(userId ? { user_id: userId } : {}),
          ...(totalTax ? { totalTax } : {}),
          ...(trackingCategory ? { trackingCategory } : {}),
        });
      } else {
        // Limits https://support.google.com/analytics/answer/9267744
        const finalPayload = {
          ...properties,
          ...campaignData,
          ...(userId ? { user_id: userId } : {}),
        };
        window[gtagName]('event', event, finalPayload);
      }
    },
    /* Verify gtag loaded and ready to use */
    loaded: () => {
      const { dataLayerName, customScriptSrc } = initConfig;
      const hasDataLayer =
        dataLayerName &&
        window[dataLayerName] &&
        Array.prototype.push === window[dataLayerName].push;
      return scriptLoaded(customScriptSrc || gtagScriptSource) && hasDataLayer;
    },
    /* Custom methods */
    methods: {
      addTag(tagId, settings = {}) {
        // https://developers.google.com/tag-platform/devguides/install-gtagjs#add_products_to_your_tag
        if (window[initConfig.gtagName]) {
          window[initConfig.gtagName]('config', tagId, settings);
          if (measurementIds && !measurementIds.includes(tagId)) {
            measurementIds = measurementIds.concat(tagId);
          }
        }
      },
      /* Disable gtag for user */
      disable: (ids) => {
        const gaIds = ids ? getIds(ids) : measurementIds;
        for (const gaId of measurementIds) {
          if (gaIds.includes(gaId)) {
            // https://developers.google.com/analytics/devguides/collection/gtagjs/user-opt-out
            window[`ga-disable-${gaId}`] = true;
          }
        }
      },
      /* Enable gtag for user */
      enable: (ids) => {
        const gaIds = ids ? getIds(ids) : measurementIds;
        for (const element of measurementIds) {
          const gaId = element;
          if (gaIds.includes(gaId)) {
            // https://developers.google.com/analytics/devguides/collection/gtagjs/user-opt-out
            window[`ga-disable-${gaId}`] = false;
          }
        }
      },
    },
  };
}

function getIds(measurementIds) {
  if (!measurementIds) throw new Error('No GA Measurement ID defined');
  if (Array.isArray(measurementIds)) {
    return measurementIds;
  }
  if (typeof measurementIds === 'string') {
    return [measurementIds];
  }
  throw new Error('GA Measurement ID must be string or array of strings');
}

/**
 * Add campaign data to GA payload https://bit.ly/34qFCPn
 * @param {Object} [campaignData={}] [description]
 * @param {String} [campaignData.campaignName] - Name of campaign
 * @param {String} [campaignData.campaignSource] - Source of campaign
 * @param {String} [campaignData.campaignMedium] - Medium of campaign
 * @param {String} [campaignData.campaignContent] - Content of campaign
 * @param {String} [campaignData.campaignKeyword] - Keyword of campaign
 */
function addCampaignData(campaignData = {}) {
  let campaign = {};
  const { id, name, source, medium, content, keyword } = campaignData;
  if (id) campaign.campaignId = id;
  if (name) campaign.campaignName = name;
  if (source) campaign.campaignSource = source;
  if (medium) campaign.campaignMedium = medium;
  if (content) campaign.campaignContent = content;
  if (keyword) campaign.campaignKeyword = keyword;
  return campaign;
}

function scriptLoaded(scriptSrc) {
  const scripts = document.querySelectorAll('script[src]');
  const regex = new RegExp(`^${scriptSrc}`);
  return Boolean(
    Object.values(scripts).filter((value) => regex.test(value.src)).length
  );
}

const trackedEvents = [
  TrackingEvent.LOGIN_LANDING,
  TrackingEvent.ADD_TICKET,
  TrackingEvent.SEATS_LANDING,
  TrackingEvent.PAYMENT_LANDING,
  TrackingEvent.TICKET_CHECKOUT,
  TrackingEvent.PURCHASE,
  TrackingEvent.GIFTCARD_LANDING,
  TrackingEvent.PICK_GIFTCARD,
  TrackingEvent.GIFTCARD_PAYMENT_LANDING,
  TrackingEvent.GIFTCARD_CHECKOUT,
];
