/** @jsxImportSource theme-ui */
import React from 'react';

import { useSelector } from 'react-redux';

import { GlobalState } from '../../../@types/modelTypes';
import { ReactComponent as EyeHideSvg } from '../../../svgs/eyeHide.svg';
import { ReactComponent as EyeShowSvg } from '../../../svgs/eyeShow.svg';

interface Props {
  showPassword: boolean;
  setShowPassword: React.Dispatch<React.SetStateAction<boolean>>;
}

const ShowHideButton: React.FC<Props> = ({ showPassword, setShowPassword }) => {
  const contentSignIn = useSelector(
    (state: GlobalState) => state.content.signIn
  );

  return (
    <button
      className='btn-show-hide'
      onClick={() => {
        setShowPassword(!showPassword);
      }}
      title={
        showPassword
          ? contentSignIn.loginHidePasswordButtonTitle
          : contentSignIn.loginShowPasswordButtonTitle
      }
      type='button'
      sx={{
        svg: {
          fill: 'formInputColor',
        },
      }}
    >
      {showPassword ? <EyeHideSvg /> : <EyeShowSvg />}
    </button>
  );
};

export default ShowHideButton;
