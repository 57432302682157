import { useEffect, useCallback } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { TrackingCategory } from '../@types/analyticsTypes';
import { GlobalState } from '../@types/modelTypes';
import {
  AnalyticsTrackingEventProperties,
  TrackingEvent,
} from '../@types/trackingTypes';
import { useAnalytics } from '../analytics/analyticsContext';
import { actionCreators } from '../store/ActionCreators';
import {
  selectBookingFeeTax,
  selectConfig,
  selectConfirmationData,
  selectContent,
  selectProductsTax,
  selectState,
} from '../store/Selectors';

export const useConfirmationTracking = (trackingCategory: TrackingCategory) => {
  const dispatch = useDispatch();
  const analytics = useAnalytics();
  const globalState = useSelector(selectState);
  const config = useSelector(selectConfig);
  const content = useSelector(selectContent);
  const bookingFeeTax = useSelector(selectBookingFeeTax);
  const confirmationData = useSelector(selectConfirmationData);
  const sendAnalytics = useSelector(
    (state: GlobalState) => state.sendAnalytics
  );
  const dataLayerPopulated = useSelector(
    (state: GlobalState) => state.dataLayerPopulated
  );
  const productsTax = useSelector(selectProductsTax);
  const totalTax = productsTax + bookingFeeTax;

  const isLoadedInMainWindow =
    window.top && window.location === window.top.location;

  const sendPurchaseEvent = useCallback(() => {
    if (confirmationData && analytics) {
      const trackProperties: AnalyticsTrackingEventProperties = {
        globalState,
        options: { trackingCategory, totalTax },
      };
      analytics.track(TrackingEvent.PURCHASE, trackProperties);
      dispatch(actionCreators.setSendAnalytics(false));
    }
  }, [
    analytics,
    dispatch,
    confirmationData,
    totalTax,
    trackingCategory,
    globalState,
  ]);

  useEffect(() => {
    const hasAllData =
      sendAnalytics &&
      isLoadedInMainWindow &&
      confirmationData &&
      content &&
      config &&
      dataLayerPopulated;
    if (hasAllData) {
      sendPurchaseEvent();
    }
  }, [
    config,
    confirmationData,
    content,
    dataLayerPopulated,
    isLoadedInMainWindow,
    sendAnalytics,
    sendPurchaseEvent,
  ]);
};
