import React from 'react';

import { useSelector } from 'react-redux';

import SeasonPassMovieItem from './SeasonPassMovieItem';

import { useBoostNavigate } from '../../../hooks/useBoostNavigate';
import {
  selectSeasonPassMovies,
  selectSelectedSeasonPassSessions,
} from '../../../store/Selectors';

const SeasonPassSeatsMoviesContainer: React.FC = () => {
  const boostNavigate = useBoostNavigate();

  const seasonPassMovies = useSelector(selectSeasonPassMovies);
  const selectedSeasonPassSessions = useSelector(
    selectSelectedSeasonPassSessions
  );

  const allSelectedSessionIds = selectedSeasonPassSessions
    .flatMap((s) => s.session)
    .map((s) => s.sessionId);

  const selectedMovies =
    seasonPassMovies?.filter((s) =>
      s.showTimes
        .flatMap((st) => st.times)
        .some((t) => allSelectedSessionIds.includes(t.sessionId))
    ) ?? [];

  if (!selectedMovies?.length) {
    boostNavigate.navigateToStartJourneyUsingGlobalState();
    return null;
  }

  return (
    <>
      {selectedMovies.map((movie) => (
        <SeasonPassMovieItem key={movie.filmId} movie={movie} />
      ))}
    </>
  );
};

export default SeasonPassSeatsMoviesContainer;
