/** @jsxImportSource theme-ui */
import React, { useEffect, useRef, useState } from 'react';

import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Box } from 'theme-ui';

import { DidomiInstance, getRegulation } from '../../../services/DidomiHelpers';
import { selectContent } from '../../../store/Selectors';

const FooterPrivacySettings: React.FC = () => {
  const content = useSelector(selectContent);
  const didomiInstance = useRef<DidomiInstance | null>(null);
  const [hasRegulation, setHasRegulation] = useState<boolean>(false);

  useEffect(() => {
    if (!window.didomiOnReady) {
      window.didomiOnReady = [];
    }

    window.didomiOnReady.push((_didomiInstance) => {
      didomiInstance.current = _didomiInstance;

      const regulation = getRegulation(_didomiInstance);
      if (regulation) {
        setHasRegulation(true);
      }
    });
  }, []);

  if (!content) return null;

  if (!window.didomiOnReady) {
    window.didomiOnReady = [];
  }
  if (hasRegulation) {
    return (
      <Box data-testid='privacy-settings'>
        <Button
          variant='link'
          className='a'
          onClick={() => didomiInstance.current?.preferences?.show?.()}
          data-testid='privacy-settings-btn'
        >
          {content.footerPrivacySettingsText}
        </Button>
      </Box>
    );
  } else {
    return null;
  }
};

export default FooterPrivacySettings;
