import { isMobileOnly } from 'react-device-detect';

import {
  BookingData,
  Concession,
  ConfirmationData,
  Customer,
  GlobalState,
} from '../@types/modelTypes';
import {
  getConcessionTotalPriceWithModifiersInCents,
  getSelectedModifiers,
} from '../analytics/googleAnalyticsHelpers';
import { getPriceFromCentsWithDecimals } from '../services/Helpers';

export const populateDataLayerWithBookingData = (
  confirmationData: ConfirmationData,
  customer: Customer,
  config: GlobalState['config'],
  ticketsQuantity: number,
  movieName: string,
  filmId: number | undefined | null,
  attributes: string[]
) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    bookingReference: confirmationData.codeUsedInBarcode,
    email: customer.email,
    amount: (confirmationData.totalPrice / 100).toFixed(2),
    currency: config.currency,
    cinema: {
      internalId: config.currentCinema.cinemaId,
      name: config.currentCinema.title,
    },
    movieName: movieName,
    quantity: ticketsQuantity,
    event: 'ConfirmationEvent',
    filmId,
    attributes,
  });
};

export const populateDataLayerInPaymentStep = (
  bookingData: BookingData,
  config: GlobalState['config'],
  grandTotal: number,
  ticketTypes: GlobalState['ticketTypes'],
  serviceCharge: GlobalState['bookingFee'],
  concessionsList: Concession[],
  donation: GlobalState['donation'],
  selectedGratuity: GlobalState['selectedGratuity'],
  userAgent: string
) => {
  window.dataLayer = window.dataLayer || [];

  const cartItems = [];

  if (ticketTypes?.ticketTypeModels) {
    ticketTypes.ticketTypeModels.forEach((t) => {
      if (!t.quantity) return;

      cartItems.push({
        price: (t.price / 100).toFixed(2),
        productname: t.displayName,
        quantity: t.quantity,
      });
    });
  }

  concessionsList?.forEach((concession: Concession) => {
    if (concession.quantity) {
      cartItems.push({
        price: getPriceFromCentsWithDecimals(
          getConcessionTotalPriceWithModifiersInCents(concession)
        ),
        productname: concession.description,
        quantity: concession.quantity,
        modifiers: getSelectedModifiers(concession),
      });
    }
  });

  if (serviceCharge) {
    cartItems.push({
      price: (serviceCharge / 100).toFixed(2),
      productname: 'Service Charge',
    });
  }

  if (donation) {
    cartItems.push({
      price: (donation / 100).toFixed(2),
      productname: 'Donation',
    });
  }

  window.dataLayer.push({
    email: bookingData?.loyaltyEmailAddress,
    amount: (grandTotal / 100).toFixed(2),
    currency: config.currency,
    firstname: bookingData?.loyaltyFirstName,
    lastname: bookingData?.loyaltyLastName,
    mobile: bookingData?.loyaltyTelephone,
    cinema: {
      internalId: config.currentCinema.cinemaId,
      id: config.currentCinema.cinemaId,
      name: config.currentCinema.title,
    },
    cartItems: JSON.stringify(cartItems),
    userAgent: userAgent,
    clientType: isMobileOnly ? 'MobileWeb' : 'DesktopWeb',
    event: 'PaymentEvent',
  });
};
