import React from 'react';

import { useSelector } from 'react-redux';
import { Box, Flex } from 'theme-ui';

import { concessionItemSxProp } from './constants';

import { getFullAddress } from '../../../services/GiftCardHelpers';
import {
  selectContentPhysicalGiftcards,
  selectPhysicalGiftCardRecipient,
} from '../../../store/Selectors';

const CartSummaryPhysicalGiftCards: React.FC = () => {
  const contentPhysicalGiftCards = useSelector(selectContentPhysicalGiftcards);
  const physicalGiftCardRecipient = useSelector(
    selectPhysicalGiftCardRecipient
  );

  return (
    <div className='cart-summary-row' data-testid='cs-physical-gift-cards'>
      <Flex className='concession-item' sx={concessionItemSxProp}>
        <Box sx={{ textAlign: 'left' }}>
          {contentPhysicalGiftCards.cartSummaryNameLabel}
        </Box>
        <Box sx={{ textAlign: 'right' }}>{physicalGiftCardRecipient.name}</Box>
      </Flex>
      <Flex sx={{ mt: 4, justifyContent: 'space-between' }}>
        <Box sx={{ textAlign: 'left' }}>
          {contentPhysicalGiftCards.cartSummaryAddressLabel}
        </Box>
        <Box sx={{ textAlign: 'right' }}>
          {getFullAddress(
            physicalGiftCardRecipient.addressLineOne,
            physicalGiftCardRecipient.addressLineTwo,
            physicalGiftCardRecipient.city,
            physicalGiftCardRecipient.postcode
          )}
        </Box>
      </Flex>
      {physicalGiftCardRecipient.message && (
        <Flex sx={{ mt: 4 }}>
          <Box sx={{ textAlign: 'left' }}>
            {contentPhysicalGiftCards.cartSummaryMessageLabel}
          </Box>
          <Box sx={{ textAlign: 'right' }}>
            {physicalGiftCardRecipient.message}
          </Box>
        </Flex>
      )}
    </div>
  );
};

export default CartSummaryPhysicalGiftCards;
