/** @jsxImportSource theme-ui */
import React from 'react';

import { useSelector } from 'react-redux';

import { GlobalState } from '../../../@types/modelTypes';
import {
  selectContentCartSummary,
  selectDisplayPrice,
} from '../../../store/Selectors';

interface Props {
  gratuityInCents: number;
}

const CartSummaryGratuity: React.FC<Props> = ({ gratuityInCents }) => {
  const contentCartSummary = useSelector(selectContentCartSummary);
  const priceToDisplay = useSelector((state: GlobalState) =>
    selectDisplayPrice(state, gratuityInCents)
  );

  if (gratuityInCents === 0) return null;

  return (
    <div className='cart-summary-row-flex' data-testid='cs-gratuity'>
      {contentCartSummary.gratuityLabel}
      <span sx={{ textAlign: 'right' }}>{priceToDisplay}</span>
    </div>
  );
};

export default CartSummaryGratuity;
