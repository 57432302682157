/** @jsxImportSource theme-ui */
import React, { useState } from 'react';

import moment from 'moment';
import { Button, Collapse } from 'react-bootstrap';
import { useCookies } from 'react-cookie';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Box, Flex } from 'theme-ui';

import * as StepPaths from '../../../constants/stepPaths';
import { useBoostNavigate } from '../../../hooks/useBoostNavigate';
import {
  handleExternalLogInClick,
  handleRegisterClick,
} from '../../../services/LoyaltyHelpers';
import {
  selectBookingData,
  selectConfig,
  selectContent,
  selectJourneyTypeConfig,
  selectLoginUrl,
  selectRegisterUrl,
  selectSource,
} from '../../../store/Selectors';
import { ReactComponent as CloseXSvg } from '../../../svgs/closeX.svg';
import ActionButton from '../actionbutton/ActionButton';
import ContainedRow from '../layout/ContainedRow';
import RichText from '../richtext/RichText';

const LoyaltyPromo: React.FC = () => {
  const [cookies, setCookies] = useCookies();

  const { pathname } = useLocation();
  const boostNavigate = useBoostNavigate();

  const bookingData = useSelector(selectBookingData);
  const config = useSelector(selectConfig);
  const content = useSelector(selectContent);
  const journeyTypeConfig = useSelector(selectJourneyTypeConfig);
  const source = useSelector(selectSource);
  const registerUrl = useSelector(selectRegisterUrl);
  const loginUrl = useSelector(selectLoginUrl);

  const [showLoyaltyPromoArea, setShowLoyaltyPromoArea] = useState(true);

  const handleCloseButtonClick = () => {
    const cookieOptions = {
      path: '/',
      expires: moment().add(5, 'y').toDate(),
      secure: true,
      sameSite: true,
    };
    setShowLoyaltyPromoArea(false);
    setTimeout(() => {
      setCookies('hideLoyaltyPromoArea', true, cookieOptions);
    }, 1000);
  };

  const handleLoginButtonClick = () => {
    if (config.signIn.isLoginWithinBoostEnabled) {
      if (journeyTypeConfig.isConcessionsOnlyJourney) {
        boostNavigate.navigateToPath('/login', { appendQueryString: true });
      } else {
        boostNavigate.navigateToStep(0, {
          appendQueryString: true,
          appendCinemaAndSessionIdsFromBookingData: true,
        });
      }
    } else {
      handleExternalLogInClick(
        journeyTypeConfig,
        bookingData,
        source,
        loginUrl
      );
    }
  };

  if (!config || !content) return null;

  const signInOrRegisterIsEnabledSomewhere =
    config.signIn.allowSignIn ||
    config.currentCinema?.allowSignIn ||
    config.signIn.displayRegisterButton;

  const hideLoyaltyPromo =
    !config.showLoyaltyPromoArea || cookies.hideLoyaltyPromoArea;

  const pathNameIsUnsuitable =
    pathname.includes(`/${StepPaths.CONFIRMATION}`) ||
    pathname.includes(`/${StepPaths.LANDING}`) ||
    pathname.includes(`/${StepPaths.LOGIN}`) ||
    pathname.includes(`/${StepPaths.SIGNUP}`) ||
    pathname.startsWith(`/${StepPaths.ERROR}`) ||
    pathname.startsWith(`/${StepPaths.REFUND}`) ||
    pathname.startsWith(`/${StepPaths.WARNING}`);

  const shouldHidePromo =
    !signInOrRegisterIsEnabledSomewhere ||
    hideLoyaltyPromo ||
    bookingData?.isUserValidated ||
    pathNameIsUnsuitable;

  if (shouldHidePromo) return null;

  return (
    <ContainedRow styles={{ my: 5 }}>
      <Collapse in={showLoyaltyPromoArea}>
        <Box
          className='loyalty-promo'
          data-testid='loyalty-promo'
          sx={{
            mb: 6,
            borderBottom: 'bodySeparator',
            '.hide-button': {
              color: 'primary',

              svg: {
                fill: 'mostReadableOnWebsiteBackground',
              },

              '&:hover': {
                color: 'mostReadableOnWebsiteBackground',
              },
            },
          }}
        >
          <Flex sx={{ justifyContent: 'center' }}>
            <Button
              className='hide-button small'
              data-testid='hide-button'
              onClick={() => handleCloseButtonClick()}
              variant='link'
              sx={{ display: 'flex', alignItems: 'center' }}
            >
              {content.loyaltyPromoCloseButtonText}
              <CloseXSvg className='icon' />
            </Button>
          </Flex>

          <Flex sx={{ justifyContent: 'center' }}>
            <RichText
              text={content.loyaltyPromoRichText}
              styles={{ textWrap: 'balance', textAlign: 'center' }}
            />
          </Flex>

          <Flex
            sx={{
              justifyContent: 'center',
              alignItems: 'center',
              mt: 4,
              mb: 2,
            }}
          >
            <Box sx={{ width: '50%' }}>
              <ActionButton
                onClick={() => handleRegisterClick(registerUrl)}
                contained
                mb={0}
                mt={0}
                variant='secondary'
              >
                {content.loyaltyPromoRegisterButtonText}
              </ActionButton>
            </Box>
          </Flex>

          <Flex sx={{ justifyContent: 'center' }}>
            <p className='tiny' sx={{ my: 2 }}>
              {content.loyaltyPromoLoginText}{' '}
              <Button
                className='a tiny'
                data-testid='login-button'
                onClick={() => handleLoginButtonClick()}
                variant='link'
                sx={{ fontWeight: 'bold' }}
              >
                {content.loyaltyPromoLoginButtonText}
              </Button>
            </p>
          </Flex>
        </Box>
      </Collapse>
    </ContainedRow>
  );
};

export default LoyaltyPromo;
