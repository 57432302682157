/** @jsxImportSource theme-ui */
import React from 'react';

import classNames from 'classnames';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Flex } from 'theme-ui';

import TierImage from './TierImage';

import { QuantitySelectorContext } from '../../../@types/actionTypes';
import {
  Concession,
  ConcessionPricing,
  GlobalState,
} from '../../../@types/modelTypes';
import { findModifiedConcessionItemCost } from '../../../services/KioskHelpers';
import { actionCreators } from '../../../store/ActionCreators';
import {
  selectConfig,
  selectContent,
  selectDisplayPrice,
  selectSelectedFaBConcessions,
} from '../../../store/Selectors';
import { ReactComponent as RadioSelectedSvg } from '../../../svgs/radioSelected.svg';
import { ReactComponent as RadioUnselectedSvg } from '../../../svgs/radioUnselected.svg';
import RichText from '../richtext/RichText';

const TierItem: React.FC<Concession> = ({
  id,
  code,
  description,
  extendedDescription,
  image,
  taxInCents,
  cost,
  quantity,
  modifierGroups,
  isVariablePriceItem,
  minVariableCost,
  maxVariableCost,
  memberLevelId,
  memberClubId,
  recognitionId,
  sequenceNumber,
}) => {
  const concession: Concession = {
    id,
    code,
    description,
    extendedDescription,
    image,
    taxInCents,
    cost,
    quantity,
    modifierGroups,
    modifiers: [],
    isVariablePriceItem,
    minVariableCost,
    maxVariableCost,
    hidden: false,
    variablePriceInCents: 0,
    orderDelivery: null,
    isSoldOut: false,
    memberLevelId,
    memberClubId,
    recognitionId,
    sequenceNumber,
  };
  const dispatch = useDispatch();

  const config = useSelector(selectConfig);
  const content = useSelector(selectContent);
  const selectedConcessions = useSelector(selectSelectedFaBConcessions);

  const hideTax: boolean = config.currentCinema.hideTax;

  const imagePath: string | undefined = concession.image
    ? concession.image.replace('https://', '')
    : undefined;

  const selectedConcession: Concession | undefined =
    selectedConcessions.list.find((x) => x.id === concession.id);

  const quantityInCart: number = selectedConcession
    ? selectedConcession.quantity
    : 0;

  const itemPricing: ConcessionPricing =
    findModifiedConcessionItemCost(concession);

  const handleConcessionChange = (
    context: QuantitySelectorContext,
    concession: Concession
  ) => {
    dispatch(actionCreators.setLoyaltyTierSelection(concession, context));
  };

  const priceToDisplay = hideTax
    ? itemPricing.costIncTax
    : itemPricing.costMinusTax;
  const subTotal = (quantityInCart > 0 ? quantityInCart : 1) * priceToDisplay;
  const subTotalToDisplay = useSelector((state: GlobalState) =>
    selectDisplayPrice(state, subTotal)
  );

  const selected = quantityInCart >= 1;

  return (
    <Box
      className='concession'
      sx={{
        py: 4,
        px: 4,
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'space-between',
        display: ['block', 'block', 'flex'],
        width: '100%',
      }}
    >
      <Flex sx={{ alignItems: 'center' }}>
        <Box className='image-container'>
          <TierImage imageUrl={imagePath ?? content.loyalty.defaultImage} />
        </Box>
        <Box
          className='details'
          sx={{ pl: 3, maxWidth: '304px', textAlign: 'left' }}
        >
          <Box className='h3'>{concession.description}</Box>
          {concession.extendedDescription && (
            <RichText
              className='tiny'
              styles={{ mt: 1, textWrap: 'balance' }}
              text={concession.extendedDescription}
            />
          )}
        </Box>
      </Flex>
      <Flex
        className='options-selector-container'
        sx={{
          justifyContent: 'flex-end',
          alignItems: 'center',
          pt: [6, 6, 0],
        }}
      >
        <Button
          className={classNames('option-button', selected && 'selected')}
          onClick={() =>
            handleConcessionChange(selected ? 'remove' : 'add', concession)
          }
          sx={{
            mr: 6,
            flexGrow: 1,
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Flex sx={{ alignItems: 'center' }}>
            {selected ? (
              <RadioSelectedSvg className='check' />
            ) : (
              <RadioUnselectedSvg className='check' />
            )}

            <Box sx={{ minWidth: 'unset' }}>
              {itemPricing.costMinusTax < 100
                ? content.loyalty.signupOptionButtonFreeText
                : content.loyalty.signupOptionButtonPaidText}
            </Box>
          </Flex>
        </Button>
        <Flex
          className='options-price'
          sx={{
            alignItems: 'center',
            justifyContent: 'flex-end',
            minWidth: '60px',
          }}
        >
          <span sx={{ fontWeight: selected ? 'bold' : 'normal' }}>
            {itemPricing.costMinusTax < 100
              ? content.loyalty.signupFreeTierCostText
              : subTotalToDisplay}
          </span>
        </Flex>
      </Flex>
    </Box>
  );
};

export default TierItem;
