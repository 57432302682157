/** @jsxImportSource theme-ui */
import React from 'react';

import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import {
  selectContent,
  selectCurrentCinemaHomeUrl,
} from '../../../../store/Selectors';
import { ReactComponent as IconArrowSvg } from '../../../../svgs/iconArrow.svg';
import ContainedRow from '../../layout/ContainedRow';
import RichText from '../../richtext/RichText';
import LoginSection from '../LoginSection';
import Register from '../Register';

const LoyaltyOnlyVouchersOptions: React.FC = () => {
  const currentCinemaHomeUrl = useSelector(selectCurrentCinemaHomeUrl);
  const content = useSelector(selectContent);

  return (
    <>
      <ContainedRow textAlign='center'>
        <h2 sx={{ mt: 6 }}>
          {content.signIn.loyaltyOnlyVouchersMemberHeading}
        </h2>
        <RichText text={content.signIn.loyaltyOnlyVouchersMemberRichText} />
        <LoginSection />
      </ContainedRow>

      <Register showDivider />

      <ContainedRow textAlign='center'>
        <Button
          onClick={() => (window.location.href = currentCinemaHomeUrl)}
          variant='link'
          size='sm'
          className='go-back-to-website-button '
          sx={{
            textTransform: 'uppercase',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <IconArrowSvg className='arrow' />
          {content.goBackText}
        </Button>
      </ContainedRow>
    </>
  );
};

export default LoyaltyOnlyVouchersOptions;
