/** @jsxImportSource theme-ui */
import React, { useEffect, useState } from 'react';

import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { Box } from 'theme-ui';

import {
  AddConcessionRequestModel,
  Concession,
  ConcessionGrouping,
} from '../../../@types/modelTypes';
import { PEACH_CODES } from '../../../constants';
import { useBoostNavigate } from '../../../hooks/useBoostNavigate';
import { useValidateConcessionsJourney } from '../../../hooks/useValidateConcessionsJourney';
import backend from '../../../services/RestUtilities';
import { actionCreators } from '../../../store/ActionCreators';
import {
  selectConcessionsAdded,
  selectConfig,
  selectContent,
  selectCustomer,
  selectSelectedFaBConcessions,
  selectTandCsAcceptedSignup,
  selectToken,
  selectJourneyTypeConfig,
  selectAllConcessions,
} from '../../../store/Selectors';
import ActionButton from '../../common/actionbutton/ActionButton';
import CustomerDetailsSignup from '../../common/customerdetails/CustomerDetailsSignup';
import ContainedRow from '../../common/layout/ContainedRow';
import RichText from '../../common/richtext/RichText';
import SignUpSummaryTierItem from '../../common/signup/SignUpSummaryTierItem';

const SignupDetails: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useValidateConcessionsJourney();
  const boostNavigate = useBoostNavigate();
  const availableConcessions = useSelector(selectAllConcessions);
  const concessionsAddedToPos = useSelector(selectConcessionsAdded);
  const config = useSelector(selectConfig);
  const content = useSelector(selectContent);
  const customer = useSelector(selectCustomer);
  const dataToken = useSelector(selectToken);
  const journeyTypeConfig = useSelector(selectJourneyTypeConfig);
  const selectedConcessions = useSelector(selectSelectedFaBConcessions);
  const isTandCsAccepted = useSelector(selectTandCsAcceptedSignup);
  const concession: Concession | undefined = selectedConcessions.list.find(
    (c: Concession) => c.quantity === 1
  );

  const [showUserDetails, setShowUserDetails] = useState<boolean>(false);
  const [signupFormValidated, setSignupFormValidated] =
    useState<boolean>(false);

  const hasConcessions = availableConcessions?.some(
    (x: ConcessionGrouping) => !!x.items.length
  );

  const hasSelection = selectedConcessions?.list.some((x) => x.quantity >= 1);

  useEffect(() => {
    setSignupFormValidated(!!(isTandCsAccepted && customer.isValid));
  }, [customer, isTandCsAccepted]);

  useEffect(() => {
    if (!hasSelection && showUserDetails) {
      setShowUserDetails(false);
    }
  }, [hasSelection, showUserDetails]);

  const addConcessionsToPos = async () => {
    dispatch(actionCreators.setLoading(true));

    if (hasConcessions || concessionsAddedToPos) {
      const data: AddConcessionRequestModel = {
        dataToken: dataToken,
        concessions: selectedConcessions ? selectedConcessions.list : [],
        journeyType: journeyTypeConfig.type,
        deliveryWindowInfo: null,
        customer,
      };
      const response = await backend.post(
        'api/Member/AddMemberConcessions',
        data
      );
      if (response.ok && response.content.peachCode === PEACH_CODES.noError) {
        dispatch(actionCreators.setConcessionsAddedToPos(hasConcessions));
        dispatch(actionCreators.setToken(response.content.dataToken));
        dispatch(actionCreators.setBookingFee(response.content.bookingFee));
        if (config.enableCountDown) {
          dispatch(
            actionCreators.setCountDown(response.content.secondsToExpiration)
          );
        }
        dispatch(actionCreators.setOrderExists());
        boostNavigate.navigateToNextStep();
      } else {
        const errorMessage =
          response.content.peachCode ===
            PEACH_CODES.loyaltyMemberAlreadyExists ||
          response.content.peachCode === PEACH_CODES.memberDetailsIncorrect
            ? content.error.loyaltyMemberAlreadyExistsRichText
            : content.error.concessionsCouldNotBeAddedRichText;
        dispatch(
          actionCreators.setError(errorMessage, response.content.peachCode)
        );
      }
    }
    dispatch(actionCreators.setLoading(false));
  };

  if (!content?.loyalty) return null;

  return (
    <Box className='loyalty' data-testid='loyalty'>
      <ContainedRow textAlign='center'>
        <h1>{content.loyalty.signupSelectTierHeading}</h1>
      </ContainedRow>

      <ContainedRow styles={{ textAlign: 'center', mt: 5 }}>
        <h2>{content.loyalty.signUpDetailsHeading}</h2>
        <RichText text={content.loyalty.signupDetailsRichText} />
      </ContainedRow>

      {concession && (
        <ContainedRow
          styles={{ mt: 6 }}
          classNameInner='loyalty-rows-container'
        >
          <SignUpSummaryTierItem
            {...concession}
            key={concession.id}
            defaultImage={
              concession?.image?.replace('https://', '') ??
              content.loyalty.defaultImage
            }
          />
        </ContainedRow>
      )}

      <ContainedRow
        classNameWrapper='tiny giftcards'
        styles={{ textAlign: 'left', mt: 4 }}
      >
        <Button
          onClick={() => navigate(-1)}
          variant='link'
          className='btn-edit'
          sx={{
            textAlign: 'center',
            textTransform: 'uppercase',
            fontWeight: 'bold',
          }}
        >
          {content.loyalty.paymentEditText}
        </Button>
      </ContainedRow>

      <ContainedRow>
        <Box mt={7}>
          <h2 sx={{ textAlign: 'center' }}>
            {content.loyalty.signUpYourDetailsHeading}
          </h2>
          <RichText text={content.loyalty.signupYourDetailsRichText} />
        </Box>
        <CustomerDetailsSignup />
      </ContainedRow>

      <Box sx={{ mt: 4 }}>
        <ActionButton
          onClick={addConcessionsToPos}
          disabled={!signupFormValidated}
          stickyMobileDesktop={false}
          showIcon
          contained
          hasMarginX
          variant='primary'
        >
          {content.loyalty.continueToPaymentButtonText}
        </ActionButton>
      </Box>
    </Box>
  );
};

export default SignupDetails;
