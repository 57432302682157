import { defineMessages } from 'react-intl';

export default defineMessages({
  confirmationIdSubHeading: {
    id: 'order-details.confirmation-id-sub-heading',
    defaultMessage: 'Your booking number is:',
  },
  yourTicketsSubHeading: {
    id: 'order-details.your-tickets-sub-heading',
    defaultMessage: 'Your tickets have been emailed to you at:',
  },
});
