import React from 'react';

import { useSelector } from 'react-redux';

import CartSummaryFilmInfoPoster from './CartSummaryFilmInfoPoster';
import CartSummaryFilmInfoStillImage from './CartSummaryFilmInfoStillImage';

import { getDisplayDateAndTime } from '../../../services/Helpers';
import {
  selectCartSummary,
  selectConfig,
  selectContentCartSummary,
  selectSelectedLanguageCulture,
  selectShowBookingInfo,
} from '../../../store/Selectors';

const CartSummaryFilmInfo: React.FC = () => {
  const config = useSelector(selectConfig);
  const cartSummary = useSelector(selectCartSummary);
  const contentCartSummary = useSelector(selectContentCartSummary);
  const selectedLanguageCulture = useSelector(selectSelectedLanguageCulture);
  const showBookingInfo = useSelector(selectShowBookingInfo);

  if (!cartSummary.sessionDate) return null;

  const dateAndTime =
    cartSummary.sessionDate &&
    getDisplayDateAndTime(cartSummary.sessionDate, selectedLanguageCulture);

  return (
    <div
      className='cart-summary-row cart-summary-film-info'
      data-testid='cs-film-info'
    >
      <div className='cart-summary-left'>
        {config.useStillImageInCartSummary ? (
          <CartSummaryFilmInfoStillImage />
        ) : (
          <CartSummaryFilmInfoPoster />
        )}
      </div>

      <div className='cart-summary-right'>
        <h2>{cartSummary.title}</h2>
        <div>
          <p className='tiny'>
            {cartSummary.rated && (
              <span data-testid='cs-rated'>{cartSummary.rated}</span>
            )}
            {cartSummary.formattedDuration && cartSummary.rated && (
              <span className='dot-separator'>·</span>
            )}
            {cartSummary.formattedDuration && (
              <span>{cartSummary.formattedDuration}</span>
            )}
          </p>
        </div>

        <div className='bottom'>
          <p>
            <b>{contentCartSummary.atLabel} </b>
            {cartSummary.theaterLocationName}
            {showBookingInfo && <> - {cartSummary.screen}</>}
          </p>
          {showBookingInfo && (
            <p>
              <b>{contentCartSummary.onLabel} </b>
              {!!dateAndTime && (
                <>
                  {dateAndTime.displayDate} - {dateAndTime.displayTime}
                </>
              )}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default CartSummaryFilmInfo;
