/** @jsxImportSource theme-ui */
import React, { useState } from 'react';

import { Button, Collapse } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { Box, Flex } from 'theme-ui';

import { Concession } from '../../../../@types/modelTypes';
import { displayPrice } from '../../../../services/Helpers';
import {
  selectContent,
  selectCurrencyConfig,
  selectGiftCardRecipient,
} from '../../../../store/Selectors';
import { ReactComponent as EyeHideSvg } from '../../../../svgs/eyeHide.svg';
import { ReactComponent as EyeShowSvg } from '../../../../svgs/eyeShow.svg';
import GiftCardImage from '../../concessionsonlycommon/GiftCardImage';

interface GiftCardsRowProps {
  concession: Concession;
}

const GiftCardUpsellSummaryRow: React.FC<GiftCardsRowProps> = ({
  concession,
}) => {
  const navigate = useNavigate();

  const content = useSelector(selectContent);
  const currencyConfig = useSelector(selectCurrencyConfig);
  const giftCardRecipient = useSelector(selectGiftCardRecipient);

  const [showGiftCardDetails, setShowGiftCardDetails] = useState(false);

  const imagePath = concession?.image
    ? concession.image.replace('https://', '')
    : content.giftCards.defaultImage;

  if (!concession || concession.quantity < 1) return null;

  const getDescription = () => {
    return `${displayPrice(concession.cost, currencyConfig)} ${
      content.giftCards.summaryGiftCardLabel
    }`;
  };

  return (
    <Box className='giftcards-row' sx={{ mt: 5 }}>
      <Flex sx={{ alignItems: 'center' }}>
        <Box className='image-container' sx={{ pr: 4 }}>
          <GiftCardImage imageUrl={imagePath} />
        </Box>
        <Box className='summary-item b' sx={{ flexGrow: 1, textAlign: 'left' }}>
          <span>{getDescription()}</span>
        </Box>
        <Box sx={{ pl: 3 }}>
          <Button
            className='collapse-btn small'
            onClick={() => {
              setShowGiftCardDetails(!showGiftCardDetails);
            }}
            variant='link'
            sx={{
              display: 'flex',
              alignItems: 'center',
              svg: {
                fill: 'mostReadableOnWebsiteBackground',
              },
            }}
          >
            <div>
              {showGiftCardDetails
                ? content.payment.giftCardSummaryHideDetailsButtonText
                : content.payment.giftCardSummaryShowDetailsButtonText}
            </div>
            <div>{showGiftCardDetails ? <EyeHideSvg /> : <EyeShowSvg />}</div>
          </Button>
        </Box>
      </Flex>
      <Collapse in={showGiftCardDetails}>
        <Box className='recipient-details tiny' sx={{ textAlign: 'left' }}>
          <Box sx={{ mt: 2 }}>
            <Flex sx={{ justifyContent: 'space-between' }}>
              <div>
                <div className='detail-line'>
                  <span sx={{ fontWeight: 'bold' }}>
                    {content.giftCards.paymentRecipientNameLabel}
                  </span>{' '}
                  <span>{giftCardRecipient.name}</span>
                </div>
                <div className='detail-line'>
                  <span sx={{ fontWeight: 'bold' }}>
                    {content.giftCards.paymentRecipientEmailLabel}
                  </span>{' '}
                  <span>{giftCardRecipient.email}</span>
                </div>
                {giftCardRecipient.message && (
                  <Box sx={{ mt: 2 }}>
                    <span sx={{ fontWeight: 'bold' }}>
                      {content.giftCards.paymentRecipientMessageLabel}
                    </span>{' '}
                    <span>{giftCardRecipient.message}</span>
                  </Box>
                )}
              </div>
            </Flex>
            <Box sx={{ mt: 5 }}>
              <Button
                onClick={() => navigate(-1)}
                variant='link'
                className='btn-edit'
                sx={{
                  textAlign: 'center',
                  textTransform: 'uppercase',
                  fontWeight: 'bold',
                }}
              >
                {content.giftCards.paymentEditText}
              </Button>
            </Box>
          </Box>
        </Box>
      </Collapse>
    </Box>
  );
};

export default GiftCardUpsellSummaryRow;
