import {
  AnalyticsPurchaseProperties,
  TrackingCategory,
} from '../@types/analyticsTypes';
import { TicketTypeModel } from '../@types/modelTypes';
import { AnalyticsTrackingEventProperties } from '../@types/trackingTypes';

export const extractPurchaseProperties = (
  properties: AnalyticsTrackingEventProperties
): AnalyticsPurchaseProperties => {
  const { globalState, options } = properties;

  const isTrackingCategory = (val: unknown): val is TrackingCategory => {
    const VALID_TRACKING_CATEGORIES: TrackingCategory[] = [
      'Ticket',
      'GiftCard',
      'Voucher',
      'Kiosk',
      'Loyalty',
    ];
    return VALID_TRACKING_CATEGORIES.includes(val as TrackingCategory);
  };

  const trackingCategory: TrackingCategory | null =
    options && isTrackingCategory(options.trackingCategory)
      ? options.trackingCategory
      : null;
  const totalTax: number | null =
    options && typeof options.totalTax === 'number' ? options.totalTax : null;

  const purchaseProperties: AnalyticsPurchaseProperties = {
    confirmationData: globalState.confirmationData,
    ticketTypes: globalState.ticketTypes,
    cartSummary: globalState.cartSummary,
    config: globalState.config,
    totalTax: totalTax,
    bookingFee: globalState.bookingFee,
    bookingData: globalState.bookingData,
    trackingCategory: trackingCategory,
    content: globalState.content,
    selectedConcessions: globalState.selectedConcessions,
    selectedGiftCards: globalState.selectedGiftCards,
  };

  return purchaseProperties;
};

export const getAddedTicketTypes = (
  ticketTypeModels?: TicketTypeModel[]
): string[] => {
  return (
    ticketTypeModels?.reduce<string[]>((acc, ticketType) => {
      if (ticketType.quantity > 0 && !acc.includes(ticketType.displayName)) {
        return [...acc, ticketType.displayName];
      }
      return acc;
    }, []) ?? []
  );
};

export const getTicketTypesInCart = (
  ticketTypeModels?: TicketTypeModel[]
): TicketTypeModel[] =>
  ticketTypeModels?.filter((ticketType) => ticketType.quantity > 0) ?? [];
