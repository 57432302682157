import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Box } from 'theme-ui';

import { QuantitySelectorContext } from '../../../@types/actionTypes';
import {
  GlobalState,
  TicketTypeModel,
  TicketTypes,
  ValidatedCardPaymentPromoBankCard,
  ValidatedMemberCard,
} from '../../../@types/modelTypes';
import {
  AnalyticsTrackingEventProperties,
  TrackingEvent,
} from '../../../@types/trackingTypes';
import { useAnalytics } from '../../../analytics/analyticsContext';
import { useSetBookingFeeAndTax } from '../../../hooks/useSetBookingFeeAndTax';
import {
  getOrderedTicketGroups,
  setDeals,
  updateJourneyType,
} from '../../../services/TicketHelpers';
import { actionCreators } from '../../../store/ActionCreators';
import {
  selectCartSummary,
  selectConfig,
  selectTicketTypes,
  selectDeals,
  selectDealsInTicketsStep,
  selectJourneyTypeConfig,
  selectState,
  selectUseDynamicTicket,
  selectAppliedMemberCards,
  selectBookingData,
  selectIsSeatsFirstJourney,
  selectAppliedCardPaymentPromoBankCards,
} from '../../../store/Selectors';

interface Props {
  orderHasMaxTickets: boolean;
  ticketTypeGroup:
    | 'members-only'
    | 'non-member-tickets'
    | 'all-tickets'
    | 'card-promo';
  setShowRegister?: React.Dispatch<React.SetStateAction<boolean>>;
}

const TicketSelectorContainer: React.FC<Props> = ({
  orderHasMaxTickets,
  ticketTypeGroup,
  setShowRegister,
}) => {
  const dispatch = useDispatch();
  const analytics = useAnalytics();
  const globalState = useSelector(selectState);
  const bookingData = useSelector(selectBookingData);
  const cartSummary = useSelector(selectCartSummary);
  const config = useSelector(selectConfig);
  const contentTickets = useSelector(
    (state: GlobalState) => state.content.tickets
  );
  const journeyTypeConfig = useSelector(selectJourneyTypeConfig);
  const availablePosTickets = useSelector(
    (state: GlobalState) => state.availablePosTickets
  );
  const ticketTypes = useSelector(selectTicketTypes);
  const deals = useSelector(selectDeals) ?? [];
  const dealsInTicketsStep = useSelector(selectDealsInTicketsStep) ?? [];
  const useDynamicTicket = useSelector(selectUseDynamicTicket);
  const appliedMemberCards: ValidatedMemberCard[] = useSelector(
    selectAppliedMemberCards
  );
  const appliedCardPaymentPromoBankCards: ValidatedCardPaymentPromoBankCard[] =
    useSelector(selectAppliedCardPaymentPromoBankCards);
  const isSeatsFirstJourney = useSelector(selectIsSeatsFirstJourney);

  useSetBookingFeeAndTax();

  useEffect(() => {
    if (ticketTypes || !availablePosTickets) return;
    const createTicketsList = () => {
      const { groupedTicketTypes } = availablePosTickets;
      const ticketTypeModels: TicketTypeModel[] = [];
      groupedTicketTypes.forEach((x) => {
        x.ticketTypeModels.forEach((m) => {
          ticketTypeModels.push(m);
        });
      });
      const ticketTypes = { ticketTypeModels };
      dispatch(actionCreators.setTicketTypes(ticketTypes));
    };
    createTicketsList();
  }, [ticketTypes, availablePosTickets, dispatch]);

  if (!ticketTypes || !cartSummary) return null;

  const handleTicketButtonClick = async (
    ticketTypeId: TicketTypeModel['id'],
    context: QuantitySelectorContext
  ) => {
    const { ticketTypeModels } = ticketTypes;
    const ticketTypeModel = ticketTypeModels.find((x) => x.id === ticketTypeId);
    if (!ticketTypeModel) {
      return;
    }
    const shouldShowRegister =
      setShowRegister &&
      config.tickets.enableMultipleLoyaltyCardTicketPurchase &&
      !globalState.bookingData.isUserValidated &&
      context === 'add';

    const hasDeals = !!deals && deals.length;
    if (shouldShowRegister) {
      setShowRegister(true);
      return;
    }

    const tryRemoveMemberTicket = () => {
      const shouldRemoveMemberTicket =
        config.tickets.enableMultipleLoyaltyCardTicketPurchase &&
        bookingData.isUserValidated &&
        ticketTypeModel.isMemberTicket;
      if (shouldRemoveMemberTicket) {
        const appliedMemberCardsWithSameTicketId = appliedMemberCards
          .filter((x) => x.usedTicket.id === ticketTypeId)
          .map((x) => ({ ...x }));

        if (
          appliedMemberCardsWithSameTicketId?.length > 0 &&
          (ticketTypeModel.quantity === 2 ||
            ticketTypeModel.quantity ===
              appliedMemberCardsWithSameTicketId.length)
        ) {
          const lastUsedMemberCardNumber =
            appliedMemberCardsWithSameTicketId[
              appliedMemberCardsWithSameTicketId.length - 1
            ]?.memberCardNumber;

          if (lastUsedMemberCardNumber) {
            if (ticketTypeModel.validatedVouchers) {
              ticketTypeModel.validatedVouchers =
                ticketTypeModel.validatedVouchers.filter(
                  (card) => card !== lastUsedMemberCardNumber
                );
              dispatch(
                actionCreators.removeMemberCard(lastUsedMemberCardNumber)
              );
            }
          }
        }
      }
    };

    const tryRemovePromoTicket = () => {
      const appliedBankCardToRemove = appliedCardPaymentPromoBankCards.find(
        (c) => c.usedTicketId === ticketTypeId
      );

      appliedBankCardToRemove &&
        dispatch(
          actionCreators.removeCardPaymentPromoBankCard(
            appliedBankCardToRemove.bankCardNumber
          )
        );
    };

    if (context === 'add') {
      ticketTypeModel.quantity += 1;
      const trackProperties: AnalyticsTrackingEventProperties = {
        globalState,
      };
      analytics?.track(TrackingEvent.ADD_TICKET, trackProperties);
      useDynamicTicket &&
        dispatch(actionCreators.addSingleTicket(ticketTypeModel));
    } else if (context === 'remove') {
      ticketTypeModel.isCardPaymentPromotionTicket && tryRemovePromoTicket();
      tryRemoveMemberTicket();
      ticketTypeModel.quantity += -1;
      if (!isSeatsFirstJourney) {
        dispatch(actionCreators.removeAllSeats());
      }
      useDynamicTicket &&
        dispatch(actionCreators.removeSingleTicket(ticketTypeModel));
    }

    const nextTicketTypes: TicketTypes = { ticketTypeModels };
    dispatch(actionCreators.setTicketTypes(nextTicketTypes));

    if (config.tickets.useAllocatableSeatingToUpdateJourneyType) {
      updateJourneyType(availablePosTickets, journeyTypeConfig.type, dispatch);
    }

    if (hasDeals) {
      setDeals(
        ticketTypeId,
        nextTicketTypes,
        deals,
        dealsInTicketsStep,
        context,
        dispatch
      );
    }
  };

  const memberTickets =
    ticketTypeGroup === 'non-member-tickets'
      ? []
      : ticketTypes.ticketTypeModels.filter(
          (t) => t.isMemberTicket && !t.isCardPaymentPromotionTicket
        );

  const packageTickets =
    ticketTypeGroup === 'members-only'
      ? []
      : ticketTypes.ticketTypeModels.filter(
          (t) =>
            t.isPackageTicket &&
            !t.isMemberTicket &&
            !t.isCardPaymentPromotionTicket
        );

  const standardTickets =
    ticketTypeGroup === 'members-only'
      ? []
      : ticketTypes.ticketTypeModels.filter(
          (t) =>
            !t.isMemberTicket &&
            !t.isPackageTicket &&
            !t.isCardPaymentPromotionTicket
        );

  const cardPromoTickets =
    ticketTypeGroup === 'card-promo'
      ? ticketTypes.ticketTypeModels.filter(
          (t) => t.isCardPaymentPromotionTicket
        )
      : [];

  const numberOfSections = () => {
    let count = 0;
    if (memberTickets.length > 0 && contentTickets.memberTicketsHeading)
      count += 1;
    if (packageTickets.length > 0 && contentTickets.packageTicketsHeading)
      count += 1;
    if (standardTickets.length > 0 && contentTickets.standardTicketsHeading)
      count += 1;
    return count;
  };

  const showHeadings = numberOfSections() > 1;
  let margin = 7;
  if (ticketTypeGroup === 'card-promo' || showHeadings) {
    margin = 4;
  }
  const className =
    ticketTypeGroup === 'members-only' ? 'multiple-member-ticket' : 'contained';

  return (
    <Box className={className} sx={{ mt: margin, textAlign: 'center' }}>
      {getOrderedTicketGroups(
        config,
        orderHasMaxTickets,
        showHeadings,
        contentTickets,
        memberTickets,
        packageTickets,
        standardTickets,
        cardPromoTickets,
        handleTicketButtonClick
      )}
    </Box>
  );
};

export default TicketSelectorContainer;
