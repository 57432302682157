import { ThemeUICSSObject } from 'theme-ui';

export const priceSxProp: ThemeUICSSObject = {
  textAlign: 'right',
  minWidth: '68px',
};
export const quantitySxProp: ThemeUICSSObject = {
  minWidth: '20px',
  textAlign: 'center',
};
export const descriptionSxProp: ThemeUICSSObject = {
  textAlign: 'left',
  pr: 1,
  alignItems: 'center',
  textWrap: 'balance',
};
export const detailSxProp: ThemeUICSSObject = {
  alignItems: 'center',
  justifyContent: 'flex-end',
};
export const concessionItemSxProp: ThemeUICSSObject = {
  alignItems: 'center',
  justifyContent: 'space-between',
};
