/** @jsxImportSource theme-ui */
import React from 'react';

import { Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { Cinema } from '../../../../@types/configTypes';
import { selectConfig, selectContent } from '../../../../store/Selectors';
import ContainedRow from '../../layout/ContainedRow';
import RichText from '../../richtext/RichText';

interface Props {
  handleCinemaChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  cinemaId: string | undefined;
}

const CinemaSelectorLoyaltyUpgrade: React.FC<Props> = ({
  handleCinemaChange,
  cinemaId,
}) => {
  const config = useSelector(selectConfig);
  const content = useSelector(selectContent);

  const allowCinemaSelection =
    config && config.loyalty && config.loyalty.allowCinemaSelection;
  const cinemas = config?.cinemas?.filter((x: Cinema) => !x.isBookingDisabled);

  if (!allowCinemaSelection || !cinemas) {
    return null;
  }

  return (
    <>
      <ContainedRow styles={{ mt: 5 }}>
        <div className='separator' />
      </ContainedRow>
      <ContainedRow styles={{ mt: 5, textAlign: 'left' }}>
        <h1>{content.concessionsOnlyJourney.locationSelectorHeading}</h1>
        <RichText
          styles={{ mt: 4 }}
          text={content.concessionsOnlyJourney.locationSelectorRichText}
        />
        <Form.Group sx={{ mb: 4 }}>
          <Form.Label>
            {content.concessionsOnlyJourney.locationSelectorLabel}
          </Form.Label>
          <Form.Select
            className='form-control'
            onChange={handleCinemaChange}
            value={cinemaId}
          >
            <option key={-1} value={-1}>
              {content.concessionsOnlyJourney.locationSelectorOptionText}
            </option>
            {cinemas.map((c: Cinema) => (
              <option key={c.cinemaId} value={c.cinemaId}>
                {c.title}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
      </ContainedRow>
    </>
  );
};

export default CinemaSelectorLoyaltyUpgrade;
