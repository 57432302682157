import { useEffect } from 'react';

import { useBoostNavigate } from './useBoostNavigate';

export const useValidateSeasonPassJourney = (isValidData: boolean) => {
  const boostNavigate = useBoostNavigate();

  useEffect(() => {
    if (isValidData) return;

    boostNavigate.navigateToStartJourneyUsingLocation();
  }, [boostNavigate, isValidData]);
};
