/** @jsxImportSource theme-ui */
import React, { useEffect, useCallback } from 'react';

import { useCookies } from 'react-cookie';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import { Box } from 'theme-ui';

import { ConfirmationRequestModel } from '../../../@types/modelTypes';
import { useConfirmationTracking } from '../../../hooks/useConfirmationTracking';
import { createConfirmation } from '../../../services/Helpers';
import { buildQueryString } from '../../../services/QueryStringHelper';
import backend from '../../../services/RestUtilities';
import { actionCreators } from '../../../store/ActionCreators';
import {
  selectConfig,
  selectConfirmationData,
  selectContent,
  selectCurrentCinemaHomeUrl,
  selectCustomer,
  selectGuestSessionToken,
  selectJourneyType,
  selectPhysicalGiftCardRecipient,
  selectToken,
} from '../../../store/Selectors';
import ActionButton from '../../common/actionbutton/ActionButton';
import ContainedRow from '../../common/layout/ContainedRow';
import RichText from '../../common/richtext/RichText';

const GiftMembershipConfirmation: React.FC = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const { orderId } = useParams();
  const [cookies] = useCookies();

  const config = useSelector(selectConfig);
  const token = useSelector(selectToken);
  const content = useSelector(selectContent);
  const confirmationData = useSelector(selectConfirmationData);
  const guestSessionToken = useSelector(selectGuestSessionToken);

  const physicalGiftCardRecipient = useSelector(
    selectPhysicalGiftCardRecipient
  );

  const customer = useSelector(selectCustomer);
  const journeyType = useSelector(selectJourneyType);
  const currentCinemaHomeUrl = useSelector(selectCurrentCinemaHomeUrl);

  useConfirmationTracking('GiftCard');

  const fetchData = useCallback(async () => {
    dispatch(actionCreators.setLoading(true));
    const qs = buildQueryString({
      circuitId: searchParams.get('circuitId') ?? undefined,
      languageCulture: cookies.preferredLanguageCulture,
    });
    const url = `api/Confirmation/${orderId}${qs}`;

    let body: ConfirmationRequestModel | undefined;

    if (guestSessionToken) {
      body = {
        DataToken: token,
        GuestSessionToken: guestSessionToken,
      };
    }

    const response = await backend.post(url, body);

    if (response.ok) {
      const data = response.content;
      const confirmation = createConfirmation(data, customer, journeyType);
      dispatch(actionCreators.setConfirmation(confirmation));
    }

    dispatch(actionCreators.setGuestSessionToken(undefined));
    dispatch(actionCreators.setLoading(false));
  }, [
    dispatch,
    searchParams,
    cookies.preferredLanguageCulture,
    orderId,
    guestSessionToken,
    token,
    customer,
    journeyType,
  ]);

  // fetch data
  useEffect(() => {
    if (!confirmationData || confirmationData.externalOrderId !== orderId) {
      fetchData();
    }
  }, [confirmationData, orderId, fetchData]);

  const processMode = process.env.NODE_ENV.toLowerCase();
  const isDevelopment = processMode === 'development';
  const buyAnotherGiftCardButtonUrl = isDevelopment
    ? `${'/physicalgiftcards/?circuitId='}${config?.circuitId}`
    : '/physicalgiftcards/';

  const contentGiftMembership = content?.giftMembership;

  if (!content || !config || !contentGiftMembership) return null;

  return (
    <div className='payment' data-testid='payment' sx={{ textAlign: 'center' }}>
      <ContainedRow textAlign='center'>
        <h1>{contentGiftMembership.stepThreeHeading}</h1>
        <h2 sx={{ mt: 5 }}>{contentGiftMembership.confirmationHeading}</h2>
        <RichText
          text={contentGiftMembership.confirmationRichText}
          styles={{ mt: 4, textAlign: 'left' }}
        />
        <Box sx={{ mt: 5, textAlign: 'left' }}>
          <div>
            <span>{physicalGiftCardRecipient.name}</span>
          </div>
          <div sx={{ mt: 2 }}>
            <span>{physicalGiftCardRecipient.addressLineOne}</span>
            <br />
            {physicalGiftCardRecipient.addressLineTwo && (
              <>
                <span>{physicalGiftCardRecipient.addressLineTwo}</span>
                <br />
              </>
            )}
            <span>{physicalGiftCardRecipient.city}</span>
            <br />
            <span>{physicalGiftCardRecipient.postcode}</span>
            <br />
          </div>
          {physicalGiftCardRecipient.message && (
            <div sx={{ mt: 2 }}>
              <span>
                {contentGiftMembership.confirmationMessageIncludedText}
              </span>
            </div>
          )}
        </Box>
        <h2 sx={{ mt: 6 }}>
          {contentGiftMembership.confirmationAdditionalHeading}
        </h2>
        <RichText
          text={contentGiftMembership.confirmationAdditionalRichText}
          styles={{ mt: 4 }}
        />
      </ContainedRow>
      <ActionButton
        contained
        disabled={false}
        hasMarginX
        onClick={() => (window.location.href = currentCinemaHomeUrl)}
        showIcon={true}
        sticky={false}
        variant='primary'
      >
        {contentGiftMembership.goBackToWebsiteButtonText}
      </ActionButton>

      {config.giftCardsPurchase.showBuyAnotherGiftCardButton && (
        <ActionButton
          contained
          disabled={false}
          mt={0}
          hasMarginX
          onClick={() =>
            (window.location.href = `${buyAnotherGiftCardButtonUrl}`)
          }
          showIcon={true}
          sticky={false}
          variant='secondary'
        >
          {contentGiftMembership.buyAnotherGiftMembershipButtonText}
        </ActionButton>
      )}
    </div>
  );
};

export default GiftMembershipConfirmation;
