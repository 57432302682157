/** @jsxImportSource theme-ui */
import React from 'react';

import classNames from 'classnames';
import { useSelector } from 'react-redux';

import PaymentIcons from './PaymentIcons';

import { selectConfig, selectContent } from '../../../../../../store/Selectors';

interface Props {
  creditCardType: string;
  paymentDesignName: 'split' | 'tabbed';
}

const PaymentIconsContainer: React.FC<Props> = ({
  creditCardType,
  paymentDesignName,
}) => {
  const config = useSelector(selectConfig);
  const content = useSelector(selectContent);

  const allPaymentIconsHidden =
    !config.payment.showApplePay &&
    !config.payment.showGooglePay &&
    config.payment.hideAmex &&
    config.payment.hideDiscover &&
    config.payment.hideMaestro &&
    config.payment.hideMastercard &&
    config.payment.hideVisa;

  const paymentIconsTextClassName =
    paymentDesignName == 'tabbed' ? 'tiny' : undefined;

  const showPaymentIconsText = paymentDesignName === 'split';

  return (
    <>
      {allPaymentIconsHidden || (
        <>
          {showPaymentIconsText && (
            <div
              className={classNames(
                'payment-logos-text tiny',
                paymentIconsTextClassName && paymentIconsTextClassName
              )}
              data-testid='payment-logos-text'
            >
              {content.payment.paymentLogosText}
            </div>
          )}
          <div
            sx={{
              display: paymentDesignName == 'split' ? 'flex' : 'block',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
            data-testid='payment-icons-container'
          >
            <PaymentIcons cardType={creditCardType} />
          </div>
        </>
      )}
    </>
  );
};

export default PaymentIconsContainer;
