import React, { FC, memo } from 'react';

import ContainedRow from '../../common/layout/ContainedRow';
import { WidgetData } from '../types';
type Props = {
  widget: WidgetData<'TicketingCMSJourneyDividerWidget'>;
};

export const Divider: FC<Props> = () => {
  return (
    <div className='widget-row'>
      <ContainedRow>
        <div className='separator'></div>
      </ContainedRow>
    </div>
  );
};

export default memo(Divider);
