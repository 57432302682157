import { useEffect } from 'react';

import { useLocation } from 'react-router-dom';

export const useRemoveNoScroll = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    document.documentElement.classList.remove('no-scroll');
    document.body.classList.remove('no-scroll');
  }, [pathname]);
};
