import React, { useEffect, useRef } from 'react';

import { Stripe, StripeConstructorOptions } from '@stripe/stripe-js';
import { useSelector } from 'react-redux';

import { PaymentProvidersEnum } from '../../../../../@types/enums';
import { useGetPaymentProvider } from '../../../../../hooks/useGetPaymentProvider';
import { displayPrice } from '../../../../../services/Helpers';
import {
  selectBankCardAmount,
  selectContent,
  selectCurrencyConfig,
  selectHostedPayments,
  selectIsCustomerReadyForPayment,
  selectMaxRetriesExceeded,
  selectSelectedPaymentProvider,
} from '../../../../../store/Selectors';
import ActionButton from '../../../actionbutton/ActionButton';
type LoadStripeFn = (
  publishableKey: string,
  options?: StripeConstructorOptions | undefined
) => Promise<Stripe | null>;

const StripeCheckoutPaymentV2: React.FC = () => {
  const isCustomerReadyForPayment = useSelector(
    selectIsCustomerReadyForPayment
  );
  const hostedPayments = useSelector(selectHostedPayments);
  const selectedPaymentProvider = useSelector(selectSelectedPaymentProvider);
  const maxRetriesExceeded = useSelector(selectMaxRetriesExceeded);
  const content = useSelector(selectContent);
  const currencyConfig = useSelector(selectCurrencyConfig);
  const bankCardAmount = useSelector(selectBankCardAmount);

  const callStripe = useRef<LoadStripeFn>();

  const hostedPayment = hostedPayments[PaymentProvidersEnum.STRIPEV2];

  useGetPaymentProvider(PaymentProvidersEnum.STRIPEV2);

  useEffect(() => {
    const getStripeLib = async () => {
      const { loadStripe } = await import('@stripe/stripe-js');
      callStripe.current = loadStripe;
    };
    if (!callStripe.current) {
      getStripeLib();
    }
  }, [callStripe]);

  const handlePaymentClick = async () => {
    const launchStripeCheckout = async () => {
      if (
        !callStripe?.current ||
        !hostedPayment ||
        hostedPayment.publishKey === undefined ||
        selectedPaymentProvider !== PaymentProvidersEnum.STRIPEV2
      ) {
        return;
      }
      const stripe = await callStripe.current(hostedPayment.publishKey);
      const stripeResponse = await stripe?.redirectToCheckout({
        sessionId: hostedPayment.transactionId,
      });
      if (stripeResponse?.error) {
        //console.log(stripeResponse.error);
      }
    };

    if (hostedPayment && isCustomerReadyForPayment) {
      launchStripeCheckout();
    }
  };

  return selectedPaymentProvider === PaymentProvidersEnum.STRIPEV2 ? (
    <ActionButton
      onClick={handlePaymentClick}
      disabled={maxRetriesExceeded || !hostedPayment}
      showIcon
      mb={0}
      mt={0}
      variant='primary'
    >
      {`${content.payment.submitText} ${displayPrice(
        bankCardAmount,
        currencyConfig
      )}`}
    </ActionButton>
  ) : null;
};

export default StripeCheckoutPaymentV2;
