import { useLayoutEffect, useState } from 'react';

export const useWindowSize = () => {
  const [size, setSize] = useState(0);
  useLayoutEffect(() => {
    function updateWidth() {
      const vw =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
      setSize(vw);
    }
    window.addEventListener('resize', updateWidth);
    updateWidth();
    return () => window.removeEventListener('resize', updateWidth);
  }, []);
  return size;
};
