import React from 'react';

import { Box, ThemeUIStyleObject } from 'theme-ui';

import TokenReplacer from '../../../services/TokenReplacer';

interface Props {
  className?: string;
  text: string;
  styles?: ThemeUIStyleObject;
}

const RichText: React.FC<Props> = ({ text, className, styles }) => {
  if (!text) return null;

  return (
    <Box
      className={className}
      dangerouslySetInnerHTML={{
        __html: TokenReplacer(text),
      }}
      data-testid='richtext'
      sx={styles}
    />
  );
};

export default RichText;
