/** @jsxImportSource theme-ui */
import React from 'react';

import { useSelector } from 'react-redux';

import { CommonTrackingEvent } from '../../../@types/trackingTypes';
import { useAnalytics } from '../../../analytics/analyticsContext';
import { JOURNEY_TYPES } from '../../../constants';
import { handleRegisterClick } from '../../../services/LoyaltyHelpers';
import {
  selectCartSummary,
  selectConfig,
  selectContent,
  selectJourneyTypeConfig,
  selectRegisterUrl,
} from '../../../store/Selectors';
import ActionButton from '../actionbutton/ActionButton';
import ContainedRow from '../layout/ContainedRow';
import RichText from '../richtext/RichText';

interface Props {
  showDivider?: boolean;
}

const Register: React.FC<Props> = ({ showDivider }) => {
  const config = useSelector(selectConfig);
  const content = useSelector(selectContent);
  const registerUrl = useSelector(selectRegisterUrl);
  const journeyTypeConfig = useSelector(selectJourneyTypeConfig);
  const cartSummary = useSelector(selectCartSummary);
  const analytics = useAnalytics();

  if (!config?.signIn?.displayRegisterButton) return null;

  const getHeading = () => {
    if (journeyTypeConfig.type === JOURNEY_TYPES.VOUCHERS_ONLY) {
      return content.signIn.loyaltyOnlyVouchersRegisterHeading;
    } else if (cartSummary.isLoyaltyOnlyShowtime) {
      return content.signIn.loyaltyOnlyShowtimeRegisterHeading;
    } else {
      return content.signIn.registerHeading;
    }
  };

  const getRichText = () => {
    switch (journeyTypeConfig.type) {
      case JOURNEY_TYPES.LOYALTY_UPGRADE:
        return content.signIn.upgradeMemberRichText;
      case JOURNEY_TYPES.VOUCHERS_ONLY:
        return content.signIn.loyaltyOnlyVouchersRegisterRichText;
      default:
        return cartSummary.isLoyaltyOnlyShowtime
          ? content.signIn.loyaltyOnlyShowtimeRegisterRichText
          : content.signIn.registerRichText;
    }
  };

  const handleClick = () => {
    handleRegisterClick(registerUrl);
    analytics?.track(CommonTrackingEvent.REGISTER_CLICK);
  };

  return (
    <>
      {showDivider && (
        <ContainedRow styles={{ mt: 5, textAlign: 'center' }}>
          <div className='separator' />
        </ContainedRow>
      )}
      <ContainedRow textAlign='center'>
        <h2 sx={{ mt: 7 }}>{getHeading()}</h2>
        <RichText text={getRichText()} />
      </ContainedRow>

      <ContainedRow textAlign='center'>
        <ActionButton
          onClick={() => handleClick()}
          showIcon
          contained
          variant='secondary'
          hasMarginX
        >
          {content.signIn.registerButtonText}
        </ActionButton>
      </ContainedRow>
    </>
  );
};

export default Register;
