/** @jsxImportSource theme-ui */
import React from 'react';

import { useSelector } from 'react-redux';

import PhysicalConcessionRecipientDetails from './RecipientDetailsPhysical';

import { selectContentPhysicalGiftcards } from '../../../../store/Selectors';
import CustomerDetails from '../../customerdetails/CustomerDetails';
import ContainedRow from '../../layout/ContainedRow';
import RichText from '../../richtext/RichText';
import DeliveryOptionsSelector from '../DeliveryOptionsSelector';

interface Props {
  isPageValidated: boolean;
}

const DeliveryDetails: React.FC<Props> = ({ isPageValidated }) => {
  const contentPhysicalGiftCards = useSelector(selectContentPhysicalGiftcards);

  if (!contentPhysicalGiftCards) {
    return null;
  }

  return (
    <ContainedRow classNameWrapper='delivery-details'>
      <h2 sx={{ mt: 7, textAlign: 'center' }}>
        2. {contentPhysicalGiftCards.recipientDetailsHeading}
      </h2>
      <RichText
        styles={{ mb: 5 }}
        text={contentPhysicalGiftCards.recipientDetailsRichText}
      />
      <PhysicalConcessionRecipientDetails isPageValidated={isPageValidated} />
      <DeliveryOptionsSelector />
      <h2 sx={{ mt: 7, textAlign: 'center' }}>
        3. {contentPhysicalGiftCards.billingDetailsHeading}
      </h2>
      <RichText text={contentPhysicalGiftCards.billingDetailsRichText} />
      <CustomerDetails isPageValidated={isPageValidated} />
    </ContainedRow>
  );
};

export default DeliveryDetails;
