import { defineMessages } from 'react-intl';

export default defineMessages({
  backOfTheaterLabel: {
    id: 'seat-selection.back-of-theater-label',
    defaultMessage: 'BACK OF THEATER',
  },
  companionSeatWarningText: {
    id: 'seat-selection.companion-seat-warning-text',
    defaultMessage:
      'This seat is reserved for companions of wheelchair users. If this seat is required by a companion, you may be asked to move. Would you still like to select this seat?',
  },
  companionSeatWarningTitle: {
    id: 'seat-selection.companion-seat-warning-title-label',
    defaultMessage: 'Companion Seat Warning',
  },
  screenLabel: {
    id: 'seat-selection.screen-label',
    defaultMessage: 'SCREEN',
  },
  seatWarningButtonNo: {
    id: 'seat-selection.seat-warning-button-no-label',
    defaultMessage: 'Cancel',
  },
  seatWarningButtonYes: {
    id: 'seat-selection.seat-warning-button-yes-label',
    defaultMessage: 'Select',
  },
  singleSeatRuleText: {
    id: 'seat-selection.single-seat-rule-text',
    defaultMessage:
      'No single seats between occupied seats or aisle permitted.',
  },
  subTitle: {
    id: 'seat-selection.sub-title-label',
    defaultMessage: 'Select your seats',
  },
  titleAccessibleText: {
    id: 'seat-selection.title-accessible-text',
    defaultMessage: 'Accessible',
  },
  titleAvailableText: {
    id: 'seat-selection.title-available-text',
    defaultMessage: 'Available',
  },
  titleBufferText: {
    id: 'seat-selection.title-buffer-text',
    defaultMessage: 'Buffer',
  },
  titleCompanionText: {
    id: 'seat-selection.title-companion-text',
    defaultMessage: 'Companion',
  },
  titleEntranceText: {
    id: 'seat-selection.title-entrance-text',
    defaultMessage: 'Entrance',
  },
  titleEmergencyExitText: {
    id: 'seat-selection.title-emergency-exit-text',
    defaultMessage: 'Emergency exit',
  },
  titleLeftSofaText: {
    id: 'seat-selection.title-left-sofa-text',
    defaultMessage: 'Left sofa',
  },
  titleLuxuryReclinerText: {
    id: 'seat-selection.title-luxury-recliner-text',
    defaultMessage: 'Luxury recliner',
  },
  titleMiddleSofaText: {
    id: 'seat-selection.title-middle-sofa-text',
    defaultMessage: 'Middle sofa',
  },
  titleRightSofaText: {
    id: 'seat-selection.title-right-sofa-text',
    defaultMessage: 'Right sofa',
  },
  titleSeatText: {
    id: 'seat-selection.title-seat-text',
    defaultMessage: 'Seat',
  },
  titleRowText: {
    id: 'seat-selection.title-row-text',
    defaultMessage: 'Row',
  },
  titleSeatNumberText: {
    id: 'seat-selection.title-seat-number-text',
    defaultMessage: 'Seat number',
  },
  titleSelectedText: {
    id: 'seat-selection.title-selected-text',
    defaultMessage: 'Selected',
  },
  titleSofaText: {
    id: 'seat-selection.title-sofa-text',
    defaultMessage: 'Sofa',
  },
  titleObstructedView: {
    id: 'seat-selection.title-obstructed-view-text',
    defaultMessage: 'Obstructed view',
  },
  titleUnavailableText: {
    id: 'seat-selection.title-unavailable-text',
    defaultMessage: 'Unavailable',
  },
  titleUnavailableWithSelectedTicketsText: {
    id: 'seat-selection.title-unavailable-with-selected-tickets-text',
    defaultMessage: 'Unavailable with your ticket types.',
  },
  titleVipText: {
    id: 'seat-selection.title-vip-text',
    defaultMessage: 'VIP',
  },
  titleZoomInText: {
    id: 'seat-selection.title-zoom-in-text',
    defaultMessage: 'Zoom in',
  },
  titleZoomOutText: {
    id: 'seat-selection.title-zoom-out-text',
    defaultMessage: 'Zoom out',
  },
  titleStairsText: {
    id: 'seat-selection.title-stairs-text',
    defaultMessage: 'Stairs',
  },
  wheelchairSeatWarningText: {
    id: 'seat-selection.wheelchair-seat-warning-text',
    defaultMessage:
      'This space is reserved for wheelchair use only and does not have a seat. This wheelchair space and the seat adjacent to it are reserved for a patron using a wheelchair and their companion only.',
  },
  wheelchairSeatWarningTitle: {
    id: 'seat-selection.wheelchair-seat-warning-title-label',
    defaultMessage: 'Wheelchair Space Warning',
  },
  yourSeats: {
    id: 'seat-selection.your-seats-title-label',
    defaultMessage: 'Your Seats',
  },
  cartUpdateModalTitle: {
    id: 'seat-selection.cart-update-modal-title-label',
    defaultMessage: 'Cart update',
  },
  cartUpdateModalExplanationText: {
    id: 'seat-selection.cart-update-modal-explanation-text',
    defaultMessage:
      'The following items have been removed from your cart because they are no longer available.',
  },
  cartUpdateModalRemovedItemText: {
    id: 'seat-selection.cart-update-modal-removed-item-text',
    defaultMessage: 'Removed item',
  },
  cartUpdateModalKioskCollectionText: {
    id: 'seat-selection.cart-update-modal-kiosk-collection-text',
    defaultMessage: 'Kiosk collection',
  },
  cartUpdateModalCancelButtonTitle: {
    id: 'seat-selection.cart-update-modal-cancel-button-title-label',
    defaultMessage: 'Cancel',
  },
  cartUpdateModalContinueButtonTitle: {
    id: 'seat-selection.cart-update-modal-continue-button-label',
    defaultMessage: 'Continue',
  },
});
