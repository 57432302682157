import React from 'react';

import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { Box, Flex } from 'theme-ui';

import { resolveSvg } from './SeatSvgResolver';

import { selectContentSeats } from '../../../store/Selectors';

interface Props {
  label: string;
  svgType: string;
  svgClassName: string;
  svgSource?: string;
}

const LegendItem: React.FC<Props> = ({
  label: title,
  svgType,
  svgClassName,
  svgSource,
}) => {
  const contentSeats = useSelector(selectContentSeats);

  return (
    <Flex sx={{ alignItems: 'center' }}>
      {resolveSvg(
        svgType,
        classNames(svgClassName, 'legend-icon'),
        title,
        svgSource
      )}
      <Box sx={{ ml: 2 }}>
        <span>{title}</span>
        <span className='visually-hidden'> {contentSeats.titleSeatText}</span>
      </Box>
    </Flex>
  );
};

export default LegendItem;
