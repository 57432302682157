import { useEffect } from 'react';

import { useSelector } from 'react-redux';

import { cinemaHomeUrlKey } from '../constants';
import { selectCurrentCinemaHomeUrl } from '../store/Selectors';

export const useCinemaHomeUrlUpdater = () => {
  const currentCinemaHomeUrl = useSelector(selectCurrentCinemaHomeUrl);

  const cinemaHomeUrl = window.sessionStorage.getItem(cinemaHomeUrlKey);

  useEffect(() => {
    if (currentCinemaHomeUrl === cinemaHomeUrl) return;

    window.sessionStorage.setItem(cinemaHomeUrlKey, currentCinemaHomeUrl);
  }, [cinemaHomeUrl, currentCinemaHomeUrl]);
};
