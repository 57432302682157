/** @jsxImportSource theme-ui */
import React from 'react';

import { Button } from 'theme-ui';

import { ReactComponent as ChevronLeftSvg } from '../../../svgs/chevronLeft.svg';

interface Props {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

const FandBItemModalGoBack: React.FC<Props> = ({ onClick }) => {
  return (
    <Button
      onClick={onClick}
      variant='link'
      sx={{
        position: 'absolute',
        left: 0,

        svg: {
          height: '1.25rem',
          width: '1.25rem',

          '& .icon': {
            fill: 'mostReadableOnWebsiteBackground',
          },
        },
      }}
    >
      <ChevronLeftSvg />
    </Button>
  );
};

export default FandBItemModalGoBack;
