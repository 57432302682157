const destroyChildElementByIdIfExists = (parentElement, childElementId) => {
  var childElement = document.getElementById(childElementId);
  if (childElement) {
    parentElement.removeChild(childElement);
  }
};

export const queryPayrixPayFields = () => {
  return document.getElementById('payFields-iframe');
};

export const queryPayrixApplePay = () => {
  return document.getElementById('payrix-applepay');
};

export const loadPayrixPayFields = (scriptBaseUrl, callback) => {
  destroyChildElementByIdIfExists(document.body, 'payFields-iframe');
  destroyChildElementByIdIfExists(document.head, 'payrix-jquery');
  destroyChildElementByIdIfExists(document.head, 'payrix-pay');

  const script = document.createElement('script');
  script.src = 'https://code.jquery.com/jquery-2.2.4.min.js';
  script.type = 'text/javascript';
  script.id = 'payrix-jquery';
  script.crossorigin = '';
  document.head.appendChild(script);
  script.onload = () => {
    const script = document.createElement('script');
    script.src = `${scriptBaseUrl}/payFieldsScript`;
    script.type = 'text/javascript';
    script.id = 'payrix-pay';
    script.crossorigin = '';
    document.head.appendChild(script);
    script.onload = () => {
      if (callback) callback();
    };
  };
};

export const loadPayrixApplePay = (callback) => {
  const script = document.createElement('script');
  script.src = 'https://applepay.cdn-apple.com/jsapi/v1/apple-pay-sdk.js';
  script.id = 'payrix-applepay';
  script.crossorigin = '';
  document.head.appendChild(script);
  script.onload = () => {
    if (callback) callback();
  };
};
