import moment from 'moment';

import { getAddedTicketTypes, getTicketTypesInCart } from './analyticsHelpers';

import { GlobalState, TicketTypeModel } from '../@types/modelTypes';
import { IEventTrackingStrategy, TrackingEvent } from '../@types/trackingTypes';

export class EMGruviEventTrackingStrategy implements IEventTrackingStrategy {
  processEvent(
    eventName: string,
    state: GlobalState,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    options?: any
  ) {
    const dataLayer = window[options?.dataLayerName];
    const populateDataLayer = (payload: object) => {
      if (dataLayer && Array.isArray(dataLayer)) {
        dataLayer.push(payload);
      }
    };
    if (!state.bookingData || !state.cartSummary) {
      return;
    }

    const formatCentsValue = (value: number | undefined) => {
      return value ? (value / 100).toFixed(2) : null;
    };

    const dateTime = state.cartSummary.sessionDate
      ? moment(state.cartSummary.sessionDate)
      : moment();
    const formattedShowDate = dateTime.format('YYYY-MM-DD');
    const formattedShowTime = dateTime.format('HH:mm');
    const formattedShowWeekDay = dateTime.locale('en-GB').format('dddd');
    const formattedReleaseDate = moment(state.cartSummary.releaseDate).format(
      'YYYY-MM-DD'
    );

    const userData = {
      user_id: this.getUserId(state.bookingData),
      membership_type: this.getMembershipType(state.bookingData),
      source_type: state.source === 'web' ? 'website' : state.source,
      logged_user: `${state.bookingData.isUserValidated}`,
      email: this.getUserEmail(state.bookingData),
      firstName: this.getUserFirstName(state.bookingData),
      surname: this.getUserLastName(state.bookingData),
    };

    const filmData = {
      film_name: state.cartSummary.title,
      film_director: null,
      cast: null,
      cms_id: null, // NEEDS to be item_id
      // imdb_id: state.cartSummary.filmExternalId,
      vista_id: state.cartSummary.filmExternalId,
      genre: state.cartSummary.filmGenres,
      age_rating: state.cartSummary.rated,
      release_date: formattedReleaseDate,
      subtitles: null,
      dubbed: null,
      dubbed_language: null,
      show_date: formattedShowDate,
      show_time: formattedShowTime,
      show_week_day: formattedShowWeekDay,
    };

    const cinemaData = {
      cinema_id: `${state.bookingData.boxofficeCinemaId}`,
      cinema_vista_id: `${state.bookingData.externalCinemaId}`,
      cinema_name: `${state.cartSummary.theaterLocationName}`,
      cinema_city: `${state.config.currentCinema.city}`,
      screen_format: null,
    };

    const getLoginLandingData = () => {
      const loginLandingData = {
        event: 'autoEvent',
        event_category: 'Ticket Checkout',
        event_action: 'Ticketing start Login',
        event_label: 'ticketing_start',
        ...userData,
        ...filmData,
        ...cinemaData,
      };
      return loginLandingData;
    };

    const getAddTicketData = () => {
      const selectedSeats = state.selectedSeats?.map((seat) => seat.seatName);

      const getEcommerceItems = (ticketTypesInCart: TicketTypeModel[]) =>
        ticketTypesInCart.map((ticketType, index) => {
          const seatName =
            selectedSeats && selectedSeats.length > index
              ? selectedSeats[index]
              : null;

          return {
            item_id: ticketType.id,
            item_name: filmData.film_name,
            item_brand: 'Everyman Cinemas',
            item_category:
              state.cartSummary.filmGenres &&
              state.cartSummary.filmGenres.length > 0
                ? `${state.cartSummary.filmGenres}`
                : null, // genres
            item_category3: ticketType.displayName,
            item_category5: seatName, // seats
            item_variant: 'Ticket',
            price: (ticketType.price / 100).toFixed(2),
            quantity: ticketType.quantity,
          };
        });

      const addedTicketTypes = getAddedTicketTypes(
        state.ticketTypes?.ticketTypeModels
      );
      const ticketTypesInCart = getTicketTypesInCart(
        state.ticketTypes?.ticketTypeModels
      );
      const ecommerceItems = getEcommerceItems(ticketTypesInCart);

      return {
        event: 'autoEvent',
        event_category: 'Ticket Checkout',
        event_action: 'Select your tickets',
        event_label: 'add_to_cart',
        ...userData,
        ...filmData,
        ...cinemaData,
        ticket_type: addedTicketTypes,
        ecommerce: {
          checkout_type: state.bookingData.isUserValidated ? 'member' : 'guest',
          items: ecommerceItems,
        },
      };
    };

    const getSeatsLandingData = () => {
      const addTicketPayload = getAddTicketData();
      const seatsQuantity = state.ticketTypes?.ticketTypeModels.reduce(
        (total, ticketType) => {
          return total + ticketType.quantity;
        },
        0
      );
      const seatsLandingData = {
        ...addTicketPayload,
        event: 'autoEvent',
        event_category: 'Ticket Checkout',
        event_action: 'Select your seats',
        event_label: 'begin_checkout',
        seats_quantity: seatsQuantity !== undefined ? `${seatsQuantity}` : null,
        ecommerce: {
          items: addTicketPayload?.ecommerce.items,
          checkout_type: addTicketPayload?.ecommerce.checkout_type,
        },
      };

      return seatsLandingData;
    };

    const getPaymentLandingData = () => {
      const seatsLandingData = getSeatsLandingData();
      const selectedSeats = state.selectedSeats?.map((seat) => seat.seatName);

      const paymentLandingData = {
        ...seatsLandingData,
        event: 'autoEvent',
        event_category: 'Ticket Checkout',
        event_action: 'Secure Payment',
        event_label: 'add_payment_info',
        seats: selectedSeats,
      };
      return paymentLandingData;
    };

    const getPurchaseData = () => {
      const paymentLandingData = getPaymentLandingData();
      const transaction_id = state.confirmationData?.codeUsedInBarcode;
      const currency = state.config?.currency;
      const taxValue = formatCentsValue(state.confirmationData?.productsTax);

      const grandTotal = formatCentsValue(state.grandTotalWithDiscount);
      const totalDiscount = formatCentsValue(state.totalDiscount) ?? 0;

      const trackPurchaseData = {
        ...paymentLandingData,
        event: 'autoEvent',
        event_category: 'Ticket Checkout',
        event_action: 'Purchase',
        event_label: 'purchase',
        ecommerce: {
          ...paymentLandingData.ecommerce,
          currency,
          transaction_id,
          value: grandTotal,
          discount: totalDiscount,
          tax: taxValue,
        },
      };
      return trackPurchaseData;
    };

    const getGiftCardLandingData = () => {
      const physicalGiftCardLandingData = {
        event: 'autoEvent',
        event_category: 'Gift Card Checkout',
        event_action: 'Pick a Card',
        event_label: 'add_to_cart',
        ...userData,
        ecommerce: {
          items: [],
        },
      };
      return physicalGiftCardLandingData;
    };

    const getPickGiftCardData = () => {
      const selectedGiftCards = state.selectedGiftCards?.list?.filter(
        (item) => item.quantity > 0 && !item.isDeliveryItem
      );
      const selectedGiftCardsItems = selectedGiftCards.map((giftCard) => ({
        item_id: giftCard.id,
        item_name: giftCard.description,
        item_brand: 'Everyman Cinemas',
        item_category: 'Gift Card',
        item_variant: 'e-Gift Card',
        price: formatCentsValue(giftCard.cost),
        quantity: giftCard.quantity,
      }));

      const pickGiftCardData = {
        event: 'autoEvent',
        event_category: 'Gift Card Checkout',
        event_action: 'E-Gift choose amount',
        event_label: 'add_to_cart',
        ...userData,
        ecommerce: {
          items: [...selectedGiftCardsItems],
        },
      };
      return pickGiftCardData;
    };

    const getGiftCardPaymentLandingData = () => {
      const pickGiftCardData = getPickGiftCardData();
      const giftPaymentLandingData = {
        ...pickGiftCardData,
        event_action: 'Review & Pay',
        event_label: 'add_payment_info',
      };
      return giftPaymentLandingData;
    };

    const getGiftCardCheckoutData = () => {
      const pickGiftCardData = getPickGiftCardData();
      const transaction_id = state.confirmationData?.externalOrderId;
      const currency = state.config?.currency;
      const grandTotal = formatCentsValue(state.grandTotalWithDiscount);
      const totalDiscount = formatCentsValue(state.totalDiscount);
      const postageItem = state.selectedGiftCards.list.find(
        (c) => c.isDeliveryItem === true
      );
      let shippingCostInCents = 0;
      if (postageItem) {
        shippingCostInCents = postageItem.cost * postageItem.quantity;
      }
      const shippingCost = formatCentsValue(shippingCostInCents);

      const giftCardCheckoutData = {
        ...pickGiftCardData,
        event_action: 'Purchase',
        event_label: 'purchase',
        ecommerce: {
          items: [...pickGiftCardData.ecommerce.items],
          currency,
          transaction_id,
          value: grandTotal,
          checkout_type: state.bookingData.isUserValidated ? 'member' : 'guest',
          discount: totalDiscount,
          shipping: shippingCost,
        },
      };
      return giftCardCheckoutData;
    };

    const getPageViewData = () => {
      const pageViewData = {
        event: 'PageView',
        event_category: 'Initial Page View',
        event_action: 'PageView',
        event_label: 'page_view',
        ...userData,
      };
      return pageViewData;
    };

    const eventMapping = {
      [TrackingEvent.LOGIN_LANDING]: getLoginLandingData,
      [TrackingEvent.ADD_TICKET]: getAddTicketData,
      [TrackingEvent.SEATS_LANDING]: getSeatsLandingData,
      [TrackingEvent.PAYMENT_LANDING]: getPaymentLandingData,
      [TrackingEvent.TICKET_CHECKOUT]: getPurchaseData,
      [TrackingEvent.GIFTCARD_LANDING]: getGiftCardLandingData,
      [TrackingEvent.PICK_GIFTCARD]: getPickGiftCardData,
      [TrackingEvent.GIFTCARD_PAYMENT_LANDING]: getGiftCardPaymentLandingData,
      [TrackingEvent.GIFTCARD_CHECKOUT]: getGiftCardCheckoutData,
      [TrackingEvent.PAGE_VIEW]: getPageViewData,
    };

    const eventDataBuilder =
      eventMapping[eventName as keyof typeof eventMapping];

    if (eventDataBuilder) {
      const eventPayload = eventDataBuilder();
      if (eventPayload) {
        return populateDataLayer(eventPayload);
      }
    }
  }

  getUserId(bookingData: GlobalState['bookingData']) {
    return bookingData.memberId || 'guest';
  }

  getUserEmail(bookingData: GlobalState['bookingData']) {
    return bookingData.loyaltyEmailAddress || '';
  }

  getUserFirstName(bookingData: GlobalState['bookingData']) {
    return bookingData.loyaltyFirstName || '';
  }

  getUserLastName(bookingData: GlobalState['bookingData']) {
    return bookingData.loyaltyLastName || '';
  }

  getMembershipType(bookingData: GlobalState['bookingData']) {
    return bookingData.memberLevelId ? bookingData.memberLevelId : 'guest';
  }
}
