import React from 'react';

import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Box, Flex } from 'theme-ui';

import { QuantitySelectorContext } from '../../../@types/actionTypes';
import {
  TicketTypeModel,
  ValidatedCardPaymentPromoBankCard,
} from '../../../@types/modelTypes';
import { displayPrice } from '../../../services/Helpers';
import {
  selectAppliedCardPaymentPromoBankCards,
  selectContent,
  selectCurrencyConfig,
  selectTicketTypes,
} from '../../../store/Selectors';

interface AppliedCardPaymentPromoBankCardsProps {
  handleTicketButtonClick: (
    ticketTypeId: TicketTypeModel['id'],
    context: QuantitySelectorContext
  ) => Promise<void>;
}

const AppliedCardPaymentPromoBankCards: React.FC<
  AppliedCardPaymentPromoBankCardsProps
> = ({ handleTicketButtonClick }) => {
  const content = useSelector(selectContent);
  const appliedCardPaymentPromoBankCards: ValidatedCardPaymentPromoBankCard[] =
    useSelector(selectAppliedCardPaymentPromoBankCards);
  const currencyConfig = useSelector(selectCurrencyConfig);
  const ticketTypes = useSelector(selectTicketTypes);

  const cardHasMatchingTicketWithQuantity = (): boolean => {
    return appliedCardPaymentPromoBankCards.some(
      (card: ValidatedCardPaymentPromoBankCard) => {
        const matchingTicketType = ticketTypes?.ticketTypeModels?.find(
          (ticketType: TicketTypeModel) => ticketType.id === card.usedTicketId
        );
        return matchingTicketType && matchingTicketType.quantity > 0;
      }
    );
  };

  if (
    !ticketTypes?.ticketTypeModels?.length ||
    !cardHasMatchingTicketWithQuantity
  )
    return;

  const handleRemoveAppliedBankCard = (bankCardNo: string) => {
    const appliedBankCard = appliedCardPaymentPromoBankCards?.find(
      (usedBankCard) => usedBankCard.bankCardNumber === bankCardNo
    );
    const ticketIdToRemove = appliedBankCard?.usedTicketId;
    if (ticketIdToRemove) {
      handleTicketButtonClick(ticketIdToRemove, 'remove');
    }
  };

  const formatBankCardNumber = (bankCardNumber: string): string => {
    return `${bankCardNumber.slice(0, 4)} ${bankCardNumber.slice(4)}`;
  };

  const getMatchingTicketType = (
    usedTicketId: string
  ): TicketTypeModel | undefined => {
    const matchingTicketTypeModel = ticketTypes?.ticketTypeModels.find(
      (ticketType: TicketTypeModel) => ticketType.id === usedTicketId
    );
    return matchingTicketTypeModel;
  };

  return (
    <div data-testid='applied-bank-promo-cards'>
      <Box
        className='h3'
        sx={{
          textAlign: 'center',
          borderTop: 'bodySeparator',
          borderBottom: 'bodySeparator',
          pt: 6,
          pb: 4,
        }}
      >
        {content.tickets.cardPaymentPromoTicketsAdditionalTicketsHeading}
      </Box>
      {appliedCardPaymentPromoBankCards?.map(
        (card: ValidatedCardPaymentPromoBankCard) => (
          <Flex
            key={card.usedTicketId}
            sx={{
              borderBottom: 'bodySeparator',
              py: 4,
            }}
          >
            <Box sx={{ flexGrow: 1, pr: 2, textAlign: 'left' }}>
              <div>
                {getMatchingTicketType(card.usedTicketId)?.displayName}
                <span> x </span>
                <span className='quantity'>
                  {getMatchingTicketType(card.usedTicketId)?.quantity}
                </span>
              </div>
              <div className='tiny'>
                <span>
                  {getMatchingTicketType(card.usedTicketId)?.longDescription}
                  {` • `}
                </span>
                <span>
                  {content.tickets.cardPaymentPromoTicketsCardNumberText}{' '}
                  {card?.bankCardNumber &&
                    formatBankCardNumber(card.bankCardNumber)}
                </span>
              </div>
            </Box>
            <Flex
              sx={{
                alignItems: 'center',
                justifyContent: 'flex-end',
                flexShrink: 1,
                minWidth: '130px',
              }}
            >
              <Flex
                sx={{
                  alignItems: 'center',
                  justifyContent: 'center',
                  mr: 3,
                  minWidth: 'unset',
                }}
              >
                <Button
                  onClick={() =>
                    handleRemoveAppliedBankCard(card?.bankCardNumber)
                  }
                  variant='link'
                  size='sm'
                >
                  <span className='tiny'>
                    {content.payment.removeButtonText.toUpperCase()}{' '}
                  </span>
                </Button>
              </Flex>
              <Flex
                sx={{
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontWeight: 'bold',
                }}
              >
                <span>
                  {displayPrice(
                    getMatchingTicketType(card.usedTicketId)?.price ?? 0,
                    currencyConfig
                  )}
                </span>
              </Flex>
            </Flex>
          </Flex>
        )
      )}
    </div>
  );
};

export default AppliedCardPaymentPromoBankCards;
