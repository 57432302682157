import React, { useState } from 'react';

import { Form } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Box, Grid } from 'theme-ui';

import messages from './intl';
import TicketSelectorContainer from './TicketSelectorContainer';

import { handleRegisterClick } from '../../../services/LoyaltyHelpers';
import {
  selectBookingData,
  selectRegisterUrl,
  selectTicketTypes,
} from '../../../store/Selectors';
import ActionButton from '../../common/actionbutton/ActionButton';
import BorderedCollapse from '../../common/borderedcollapse/BorderedCollapse';
import globalMessages from '../intl';
import { WidgetData } from '../types';

interface Props {
  orderHasMaxTickets: boolean;
  widget: WidgetData<'TicketingCMSJourneyTicketSelectionWidget'>;
}

const LoyaltyTicketSelector: React.FC<Props> = ({
  orderHasMaxTickets,
  widget,
}) => {
  const bookingData = useSelector(selectBookingData);
  const ticketTypes = useSelector(selectTicketTypes);
  const { formatMessage } = useIntl();

  const registerUrl = useSelector(selectRegisterUrl);

  const [showLoyaltyCardForm, setShowLoyaltyCardForm] = useState(true);
  const [showRegister, setShowRegister] = useState(false);
  const [showLoginSection, setShowLoginSection] = useState(false);

  const hasMemberTickets = ticketTypes?.ticketTypeModels.find(
    (t) => t.isMemberTicket
  );

  if (!ticketTypes || !hasMemberTickets) return null;

  const onLoginClick = () => {
    setShowLoginSection(true);
  };

  return (
    <BorderedCollapse
      closeButtonText={formatMessage(globalMessages.closeButtonText)}
      heading={formatMessage(messages.memberTicketsHeading)}
      setShow={setShowLoyaltyCardForm}
      show={showLoyaltyCardForm}
    >
      {(!showLoginSection || bookingData.isUserValidated) && (
        <div className='loyalty-ticket-selector-container'>
          <div
            className='loyalty-ticket-selector'
            data-testid='loyalty-ticket-selector'
          >
            <p>{formatMessage(messages.memberTicketsInfoText)}</p>
            <Form>
              <Form.Label />
              <TicketSelectorContainer
                ticketTypeGroup='members-only'
                orderHasMaxTickets={orderHasMaxTickets}
                setShowRegister={setShowRegister}
                widget={widget}
              />

              {showRegister && !bookingData.isUserValidated && (
                <Box
                  className='register-section'
                  data-testid='register-section'
                  mt={4}
                >
                  <span>
                    {formatMessage(messages.memberTicketsLoginOrRegisterText)}
                  </span>
                  <Grid className='register-section-buttons' mt={4} columns={2}>
                    <ActionButton
                      onClick={onLoginClick}
                      variant='secondary'
                      mb={0}
                      mt={0}
                    >
                      {formatMessage(messages.memberTicketsLoginButtonText)}
                    </ActionButton>
                    <ActionButton
                      onClick={() => handleRegisterClick(registerUrl)}
                      variant='secondary'
                      mb={0}
                      mt={0}
                    >
                      {formatMessage(messages.memberTicketsRegisterButtonText)}
                    </ActionButton>
                  </Grid>
                </Box>
              )}
            </Form>
          </div>
        </div>
      )}
    </BorderedCollapse>
  );
};

export default LoyaltyTicketSelector;
