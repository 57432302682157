/** @jsxImportSource theme-ui */
import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Flex } from 'theme-ui';

import RecipientDetails from './RecipientDetails';

import { Customer } from '../../../@types/modelTypes';
import { actionCreators } from '../../../store/ActionCreators';
import {
  selectVoucherRecipient,
  selectContentVouchers,
} from '../../../store/Selectors';
import CheckBoxButton from '../checkboxbutton/CheckBoxButton';
import CustomerDetails from '../customerdetails/CustomerDetails';
import ContainedRow from '../layout/ContainedRow';
import RichText from '../richtext/RichText';

interface Props {
  isPageValidated: boolean;
}

const DeliveryDetails: React.FC<Props> = ({ isPageValidated }) => {
  const dispatch = useDispatch();

  const contentVouchers = useSelector(selectContentVouchers);
  const voucherRecipient = useSelector(selectVoucherRecipient);

  const handleCustomerChange = (useRecipient: boolean) => {
    let nextCustomerState: Customer | undefined;
    if (useRecipient) {
      nextCustomerState = {
        ...voucherRecipient,
      };
    } else {
      nextCustomerState = {
        name: '',
        nameIsValid: false,
        email: '',
        emailIsValid: false,
        confirmEmail: '',
        confirmEmailIsValid: false,
        telephone: '',
        telephoneIsValid: false,
        isValid: false,
      };
    }
    dispatch(actionCreators.setCustomerDetails(nextCustomerState));
  };

  const toggleBillingAsRecipient = () => {
    if (!voucherRecipient.useSameDetailsForBilling) {
      handleCustomerChange(true);
    } else {
      handleCustomerChange(false);
    }
    voucherRecipient.useSameDetailsForBilling =
      !voucherRecipient.useSameDetailsForBilling;
    dispatch(actionCreators.setVoucherRecipient(voucherRecipient));
  };

  const checked = voucherRecipient.useSameDetailsForBilling;

  if (!contentVouchers) {
    return null;
  }

  return (
    <ContainedRow classNameWrapper='delivery-details'>
      <h2 sx={{ mt: 7, textAlign: 'center' }}>
        {contentVouchers.recipientDetailsHeading}
      </h2>
      <RichText
        text={contentVouchers.recipientDetailsRichText}
        styles={{ mb: 5 }}
      />
      <RecipientDetails isPageValidated={isPageValidated} billingAsRecipient />
      <h2 sx={{ mt: 7, textAlign: 'center' }}>
        {contentVouchers.customerDetailsHeading}
      </h2>
      <RichText text={contentVouchers.customerDetailsRichText} />

      <Flex sx={{ alignItems: 'center' }}>
        <div>
          <CheckBoxButton
            checked={checked}
            onClick={toggleBillingAsRecipient}
          />
        </div>
        <div sx={{ flexGrow: 1 }}>
          <p sx={{ fontWeight: checked ? 'bold' : 'normal' }}>
            {contentVouchers.useSameEmailLabel}
          </p>
        </div>
      </Flex>

      {checked || (
        <>
          <p sx={{ mt: 4, fontWeight: 'bold' }}>
            {contentVouchers.useBillingEmailText}
          </p>
          <CustomerDetails isPageValidated={isPageValidated} />
        </>
      )}
    </ContainedRow>
  );
};

export default DeliveryDetails;
