import { useEffect, useState } from 'react';

import { useSearchParams } from 'react-router-dom';

const testIdKey = 'testId';
const testCaptureIdKey = 'testCaptureId';
export const useMockedCypher = () => {
  const [isSet, setIsSet] = useState(false);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (!isSet) {
      const testId = searchParams.get('testId') ?? '';
      const testCaptureId = searchParams.get('testCaptureId') ?? '';
      const existingTestId = window.sessionStorage.getItem(testIdKey);
      const existingCaptureId = window.sessionStorage.getItem(testCaptureIdKey);
      if (existingTestId !== testId) {
        window.sessionStorage.setItem(testIdKey, testId);
      }
      if (existingCaptureId !== testCaptureId) {
        window.sessionStorage.setItem(testCaptureIdKey, testCaptureId);
      }
      setIsSet(true);
    }
  }, [isSet, searchParams]);
};
