import React, { PropsWithChildren } from 'react';

import { useSelector } from 'react-redux';

import { GlobalState } from '../../../@types/modelTypes';
import { JOURNEY_TYPES } from '../../../constants';
import {
  selectContentCartSummary,
  selectDisplayPrice,
  selectJourneyTypeConfig,
  selectSelectedSeasonPass,
} from '../../../store/Selectors';

interface Props {
  hideTax: boolean;
  pathname: string;
  subtotal: number;
}

const CartSummarySubtotal: React.FC<PropsWithChildren<Props>> = ({
  children,
  hideTax,
  pathname,
  subtotal,
}) => {
  const contentCartSummary = useSelector(selectContentCartSummary);
  const priceToDisplay = useSelector((state: GlobalState) =>
    selectDisplayPrice(state, subtotal)
  );
  const selectedSeasonPass = useSelector(selectSelectedSeasonPass);
  const journeyTypeConfig = useSelector(selectJourneyTypeConfig);

  const subtotalLabel =
    pathname.startsWith('/confirmation/') && !hideTax
      ? contentCartSummary.subtotalLabel + ' ' + contentCartSummary.excTaxText
      : contentCartSummary.subtotalLabel;

  if (
    !selectedSeasonPass?.sessions.length &&
    journeyTypeConfig.type === JOURNEY_TYPES.SEASON_PASS
  )
    return null;

  return (
    <div className='cart-summary-row' data-testid='cs-subtotal'>
      <div className='cart-summary-row-flex b'>
        <span>{subtotalLabel}</span>
        <span>{priceToDisplay}</span>
      </div>
      {children}
    </div>
  );
};

export default CartSummarySubtotal;
