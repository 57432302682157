import { useEffect } from 'react';

import { useSelector } from 'react-redux';

import { useBoostNavigate } from './useBoostNavigate';

import {
  selectConfig,
  selectBookingData,
  selectToken,
  selectJourneyTypeConfig,
} from '../store/Selectors';

export const useValidateConcessionsJourney = () => {
  const boostNavigate = useBoostNavigate();

  const config = useSelector(selectConfig);
  const bookingData = useSelector(selectBookingData);
  const token = useSelector(selectToken);
  const journeyTypeConfig = useSelector(selectJourneyTypeConfig);

  const isValidRouteData = !!config && !!token;

  useEffect(() => {
    if (isValidRouteData) return;

    if (bookingData) {
      boostNavigate.navigateToStartJourneyUsingGlobalState();
    } else {
      boostNavigate.navigateToStartJourneyUsingLocation();
    }
  }, [bookingData, boostNavigate, isValidRouteData, journeyTypeConfig]);
};
