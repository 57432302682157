import React, { useEffect } from 'react';

import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useSelector, useDispatch } from 'react-redux';
import StripeCheckout, { Token } from 'react-stripe-checkout';
import { Box } from 'theme-ui';

import { PaymentProvidersEnum } from '../../../../../@types/enums';
import { IntializePaymentProviderResponse } from '../../../../../@types/modelTypes';
import { useRecaptcha } from '../../../../../contextProviders/recaptchaContext';
import { useTurnstile } from '../../../../../contextProviders/turnstileContext';
import { actionCreators } from '../../../../../store/ActionCreators';
import {
  selectBankCardAmount,
  selectBankCardAmountDisplayPrice,
  selectCartSummary,
  selectConfig,
  selectContent,
  selectCustomer,
  selectIsCustomerReadyForPayment,
  selectLoyaltyRecognitionNumber,
  selectSelectedPaymentProvider,
} from '../../../../../store/Selectors';
import ActionButton from '../../../actionbutton/ActionButton';

interface Props {
  isPageValidated: boolean;
  handleValidatePage: () => void;
  initializePaymentProviderResponse?: IntializePaymentProviderResponse;
}

const StripeCheckoutPayment: React.FC<Props> = ({
  isPageValidated,
  handleValidatePage,
  initializePaymentProviderResponse,
}) => {
  const dispatch = useDispatch();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const recaptcha = useRecaptcha();
  const turnstile = useTurnstile();

  const cartSummary = useSelector(selectCartSummary);
  const config = useSelector(selectConfig);
  const content = useSelector(selectContent);
  const customer = useSelector(selectCustomer);
  const loyaltyRecognitionNumber = useSelector(selectLoyaltyRecognitionNumber);
  const bankCardAmount = useSelector(selectBankCardAmount);
  const priceToDisplay = useSelector(selectBankCardAmountDisplayPrice);

  const isCustomerReadyForPayment = useSelector(
    selectIsCustomerReadyForPayment
  );
  const selectedPaymentProvider = useSelector(selectSelectedPaymentProvider);

  useEffect(() => {
    if (selectedPaymentProvider !== PaymentProvidersEnum.STRIPEPOPUP) return;
    if (!isPageValidated) {
      handleValidatePage();
    }
  }, [handleValidatePage, selectedPaymentProvider, isPageValidated]);

  const showCheckout =
    isCustomerReadyForPayment &&
    selectedPaymentProvider === PaymentProvidersEnum.STRIPEPOPUP &&
    initializePaymentProviderResponse;

  const makePayment = async (stripeCheckoutToken: Token) => {
    if (!isCustomerReadyForPayment || !executeRecaptcha) return;

    dispatch(
      actionCreators.submitMakePayment({
        makePaymentModelOverrideProps: {
          stripeCheckoutToken: stripeCheckoutToken,
          loyaltyCardNumber: loyaltyRecognitionNumber,
          cardType: stripeCheckoutToken.card.brand,
          paymentProvider: PaymentProvidersEnum.STRIPEPOPUP,
        },
        executeRecaptcha,
        turnstile,
        recaptcha,
      })
    );
  };

  const onToken = (token: Token) => {
    makePayment(token);
  };

  const buttonText = `${content.payment.submitText} ${priceToDisplay}`;

  return (
    <Box
      className='stripe-checkout-payment contained'
      data-testid='stripe-checkout-payment'
      sx={{ mt: 2 }}
    >
      {showCheckout && (
        // used after customer is valid
        <StripeCheckout
          amount={bankCardAmount}
          description={cartSummary.title}
          image={content.logoUrl}
          locale='auto'
          email={customer.email}
          name={config.currentCinema.title}
          stripeKey={initializePaymentProviderResponse.PublishKey}
          token={onToken}
          zipCode
          currency={config.currency}
          allowRememberMe
          panelLabel={`${content.payment.stripePayButtonText} {{amount}}`}
          label={buttonText}
          ComponentClass='div'
        >
          <ActionButton showIcon variant='primary'>
            {buttonText}
          </ActionButton>
        </StripeCheckout>
      )}
    </Box>
  );
};

export default StripeCheckoutPayment;
