import { defineMessages } from 'react-intl';

export default defineMessages({
  atLabel: {
    id: 'global.at-label',
    defaultMessage: 'At',
  },
  onLabel: {
    id: 'global.on-label',
    defaultMessage: 'On',
  },
  ofLabel: {
    id: 'global.of-label',
    defaultMessage: 'of',
  },
  selectedLabel: {
    id: 'global.selected-label',
    defaultMessage: 'selected',
  },
  includesLabel: {
    id: 'global.includes-label',
    defaultMessage: 'includes',
  },
  yesButtonText: {
    id: 'global.yes-button-text',
    defaultMessage: 'Yes',
  },
  noButtonText: {
    id: 'global.no-button-text',
    defaultMessage: 'No',
  },
  cancelButtonText: {
    id: 'global.cancel-button-text',
    defaultMessage: 'Cancel',
  },
  selectButtonText: {
    id: 'global.select-button-text',
    defaultMessage: 'Select',
  },
  closeButtonText: {
    id: 'global.close-button-text',
    defaultMessage: 'Close',
  },
  checkButtonText: {
    id: 'global.check-button-text',
    defaultMessage: 'Check',
  },
  fullNameLabel: {
    id: 'global.full-name-label',
    defaultMessage: 'Full name',
  },
  fullNamePlaceholder: {
    id: 'global.full-name-placeholder',
    defaultMessage: 'Full name',
  },
  fullNameValidationText: {
    id: 'global.full-name-validation-text',
    defaultMessage: 'Full name is invalid',
  },
  emailLabel: {
    id: 'global.email-label',
    defaultMessage: 'Email',
  },
  emailPlaceholder: {
    id: 'global.email-placeholder',
    defaultMessage: 'Email',
  },
  emailValidationText: {
    id: 'global.email-validation-text',
    defaultMessage: 'Email is invalid',
  },
  confirmEmailLabel: {
    id: 'global.confirm-email-label',
    defaultMessage: 'Confirm email',
  },
  confirmEmailPlaceholder: {
    id: 'global.confirm-email-placeholder',
    defaultMessage: 'Confirm email',
  },
  confirmEmailValidationText: {
    id: 'global.confirm-email-validation-text',
    defaultMessage: 'Email is invalid',
  },
  phoneLabel: {
    id: 'global.phone-label',
    defaultMessage: 'Phone',
  },
  phonePlaceholder: {
    id: 'global.phone-placeholder',
    defaultMessage: 'Phone',
  },
  phoneValidationText: {
    id: 'global.phone-validation-text',
    defaultMessage: 'Phone is invalid',
  },
  zipCodeLabel: {
    id: 'global.zip-code-label',
    defaultMessage: 'Zip code',
  },
  zipCodePlaceholder: {
    id: 'global.zip-code-placeholder',
    defaultMessage: 'Zip code',
  },
  zipCodeValidationText: {
    id: 'global.zip-code-validation-text',
    defaultMessage: 'Zip code is invalid',
  },
});
