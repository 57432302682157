import moment from 'moment';

import {
  GlobalState,
  Concession,
  SelectedConcessions,
  ConcessionPricing,
  TicketTypeModel,
} from '../@types/modelTypes';
import { IEventTrackingStrategy, TrackingEvent } from '../@types/trackingTypes';
import { getSessionOccupancy } from '../services/Helpers';
import { findModifiedConcessionItemCost } from '../services/KioskHelpers';

interface PriceDetails {
  totalPrice: string;
  unitPrice: string;
}

interface ProductInfo {
  name: string;
  productId: string;
  productType: string;
}

interface Product {
  price: PriceDetails;
  productInfo: ProductInfo;
  quantity: number;
}

export class VueEventTrackingStrategy implements IEventTrackingStrategy {
  processEvent(
    eventName: string,
    state: GlobalState,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    options?: any
  ) {
    const dataLayer = window[options?.dataLayerName];
    const populateDataLayer = (payload: object) => {
      if (dataLayer && Array.isArray(dataLayer)) {
        dataLayer.push(payload);
      }
    };

    const pageData = {
      pageName: options?.pathName,
      pageType: options?.pathName?.includes('login') ? 'Login' : 'Booking',
      platform: 'web',
      siteCountry: 'DK',
      siteBrand: state?.cartSummary?.theaterLocationName
        ?.split(' ')[0]
        ?.toLowerCase(),
      cmsVersion: 'Boost',
    };

    const venueData = {
      venueCode: state.bookingData?.boxofficeCinemaId,
      venueName: state.cartSummary?.theaterLocationName,
      venueCurrency: state?.config?.currency,
    };

    const filmEventData = {
      // cast: [], // ATHENA
      certificate: state.cartSummary?.rated,
      // director: '', // ATHENA
      // distributor: '', // ATHENA
      filmId: state.cartSummary?.filmExternalId,
      filmName: state.cartSummary?.title,
      // filmNameEng: '', // ATHENA
      // filmPagePath: '',
      filmThumbnailURL: state.cartSummary?.stillUrl,
      // genre: [],
      releaseDate: state.cartSummary?.releaseDate
        ? moment(state.cartSummary.releaseDate).format('DD/MM/YYYY')
        : null,
      sessionsAvailable: true,
    };

    const sessionData = {
      sessionAttributes: state.cartSummary?.sessionAttributes
        ?.map((attribute) => attribute.name)
        ?.join(', '),
      sessionDateTime: state.cartSummary?.sessionDate,
      sessionId: state.bookingData?.externalSessionId,
      sessionOccupancy: getSessionOccupancy(state.seatsModel),
      sessionScreenNumber: state.cartSummary?.screen,
    };

    const userData = {
      userId: this.getUserId(state.bookingData),
      loginStatus: state.bookingData?.isUserValidated ? 'logged in' : 'guest',
    };

    const transactionData = {
      orderLevelDiscountCode: null,
      orderLevelDiscountValue: null,
      purchaseId: state?.confirmationData?.codeUsedInBarcode,
      payment: {
        paymentAmount: state?.confirmationData?.totalPrice
          ? (state?.confirmationData?.totalPrice / 100).toFixed(2)
          : null,
        // paymentMethod:
      },
    };

    const buildProductData = (
      ticketTypeModels: TicketTypeModel[] | undefined,
      selectedConcessions: SelectedConcessions,
      selectedGiftCards: SelectedConcessions
    ) => {
      const ticketProducts = createProductListFromTicketTypes(ticketTypeModels);
      const concessionProducts =
        createProductListFromConcessions(selectedConcessions);
      const giftCardProducts =
        createProductListFromConcessions(selectedGiftCards);
      const combinedProducts = [
        ...ticketProducts,
        ...concessionProducts,
        ...giftCardProducts,
      ];
      return combinedProducts;
    };

    function createProductListFromConcessions(
      selectedConcessions: SelectedConcessions
    ): Product[] {
      return selectedConcessions.list
        .filter((concession: Concession) => concession.quantity > 0)
        .map((concession: Concession) => {
          let unitPrice: string;

          if (
            !concession.modifierGroups ||
            concession.modifierGroups.length === 0
          ) {
            unitPrice = (concession.cost / 100).toFixed(2);
          } else {
            const concessionPricing: ConcessionPricing =
              findModifiedConcessionItemCost(concession);
            unitPrice = (concessionPricing.costMinusTax / 100).toFixed(2);
          }

          const totalPrice = (
            concession.quantity * parseFloat(unitPrice)
          ).toFixed(2);

          return {
            price: {
              totalPrice: totalPrice,
              unitPrice: unitPrice,
            },
            productInfo: {
              name: concession.description,
              productId: concession.id,
              productType: 'concession',
            },
            quantity: concession.quantity,
          };
        });
    }

    function createProductListFromTicketTypes(
      ticketTypeModels: TicketTypeModel[] | undefined
    ): Product[] {
      if (!ticketTypeModels) return [];
      return ticketTypeModels
        .filter((ticket: TicketTypeModel) => ticket.quantity > 0)
        .map((ticket: TicketTypeModel) => {
          const unitPrice = (ticket.price / 100).toFixed(2);
          const totalPrice = (ticket.quantity * parseFloat(unitPrice)).toFixed(
            2
          );

          return {
            price: {
              totalPrice: totalPrice,
              unitPrice: unitPrice,
            },
            productInfo: {
              name: ticket.displayName,
              productId: ticket.id,
              productType: 'tickets',
            },
            quantity: ticket.quantity,
          };
        });
    }

    const getPageLoadData = () => {
      const productArr = buildProductData(
        state.ticketTypes?.ticketTypeModels,
        state.selectedConcessions,
        state.selectedGiftCards
      );

      const pageLoadData = {
        event: 'Page Load',
        page: { ...pageData },
        venue: { ...venueData },
        film: { ...filmEventData },
        product: [...productArr],
        sessionInfo: { ...sessionData },
        user: { ...userData },
      };

      return pageLoadData;
    };

    const getOrderPlacedData = () => {
      const productArr = buildProductData(
        state.ticketTypes?.ticketTypeModels,
        state.selectedConcessions,
        state.selectedGiftCards
      );

      const orderPlacedData = {
        event: 'Order Placed',
        page: { ...pageData },
        venue: { ...venueData },
        film: { ...filmEventData },
        product: [...productArr],
        sessionInfo: { ...sessionData },
        user: { ...userData },
        transaction: { ...transactionData },
      };

      return orderPlacedData;
    };

    const getVoucherCodeValidData = () => {
      return {
        event: 'Discount Code Entry Succeeded',
        voucherDiscount: {
          discountCode: options?.discountCode,
          // discountType: "<discountType>"
        },
      };
    };

    const getVoucherCodeInvalidData = () => {
      return {
        event: 'Discount Code Entry Failed',
        voucherDiscount: {
          discountCode: options?.discountCode,
          // discountType: "<discountType>"
        },
      };
    };

    const eventMapping = {
      [TrackingEvent.PAGE_VIEW]: getPageLoadData,
      [TrackingEvent.TICKET_CHECKOUT]: getOrderPlacedData,
      [TrackingEvent.GIFTCARD_CHECKOUT]: getOrderPlacedData,
      [TrackingEvent.VOUCHER_CODE_VALID]: getVoucherCodeValidData,
      [TrackingEvent.VOUCHER_CODE_INVALID]: getVoucherCodeInvalidData,
    };

    const eventDataBuilder =
      eventMapping[eventName as keyof typeof eventMapping];

    if (eventDataBuilder) {
      const eventPayload = eventDataBuilder();
      if (eventPayload) {
        return populateDataLayer(eventPayload);
      }
    }
  }

  getUserId(bookingData: GlobalState['bookingData']) {
    return bookingData?.memberId || '';
  }
}
