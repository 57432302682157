/** @jsxImportSource theme-ui */
import React, { useState, useEffect, useMemo, useCallback } from 'react';

import classnames from 'classnames';
import { Form } from 'react-bootstrap';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useSelector, useDispatch } from 'react-redux';
import { useThemeUI } from 'theme-ui';
import { isLength } from 'validator';

import { PaymentProvidersEnum } from '../../../../../@types/enums';
import {
  HeartlandPayment,
  IntializePaymentProviderResponse,
} from '../../../../../@types/modelTypes';
import { useRecaptcha } from '../../../../../contextProviders/recaptchaContext';
import { useTurnstile } from '../../../../../contextProviders/turnstileContext';
import loadHeartlandRts from '../../../../../scripts/loadHeartlandRts';
import { actionCreators } from '../../../../../store/ActionCreators';
import {
  selectBankCardAmountDisplayPrice,
  selectContent,
  selectIsCustomerReadyForPayment,
  selectPayment,
  selectSelectedPaymentProvider,
} from '../../../../../store/Selectors';
import { ReactComponent as IconArrowSvg } from '../../../../../svgs/iconArrow.svg';
import WarningMessage from '../../../warningmessage/WarningMessage';
import LabelWithTooltip from '../common/LabelWithTooltip';

interface Props {
  handleValidatePage: () => void;
  initializePaymentProviderResponse:
    | IntializePaymentProviderResponse
    | undefined;
  setCreditCardType: (cardType: string) => void;
  isPageValidated?: boolean;
}

interface PaymentState {
  nameOnCard: string;
  nameOnCardIsValid: boolean;
  zipCode: string;
  zipCodeIsValid: boolean;
  cardNumberIsValid: boolean;
  cvvCodeIsValid: boolean;
  expiryDateIsValid: boolean;
}

interface HeartlandForm {
  price: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cardForm: any;
}

const HeartlandRtsPayment: React.FC<Props> = ({
  handleValidatePage,
  initializePaymentProviderResponse,
  setCreditCardType,
  isPageValidated = false,
}) => {
  const dispatch = useDispatch();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const recaptcha = useRecaptcha();
  const turnstile = useTurnstile();
  const context = useThemeUI();
  const { theme } = context;
  const priceToDisplay = useSelector(selectBankCardAmountDisplayPrice);
  const payment = useSelector(selectPayment);
  const content = useSelector(selectContent);
  const isCustomerReadyForPayment = useSelector(
    selectIsCustomerReadyForPayment
  );
  const selectedPaymentProvider = useSelector(selectSelectedPaymentProvider);
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [tokenResponse, setTokenResponse] = useState<
    HeartlandPayment | undefined | null
  >(undefined);
  const [paymentState, setPaymentState] = useState<PaymentState>({
    nameOnCard: payment?.nameOnCard ?? '',
    nameOnCardIsValid: payment?.nameOnCardIsValid ?? false,
    zipCode: payment?.zipCode ?? '',
    zipCodeIsValid: payment?.zipCodeIsValid ?? false,
    cardNumberIsValid: false,
    cvvCodeIsValid: false,
    expiryDateIsValid: false,
  });

  const [formIsValidated, setFormIsValidated] = useState(false);
  const [isPaymentReadyToProcess, setIsPaymentReadyToProcess] = useState(false);
  const [paymentProcessing, setPaymentProcessing] = useState(false);
  const [heartlandForm, setHeartlandForm] = useState<HeartlandForm | undefined>(
    undefined
  );

  const showWarningMessage =
    formIsValidated &&
    (!paymentState.nameOnCardIsValid ||
      !paymentState.zipCodeIsValid ||
      !paymentState.cardNumberIsValid ||
      !paymentState.expiryDateIsValid ||
      !paymentState.cvvCodeIsValid);

  const isFormValid =
    isCustomerReadyForPayment &&
    formIsValidated &&
    paymentState.nameOnCardIsValid &&
    paymentState.zipCodeIsValid &&
    paymentState.cardNumberIsValid &&
    paymentState.expiryDateIsValid &&
    paymentState.cvvCodeIsValid;

  const heartlandStyles = useMemo(() => {
    return {
      input: {
        'background-color': 'transparent',
        border: 'none',
        color: theme.rawColors?.formInputColor,
        height: '26px',
        width: '100%',
        'font-size': '16px',
      },
      'input:focus-visible': {
        outline: 'none',
      },
      button: {
        width: '100%',
        height: '54px',
        'font-size': '1rem',
        padding: '12px',
        'text-align': 'left',
        'background-color': theme.rawColors?.primaryButtonBackground,
        'border-color': theme.rawColors?.primary,
        color: theme.rawColors?.primaryButtonColor,
        'border-width': '2px',
        'border-style': 'solid',
        'border-radius': '4px',
        'font-weight': 'bold',
        'line-height': '24px',
        margin: 0,
        'text-transform': 'uppercase',
      },
      'button:hover': {
        cursor: 'pointer',
        'background-color': theme.rawColors?.primaryButtonBackgroundHover,
        'border-color': theme.rawColors?.accent,
        color: theme.rawColors?.primaryButtonColorHover,
      },
      'button:focus-visible': {
        outline: 'none',
      },
    };
  }, [theme.rawColors]);

  const handleNameOnCardChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isNameFieldValid = (nameOnCard: string) => {
      return isLength(nameOnCard, { min: 1, max: 50 });
    };
    const nameOnCard = e.currentTarget.value;
    setPaymentState({
      ...paymentState,
      nameOnCard,
      nameOnCardIsValid: isNameFieldValid(nameOnCard),
    });
  };

  const handleZipCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isZipCodeFieldValid = (zipCode: string) => {
      return isLength(zipCode, { min: 1, max: 8 });
    };
    const zipCode = e.currentTarget.value;
    setPaymentState({
      ...paymentState,
      zipCode,
      zipCodeIsValid: isZipCodeFieldValid(zipCode),
    });
  };

  // Initialise Heartland Script.
  useEffect(() => {
    const isLoaded =
      scriptLoaded ||
      selectedPaymentProvider !== PaymentProvidersEnum.RTSHEARTLAND;
    if (isLoaded) return;
    loadHeartlandRts(() => setScriptLoaded(true));
  }, [scriptLoaded, selectedPaymentProvider]);

  useEffect(() => {
    if (heartlandForm && isReady && heartlandForm.price !== priceToDisplay) {
      heartlandForm.cardForm.dispose();
      setIsReady(false);
    }
  }, [heartlandForm, isReady, priceToDisplay]);

  // create Heartland fields
  useEffect(() => {
    if (!scriptLoaded || isReady) return;
    const GlobalPayments = window.GlobalPayments;

    GlobalPayments.configure({
      publicApiKey: initializePaymentProviderResponse?.PublishKey,
    });
    GlobalPayments.on('error', (error: unknown) => {
      // eslint-disable-next-line no-console
      console.error(error);
    });

    const cardForm = GlobalPayments.ui.form({
      fields: {
        'card-number': {
          placeholder: content.payment.cardNumberPlaceHolder,
          target: '#credit-card-card-number',
        },
        'card-expiration': {
          placeholder: 'mm / yyyy',
          target: '#credit-card-card-expiration',
        },
        'card-cvv': {
          placeholder: content.payment.cvvPlaceholder,
          target: '#credit-card-card-cvv',
        },
        submit: {
          target: '#credit-card-submit',
          text: `${content.payment.submitText} ${priceToDisplay}`,
        },
      },
      styles: heartlandStyles,
    });

    cardForm.ready(() => {
      setIsReady(true);
    });

    cardForm.on('token-success', (resp: HeartlandPayment) => {
      setTokenResponse(resp);
    });

    cardForm.on('token-error', () => {
      setTokenResponse(null);
    });

    const heartlandForm: HeartlandForm = {
      price: priceToDisplay,
      cardForm: cardForm,
    };
    setHeartlandForm(heartlandForm);
  }, [
    content,
    handleValidatePage,
    heartlandStyles,
    initializePaymentProviderResponse?.PublishKey,
    isPageValidated,
    isReady,
    priceToDisplay,
    scriptLoaded,
  ]);

  // validate Heartland
  useEffect(() => {
    if (!isPageValidated) {
      handleValidatePage();
    }
    if (tokenResponse === null) {
      setCreditCardType('');
      setFormIsValidated(true);
      setPaymentState(
        (previousState) =>
          ({
            ...previousState,
            cvvCodeIsValid: false,
            expiryDateIsValid: false,
            cardNumberIsValid: false,
          } as PaymentState)
      );
    } else if (tokenResponse) {
      setCreditCardType(tokenResponse.details.cardType);
      setFormIsValidated(true);
      setPaymentState((previousState) => ({
        ...previousState,
        cardNumberIsValid: true,
        expiryDateIsValid:
          !!tokenResponse?.details.expiryMonth &&
          !!tokenResponse?.details.expiryYear,
        cvvCodeIsValid: !!tokenResponse?.details.cardSecurityCode,
      }));
    }
  }, [handleValidatePage, isPageValidated, setCreditCardType, tokenResponse]);

  useEffect(() => {
    isFormValid && setIsPaymentReadyToProcess(true);
  }, [isFormValid]);

  const makePayment = useCallback(async () => {
    if (!executeRecaptcha || paymentProcessing || !tokenResponse) return;
    dispatch(
      actionCreators.submitMakePayment({
        makePaymentModelOverrideProps: {
          nameOnCard: paymentState.nameOnCard,
          billingPostal: paymentState.zipCode,
          paymentProvider: PaymentProvidersEnum.RTSHEARTLAND,
          heartlandPayment: tokenResponse,
        },
        executeRecaptcha,
        callBackFunction: resetPaymentOnError,
        turnstile,
        recaptcha,
      })
    );
  }, [
    dispatch,
    executeRecaptcha,
    paymentProcessing,
    paymentState,
    recaptcha,
    tokenResponse,
    turnstile,
  ]);

  // make payment
  useEffect(() => {
    if (isPaymentReadyToProcess && !paymentProcessing) {
      setPaymentProcessing(true);
      makePayment();
      setPaymentProcessing(false);
    }
  }, [isPaymentReadyToProcess, makePayment, paymentProcessing]);

  const resetPaymentOnError = () => {
    setTokenResponse(undefined);
  };

  return (
    <div className='heartland-rts-payment' data-testid='heartlandRts-payment'>
      <div className='heartland-form'>
        <Form noValidate data-testid='heartlandRts-payment-form'>
          <Form.Group sx={{ mb: 4 }}>
            <LabelWithTooltip
              labelFor='nameOnCard'
              helpText={content.payment.nameOnCardHelpText}
            >
              {content.payment.nameOnCardLabel}
            </LabelWithTooltip>
            <Form.Control
              className={classnames(
                formIsValidated &&
                  (paymentState.nameOnCardIsValid ? 'isValid' : 'is-invalid')
              )}
              type='text'
              placeholder={content.payment.nameOnCardPlaceHolder}
              onChange={handleNameOnCardChange}
              value={paymentState.nameOnCard}
              required
              maxLength={50}
              isInvalid={formIsValidated && !paymentState.nameOnCardIsValid}
              isValid={formIsValidated && paymentState.nameOnCardIsValid}
              id='nameOnCard'
              name='nameOnCard'
            />
            <Form.Control.Feedback type='invalid'>
              {content.payment.nameOnCardValidationText}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group sx={{ mb: 4 }}>
            <LabelWithTooltip
              labelFor='zipCode'
              helpText={content.payment.zipCodeHelpText}
            >
              {content.payment.zipCodeLabel}
            </LabelWithTooltip>
            <Form.Control
              className={classnames(
                formIsValidated &&
                  (paymentState.zipCodeIsValid ? 'isValid' : 'is-invalid')
              )}
              type='text'
              placeholder={content.payment.zipCodePlaceholder}
              onChange={handleZipCodeChange}
              value={paymentState.zipCode}
              required
              maxLength={8}
              isInvalid={formIsValidated && !paymentState.zipCodeIsValid}
              isValid={formIsValidated && paymentState.zipCodeIsValid}
              id='zipCode'
              name='zipCode'
            />
            <Form.Control.Feedback type='invalid'>
              {content.payment.zipCodeValidationText}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group sx={{ mb: 4 }}>
            <LabelWithTooltip
              labelFor='credit-card-card-number'
              helpText={content.payment.cardNumberHelpText}
            >
              {content.payment.cardNumberLabel}
            </LabelWithTooltip>
            <div
              id='credit-card-card-number'
              className={classnames(
                'heartland-input-container form-control',
                formIsValidated &&
                  (paymentState.cardNumberIsValid ? 'is-valid' : 'is-invalid')
              )}
            />
            <Form.Control.Feedback type='invalid'>
              {content.payment.cardNumberValidationText}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group sx={{ mb: 4 }}>
            <LabelWithTooltip
              labelFor='credit-card-card-expiration'
              helpText={content.payment.expiryMonthValidationText}
            >
              {content.payment.expiryDateLabel}
            </LabelWithTooltip>
            <div
              id='credit-card-card-expiration'
              className={classnames(
                'heartland-input-container form-control',
                formIsValidated &&
                  (paymentState.expiryDateIsValid ? 'is-valid' : 'is-invalid')
              )}
            />
            <Form.Control.Feedback type='invalid'>
              {content.payment.expiryMonthValidationText}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group sx={{ mb: 4 }}>
            <LabelWithTooltip
              labelFor='credit-card-card-cvv'
              helpText={content.payment.cvvHelpText}
            >
              {content.payment.cvvLabel}
            </LabelWithTooltip>
            <div
              id='credit-card-card-cvv'
              className={classnames(
                'heartland-input-container form-control',
                formIsValidated &&
                  (paymentState.cvvCodeIsValid ? 'is-valid' : 'is-invalid')
              )}
            />
            <Form.Control.Feedback type='invalid'>
              {content.payment.cvvValidationText}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className='position-relative' sx={{ my: 5 }}>
            <div
              sx={{
                height: '54px',
                svg: {
                  fill: theme.rawColors?.primaryButtonColor,
                  position: 'absolute',
                  right: '14px',
                  top: '14px',
                },
                '&:hover': {
                  svg: { fill: 'primaryButtonColorHover' },
                  color: 'primaryButtonColorHover',
                },
              }}
            >
              <IconArrowSvg
                className='icon-arrow'
                data-testid='action-button-icon'
              />
              <div
                id='credit-card-submit'
                className='heartland-input-container'
                sx={{ height: '100%', color: 'inherit' }}
              />
            </div>
          </Form.Group>
        </Form>
        {showWarningMessage && (
          <WarningMessage
            warningMessage={content.payment.formErrorsMessage}
            warningTitle={content.payment.formErrorsSubTitle}
          />
        )}
      </div>
    </div>
  );
};

export default HeartlandRtsPayment;
