export interface QueryObject {
  [key: string]: string | number | undefined | null;
}

export function buildQueryString(queryObject: QueryObject) {
  const params: string[][] = createArray(queryObject);
  const query: string = new URLSearchParams(params).toString();
  return query.length ? `?${query}` : '';
}

function createArray(queryObject: QueryObject) {
  const params: string[][] = [];
  Object.entries(queryObject).forEach((entry) => {
    const [key, value] = entry;
    if (value !== undefined && value !== null && value != '') {
      params.push([key, value.toString()]);
    }
  });
  return params;
}
