import { useEffect } from 'react';

import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { getRouteFromStep } from '../services/JourneyService';
import {
  selectConfirmationData,
  selectJourneyTypeConfig,
  selectSource,
  selectStep,
} from '../store/Selectors';

const useConfirmationPageRedirect = () => {
  const navigate = useNavigate();

  const confirmationData = useSelector(selectConfirmationData);
  const journeyTypeConfig = useSelector(selectJourneyTypeConfig);
  const source = useSelector(selectSource);
  const step = useSelector(selectStep);

  useEffect(() => {
    if (!confirmationData?.externalOrderId) {
      return;
    }

    const { externalOrderId } = confirmationData;

    const path = getRouteFromStep(journeyTypeConfig, step + 1);
    const fullPath = `/${path}/${externalOrderId}`;
    if (source === 'app') {
      // bumblebee needs serverside re-render to trigger my-tickets call event
      window.location.assign(fullPath);
    } else {
      navigate(fullPath);
    }
  }, [confirmationData, journeyTypeConfig, navigate, source, step]);

  return null;
};

export default useConfirmationPageRedirect;
