/* eslint-disable @typescript-eslint/no-empty-function */

export const movioName = 'movio';

export default function movioPlugin(movioConfig) {
  return {
    name: movioName,
    config: { ...movioConfig },
    initialize: ({ config }) => {
      window.MovioTrackingFunctionName = 'mt';
      window['mt'] =
        window['mt'] ||
        function () {
          (window['mt'].queue = window['mt'].queue || []).push(arguments);
        };
      const script = document.createElement('script');
      script.async = 1;
      script.src = `https://cdn-cinema-ui-assets-prod.movio.co/webtracker/${config.movioCustomerId}.api.js`;
      document.body.appendChild(script);
    },
    page: () => {},
    track: ({ payload }) => {
      const { bookingData, cartSummary } = payload.properties;
      if (payload.event !== 'abortedPurchase') {
        return;
      }
      if (window.mt && bookingData?.memberId && cartSummary?.movioFilmId) {
        window.mt('set', 'memberId', {
          id: bookingData.memberId,
          hashed: true,
        });
        window.mt('send', 'TrackAbortedPurchase', {
          movieId: cartSummary.movioFilmId,
          abortDateTime: new Date(),
        });
      }
    },
    identify: ({ payload }) => {
      if (!window.mt || !payload.userId) {
        return;
      }
      window.mt('set', 'memberId', {
        id: payload.userId,
        hashed: true,
      });
      window.mt('send', 'TrackMemberLogin', {
        loginDateTime: new Date(),
      });
    },
    loaded: () => {
      const movioLoaded = !!window.mt;
      return movioLoaded;
    },
  };
}
