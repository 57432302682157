import React from 'react';

import { useSelector } from 'react-redux';

import SeasonPassImage from './SeasonPassImage';

import { selectSelectedSeasonPassItem } from '../../../store/Selectors';

const SelectedSeasonPassImage: React.FC = () => {
  const selectedSeasonPassItem = useSelector(selectSelectedSeasonPassItem);

  if (!selectedSeasonPassItem) {
    return null;
  }

  return (
    <SeasonPassImage
      imageUrl={selectedSeasonPassItem.imageUrl}
      title={selectedSeasonPassItem.title}
    />
  );
};

export default SelectedSeasonPassImage;
