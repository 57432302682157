import React from 'react';

import { useSelector } from 'react-redux';
import { Box, Flex } from 'theme-ui';

import { detailSxProp, priceSxProp, quantitySxProp } from './constants';

import { SEASON_PASS_STEPS } from '../../../constants';
import { displayPrice } from '../../../services/Helpers';
import {
  selectCurrencyConfig,
  selectSelectedSeasonPassItem,
  selectSelectedSeasonPassSessions,
  selectStep,
} from '../../../store/Selectors';

const CartSummarySeasonPassTickets: React.FC = () => {
  const step = useSelector(selectStep);
  const currencyConfig = useSelector(selectCurrencyConfig);
  const selectedSeasonPassItem = useSelector(selectSelectedSeasonPassItem);
  const selectedSeasonPassSessions = useSelector(
    selectSelectedSeasonPassSessions
  );

  const sessionsQuantity = selectedSeasonPassSessions.length;
  const isShowtimesStep = step === SEASON_PASS_STEPS.SHOWTIMES;

  const selectedTicketTypes = selectedSeasonPassItem?.ticketTypes.filter(
    (ticket) => ticket.quantity > 0
  );

  if (!selectedTicketTypes?.length) return null;

  return (
    <div className='cart-summary-row tickets'>
      {selectedTicketTypes.map((t, i) => {
        let totalPriceInCents = t.quantity * t.priceInCents;
        if (sessionsQuantity > 0) {
          totalPriceInCents *= sessionsQuantity;
        }
        const ticketPrice = displayPrice(totalPriceInCents, currencyConfig);

        return (
          <Flex
            key={`${t.ticketTypeId}_${i}`}
            sx={{
              mb: 0,
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Box sx={{ textAlign: 'left' }}>
              <b>{t.displayName}</b>
            </Box>
            <Flex sx={detailSxProp}>
              <Box className='tickets-quantity' sx={quantitySxProp}>
                {sessionsQuantity === 0
                  ? t.quantity
                  : sessionsQuantity * t.quantity}
              </Box>
              <Box className='price' sx={priceSxProp}>
                {isShowtimesStep && sessionsQuantity === 0 ? '-' : ticketPrice}
              </Box>
            </Flex>
          </Flex>
        );
      })}
    </div>
  );
};

export default CartSummarySeasonPassTickets;
