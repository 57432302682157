import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useSelector } from 'react-redux';

import { NullableString } from '../../@types/modelTypes';
import { useRecaptcha } from '../../contextProviders/recaptchaContext';
import { useTurnstile } from '../../contextProviders/turnstileContext';
import backend from '../../services/RestUtilities';
import { selectTicketTypes, selectToken } from '../../store/Selectors';

interface ValidateThirdPartyVoucherRequest {
  voucherCode: string;
  thirdPartyTicketsName: NullableString;
}

export const useValidateThirdPartyVoucherCode = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const recaptcha = useRecaptcha();
  const turnstile = useTurnstile();

  const ticketTypes = useSelector(selectTicketTypes);
  const token = useSelector(selectToken);

  const validateThirdPartyVoucherCode = async (
    request: ValidateThirdPartyVoucherRequest
  ) => {
    if (!executeRecaptcha || !ticketTypes) return null;

    const recaptchaToken = await recaptcha?.getRecaptchaToken(
      'AddVoucherCode',
      executeRecaptcha
    );

    const turnstileToken = await turnstile?.getToken();

    const unlockedRedemptionTicketIds: string[] = ticketTypes.ticketTypeModels
      .filter((x) => x.isRedemptionTicket)
      .map((x) => x.id);
    const data = {
      voucherCode: request.voucherCode,
      dataToken: token,
      recaptchaToken: recaptchaToken ?? null,
      unlockedRedemptionTicketIds,
      thirdPartyTicketsName: request.thirdPartyTicketsName,
    };
    const response = await backend.post(
      'api/Tickets/ValidateThirdPartyVoucherCode',
      data,
      turnstileToken
    );

    turnstile?.resetToken();

    return response;
  };

  return validateThirdPartyVoucherCode;
};
