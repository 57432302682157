import React from 'react';

import { useSelector } from 'react-redux';

import TicketType from './TicketType';

import { QuantitySelectorContext } from '../../../@types/actionTypes';
import { TicketTypeModel } from '../../../@types/modelTypes';
import { JOURNEY_TYPES } from '../../../constants';
import {
  exceedsMaxGuestTickets,
  exceedsMaxMemberTickets,
  exceedsMaxTickets,
} from '../../../services/Helpers';
import {
  selectConfig,
  selectDeals,
  selectDealsInTicketsStep,
  selectJourneyType,
  selectSelectedSeats,
  selectTicketTypes,
} from '../../../store/Selectors';

interface Props {
  handleTicketButtonClick: (
    ticketTypeId: TicketTypeModel['id'],
    context: QuantitySelectorContext
  ) => Promise<void>;
  orderHasMaxTickets: boolean;
  ticketTypeModels: TicketTypeModel[];
}

const TicketSelector: React.FC<Props> = ({
  handleTicketButtonClick,
  orderHasMaxTickets,
  ticketTypeModels,
}) => {
  const allTicketTypes = useSelector(selectTicketTypes);
  const selectedSeats = useSelector(selectSelectedSeats);
  const journeyType = useSelector(selectJourneyType);
  const deals = useSelector(selectDeals) ?? [];
  const dealsInTicketsStep = useSelector(selectDealsInTicketsStep) ?? [];
  const config = useSelector(selectConfig);

  if (ticketTypeModels?.length <= 0) return null;

  const isSeatFirst =
    journeyType === JOURNEY_TYPES.SEAT_FIRST ||
    journeyType === JOURNEY_TYPES.SEAT_FIRST_KIOSK;

  const shouldDisplayTicket = (t: TicketTypeModel) => {
    return (
      !t.voucherCode &&
      (!isSeatFirst ||
        selectedSeats.some((x) => x.areaCategoryCode === t.areaCategoryCode)) &&
      !t.ticketExclusionStrategy?.isExcluded
    );
  };

  const shouldDisableAddTicket = (t: TicketTypeModel) => {
    const ticketsInArea = allTicketTypes?.ticketTypeModels.filter(
      (ti: TicketTypeModel) => ti.areaCategoryCode === t.areaCategoryCode
    );

    const selectedTicketsInAreaCount =
      ticketsInArea?.reduce((a, b) => a + (b.quantity * b.nbSeats || 0), 0) ??
      0;

    const selectedSeatsInArea = selectedSeats?.filter(
      (s) => s.areaCategoryCode === t.areaCategoryCode
    );

    const ticketLimitsConfig = {
      ticketTypes: allTicketTypes,
      ticketsConfig: config.tickets,
      t,
    };

    return (
      orderHasMaxTickets ||
      exceedsMaxMemberTickets(ticketLimitsConfig) ||
      exceedsMaxGuestTickets(ticketLimitsConfig) ||
      exceedsMaxTickets(ticketLimitsConfig) ||
      !!(t.maxQuantity && t.quantity === t.maxQuantity) ||
      (isSeatFirst &&
        selectedSeatsInArea?.length === selectedTicketsInAreaCount) ||
      (isSeatFirst &&
        selectedSeatsInArea?.length < selectedTicketsInAreaCount + t.nbSeats)
    );
  };

  return (
    <>
      {ticketTypeModels
        ?.filter((t) => shouldDisplayTicket(t))
        .map((t) => (
          <TicketType
            key={t.id}
            ticket={t}
            onClick={handleTicketButtonClick}
            disableAdd={shouldDisableAddTicket(t)}
            disableRemove={t.quantity === 0}
            isPackageTicket={t.isPackageTicket}
            isCardPaymentPromotionTicket={t.isCardPaymentPromotionTicket}
            deals={deals}
            dealsInTicketsStep={dealsInTicketsStep}
            orderHasMaxTickets={orderHasMaxTickets}
          />
        ))}
    </>
  );
};

export default TicketSelector;
