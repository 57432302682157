import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { SEASON_PASS_STEPS } from '../constants';
import { actionCreators } from '../store/ActionCreators';
import {
  selectUseCalculatedBookingFeeStrategy,
  selectStep,
  selectSelectedSeasonPassSessions,
  selectBookingFee,
} from '../store/Selectors';

const useSetBookingFeeAndTaxForSeasonPass = () => {
  const dispatch = useDispatch();
  const {
    useBookingFeeStrategy,
    calculatedBookingFee,
    calculatedBookingFeeTax,
  } = useSelector(selectUseCalculatedBookingFeeStrategy);
  const step = useSelector(selectStep);
  const isShowtimesStep = step === SEASON_PASS_STEPS.SHOWTIMES;
  const seasonPassSessions = useSelector(selectSelectedSeasonPassSessions);
  const bookingFee = useSelector(selectBookingFee);

  useEffect(() => {
    if (bookingFee === calculatedBookingFee) {
      return;
    }

    if (!useBookingFeeStrategy || !isShowtimesStep) {
      return;
    }

    dispatch(
      actionCreators.setBookingFeeAndTax(
        calculatedBookingFee,
        calculatedBookingFeeTax
      )
    );
  }, [
    calculatedBookingFee,
    calculatedBookingFeeTax,
    seasonPassSessions,
    useBookingFeeStrategy,
    isShowtimesStep,
    dispatch,
    bookingFee,
  ]);
};

export default useSetBookingFeeAndTaxForSeasonPass;
