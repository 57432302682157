import { GetPayrixPayFieldStyle } from './PayrixPaymentStyleHelpers';
import {
  PayrixPayField,
  PayrixPayFields,
  PayrixPaymentStateValidationPair,
  PayrixPaymentStateValidationPairWithValue,
  PayrixResponseError,
  PayrixSaleResponseData,
  PayrixTokenResponseData,
} from './PayrixPaymentTypes';

export const IsPayrixNewCardTokenResponseData = (
  payrixResponseData: PayrixTokenResponseData | PayrixSaleResponseData
): payrixResponseData is PayrixTokenResponseData => {
  return (
    (payrixResponseData as PayrixTokenResponseData).token !== undefined &&
    payrixResponseData.id.split('_')[1] === 'tok'
  );
};

export const IsPayrixApplePaySaleResponseData = (
  payrixResponseData: PayrixTokenResponseData | PayrixSaleResponseData
): payrixResponseData is PayrixSaleResponseData => {
  return payrixResponseData.id.split('_')[1] === 'txn';
};

export const GetPayrixPayFieldFieldArray: () => PayrixPayFields['fields'] =
  () => {
    return [
      {
        type: 'number',
        element: '#numberDiv',
      },
      {
        type: 'cvv',
        element: '#cvvDiv',
      },
      {
        type: 'name',
        element: '#nameDiv',
      },
      {
        type: 'expiration',
        element: '#expirationDiv',
      },
    ];
  };

export const GetPayrixPayFieldCustomizations: () => PayrixPayFields['customizations'] =
  () => ({
    style: GetPayrixPayFieldStyle(),
    optionalFields: ['#address1', '#city', '#state', '#email', '#phone'],
  });

export const getValidationPair: (
  isValid: boolean,
  isValidated: boolean
) => PayrixPaymentStateValidationPair = (
  isValid: boolean,
  isValidated: boolean
) => ({ isValid, isValidated });

export const getValidationPairWithValue: (
  isValid: boolean,
  isValidated: boolean,
  value: string
) => PayrixPaymentStateValidationPairWithValue = (
  isValid: boolean,
  isValidated: boolean,
  value: string
) => ({ isValid, isValidated, value });

export const getValidationUpdateForPayFieldError = (
  invalidField: PayrixPayField['type'],
  thisField: PayrixPayField['type'],
  validationPair: PayrixPaymentStateValidationPair
) =>
  ({
    isValid: invalidField === thisField ? false : validationPair.isValid,
    isValidated: true,
  } as PayrixPaymentStateValidationPair);

export const getPayFieldForError: (
  errorField: PayrixResponseError['field']
) => PayrixPayField['type'] = (errorField: PayrixResponseError['field']) => {
  switch (errorField) {
    case 'expiration':
      return 'expiration';
    case 'payment.number':
    case 'payment.method':
      return 'number';
  }
};
