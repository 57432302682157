import React from 'react';

const LoadingMask = () => (
  <div className='loading-mask' data-testid='loading-mask'>
    <div className='spinner-grow spinner' role='status'>
      <span className='visually-hidden'>Loading...</span>
    </div>
  </div>
);

export default LoadingMask;
