import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Concession, ConcessionGrouping } from '../@types/modelTypes';
import { actionCreators } from '../store/ActionCreators';
import {
  selectDeliveryItemId,
  selectSelectedGiftCards,
  selectAllConcessions,
} from '../store/Selectors';

export const useMaintainDeliveryItemCount = (totalCardsSelected: number) => {
  const dispatch = useDispatch();
  const concessions = useSelector(selectAllConcessions);
  const selectedGiftCards = useSelector(selectSelectedGiftCards);
  const deliveryItemId = useSelector(selectDeliveryItemId);
  const noDeliveryCharge = 0;
  const deliveryChargePerOrder = 1;
  const deliveryChargePerItem = 2;
  const deliveryItem =
    deliveryItemId &&
    concessions &&
    concessions[0].deliveryItems.find(
      (c: Concession) => c.id === deliveryItemId
    );

  useEffect(() => {
    const giftCardDeliveryItemStrategy: ConcessionGrouping['giftCardDeliveryItemStrategy'] =
      (concessions && concessions[0].giftCardDeliveryItemStrategy) || 0;
    if (!deliveryItem || giftCardDeliveryItemStrategy === noDeliveryCharge)
      return;

    let selectedDeliveryItem = selectedGiftCards.list.find(
      (concession) => concession.id === deliveryItem.id
    );
    const expectedQuantity =
      giftCardDeliveryItemStrategy == deliveryChargePerItem
        ? totalCardsSelected
        : giftCardDeliveryItemStrategy == deliveryChargePerOrder
        ? Math.min(totalCardsSelected, 1)
        : 0;

    if (!selectedDeliveryItem) {
      selectedDeliveryItem = deliveryItem;
      selectedDeliveryItem.quantity = expectedQuantity;
      selectedDeliveryItem.isDeliveryItem = true;
      dispatch(
        actionCreators.changeDeliveryItem(selectedDeliveryItem, 'insert')
      );
    } else if (
      !selectedDeliveryItem ||
      selectedDeliveryItem.quantity !== expectedQuantity
    ) {
      selectedDeliveryItem.quantity = expectedQuantity;
      selectedDeliveryItem.isDeliveryItem = true;
      dispatch(
        actionCreators.changeDeliveryItem(selectedDeliveryItem, 'update')
      );
    }
  }, [
    deliveryItem,
    totalCardsSelected,
    concessions,
    dispatch,
    selectedGiftCards.list,
  ]);
};
