/** @jsxImportSource theme-ui */
import React from 'react';

import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';

import { useScreenWidth } from '../../../contextProviders/screenWidthContext';

interface Props {
  message: string;
  show: boolean;
  onToastClose: () => void;
}

const ToastNotification: React.FC<Props> = ({
  message,
  show,
  onToastClose,
}) => {
  const { isLargeScreenWidth } = useScreenWidth();

  return (
    <ToastContainer
      sx={{
        position: 'fixed',
        top: '20px',
        left: isLargeScreenWidth ? `${'calc(33.3333% - 15px)'}` : '50%',
        transform: isLargeScreenWidth
          ? `${'translateX(calc(-33.3333% - 45px))'}`
          : `${'translateX(-50%)'}`,
      }}
    >
      <Toast onClose={onToastClose} show={show} delay={3000} autohide>
        <Toast.Header
          sx={{
            alignItems: 'center',
            backgroundColor: 'uiContainerBackground',
            border: 'uiInfoBorder',
            borderRadius: '4px',
            boxShadow: 'mostReadableOnWebsiteBackgroundShadow',
            display: 'flex',
            justifyContent: 'space-between',
            padding: 5,
          }}
        >
          <span
            dangerouslySetInnerHTML={{
              __html: message,
            }}
          />
        </Toast.Header>
      </Toast>
    </ToastContainer>
  );
};

export default ToastNotification;
