import React, { useState } from 'react';

import { useSelector } from 'react-redux';
import { Box, Flex } from 'theme-ui';

import ContextAwareQuantitySelector from './ContextAwareQuantitySelector';

import { ChangeGiftCardByIdContext } from '../../../../../@types/actionTypes';
import { Concession } from '../../../../../@types/modelTypes';
import { isVariableCostInvalid } from '../../../../../services/GiftCardHelpers';
import { selectConfig, selectContent } from '../../../../../store/Selectors';
import ConcessionVariableInput from '../../../concessionVariableInput/ConcessionVariableInput';

interface VariableCostConcession extends Concession {
  handleConcessionChange: (
    context: ChangeGiftCardByIdContext,
    concession: Concession,
    newValueInCents: number | undefined
  ) => void;
  totalCardsSelected: number;
  selectedConcession: Concession | undefined;
}

const VariableCostGiftCard: React.FC<VariableCostConcession> = ({
  id,
  code,
  description,
  extendedDescription,
  image,
  taxInCents,
  quantity,
  modifierGroups,
  isVariablePriceItem,
  minVariableCost,
  maxVariableCost,
  orderDelivery,
  handleConcessionChange,
  totalCardsSelected,
  selectedConcession,
}) => {
  const concession: Concession = {
    id,
    code,
    description,
    extendedDescription,
    image,
    taxInCents,
    quantity,
    modifierGroups,
    isVariablePriceItem,
    minVariableCost,
    maxVariableCost,
    orderDelivery,
    modifiers: [],
    cost: 0,
    variablePriceInCents: 0,
    hidden: false,
    isSoldOut: false,
  };

  const config = useSelector(selectConfig);
  const content = useSelector(selectContent);

  const currentVariableCost =
    selectedConcession?.isVariablePriceItem && selectedConcession.cost > 0
      ? (selectedConcession.cost / 100).toString()
      : '';

  const [currentValue, setCurrentValue] = useState(currentVariableCost);

  const newValueInCents: number = Number(currentValue) * 100;

  const cartItemHasDifferentCost =
    selectedConcession && selectedConcession.cost !== newValueInCents;

  const variableCostIsNotValid = isVariableCostInvalid(
    concession,
    newValueInCents
  );

  const isAddDisabled =
    totalCardsSelected >= config.giftCardsPurchase.maxQuantityOfGiftCards ||
    variableCostIsNotValid;

  return (
    <Box className='giftcard-container single' py={5}>
      <Box className='h3'>{concession.description}</Box>
      <Flex
        sx={{ justifyContent: 'space-between', alignItems: 'center', mt: 4 }}
      >
        <Box sx={{ flex: '1 0', mr: 3 }}>
          <ConcessionVariableInput
            concession={concession}
            currentValue={currentValue}
            setCurrentValue={setCurrentValue}
          />
        </Box>
        <Box>
          <ContextAwareQuantitySelector
            quantity={selectedConcession?.quantity ?? 0}
            maxQuantity={config.giftCardsPurchase.maxQuantityOfGiftCards}
            concession={concession}
            handleConcessionChange={handleConcessionChange}
            addIsDisabled={isAddDisabled}
            optionButtonSelectedText={
              content.giftCards.optionButtonSelectedText
            }
            optionButtonUnselectedText={
              content.giftCards.optionButtonUnselectedText
            }
            optionButtonUpdateText={content.giftCards.optionButtonUpdateText}
            showPrice
            cost={newValueInCents}
            variableCostIsInvalid={variableCostIsNotValid}
            useUpdateButton={cartItemHasDifferentCost}
          />
        </Box>
      </Flex>
    </Box>
  );
};

export default VariableCostGiftCard;
