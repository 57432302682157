import {
  IEventTrackingStrategy,
  TrackingStrategy,
} from '../@types/trackingTypes';
import { DefaultEventTrackingStrategy } from '../analytics/defaultEventTrackingStrategy';
import { EMGruviEventTrackingStrategy } from '../analytics/emGruviEventTrackingStrategy';

export class TrackingStrategyResolver {
  static resolve(eventTrackingStrategy: number): IEventTrackingStrategy {
    switch (eventTrackingStrategy) {
      case TrackingStrategy.DEFAULT:
        return new DefaultEventTrackingStrategy();
      case TrackingStrategy.EMGRUVI:
        return new EMGruviEventTrackingStrategy();
      default:
        return new DefaultEventTrackingStrategy();
    }
  }
}
