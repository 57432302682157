import React from 'react';

import Image from 'react-bootstrap/Image';

import { ReactComponent as GiftCardLargeSvg } from '../../../svgs/giftcardLarge.svg';

interface Props {
  imageUrl: string | null | undefined;
}
const VoucherImage: React.FC<Props> = ({ imageUrl }) => {
  const blobImage: string | null = imageUrl
    ? `https://blobimageprocessor.azurewebsites.net/blob-image/${imageUrl.replace(
        'https://',
        ''
      )}?width=100`
    : '';

  return (
    <>
      {imageUrl ? (
        <Image src={blobImage} alt={'Voucher'} rounded />
      ) : (
        <GiftCardLargeSvg className='fallback' />
      )}
    </>
  );
};

export default VoucherImage;
