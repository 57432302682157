/** @jsxImportSource theme-ui */
import React from 'react';

import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Flex } from 'theme-ui';

import { Voucher } from '../../../../@types/modelTypes';
import { PEACH_CODES } from '../../../../constants';
import { displayPrice } from '../../../../services/Helpers';
import { getContentForError } from '../../../../services/PeachErrorResolver';
import backend from '../../../../services/RestUtilities';
import { actionCreators } from '../../../../store/ActionCreators';
import {
  selectAppliedVouchers,
  selectContent,
  selectCurrencyConfig,
  selectToken,
} from '../../../../store/Selectors';

interface Props {
  grandTotal: number;
  isConfirmation?: boolean;
  variant?: 'cartSummary' | 'confirmation';
}

const VoucherBreakDown: React.FC<Props> = ({
  grandTotal,
  isConfirmation,
  variant,
}) => {
  const dispatch = useDispatch();

  const appliedVouchers = useSelector(selectAppliedVouchers);
  const currencyConfig = useSelector(selectCurrencyConfig);
  const content = useSelector(selectContent);
  const token = useSelector(selectToken);

  const handleRemoveVoucher = async (voucher: Voucher) => {
    const voucherCode = voucher.voucherCode;

    if (!voucherCode) return;
    dispatch(actionCreators.setLoading(true));

    const response = await backend.post(
      'api/Voucher/RemoveVoucher/' + voucherCode,
      token
    );
    if (response.ok) {
      const responseContent = response.content;
      if (responseContent.peachCode === PEACH_CODES.noError) {
        dispatch(actionCreators.setHasVoucherApplied(false));
        dispatch(actionCreators.removeVoucher(voucher));
        dispatch(actionCreators.setGrandTotalWithDiscount(undefined, 0));
      } else {
        dispatch(
          actionCreators.setError(
            getContentForError(responseContent.peachCode, content),
            responseContent.peachCode
          )
        );
      }
    } else {
      dispatch(
        actionCreators.setError(
          getContentForError(PEACH_CODES.unknownError, content),
          PEACH_CODES.unknownError
        )
      );
    }
    dispatch(actionCreators.setLoading(false));
  };

  if (!appliedVouchers?.length) return null;

  return (
    <>
      {variant === 'cartSummary' ? (
        <div
          className='breakdown'
          data-testid='voucher-break-down-cart-summary'
        >
          {appliedVouchers
            .slice(0)
            .reverse()
            .map((voucher) => {
              return (
                <Flex
                  key={`cart-summary-${voucher.voucherCode}`}
                  sx={{ justifyContent: 'space-between' }}
                >
                  <Flex sx={{ alignItems: 'center', flexGrow: 1, pr: 3 }}>
                    <span>
                      {content.payment.voucherAppliedLabel}{' '}
                      {voucher.voucherCode}
                    </span>
                  </Flex>
                  {!isConfirmation && (
                    <Flex sx={{ alignItems: 'center', pr: 3 }}>
                      <Button
                        onClick={() => handleRemoveVoucher(voucher)}
                        variant='link'
                        size='sm'
                        className='small'
                        sx={{ textTransform: 'uppercase' }}
                      >
                        {content.payment.voucherRemoveButtonText}
                      </Button>
                    </Flex>
                  )}
                  <Flex
                    sx={{
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                      textAlign: 'right',
                      minWidth: '68px',
                    }}
                  >
                    {displayPrice(voucher.totalDiscount, currencyConfig)}
                  </Flex>
                </Flex>
              );
            })}
        </div>
      ) : variant === 'confirmation' ? (
        <Box
          className='breakdown'
          data-testid='voucher-break-down-confirmation'
          sx={{ mt: 5 }}
        >
          {appliedVouchers
            .slice(0)
            .reverse()
            .map((voucher) => {
              return (
                <Box key={`${voucher.voucherCode}`} sx={{ py: 4 }}>
                  <Flex
                    sx={{
                      alignItems: 'center',
                      textAlign: 'left',
                      flexGrow: 1,
                    }}
                  >
                    <span>
                      <b>{content.payment.voucherAppliedLabel}</b>
                      {' ...'}
                      {voucher.voucherCode}
                    </span>
                  </Flex>
                </Box>
              );
            })}
        </Box>
      ) : (
        <Box
          className='breakdown'
          data-testid='voucher-break-down'
          sx={{ mt: 5 }}
        >
          {appliedVouchers
            .slice(0)
            .reverse()
            .map((voucher) => {
              return (
                <Flex
                  key={`${voucher.voucherCode}`}
                  sx={{ my: 4, justifyContent: 'space-between' }}
                >
                  <Flex
                    sx={{
                      alignItems: 'center',
                      textAlign: 'left',
                      flexGrow: 1,
                      pr: 3,
                    }}
                  >
                    <span>
                      <b>{content.payment.voucherAppliedLabel}</b>
                      {voucher.voucherCode}
                    </span>
                  </Flex>
                  <Flex sx={{ alignItems: 'center', pr: 3 }}>
                    <Button
                      onClick={() => handleRemoveVoucher(voucher)}
                      variant='link'
                      size='sm'
                      sx={{ textTransform: 'uppercase' }}
                    >
                      {content.payment.voucherRemoveButtonText}
                    </Button>
                  </Flex>
                  <Flex
                    sx={{
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                      textAlign: 'right',
                      minWidth: '68px',
                    }}
                  >
                    <b>{displayPrice(voucher.totalDiscount, currencyConfig)}</b>
                  </Flex>
                </Flex>
              );
            })}
          <Flex sx={{ py: 4 }}>
            <Flex sx={{ alignItems: 'center', textAlign: 'left', flexgrow: 1 }}>
              <span>
                <b>{content.payment.voucherStillToPayLabel}</b>
              </span>
            </Flex>
            <Flex
              sx={{
                textAlign: 'right',
                minWidth: '68px',
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}
            >
              <b>{displayPrice(grandTotal, currencyConfig)}</b>
            </Flex>
          </Flex>
        </Box>
      )}
    </>
  );
};

export default VoucherBreakDown;
