import { VueEventTrackingStrategy } from './vueEventTrackingStrategy';

import { TrackingEvent } from '../@types/trackingTypes';

export const adobeAnalyticsName = 'adobeAnalytics';

export const adobeAnalyticsConfig = {
  debug: false,
  orgId: 'Boost',
  dataLayerName: 'adobeDataLayer',
};

export function customAdobeAnalytics(adobeAnalyticsTagUrl) {
  return {
    name: adobeAnalyticsName,
    config: {
      ...adobeAnalyticsConfig,
      adobeAnalyticsTagUrl,
    },
    initialize: ({ config }) => {
      const loaded = isScriptLoaded(config.adobeAnalyticsTagUrl);
      if (loaded) return;
      window[config.dataLayerName] = window[config.dataLayerName] || [];
      window[config.dataLayerName].push({
        event: 'didomi-ready',
        didomiState: { ...window.didomiState },
      });
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = config.adobeAnalyticsTagUrl;
      script.async = true;
      document.head.appendChild(script);
    },
    identify: ({ payload }) => {
      safelyPushToDataLayer(payload);
    },
    page: ({ payload, config }) => {
      const { properties = {} } = payload;
      const { dataLayerName } = config;
      if (!window[dataLayerName]) return;
      const globalState = properties.globalState;
      const pathName = properties.path;
      const vueTracking = new VueEventTrackingStrategy();
      vueTracking.processEvent(TrackingEvent.PAGE_VIEW, globalState, {
        ...properties.options,
        dataLayerName,
        pathName,
      });
    },
    loaded: ({ config }) => {
      const loaded = isScriptLoaded(config.adobeAnalyticsTagUrl);
      return loaded;
    },
  };

  function isScriptLoaded(scriptSrc) {
    const regex = new RegExp(`^${scriptSrc}`);
    return Array.from(document.querySelectorAll('script[src]')).some((script) =>
      regex.test(script.src)
    );
  }

  function safelyPushToDataLayer(data) {
    if (
      window[adobeAnalyticsConfig.dataLayerName] &&
      Array.isArray(window[adobeAnalyticsConfig.dataLayerName])
    ) {
      window[adobeAnalyticsConfig.dataLayerName].push(data);
    }
  }
}
