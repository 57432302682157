import { GlobalState } from './modelTypes';

export interface IEventTrackingStrategy {
  processEvent(
    eventName: string,
    state: GlobalState,
    options?: unknown
  ): unknown;
}

export interface AnalyticsTrackingEventProperties {
  globalState: GlobalState;
  options?: { [key: string]: unknown };
}

export enum TrackingEvent {
  LOGIN_LANDING = 'LOGIN_LANDING',
  ADD_TICKET = 'ADD_TICKET',
  SEATS_LANDING = 'SEATS_LANDING',
  PAYMENT_LANDING = 'PAYMENT_LANDING',
  TICKET_CHECKOUT = 'TICKET_CHECKOUT',
  PURCHASE = 'PURCHASE',
  GIFTCARD_LANDING = 'GIFTCARD_LANDING',
  PICK_GIFTCARD = 'PICK_GIFTCARD',
  GIFTCARD_PAYMENT_LANDING = 'GIFTCARD_PAYMENT_LANDING',
  GIFTCARD_CHECKOUT = 'GIFTCARD_CHECKOUT',
  PAGE_VIEW = 'PAGE_VIEW',
  VOUCHER_CODE_VALID = 'VOUCHER_CODE_VALID',
  VOUCHER_CODE_INVALID = 'VOUCHER_CODE_INVALID',
}

export enum CommonTrackingEvent {
  REGISTER_CLICK = 'REGISTER_CLICK',
  MORE_OPTIONS_CLICK = 'MORE_OPTIONS_CLICK',
  CHANGE_SHOWTIME_CLICK = 'CHANGE_SHOWTIME_CLICK',
  CEA_CODE_VALIDATED = 'CEA_CODE_VALIDATED',
  DISCOUNT_CODE_VALIDATED = 'DISCOUNT_CODE_VALIDATED',
  MEERKAT_CODE_VALIDATED = 'MEERKAT_CODE_VALIDATED',
}

export enum TrackingStrategy {
  DEFAULT = 0,
  EMGRUVI = 1,
}
