import { defineMessages } from 'react-intl';

export default defineMessages({
  usernameLabel: {
    id: 'login.username-label',
    defaultMessage: 'Username',
  },
  usernamePlaceholder: {
    id: 'login.username-placeholder',
    defaultMessage: 'Username',
  },
  usernameValidationText: {
    id: 'login.username-validation-text',
    defaultMessage: 'Something is wrong with the username',
  },
  passwordLabel: {
    id: 'login.password-label',
    defaultMessage: 'Password',
  },
  passwordPlaceholder: {
    id: 'login.password-placeholder',
    defaultMessage: 'Password',
  },
  passwordValidationText: {
    id: 'login.password-validation-text',
    defaultMessage: 'Something is wrong with the password',
  },
  hidePasswordButtonText: {
    id: 'login.hide-password-button-mouseover-text',
    defaultMessage: 'Hide password',
  },
  showPasswordButtonText: {
    id: 'login.show-password-button-mouseover-text',
    defaultMessage: 'Show password',
  },
  rememberUsernameText: {
    id: 'login.remember-username-text',
    defaultMessage: 'Remember username',
  },
  forgottenPasswordText: {
    id: 'login.forgotten-password-text',
    defaultMessage: 'Forgot password?',
  },
  loginButtonText: {
    id: 'login.login-button-text',
    defaultMessage: 'Log in',
  },
});
