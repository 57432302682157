import React from 'react';

import { useSelector } from 'react-redux';

import CartSummarySeasonPassSessions from './CartSummarySeasonPassSessions';
import CartSummarySeasonPassTickets from './CartSummarySeasonPassTickets';

import { selectSelectedSeasonPassItem } from '../../../store/Selectors';

const CartSummarySeasonPass: React.FC = () => {
  const selectedSeasonPassItem = useSelector(selectSelectedSeasonPassItem);

  const selectedTicketTypes = selectedSeasonPassItem?.ticketTypes.filter(
    (ticket) => ticket.quantity > 0
  );

  if (!selectedTicketTypes?.length) return null;

  return (
    <div
      className='season-pass-cart-summary'
      data-testid='cs-season-pass-tickets'
    >
      <CartSummarySeasonPassTickets />
      <CartSummarySeasonPassSessions />
    </div>
  );
};

export default CartSummarySeasonPass;
