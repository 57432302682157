/** @jsxImportSource theme-ui */
import React from 'react';

import { useSelector } from 'react-redux';

import NewsletterCheckbox from './NewsletterCheckbox';
import ThirdPartyCheckbox from './ThirdPartyCheckbox';

import { selectConfig, selectContent } from '../../../../store/Selectors';
import ContainedRow from '../../layout/ContainedRow';
import RichText from '../../richtext/RichText';

interface Props {
  onEdit?: () => void;
}

const GuestMarketing: React.FC<Props> = ({ onEdit }) => {
  const configPayment = useSelector(selectConfig).payment;
  const contentPayment = useSelector(selectContent).payment;

  return (
    <ContainedRow classNameWrapper='guest-marketing-wrapper'>
      <h2 sx={{ mt: 6, textAlign: 'center' }}>
        {contentPayment.guestMarketingSubtitle}
      </h2>
      <RichText
        text={contentPayment.guestMarketingRichText}
        styles={{ textAlign: 'left' }}
      />
      {configPayment.showNewsletterCheckbox && (
        <NewsletterCheckbox onEdit={onEdit} />
      )}
      {configPayment.showThirdPartyCheckbox && (
        <ThirdPartyCheckbox onEdit={onEdit} />
      )}
    </ContainedRow>
  );
};

export default GuestMarketing;
