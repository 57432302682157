/** @jsxImportSource theme-ui */
import React from 'react';

import { useSelector } from 'react-redux';

import { GlobalState } from '../../../@types/modelTypes';
import {
  selectContentCartSummary,
  selectDisplayPrice,
  selectGrandTotalAfterDiscountsInCents,
  selectHasVoucherApplied,
  selectTotalDiscount,
} from '../../../store/Selectors';

const CartSummaryDiscount: React.FC = () => {
  const contentCartSummary = useSelector(selectContentCartSummary);
  const hasVoucherApplied = useSelector(selectHasVoucherApplied);
  const grandTotalWithDiscount = useSelector(
    selectGrandTotalAfterDiscountsInCents
  );
  const totalDiscount = useSelector(selectTotalDiscount);

  const priceToDisplay = useSelector((state: GlobalState) =>
    selectDisplayPrice(state, totalDiscount)
  );
  const isVoucherDiscount = hasVoucherApplied && grandTotalWithDiscount;
  const isDealDiscount = !isVoucherDiscount && totalDiscount;
  if (!isVoucherDiscount && !isDealDiscount) {
    return null;
  }

  return (
    <div className='cart-summary-row cart-summary-row-flex b'>
      <span>
        {isDealDiscount
          ? contentCartSummary.dealsLabel
          : contentCartSummary.discountLabel}
      </span>
      <span sx={{ textAlign: 'right' }}>-{priceToDisplay}</span>
    </div>
  );
};

export default CartSummaryDiscount;
