/* eslint-disable react/no-find-dom-node */
import React from 'react';

import moment from 'moment';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';

import { renderDidomiScript } from './DidomiScript';

import TokenReplacer from '../../../services/TokenReplacer';
import {
  selectBookingData,
  selectCartSummary,
  selectConfig,
  selectContent,
} from '../../../store/Selectors';

const LayoutHelmet: React.FC = () => {
  const bookingData = useSelector(selectBookingData);
  const config = useSelector(selectConfig);
  const content = useSelector(selectContent);
  const cartSummary = useSelector(selectCartSummary);

  const getMoviePosterMeta = () => {
    return cartSummary && cartSummary.posterUrl
      ? `${window.location.protocol}${cartSummary.posterUrl}`
      : `${window.location.origin}/images/film-placeholder.png`;
  };

  const getUrlMeta = () => {
    return `${window.location.origin}/StartTicketing/${bookingData.externalCinemaId}/${bookingData.externalSessionId}`;
  };

  const ogDescription = TokenReplacer(content?.descriptionText);
  if (content && config) {
    const cacheBuster = moment().format('YYMMDD');

    return (
      <Helmet>
        <title>{content ? content.title : 'Boxoffice Co'}</title>
        <link rel='preconnect' href='https://fonts.googleapis.com'></link>
        <link
          rel='preconnect'
          href='https://fonts.gstatic.com'
          crossOrigin='anonymous'
        ></link>
        {content.primaryFontUrl && (
          <link href={content.primaryFontUrl} rel='stylesheet'></link>
        )}
        {content.secondaryFontUrl && (
          <link href={content.secondaryFontUrl} rel='stylesheet'></link>
        )}
        {content.faviconUrl && (
          <link
            rel='shortcut icon'
            href={`${content.faviconUrl}?v=${cacheBuster}`}
          />
        )}
        <meta property='og:type' content='website' />
        {config.currentCinema && (
          <meta property='og:title' content={config.currentCinema.title} />
        )}
        <meta property='og:description' content={ogDescription} />
        <meta property='og:image' content={getMoviePosterMeta()} />
        <meta property='og:image:width' content='200' />
        <meta property='og:image:height' content='300' />
        {bookingData && <meta property='og:url' content={getUrlMeta()} />}
        {renderDidomiScript(config)}
      </Helmet>
    );
  } else {
    return null;
  }
};

export default LayoutHelmet;
