import React, { useRef } from 'react';

import { useSelector } from 'react-redux';

import { PaymentProvidersEnum } from '../../../../../../@types/enums';
import { useGetPaymentProvider } from '../../../../../../hooks/useGetPaymentProvider';
import {
  selectBankCardAmountDisplayPrice,
  selectContent,
  selectCountdownExpired,
  selectHostedPayments,
  selectMaxRetriesExceeded,
} from '../../../../../../store/Selectors';
import ActionButton from '../../../../actionbutton/ActionButton';

const VantivHP: React.FC = () => {
  const priceToDisplay = useSelector(selectBankCardAmountDisplayPrice);
  const content = useSelector(selectContent);
  const countdownExpired = useSelector(selectCountdownExpired);
  const maxRetriesExceeded = useSelector(selectMaxRetriesExceeded);
  const hostedPayments = useSelector(selectHostedPayments);

  const postBackForm = useRef<HTMLFormElement | null>(null);

  const hostedPayment = hostedPayments[PaymentProvidersEnum.VANTIVHP];

  useGetPaymentProvider(PaymentProvidersEnum.VANTIVHP);

  const launchHostedPayment = () => {
    if (postBackForm.current) {
      postBackForm.current.submit();
    }
  };

  const handlePaymentClick = async () => {
    if (hostedPayment) {
      launchHostedPayment();
    }
  };

  return (
    <div className='hosted-payment' data-testid='vantiv-hosted-payment'>
      <ActionButton
        onClick={handlePaymentClick}
        disabled={maxRetriesExceeded || !hostedPayment}
        showIcon
        mb={0}
        mt={0}
        variant='primary'
      >
        {`${content.payment.submitText} ${priceToDisplay}`}
      </ActionButton>

      {!!hostedPayment && !countdownExpired && (
        <form
          action={hostedPayment.actionUrl}
          ref={postBackForm}
          method='get'
          data-testid={'vantiv-payment-form'}
        >
          <input
            type='hidden'
            name='TransactionSetupID'
            value={hostedPayment.transactionId}
          />
        </form>
      )}
    </div>
  );
};

export default VantivHP;
