/** @jsxImportSource theme-ui */
import React, { PropsWithChildren } from 'react';

import classnames from 'classnames';
import { Collapse, Button } from 'react-bootstrap';
import { Box } from 'theme-ui';

interface BorderedCollapseProps {
  className?: string;
  closeButtonText: string;
  heading: string;
  setShow: (show: boolean) => void;
  show: boolean;
  headingClassName?: string;
}

const BorderedCollapse: React.FC<PropsWithChildren<BorderedCollapseProps>> = ({
  children,
  className,
  closeButtonText,
  heading,
  setShow,
  show,
  headingClassName,
}) => {
  return (
    <div
      className={classnames('bordered-collapse', className)}
      sx={{
        backgroundColor: 'boxBackground',
        borderColor: 'boxBorderColor',
        color: 'mostReadableOnWebsiteBackground',
      }}
    >
      <Button
        className='bordered-collapse-btn'
        onClick={() => {
          setShow(!show);
        }}
        sx={{ textAlign: 'center' }}
        variant='link'
      >
        <Box
          className={classnames('h3', headingClassName)}
          sx={{ textWrap: 'balance' }}
        >
          {heading}
        </Box>
        <span
          data-testid='bordered-collapse-close-button'
          className={classnames('float-end close-button', show && 'show')}
        >
          {closeButtonText}
        </span>
      </Button>
      <Collapse in={show} data-testid='bordered-collapse-content'>
        <div>
          <Box className='bordered-collapse-open' sx={{ pt: 0, pb: 4, px: 4 }}>
            <div className='bordered-collapse-border-top'>{children}</div>
          </Box>
        </div>
      </Collapse>
    </div>
  );
};

export default BorderedCollapse;
