/** @jsxImportSource theme-ui */
import React from 'react';

import classNames from 'classnames';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Flex } from 'theme-ui';

import { ChangeGiftCardByIdContext } from '../../../../../@types/actionTypes';
import { Concession } from '../../../../../@types/modelTypes';
import { displayPrice } from '../../../../../services/Helpers';
import { selectCurrencyConfig } from '../../../../../store/Selectors';
import { ReactComponent as RadioSelectedSvg } from '../../../../../svgs/radioSelected.svg';
import { ReactComponent as RadioUnselectedSvg } from '../../../../../svgs/radioUnselected.svg';
import QuantityButton from '../../../quantitybutton/QuantityButton';

interface Props {
  quantity: number | undefined;
  addIsDisabled: boolean;
  concession: Concession;
  handleConcessionChange: (
    context: ChangeGiftCardByIdContext,
    concession: Concession,
    newValueInCents: number | undefined
  ) => void;
  maxQuantity: number;
  optionButtonSelectedText: string;
  optionButtonUnselectedText: string;
  optionButtonUpdateText?: string;
  showPrice: boolean;
  cost: number;
  variableCostIsInvalid?: boolean;
  useUpdateButton?: boolean;
}

const ContextAwareQuantitySelector: React.FC<Props> = ({
  quantity,
  addIsDisabled,
  concession,
  handleConcessionChange,
  maxQuantity,
  optionButtonSelectedText,
  optionButtonUnselectedText,
  optionButtonUpdateText,
  showPrice,
  cost,
  variableCostIsInvalid,
  useUpdateButton,
}) => {
  const currencyConfig = useSelector(selectCurrencyConfig);
  const concessionSelected = quantity && quantity >= 1;

  const renderButton = (
    context: ChangeGiftCardByIdContext,
    disabled: boolean
  ) => {
    return (
      <QuantityButton
        context={context}
        disabled={disabled}
        onClick={() => handleButtonClick(context)}
      />
    );
  };

  const handleButtonClick = (context: ChangeGiftCardByIdContext) => {
    if (handleConcessionChange && concession) {
      handleConcessionChange(context, concession, cost);
    }
  };

  return (
    <Flex
      className='context-aware-quantity-selector'
      sx={{ justifyContent: 'flex-end', alignItems: 'center' }}
    >
      {(maxQuantity === 1 || !!useUpdateButton) && (
        <div className='options-selector-container'>
          <Button
            className={classNames(
              'option-button',
              concessionSelected && !useUpdateButton && 'selected',
              variableCostIsInvalid && 'disabled'
            )}
            sx={{ px: 4, py: 2, display: 'flex', justifyContent: 'center' }}
            disabled={variableCostIsInvalid}
            onClick={() =>
              handleButtonClick(
                concessionSelected
                  ? useUpdateButton
                    ? 'swap'
                    : 'remove'
                  : 'addAndRemoveOthers'
              )
            }
          >
            <Flex sx={{ alignItems: 'center', justifyContent: 'center' }}>
              {concessionSelected && !useUpdateButton ? (
                <RadioSelectedSvg className='check' />
              ) : (
                <RadioUnselectedSvg className='check' />
              )}

              <div>
                {concessionSelected
                  ? useUpdateButton
                    ? optionButtonUpdateText
                    : optionButtonSelectedText
                  : optionButtonUnselectedText}
              </div>
            </Flex>
          </Button>
        </div>
      )}
      {maxQuantity > 1 && !useUpdateButton && (
        <Flex sx={{ justifyContent: 'center' }}>
          <Flex
            className='quantity-selector'
            sx={{ justifyContent: 'space-between' }}
          >
            <div className='quantity-button-container'>
              {renderButton('remove', quantity === 0)}
            </div>
            <Flex
              className='quantity-col'
              sx={{
                justifyContent: 'center',
                alignItems: 'center',
                mx: 4,
                fontWeight: 'bold',
              }}
            >
              {quantity}
            </Flex>
            <div className='quantity-button-container'>
              {renderButton('add', addIsDisabled)}
            </div>
          </Flex>
        </Flex>
      )}
      {showPrice && (
        <div
          className='options-price'
          sx={{
            textAlign: 'right',
            fontWeight: concessionSelected ? 'bold' : 'normal',
          }}
        >
          {displayPrice(cost, currencyConfig)}
        </div>
      )}
    </Flex>
  );
};

export default ContextAwareQuantitySelector;
