export default {
  VALID: '',
  CARD_TYPE_NOT_SUPPORTED: 'cardTypeNotSupportedText',
  CARD_NUMBER_INVALID: 'cardNumberInvalidText',
  CARD_NUMBER_REQUIRED: 'cardNumberValidationText',
  CVV_CODE_REQUIRED: 'cvvValidationText',
  CVV_CODE_INVALID: 'cvvInvalidText',
  EXP_MONTH_REQUIRED: 'expiryMonthValidationText',
  EXP_YEAR_REQUIRED: 'expiryYearValidationText',
  DATE_IN_PAST: 'dateInPastValidationText',
};
