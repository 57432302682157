import React from 'react';

import { useSelector } from 'react-redux';

import { selectDazzlerConfig } from '../../store/Selectors';
import Widget from '../widgets';
interface Props {
  step: string;
}

const DazzlerWrapper: React.FC<Props> = ({ step }) => {
  const dazzlerConfig = useSelector(selectDazzlerConfig);
  if (!dazzlerConfig?.dazzlerSteps?.includes(step)) {
    return null;
  }

  const thisStep = dazzlerConfig.dazzler?.steps.find((s) => s.path === step);
  const view = thisStep?.view;
  const widgets = view?.widgets;

  if (!widgets || widgets.length == 0) {
    return null;
  }

  if (!widgets || widgets.length == 0) {
    return null;
  }

  return (
    <>
      {widgets?.map((widget) => {
        return <Widget key={widget.id} widget={widget} />;
      })}
    </>
  );
};

export default DazzlerWrapper;
