import React from 'react';

import { useSelector } from 'react-redux';

import {
  selectContent,
  selectCurrentCinemaHomeUrl,
} from '../../../store/Selectors';
import ActionButton from '../actionbutton/ActionButton';
import ContainedRow from '../layout/ContainedRow';
import RichText from '../richtext/RichText';

const RefundConfirmed: React.FC = () => {
  const content = useSelector(selectContent);
  const currentCinemaHomeUrl = useSelector(selectCurrentCinemaHomeUrl);

  return (
    <ContainedRow dataTestId='refund-confirmed'>
      <RichText text={content.refund.refundRequestedMessage} />
      <ActionButton
        onClick={() => (window.location.href = currentCinemaHomeUrl)}
        showIcon
        contained
        variant='primary'
      >
        {content.refund.backToWebsiteButtonText}
      </ActionButton>
    </ContainedRow>
  );
};

export default RefundConfirmed;
