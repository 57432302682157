import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { useSelector } from 'react-redux';

import {
  selectDevice,
  selectRecaptchaSiteKey,
  selectShouldUseRecaptcha,
  selectSource,
} from '../store/Selectors';

export interface RecaptchaContext {
  getRecaptchaToken: (
    action: string,
    executeRecaptcha: (action?: string) => Promise<string>
  ) => Promise<string | null>;
}

export interface ProviderProps {
  children: React.ReactNode;
}

const RecaptchaContextInstance = createContext<RecaptchaContext | null>(null);

export const RecaptchaProvider: React.FC<ProviderProps> = ({ children }) => {
  const shouldUseRecaptcha = useSelector(selectShouldUseRecaptcha);
  const recaptchaSiteKey = useSelector(selectRecaptchaSiteKey);
  const source = useSelector(selectSource);
  const device = useSelector(selectDevice);
  const [siteKey, setSiteKey] = useState('');
  const [useRecaptcha, setUseRecaptcha] = useState(false);

  useEffect(() => {
    if (recaptchaSiteKey && !siteKey) {
      setSiteKey(recaptchaSiteKey);
    }
  }, [recaptchaSiteKey, siteKey]);

  useEffect(() => {
    if (shouldUseRecaptcha && !useRecaptcha) {
      setUseRecaptcha(true);
    }
  }, [shouldUseRecaptcha, useRecaptcha]);

  const recaptchaEnabled = useRecaptcha && !!siteKey;

  const recaptchaContextInstance: RecaptchaContext = useMemo(
    () => ({
      getRecaptchaToken: async (
        action: string,
        executeRecaptcha: (action?: string | undefined) => Promise<string>
      ) => {
        if (!executeRecaptcha || !recaptchaEnabled) {
          return null;
        }

        let sourceDevice = source + device;
        if (
          sourceDevice !== 'web' &&
          sourceDevice !== 'appios' &&
          sourceDevice !== 'appandroid'
        ) {
          sourceDevice = 'web';
        }

        const recaptchaToken = await executeRecaptcha(
          action + '_' + sourceDevice
        );
        return recaptchaToken;
      },
    }),
    [device, recaptchaEnabled, source]
  );

  return (
    <RecaptchaContextInstance.Provider value={recaptchaContextInstance}>
      {recaptchaEnabled ? (
        <GoogleReCaptchaProvider
          reCaptchaKey={siteKey}
          language='en'
          useRecaptchaNet={false}
          useEnterprise={true}
          scriptProps={{
            async: false, // optional, default to false,
            defer: false, // optional, default to false
            appendTo: 'head', // optional, default to "head", can be "head" or "body",
            nonce: undefined, // optional, default undefined
          }}
        >
          <>{children}</>
        </GoogleReCaptchaProvider>
      ) : (
        <>{children}</>
      )}
    </RecaptchaContextInstance.Provider>
  );
};

export const useRecaptcha = () => {
  return useContext(RecaptchaContextInstance) ?? null;
};
