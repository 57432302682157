/** @jsxImportSource theme-ui */
import React, { useCallback, useEffect, useState } from 'react';

import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';

import backend from '../../../services/RestUtilities';
import {
  selectConfig,
  selectSelectedLanguageCulture,
} from '../../../store/Selectors';
import { ReactComponent as AddToAppleWalletEsSvg } from '../../../svgs/apple/addToAppleWalletEs.svg';
import { ReactComponent as AddToAppleWalletFrSvg } from '../../../svgs/apple/addToAppleWalletFr.svg';
import { ReactComponent as AddToAppleWalletUsUkSvg } from '../../../svgs/apple/addToAppleWalletUsUk.svg';
import ContainedRow from '../layout/ContainedRow';

const APPLEWALLET = 1;
const GOOGLEWALET = 2;

const isApplePaySession = window.ApplePaySession;

const getSvgForLocale = (culture: string) => {
  switch (culture) {
    case 'fr-FR':
      return <AddToAppleWalletFrSvg />;
    case 'es-ES':
    case 'es-MX':
      return <AddToAppleWalletEsSvg />;
    case 'en-US':
    case 'en-GB':
    default:
      return <AddToAppleWalletUsUkSvg />;
  }
};

interface Props {
  orderId: string | undefined;
}

const MobileWallets: React.FC<Props> = ({ orderId }) => {
  const config = useSelector(selectConfig);
  const selectedLanguageCulture = useSelector(selectSelectedLanguageCulture);

  const [googleWalletJwt, setGoogleWalletJwt] = useState();

  const fetchGpayWallet = useCallback(async () => {
    const response = await backend.get(
      `api/WalletPasses/Google/GetETicket/${config.circuitId}/${orderId}`
    );
    if (response.ok) {
      setGoogleWalletJwt(response.content);
    }
  }, [config, orderId]);

  useEffect(() => {
    if (
      config &&
      !isApplePaySession &&
      config.currentCinema?.mobileWallets?.includes(GOOGLEWALET)
    ) {
      fetchGpayWallet();
    }
  }, [config, fetchGpayWallet]);

  return (
    <>
      {config.currentCinema?.mobileWallets?.includes(APPLEWALLET) &&
        isApplePaySession && (
          <ContainedRow>
            <div
              className='apple-wallet-container'
              sx={{ mt: 5, textAlign: 'center' }}
            >
              <a
                href={`api/WalletPasses/Apple/GetETicket/${config.circuitId}/${orderId}`}
              >
                <div className='apple-wallet'>
                  {getSvgForLocale(selectedLanguageCulture)}
                </div>
              </a>
            </div>
          </ContainedRow>
        )}

      {!isApplePaySession && googleWalletJwt && (
        <ContainedRow>
          <div
            dangerouslySetInnerHTML={{
              __html: `<g:savetoandroidpay jwt="${googleWalletJwt}" theme="light"></g:savetoandroidpay>`,
            }}
            sx={{ mt: 5, textAlign: 'center' }}
          />
          <Helmet>
            <script
              src='https://apis.google.com/js/platform.js'
              type='text/javascript'
            ></script>
          </Helmet>
        </ContainedRow>
      )}
    </>
  );
};

export default MobileWallets;
