import React, { useEffect, useState } from 'react';

import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from 'theme-ui';

import CustomLoyaltyAmountInput from './CustomLoyaltyAmountInput';
import SplitLoyaltyRedemptionBreakDown from './SplitLoyaltyRedemptionBreakDown';

import { GlobalState } from '../../../../@types/modelTypes';
import { useRecaptcha } from '../../../../contextProviders/recaptchaContext';
import { useTurnstile } from '../../../../contextProviders/turnstileContext';
import { actionCreators } from '../../../../store/ActionCreators';
import {
  selectBookingData,
  selectConfig,
  selectContent,
  selectCustomer,
  selectDisplayPrice,
  selectIsCustomerReadyForPayment,
  selectGrandTotalAfterDiscountsInCents,
  selectLoyaltyRedemptionRewards,
  selectMaxPayableByLoyaltyInCents,
} from '../../../../store/Selectors';
import ActionButton from '../../actionbutton/ActionButton';
import RichText from '../../richtext/RichText';

interface Props {
  isPageValidated: boolean;
  handleValidatePage: () => void;
  setPaymentFormVisibility: (visible: boolean) => void;
  setGiftCardFormVisibility?: (visible: boolean) => void;
}

const SplitLoyaltyRedemption: React.FC<Props> = ({
  isPageValidated,
  handleValidatePage,
  setPaymentFormVisibility,
  setGiftCardFormVisibility,
}) => {
  const dispatch = useDispatch();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const recaptcha = useRecaptcha();
  const turnstile = useTurnstile();

  const bookingData = useSelector(selectBookingData);
  const config = useSelector(selectConfig);
  const content = useSelector(selectContent);
  const customer = useSelector(selectCustomer);
  const loyaltyRedemptionRewards = useSelector(selectLoyaltyRedemptionRewards);
  const maxPayableByLoyaltyInCents = useSelector(
    selectMaxPayableByLoyaltyInCents
  );
  const isCustomerReadyForPayment = useSelector(
    selectIsCustomerReadyForPayment
  );
  const grandTotal = useSelector(selectGrandTotalAfterDiscountsInCents);

  const initialLoyaltyAmount = Math.min(
    bookingData.loyaltyCardBalance,
    maxPayableByLoyaltyInCents
  );
  const [customLoyaltyAmount, setCustomLoyaltyAmount] = useState<string>(
    String((initialLoyaltyAmount / 100).toFixed(2))
  );
  const [isLoyaltyApplied, setIsLoyaltyApplied] = useState(false);

  const isCustomAmountForLoyaltyRedemptionEnabled =
    config.loyalty.enableCustomAmountLoyaltyRedemption;

  const customLoyaltyAmountInCents =
    customLoyaltyAmount && !isNaN(parseFloat(customLoyaltyAmount))
      ? Number((parseFloat(customLoyaltyAmount) * 100).toFixed(0))
      : 0;

  const loyaltyPaymentCoversFullAmount =
    customLoyaltyAmountInCents === grandTotal;

  const customLoyaltyAmountToDisplay = useSelector((state: GlobalState) =>
    selectDisplayPrice(state, customLoyaltyAmountInCents)
  );

  const maxPayableByLoyaltyInCentsToDisplay = useSelector(
    (state: GlobalState) =>
      selectDisplayPrice(state, maxPayableByLoyaltyInCents)
  );

  useEffect(() => {
    if (loyaltyRedemptionRewards === 0) {
      setIsLoyaltyApplied(false);
    }
  }, [loyaltyRedemptionRewards]);

  const submitButtonText = `${content.payment.submitText} ${maxPayableByLoyaltyInCentsToDisplay}`;

  const handleApplyLoyaltyRedemption = () => {
    dispatch(
      actionCreators.setLoyaltyRedemptionRewards(customLoyaltyAmountInCents)
    );
    setIsLoyaltyApplied(true);
    setPaymentFormVisibility(!loyaltyPaymentCoversFullAmount);
    !!setGiftCardFormVisibility &&
      setGiftCardFormVisibility(!loyaltyPaymentCoversFullAmount);
  };

  const handleLoyaltyPayment = async () => {
    if (!executeRecaptcha) return;

    dispatch(
      actionCreators.submitMakePayment({
        makePaymentModelOverrideProps: {
          payWithLoyaltyDollars: true,
          giftCards: [],
        },
        executeRecaptcha,
        turnstile,
        recaptcha,
      })
    );
  };

  const handleLoyaltyPaymentClick = () => {
    if (!isPageValidated) {
      handleValidatePage();
    }
    if (customer.isValid) {
      handleLoyaltyPayment();
    }
  };

  if (!content || !bookingData.isUserValidated) return null;

  return (
    <div className='loyalty-redemption' data-testid='loyalty-redemption'>
      {!loyaltyRedemptionRewards && (
        <>
          <RichText
            styles={{ my: 2 }}
            text={content.payment.customLoyaltyRichText}
          />
          <RichText
            styles={{ my: 2, textAlign: 'center' }}
            text={content.payment.loyaltyRedemptionRichText}
          />
          {isCustomAmountForLoyaltyRedemptionEnabled && (
            <CustomLoyaltyAmountInput
              customAmount={customLoyaltyAmount}
              setCustomAmount={setCustomLoyaltyAmount}
              grandTotal={maxPayableByLoyaltyInCents}
            />
          )}

          {bookingData.loyaltyCardBalance > 0 ? (
            <ActionButton
              onClick={handleApplyLoyaltyRedemption}
              disabled={isLoyaltyApplied || customLoyaltyAmountInCents === 0}
              data-testid={'loyalty-apply-btn'}
              mt={6}
              mb={0}
              showIcon
              variant='primary'
            >
              {content.payment.loyaltyRedemptionButtonText.replace(
                '##LoyaltyCreditApplied##',
                customLoyaltyAmountInCents > 0
                  ? customLoyaltyAmountToDisplay
                  : ''
              )}
            </ActionButton>
          ) : (
            <Box className='info-container' sx={{ mt: 5, p: 5 }}>
              <p>{content.payment.loyaltyRedemptionNotEnoughBalanceText}</p>
            </Box>
          )}
        </>
      )}

      {!!loyaltyRedemptionRewards && (
        <SplitLoyaltyRedemptionBreakDown
          loyaltyPaymentCoversFullAmount={loyaltyPaymentCoversFullAmount}
          setPaymentFormVisibility={setPaymentFormVisibility}
          setGiftCardFormVisibility={setGiftCardFormVisibility}
        />
      )}

      {!!loyaltyRedemptionRewards && loyaltyPaymentCoversFullAmount && (
        <ActionButton
          onClick={handleLoyaltyPaymentClick}
          showIcon
          warningMessage={content.payment.formErrorsMessage}
          warningTitle={content.payment.formErrorsSubTitle}
          showWarningMessage={isPageValidated && !isCustomerReadyForPayment}
          disabled={isPageValidated && !isCustomerReadyForPayment}
          mb={0}
          variant='primary'
        >
          {submitButtonText}
        </ActionButton>
      )}
    </div>
  );
};

export default SplitLoyaltyRedemption;
