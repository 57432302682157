import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import * as JourneyService from '../../services/JourneyService';
import { actionCreators } from '../../store/ActionCreators';
import { selectJourneyTypeConfig, selectStep } from '../../store/Selectors';

export const useStepUpdater = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const journeyTypeConfig = useSelector(selectJourneyTypeConfig);
  const step = useSelector(selectStep);

  useEffect(() => {
    if (journeyTypeConfig && pathname) {
      const newStep = JourneyService.getCurrentStepNumber(
        journeyTypeConfig,
        pathname
      );
      if (!isNaN(newStep) && newStep !== step) {
        dispatch(actionCreators.setStep(newStep));
        if (window.scrollY !== 0) {
          window.scrollTo(0, 0);
        }
      }
    }
  }, [dispatch, journeyTypeConfig, pathname, step]);
};
