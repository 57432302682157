/** @jsxImportSource theme-ui */
import React from 'react';

import { Accordion } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { PaymentProvidersEnum } from '../../../../../../@types/enums';
import { useGetPaymentProvider } from '../../../../../../hooks/useGetPaymentProvider';
import {
  selectCountdownExpired,
  selectHostedPayments,
} from '../../../../../../store/Selectors';

const iframeHeightInclError = 360;

const VantivIframe: React.FC = () => {
  const countdownExpired = useSelector(selectCountdownExpired);
  const hostedPayments = useSelector(selectHostedPayments);

  const hostedPayment = hostedPayments[PaymentProvidersEnum.VANTIVHPIFRAME];
  const showExternalPaymentForm = hostedPayment !== null && !countdownExpired;

  useGetPaymentProvider(PaymentProvidersEnum.VANTIVHPIFRAME);

  return (
    <div className='hosted-payment' data-testid='vantiv-hosted-payment'>
      {!!hostedPayment && (
        <Accordion.Collapse
          data-testid='vantiv-payment-iframe'
          eventKey='0'
          in={showExternalPaymentForm}
          className='vantiv-iframe'
        >
          <div sx={{ p: 4 }}>
            <iframe
              src={`${hostedPayment.actionUrl}?TransactionSetupID=${hostedPayment.transactionId}`}
              width='100%'
              scrolling='auto'
              frameBorder='0'
              style={{
                minHeight: `${iframeHeightInclError}px`,
              }}
              title='WorldPay'
            />
          </div>
        </Accordion.Collapse>
      )}
    </div>
  );
};

export default VantivIframe;
