import React from 'react';

import { useSelector } from 'react-redux';

import { selectCartSummary } from '../../../store/Selectors';
import { ReactComponent as NoPosterSvg } from '../../../svgs/noPoster.svg';

const CartSummaryFilmInfoPoster: React.FC = () => {
  const cartSummary = useSelector(selectCartSummary);

  return (
    <div className='poster'>
      {cartSummary?.posterUrl ? (
        <img
          src={`${cartSummary.posterUrl}?width=200`}
          alt={cartSummary.title}
          data-testid='cs-poster'
        />
      ) : (
        <NoPosterSvg className='no-poster' data-testid='cs-no-poster' />
      )}
    </div>
  );
};

export default CartSummaryFilmInfoPoster;
