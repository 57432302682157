/* eslint-disable @typescript-eslint/no-explicit-any */
import 'whatwg-fetch';

import { NullableString } from '../@types/modelTypes';

const testIdKey = 'testId';
const testCaptureIdKey = 'testCaptureId';

export default class RestUtilities {
  static get(path: string) {
    return RestUtilities.request('GET', path);
  }

  static delete(path: string) {
    return RestUtilities.request('DELETE', path);
  }

  static put(path: string, data: any) {
    return RestUtilities.request('PUT', path, data);
  }

  static post(path: string, data: any, turnstileToken?: NullableString) {
    return RestUtilities.request('POST', path, data, undefined, turnstileToken);
  }

  static postFormData(path: string, data: any) {
    return RestUtilities.request('POST', path, data, true);
  }

  static request(
    method: string,
    path: string,
    data?: any,
    isFormData?: boolean,
    turnstileToken?: NullableString
  ) {
    let ok = false;
    let body = data;
    let statusCode = 200;
    let statusText = '';
    const testId = window.sessionStorage.getItem(testIdKey);
    const testCaptureId = window.sessionStorage.getItem(testCaptureIdKey);
    const headers = new Headers();

    headers.set('Accept', 'application/json');

    turnstileToken && headers.set('Turnstile-Token', turnstileToken);
    testId && headers.set(testIdKey, testId);
    testCaptureId && headers.set(testCaptureIdKey, testCaptureId);

    if (!isFormData) {
      headers.set('Content-Type', 'application/json');
      body = JSON.stringify(data);
    } else {
      //headers.set('Content-Type','multipart/form-data');
      body = data;
    }

    return fetch(path, { method, headers, body })
      .then((response) => {
        ok = response.ok;
        statusCode = response.status;
        statusText = response.statusText;
        const responseContentType = response.headers.get('content-type');
        if (
          responseContentType &&
          responseContentType.indexOf('application/json') !== -1
        ) {
          return response.json();
        }
        return response.text();
      })
      .then((responseContent) => {
        const response = {
          ok: ok,
          hasModelErrors: statusCode === 400,
          content: ok ? responseContent : null,
          errorContent: ok ? null : responseContent,
          status: statusCode,
          statusText: statusText,
        };
        return response;
      });
  }
}
