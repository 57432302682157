/** @jsxImportSource theme-ui */
import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Button, Flex } from 'theme-ui';

import { actionCreators } from '../../../store/ActionCreators';
import { selectGrandTotalAfterDiscountsInCents } from '../../../store/Selectors';
import { ReactComponent as CartEmpty } from '../../../svgs/cartEmpty.svg';
import { ReactComponent as CartFull } from '../../../svgs/cartFull.svg';

interface Props {
  variant: string;
}

const ActionButtonCartSummary: React.FC<Props> = ({ variant }) => {
  const dispatch = useDispatch();

  const grandTotalToUse = useSelector(selectGrandTotalAfterDiscountsInCents);

  return (
    <Flex sx={{ mr: 3, flexShrink: 0 }}>
      <Button
        data-testid='action-button'
        className='action-button cart-summary-button'
        onClick={(e) => {
          dispatch(actionCreators.setCartSummaryOpen(true));
          e.currentTarget.blur();
        }}
        type='button'
        variant={variant}
      >
        {grandTotalToUse ? <CartFull /> : <CartEmpty />}
      </Button>
    </Flex>
  );
};

export default ActionButtonCartSummary;
