/** @jsxImportSource theme-ui */
import React, { useState } from 'react';

import { Accordion } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import MultiOptionGiftCardsRow from './MultiOptionGiftCardsRow';

import { ContentGiftCards } from '../../../../../@types/contentTypes';
import { Concession } from '../../../../../@types/modelTypes';
import {
  selectContent,
  selectSelectedGiftCards,
} from '../../../../../store/Selectors';
import { ReactComponent as ChevronDownSvg } from '../../../../../svgs/chevronDown.svg';

interface GiftCardsRowProps {
  selectedConcessionIndex: number;
}

const CollapsibleGiftCardsRow: React.FC<GiftCardsRowProps> = ({
  selectedConcessionIndex,
}) => {
  const selectedGiftCards = useSelector(selectSelectedGiftCards);
  const content = useSelector(selectContent);

  const selectedGiftCard: Concession | undefined =
    selectedGiftCards.list.length > 0
      ? selectedGiftCards.list[selectedConcessionIndex]
      : undefined;

  const [showRow, setShowRow] = useState(
    (selectedGiftCard && selectedGiftCard.quantity > 0) ||
      selectedConcessionIndex === 0
  );

  const giftCardContent: ContentGiftCards = content.giftCards;
  const rowIsHidden: boolean | undefined = selectedGiftCard?.hidden;

  return (
    <>
      <Accordion.Collapse eventKey='0' in={showRow && !rowIsHidden}>
        <MultiOptionGiftCardsRow
          selectedConcessionIndex={selectedConcessionIndex}
          key={`giftcardrow_${selectedConcessionIndex}`}
        />
      </Accordion.Collapse>
      <Accordion.Collapse eventKey='1' in={!showRow && !rowIsHidden}>
        <div className='add-giftcard-button-row'>
          <button
            className='add-giftcard-button'
            type='button'
            onClick={() => {
              setShowRow(true);
            }}
            sx={{
              textAlign: 'left',
              backgroundColor: 'sidebarBackground',
              color: 'mostReadableOnWebsiteBackground',
              py: 2,
              px: 6,
              '.icon-arrow': {
                '.a': {
                  fill: 'mostReadableOnWebsiteBackground',
                },
              },
            }}
          >
            <span sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}>
              {giftCardContent.addAnotherText}{' '}
            </span>
            <span>{giftCardContent.addAnotherSubText}</span>

            <ChevronDownSvg className='icon-arrow float-end' />
          </button>
        </div>
      </Accordion.Collapse>
    </>
  );
};

export default CollapsibleGiftCardsRow;
