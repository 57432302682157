import React from 'react';

import { useSelector } from 'react-redux';
import { Box } from 'theme-ui';

import { selectBookingData, selectContent } from '../../../store/Selectors';

const HeaderLogin: React.FC = () => {
  const bookingData = useSelector(selectBookingData);
  const content = useSelector(selectContent);

  if (!(bookingData && bookingData.isUserValidated)) return null;

  return (
    <Box className='banner' data-testid='header-login' py={3}>
      <span>{content.signIn.signedInLabel} </span>
      <span>{bookingData.loyaltyEmailAddress}</span>
    </Box>
  );
};

export default HeaderLogin;
