/** @jsxImportSource theme-ui */
import React, { useState, useEffect, useCallback, createElement } from 'react';

import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useSelector, useDispatch } from 'react-redux';
import { isLength } from 'validator';

import PayrixPaymentForm from './PayrixPaymentForm';
import {
  getPayFieldForError,
  GetPayrixPayFieldCustomizations,
  GetPayrixPayFieldFieldArray,
  getValidationPair,
  getValidationUpdateForPayFieldError,
  getValidationPairWithValue,
  IsPayrixApplePaySaleResponseData,
  IsPayrixNewCardTokenResponseData,
} from './PayrixPaymentHelpers';
import {
  PayrixMakePaymentInput,
  PayrixPayField,
  PayrixPaymentState,
  PayrixPaymentStateValidationPair,
  PayrixResponse,
  PayrixSaleResponseData,
  PayrixTokenResponseData,
} from './PayrixPaymentTypes';

import { PaymentProvidersEnum } from '../../../../../../@types/enums';
import { IntializePaymentProviderResponse } from '../../../../../../@types/modelTypes';
import { useRecaptcha } from '../../../../../../contextProviders/recaptchaContext';
import { useTurnstile } from '../../../../../../contextProviders/turnstileContext';
import {
  loadPayrixApplePay,
  loadPayrixPayFields,
  queryPayrixApplePay,
} from '../../../../../../scripts/loadPayrix';
import { displayPriceWithCurrencyCode } from '../../../../../../services/Helpers';
import { actionCreators } from '../../../../../../store/ActionCreators';
import {
  selectBankCardAmount,
  selectConfig,
  selectContent,
  selectLoyaltyRecognitionNumber,
  selectPayWithStoredCardEnabled,
  getProductTax,
  selectCurrencyConfig,
} from '../../../../../../store/Selectors';
import ActionButton from '../../../../actionbutton/ActionButton';
import StoredCardPayment from '../../storedcard/StoredCardPayment';

interface Props {
  handleValidatePage: () => void;
  isPageValidated?: boolean;
  initializePaymentProviderResponse?: IntializePaymentProviderResponse;
}

const PayrixPayment: React.FC<Props> = ({
  isPageValidated = false,
  initializePaymentProviderResponse,
  handleValidatePage,
}) => {
  const dispatch = useDispatch();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const recaptcha = useRecaptcha();
  const turnstile = useTurnstile();

  const config = useSelector(selectConfig);
  const bankCardAmount = useSelector(selectBankCardAmount);
  const content = useSelector(selectContent);
  const loyaltyRecognitionNumber = useSelector(selectLoyaltyRecognitionNumber);
  const currencyConfig = useSelector(selectCurrencyConfig);
  const productTax: number = useSelector(getProductTax);
  const payWithStoredCardEnabled = useSelector(selectPayWithStoredCardEnabled);

  const [payrixResponse, setPayrixResponse] = useState<PayrixResponse>();
  const [payrixMakePaymentInput, setPayrixMakePaymentInput] =
    useState<PayrixMakePaymentInput>();
  const [shouldStoreCardToken, setShouldStoreCardToken] =
    useState<boolean>(false);
  const [
    selectedStoredCardTokenIdentifier,
    setSelectedStoredCardTokenIdentifier,
  ] = useState<string | null>(null);
  const [paymentStarted, setPaymentStarted] = useState(false);

  const getInitialPayrixPaymentState: () => PayrixPaymentState = () => ({
    cardNumber: getValidationPair(false, false),
    nameOnCard: getValidationPair(false, false),
    expiryDate: getValidationPair(false, false),
    cvvCode: getValidationPair(false, false),
    zipCode: getValidationPairWithValue(false, false, ''),
  });

  const [paymentState, setPaymentState] = useState<PayrixPaymentState>(
    getInitialPayrixPaymentState
  );

  const [fieldsLoaded, setFieldsLoaded] = useState<boolean>(false);
  const [applePayLoaded, setApplePayLoaded] = useState<boolean>(false);

  const newCardPaymentFields = Object.values(
    paymentState
  ) as PayrixPaymentStateValidationPair[];

  const isNewCardPaymentFieldsValidated = newCardPaymentFields.every(
    (x) => x.isValidated
  );

  const isNewCardPaymentFieldsValid = newCardPaymentFields.every(
    (x) => x.isValid
  );

  const isStoredCardPaymentFieldsValid =
    selectedStoredCardTokenIdentifier !== null;

  const isFullFormValid =
    isNewCardPaymentFieldsValid || isStoredCardPaymentFieldsValid;

  const showWarningMessage =
    isNewCardPaymentFieldsValidated &&
    !isNewCardPaymentFieldsValid &&
    !isStoredCardPaymentFieldsValid;

  const setupPayrixPayFields = useCallback(() => {
    if (!initializePaymentProviderResponse) return;

    const [publicApiKey, merchantId] =
      initializePaymentProviderResponse.PublishKey.split(';');

    window.PayFields.config.apiKey = publicApiKey;
    window.PayFields.config.merchant = merchantId;
    window.PayFields.config.order = initializePaymentProviderResponse.OrderId;
    window.PayFields.config.mode = 'token';
    window.PayFields.config.txnType = 'auth';
    window.PayFields.config.amount = bankCardAmount;
    window.PayFields.config.tax = productTax;

    window.PayFields.fields = GetPayrixPayFieldFieldArray();

    window.PayFields.customizations = GetPayrixPayFieldCustomizations();

    window.PayFields.onSuccess = function (response: PayrixResponse) {
      setPayrixResponse(response);
    };

    window.PayFields.onFailure = function (response: PayrixResponse) {
      if (!response) return;

      for (const error of response.errors) {
        const getValidationUpdate = (
          thisField: PayrixPayField['type'],
          pair: PayrixPaymentStateValidationPair
        ) => {
          const field = getPayFieldForError(error.field);
          return getValidationUpdateForPayFieldError(field, thisField, pair);
        };

        setPaymentState((prevState) => ({
          ...prevState,
          cardNumber: getValidationUpdate('number', prevState.cardNumber),
          nameOnCard: getValidationUpdate('name', prevState.nameOnCard),
          cvvCode: getValidationUpdate('cvv', prevState.cvvCode),
          expiryDate: getValidationUpdate('expiration', prevState.expiryDate),
          zipCode: { ...prevState.zipCode, isValidated: true },
        }));
      }
    };

    window.PayFields.onValidationFailure = function () {
      const invalidField = window.PayFields.fields[window.PayFields.count];

      if (!invalidField) return;

      const getValidationUpdate = (
        thisField: PayrixPayField['type'],
        pair: PayrixPaymentStateValidationPair
      ) => {
        return getValidationUpdateForPayFieldError(
          invalidField.type,
          thisField,
          pair
        );
      };

      setPaymentState((prevState) => ({
        ...prevState,
        cardNumber: getValidationUpdate('number', prevState.cardNumber),
        nameOnCard: getValidationUpdate('name', prevState.nameOnCard),
        cvvCode: getValidationUpdate('cvv', prevState.cvvCode),
        expiryDate: getValidationUpdate('expiration', prevState.expiryDate),
        zipCode: { ...prevState.zipCode, isValidated: true },
      }));
    };

    window.PayFields.appendIframe();
    window.PayFields.clearFields = () => {
      return;
    };
  }, [bankCardAmount, initializePaymentProviderResponse, productTax]);

  // Handles the payment action button.
  // Is responsible for both Stored Card payment and New Card payment.
  // Is not responsible for Apple Pay payment.
  const handlePaymentClick = () => {
    if (!isPageValidated) {
      handleValidatePage();
    }

    setPayrixMakePaymentInput(undefined);
    setPayrixResponse(undefined);

    if (selectedStoredCardTokenIdentifier) {
      if (isStoredCardPaymentFieldsValid) {
        const savedPaymentInput: PayrixMakePaymentInput = {
          completedTransactionIdentifier: null,
          cardToken: null,
          cardTokenIdentifier: selectedStoredCardTokenIdentifier,
          cardHolderFullName: null,
          cardHolderZipCode: null,
        };
        setPayrixMakePaymentInput(savedPaymentInput);
      }
    } else {
      setPaymentState((prevState) => ({
        ...prevState,
        cardNumber: getValidationPair(true, true),
        nameOnCard: getValidationPair(true, true),
        expiryDate: getValidationPair(true, true),
        cvvCode: getValidationPair(true, true),
        zipCode: { ...prevState.zipCode, isValidated: true },
      }));

      window.PayFields.submit();
    }
  };

  const makePaymentCallBack = useCallback(() => {
    setPaymentStarted(false);
    setPayrixMakePaymentInput(undefined);
    setPayrixResponse(undefined);
    setPaymentState(getInitialPayrixPaymentState);
  }, []);

  const makePayment = useCallback(
    async (payrixMakePaymentInput: PayrixMakePaymentInput) => {
      if (!executeRecaptcha) return;
      dispatch(
        actionCreators.submitMakePayment({
          executeRecaptcha,
          callBackFunction: makePaymentCallBack,
          makePaymentModelOverrideProps: {
            nameOnCard: payrixMakePaymentInput.cardHolderFullName,
            cardToken: payrixMakePaymentInput.cardToken,
            storedCardTokenIdentifier:
              payrixMakePaymentInput.cardTokenIdentifier,
            shouldStoreCardToken: selectedStoredCardTokenIdentifier
              ? false
              : shouldStoreCardToken,
            billingPostal: payrixMakePaymentInput.cardHolderZipCode,
            loyaltyCardNumber: loyaltyRecognitionNumber,
            paymentProvider: PaymentProvidersEnum.PAYRIX,
            completedPaymentProviderTransactionIdentifier:
              payrixMakePaymentInput.completedTransactionIdentifier,
          },
          turnstile,
          recaptcha,
        })
      );
    },
    [
      executeRecaptcha,
      dispatch,
      makePaymentCallBack,
      selectedStoredCardTokenIdentifier,
      shouldStoreCardToken,
      loyaltyRecognitionNumber,
      turnstile,
      recaptcha,
    ]
  );

  // Updates the PayFields configuration when the bank card amount or product tax amount changes.
  useEffect(() => {
    if (fieldsLoaded || applePayLoaded) {
      if (window.PayFields?.config) {
        window.PayFields.config.amount = bankCardAmount;
        window.PayFields.config.tax = productTax;
      }
    }
  }, [applePayLoaded, bankCardAmount, fieldsLoaded, productTax]);

  // Attempts to load payfields.
  useEffect(() => {
    if (!initializePaymentProviderResponse || fieldsLoaded) return;

    loadPayrixPayFields(initializePaymentProviderResponse.APIURL, () => {
      setupPayrixPayFields();
      setFieldsLoaded(true);
    });
  }, [fieldsLoaded, initializePaymentProviderResponse, setupPayrixPayFields]);

  // Attempts to load Apple Pay.
  useEffect(() => {
    if (!fieldsLoaded || applePayLoaded) {
      return;
    }

    if (queryPayrixApplePay()) {
      // Apple Pay can only be initialized once.
      // If it exists but Redux has "forgotten", update Redux.
      // It cannot be destroyed & re-registered with Payrix.
      setApplePayLoaded(true);
      return;
    }

    loadPayrixApplePay(() => {
      window.PayFields.addWalletButtons();
      setApplePayLoaded(true);
    });
  }, [applePayLoaded, config.payment.showApplePay, fieldsLoaded]);

  // Sets MakePaymentInput from the Payrix Response, which will then be passed into the MakePayment override props.
  // Set by the OnSuccess Payrix submit action callback (New Card and Apple Pay),
  useEffect(() => {
    if (payrixResponse) {
      setPayrixMakePaymentInput(undefined);
      setPayrixResponse(undefined);

      const payrixResponseData = payrixResponse.data[0];

      const createApplePayMakePaymentInput = (
        payrixResponseData: PayrixSaleResponseData
      ) => {
        return {
          completedTransactionIdentifier: payrixResponseData.id,
          cardToken: null,
          cardTokenIdentifier: null,
          cardHolderFullName: `${payrixResponseData.first} ${payrixResponseData.last}`,
          cardHolderZipCode: paymentState.zipCode.value,
        } as PayrixMakePaymentInput;
      };

      const createNewCardTokenMakePaymentInput = (
        payrixResponseData: PayrixTokenResponseData
      ) => {
        return {
          completedTransactionIdentifier: null,
          cardToken: payrixResponseData.token,
          cardTokenIdentifier: null,
          cardHolderFullName: `${payrixResponseData.customer.first} ${payrixResponseData.customer.last}`,
          cardHolderZipCode: paymentState.zipCode.value,
        } as PayrixMakePaymentInput;
      };

      if (IsPayrixApplePaySaleResponseData(payrixResponseData)) {
        const input = createApplePayMakePaymentInput(payrixResponseData);
        setPayrixMakePaymentInput(input);
      } else if (
        isNewCardPaymentFieldsValidated &&
        isNewCardPaymentFieldsValid &&
        IsPayrixNewCardTokenResponseData(payrixResponseData)
      ) {
        const input = createNewCardTokenMakePaymentInput(payrixResponseData);
        setPayrixMakePaymentInput(input);
      }
    }
  }, [
    isNewCardPaymentFieldsValid,
    isNewCardPaymentFieldsValidated,
    paymentState.zipCode.value,
    payrixResponse,
  ]);

  // Initiates the payment (MakePayment) using the MakePayment input data.
  // Set by the PayrixResponse effect above
  // Set by the Stored Card payment action.
  useEffect(() => {
    if (
      payrixMakePaymentInput &&
      !paymentStarted &&
      (isFullFormValid || payrixMakePaymentInput.completedTransactionIdentifier)
    ) {
      setPaymentStarted(true);
      makePayment(payrixMakePaymentInput);
    }
  }, [payrixMakePaymentInput, makePayment, isFullFormValid, paymentStarted]);

  const handleZipCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newZipCode = e.currentTarget.value;
    const newZipCodeIsValid = isLength(newZipCode, { min: 1, max: 8 });
    setPaymentState((prevState) => ({
      ...prevState,
      zipCode: getValidationPairWithValue(newZipCodeIsValid, true, newZipCode),
    }));
  };

  const applePayButton = createElement(
    'apple-pay-button',
    {
      buttonstyle: 'white-outline',
      type: 'buy',
      locale: 'en-GB',
      style: { display: 'block' },
    },
    null
  );

  return (
    <>
      {config?.payment?.showApplePay && (
        <div sx={{ mb: 4 }}>
          <div id='apple-pay-container'>{applePayButton}</div>
        </div>
      )}
      {!!initializePaymentProviderResponse && (
        <div>
          {payWithStoredCardEnabled ? (
            <StoredCardPayment
              setSelectedStoredCardTokenIdentifier={
                setSelectedStoredCardTokenIdentifier
              }
              shouldStoreCardToken={shouldStoreCardToken}
              setShouldStoreCardToken={setShouldStoreCardToken}
              paymentFormHasValidationError={
                !selectedStoredCardTokenIdentifier &&
                (isNewCardPaymentFieldsValidated ||
                  paymentState.zipCode.isValidated) &&
                (!isNewCardPaymentFieldsValid || !paymentState.zipCode.isValid)
              }
              paymentProvider={PaymentProvidersEnum.PAYRIX}
            >
              <PayrixPaymentForm
                paymentState={paymentState}
                setPaymentState={setPaymentState}
                handleZipCodeChange={handleZipCodeChange}
              />
            </StoredCardPayment>
          ) : (
            <PayrixPaymentForm
              paymentState={paymentState}
              setPaymentState={setPaymentState}
              handleZipCodeChange={handleZipCodeChange}
            />
          )}

          <ActionButton
            onClick={handlePaymentClick}
            disabled={paymentStarted}
            showIcon
            warningMessage={content.payment.formErrorsMessage}
            warningTitle={content.payment.formErrorsSubTitle}
            showWarningMessage={showWarningMessage}
            mb={0}
            variant='primary'
          >
            {`${content.payment.submitText} ${displayPriceWithCurrencyCode(
              bankCardAmount,
              currencyConfig
            )}`}
          </ActionButton>
        </div>
      )}
    </>
  );
};

export default PayrixPayment;
