import React from 'react';

import { useSelector } from 'react-redux';

import { selectDazzlerConfig } from '../../store/Selectors';

interface Props {
  children: React.ReactNode;
  step: string;
}

const LegacyWrapper: React.FC<Props> = ({ children, step }) => {
  const dazzlerConfig = useSelector(selectDazzlerConfig);
  if (dazzlerConfig?.dazzlerSteps?.includes(step)) return null;
  return <>{children}</>;
};

export default LegacyWrapper;
