import React, { useEffect, useState } from 'react';

import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { actionCreators } from '../../../store/ActionCreators';
import {
  selectContent,
  selectCountdownEndTime,
} from '../../../store/Selectors';

let timeoutHandle: NodeJS.Timeout;

const Countdown: React.FC = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const content = useSelector(selectContent);
  const countdownEndTime = useSelector(selectCountdownEndTime);

  const [seconds, setSeconds] = useState<string>('');
  const [minutes, setMinutes] = useState<string>('');

  const shouldShowCountdown = !(
    pathname.startsWith('/confirmation/') ||
    pathname.startsWith('/refund/') ||
    pathname.startsWith('/error')
  );

  useEffect(() => {
    function tick() {
      const endTime: moment.Moment = moment(countdownEndTime);
      const duration: moment.Duration = moment.duration(endTime.diff(moment()));
      const remainingSeconds: number = Math.floor(duration.asSeconds());
      const mins: number = Math.floor(remainingSeconds / 60);
      const secs: number = remainingSeconds - mins * 60;
      if (remainingSeconds > 0) {
        setSeconds(String(secs).padStart(2, '0'));
        setMinutes(String(mins));
        timeoutHandle = setTimeout(tick, 1000);
      } else if (shouldShowCountdown) {
        dispatch(actionCreators.setCountDownExpired(true));
      }
    }

    if (countdownEndTime) {
      timeoutHandle = setTimeout(tick, 1000);
    }

    return () => {
      clearTimeout(timeoutHandle);
    };
  }, [countdownEndTime, dispatch, shouldShowCountdown]);

  return (
    <>
      {countdownEndTime && shouldShowCountdown && (
        <div className='countdown-container' data-testid='countdown'>
          <div className='countdown-label'>{content.countDownLabel}</div>
          <div className='countdown-time' data-testid='countdown-display'>
            {minutes}:{seconds}
          </div>
        </div>
      )}
    </>
  );
};

export default Countdown;
