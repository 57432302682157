/** @jsxImportSource theme-ui */
import React from 'react';

import { useSelector } from 'react-redux';
import { Box } from 'theme-ui';

import PaymentIconsContainer from './paymenticons/PaymentIconsContainer';

import { PaymentProvidersEnum } from '../../../../../@types/enums';
import { selectContent } from '../../../../../store/Selectors';
import { ReactComponent as AmazonPaySVG } from '../../../../../svgs/payment/amazonPayLogo.svg';

interface Props {
  paymentProvider: PaymentProvidersEnum;
  creditCardType: string;
  hasOnePaymentOption: boolean;
}

const CreditCardPaymentsHeadingContent: React.FC<Props> = ({
  paymentProvider,
  creditCardType,
  hasOnePaymentOption,
}) => {
  const content = useSelector(selectContent);

  const getCardIcons = () => {
    return paymentProvider === PaymentProvidersEnum.AMAZONPAY ? (
      <AmazonPaySVG
        className='amazon-pay-icon'
        sx={{ display: 'flex', alignItems: 'center' }}
      />
    ) : (
      <div
        className='payment-icons-container'
        sx={{ display: 'flex', alignItems: 'center', flexShrink: 1 }}
      >
        <PaymentIconsContainer
          paymentDesignName='split'
          creditCardType={creditCardType}
        />
      </div>
    );
  };

  const getPaymentNameText = () => {
    return paymentProvider === PaymentProvidersEnum.AMAZONPAY
      ? 'Amazon Pay'
      : content.payment.multiplePaymentProvidersCardLabel;
  };
  return (
    <>
      {!hasOnePaymentOption && (
        <Box
          sx={{
            fontWeight: 'bold',
            textAlign: 'left',
            textTransform: 'uppercase',
            flexGrow: 1,
            mr: 1,
            position: 'relative',
            top: 0,
          }}
        >
          {getPaymentNameText()}
        </Box>
      )}
      {getCardIcons()}
    </>
  );
};

export default CreditCardPaymentsHeadingContent;
