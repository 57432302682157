/** @jsxImportSource theme-ui */
import React from 'react';

import classNames from 'classnames';
import { useIntl } from 'react-intl';
import { Flex } from 'theme-ui';

import messages from './intl';

import { resolveSvg } from '../../common/seats/SeatSvgResolver';

interface Props {
  label: string;
  svgType: string;
  svgClassName: string;
  svgSource?: string;
}

const LegendItem: React.FC<Props> = ({
  label: title,
  svgType,
  svgClassName,
  svgSource,
}) => {
  const { formatMessage } = useIntl();

  return (
    <Flex sx={{ alignItems: 'center' }}>
      {resolveSvg(
        svgType,
        classNames(svgClassName, 'legend-icon'),
        title,
        svgSource
      )}
      <span sx={{ ml: 3 }}>
        {title}
        <span className='visually-hidden'>
          {' '}
          {formatMessage(messages.titleSeatText)}
        </span>
      </span>
    </Flex>
  );
};

export default LegendItem;
