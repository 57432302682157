import React, { useState } from 'react';

import classNames from 'classnames';
import { Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from 'theme-ui';

import VoucherTicketType from './VoucherTicketType';

import { QuantitySelectorContext } from '../../../@types/actionTypes';
import {
  GroupedTicketTypes,
  TicketTypeModel,
} from '../../../@types/modelTypes';
import { CommonTrackingEvent } from '../../../@types/trackingTypes';
import { useAnalytics } from '../../../analytics/analyticsContext';
import { PEACH_CODES } from '../../../constants';
import { useValidateThirdPartyVoucherCode } from '../../../hooks/apiCalls/useValidateThirdPartyVoucherCode';
import { actionCreators } from '../../../store/ActionCreators';
import {
  selectAppliedCeaCards,
  selectConfig,
  selectContent,
  selectIsSeatsFirstJourney,
  selectJourneyTypeConfig,
  selectSelectedSeats,
  selectThirdPartyMembershipNames,
  selectTicketTypes,
} from '../../../store/Selectors';
import ActionButton from '../actionbutton/ActionButton';
import ActionButtonSpinner from '../actionbuttonspinner/ActionButtonSpinner';
import BorderedCollapse from '../borderedcollapse/BorderedCollapse';

interface Props {
  orderHasMaxTickets: boolean;
}

const CeaThirdPartyVoucherSelector: React.FC<Props> = ({
  orderHasMaxTickets,
}) => {
  const dispatch = useDispatch();
  const analytics = useAnalytics();
  const {
    tickets: ticketsContent,
    payment: paymentContent,
    checkButtonText,
  } = useSelector(selectContent);
  const ticketTypes = useSelector(selectTicketTypes);
  const appliedCeaCards = useSelector(selectAppliedCeaCards);
  const isSeatsFirstJourney = useSelector(selectIsSeatsFirstJourney);
  const config = useSelector(selectConfig);
  const selectedSeats = useSelector(selectSelectedSeats);
  const journeyTypeConfig = useSelector(selectJourneyTypeConfig);
  const thirdPartyMembershipNames = useSelector(
    selectThirdPartyMembershipNames
  );

  const [showCeaCardForm, setShowCeaCardForm] = useState(
    !!appliedCeaCards.length
  );
  const [disableApplyButton, setDisableApplyButton] = useState(false);
  const [ceaCardInput, setCeaCardInput] = useState('');
  const [feedback, setFeedback] = useState<string | undefined>(undefined);

  const validateThirdPartyVoucherCode = useValidateThirdPartyVoucherCode();

  const ceaThirdPartyTicketName = config.tickets.ceaThirdPartyTicketName;
  const isSeatsFirst = journeyTypeConfig.isSeatsFirst;

  if (!ceaThirdPartyTicketName || !ticketTypes) return null;

  const hasCeaThirdPartyMembershipName = !!thirdPartyMembershipNames?.includes(
    ceaThirdPartyTicketName
  );

  if (!hasCeaThirdPartyMembershipName) return null;

  const ceaTicketTypes = ticketTypes.ticketTypeModels.filter(
    (t) => t.thirdPartyTicketsName === ceaThirdPartyTicketName
  );

  const updateTicketTypes = (ticketTypeModels: TicketTypeModel[]) => {
    dispatch(actionCreators.setTicketTypes({ ticketTypeModels }));
  };

  const validateCode = async () => {
    const response = await validateThirdPartyVoucherCode({
      voucherCode: ceaCardInput,
      thirdPartyTicketsName: config.tickets.ceaThirdPartyTicketName,
    });

    const { ceaCardErrorText, ceaCardExpiredText } = ticketsContent;

    if (response?.ok) {
      const responseContent = response.content;

      if (responseContent.peachCode === PEACH_CODES.noError) {
        const voucherTicketGroups: GroupedTicketTypes[] =
          response.content.groupedTicketTypes;
        const ticketTypeModels = ticketTypes.ticketTypeModels;

        voucherTicketGroups.forEach((gpt) => {
          gpt.ticketTypeModels.forEach((t) => {
            t.validatedVouchers = [ceaCardInput];
            t.voucherCode = ceaCardInput;
            t.voucherGroupMaxQuantity = response.content.quantityLimit;
            t.voucherDisplayName = `${t.displayName} (${ceaCardInput})`;
            t.isThirdPartyMemberVoucher = true;
            t.thirdPartyTicketsName = ceaThirdPartyTicketName;
            ticketTypeModels.push(t);
          });
        });

        updateTicketTypes(ticketTypeModels);

        dispatch(actionCreators.addCeaCard(ceaCardInput));
        analytics?.track(CommonTrackingEvent.CEA_CODE_VALIDATED);
        setFeedback(undefined);
        setCeaCardInput('');
      } else if (
        responseContent.peachCode === PEACH_CODES.suspendedMemberCard
      ) {
        setFeedback(ceaCardExpiredText);
      } else {
        setFeedback(ceaCardErrorText);
      }
    } else {
      setFeedback(ceaCardErrorText);
    }
    setDisableApplyButton(false);
  };

  const handleCeaCardCheck = async () => {
    if (!ceaCardInput) return;
    setDisableApplyButton(true);

    if (!appliedCeaCards.includes(ceaCardInput)) {
      setFeedback(undefined);

      validateCode();
    } else {
      setDisableApplyButton(false);
      setFeedback(ticketsContent.ceaCardAlreadyAppliedText);
    }
  };

  const handleVoucherClick = (
    ticketTypeId: TicketTypeModel['id'],
    voucherCode: TicketTypeModel['voucherCode'],
    context: QuantitySelectorContext
  ) => {
    const { ticketTypeModels } = ticketTypes;
    const ticketTypeModel = ceaTicketTypes.find(
      (x) => x.id === ticketTypeId && x.voucherCode === voucherCode
    );

    if (!ticketTypeModel) {
      return;
    }

    if (context === 'add') {
      ticketTypeModel.quantity += 1;
    } else {
      ticketTypeModel.quantity += -1;

      if (!isSeatsFirstJourney) {
        dispatch(actionCreators.removeAllSeats());
      }
    }

    updateTicketTypes(ticketTypeModels);
  };

  const shouldDisableAddTicket = (t: TicketTypeModel) => {
    const ticketsInArea = ticketTypes?.ticketTypeModels.filter(
      (ti: TicketTypeModel) => ti.areaCategoryCode === t.areaCategoryCode
    );

    const selectedTicketsInAreaCount =
      ticketsInArea?.reduce((a, b) => a + (b.quantity * b.nbSeats || 0), 0) ??
      0;

    const selectedSeatsInArea = selectedSeats?.filter(
      (s) => s.areaCategoryCode === t.areaCategoryCode
    );

    return (
      orderHasMaxTickets ||
      t.quantity === t.voucherGroupMaxQuantity ||
      (isSeatsFirst &&
        selectedSeatsInArea.length === selectedTicketsInAreaCount) ||
      (isSeatsFirst &&
        selectedSeatsInArea.length < selectedTicketsInAreaCount + t.nbSeats)
    );
  };

  return (
    <BorderedCollapse
      closeButtonText={paymentContent.closeButtonText}
      heading={ticketsContent.ceaCardHeading}
      setShow={setShowCeaCardForm}
      show={showCeaCardForm}
    >
      <div className='voucher-container' data-testid='ceacard-selector'>
        <div className='voucher-selector'>
          <p>{ticketsContent.ceaCardLabel}</p>
          <Form>
            <Form.Label
              className={classNames(disableApplyButton && 'disabled')}
            />
            <Box
              sx={{
                justifyContent: 'space-between',
                display: ['block', 'flex'],
              }}
            >
              <Box sx={{ pr: [0, 3], flexGrow: 1 }}>
                <Form.Control
                  id='ceaCardNumber'
                  name='ceaCardNumber'
                  type='text'
                  placeholder={ticketsContent.ceaCardPlaceHolder}
                  onChange={(e) => {
                    setCeaCardInput(e.target.value);
                  }}
                  value={ceaCardInput}
                  disabled={disableApplyButton || orderHasMaxTickets}
                />
              </Box>
              <Box sx={{ flexShrink: 0, mt: [4, 0] }}>
                <ActionButton
                  onClick={handleCeaCardCheck}
                  disabled={disableApplyButton || orderHasMaxTickets}
                  variant='secondary'
                  mb={0}
                  mt={0}
                >
                  {disableApplyButton ? (
                    <ActionButtonSpinner />
                  ) : (
                    checkButtonText
                  )}
                </ActionButton>
              </Box>
            </Box>
          </Form>
          {!!feedback && (
            <Box className='warning-container' sx={{ mt: 5, p: 5 }}>
              <p>{feedback}</p>
            </Box>
          )}
          <div className='voucher-list'>
            {ceaTicketTypes.map((t) => (
              <VoucherTicketType
                key={`${t.id}${t.voucherCode}`}
                ticket={t}
                onClick={handleVoucherClick}
                disableAdd={shouldDisableAddTicket(t)}
                disableRemove={t.quantity === 0}
                packageTicketsIncludesLabel={
                  ticketsContent.packageTicketsIncludesLabel
                }
                hideTax={config.currentCinema.hideTax}
              />
            ))}
          </div>
        </div>
      </div>
    </BorderedCollapse>
  );
};

export default CeaThirdPartyVoucherSelector;
