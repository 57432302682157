import React from 'react';

import { useSelector } from 'react-redux';

import { selectCartSummary } from '../../../store/Selectors';
import { ReactComponent as NoPosterSvg } from '../../../svgs/noPoster.svg';

const CartSummaryFilmInfoStillImage: React.FC = () => {
  const cartSummary = useSelector(selectCartSummary);
  return (
    <div className='still'>
      {cartSummary.stillUrl || cartSummary.posterUrl ? (
        <img
          src={`${cartSummary.stillUrl || cartSummary.posterUrl}?width=200`}
          alt={cartSummary.title}
          data-testid='cs-still'
        />
      ) : (
        <NoPosterSvg className='no-poster' data-testid='cs-no-poster' />
      )}
    </div>
  );
};

export default CartSummaryFilmInfoStillImage;
