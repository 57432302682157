import { Dispatch } from 'react';

import { displayPrice } from './Helpers';

import { CurrencyConfig } from '../@types/configTypes';
import {
  Concession,
  ConcessionGrouping,
  //Deal,
  GiftCard,
  GiftCardWithAmount,
  SelectedConcessions,
} from '../@types/modelTypes';
import { actionCreators } from '../store/ActionCreators';

export const calculateGiftCardAmountChargedInCents = (
  appliedGiftCards: GiftCard[],
  giftCardIndex: number,
  grandTotal: number
) => {
  let stillToPay = grandTotal;

  const giftCardAmounts = appliedGiftCards.map((giftCard) => {
    let amount = 0;
    if (giftCard.giftCardBalanceInCents < stillToPay) {
      amount = giftCard.giftCardBalanceInCents;
      stillToPay -= amount;
    } else {
      amount = stillToPay;
      stillToPay = 0;
    }
    return { amount: amount };
  });

  const amountToPay = giftCardAmounts.slice(0).reverse()[giftCardIndex].amount;

  return amountToPay;
};

export const calculateGiftCardRemainingBalance = (
  appliedGiftCards: GiftCard[],
  grandTotal: number
) => {
  const giftCardsWithAmounts = getGiftCardsWithAmounts(
    appliedGiftCards,
    grandTotal
  );

  if (giftCardsWithAmounts.length === 0) return 0;

  const giftCardsToBeChargedTotalBalanceInCents = giftCardsWithAmounts.reduce(
    (a, b) => a + b.giftCardBalanceInCents,
    0
  );

  const remainingBalance =
    giftCardsWithAmounts[giftCardsWithAmounts.length - 1].giftCardAmount > 0
      ? giftCardsToBeChargedTotalBalanceInCents - grandTotal
      : giftCardsWithAmounts[giftCardsWithAmounts.length - 1]
          .giftCardBalanceInCents;

  return remainingBalance > 0 ? remainingBalance : 0;
};

export const displayGiftCardAmountChargedInCents = (
  appliedGiftCards: GiftCard[],
  giftCardIndex: number,
  grandTotal: number,
  currencyConfig: CurrencyConfig | undefined
) => {
  if (!currencyConfig) {
    return '';
  }
  const giftCardAmountChargedInCents = calculateGiftCardAmountChargedInCents(
    appliedGiftCards,
    giftCardIndex,
    grandTotal
  );
  return displayPrice(giftCardAmountChargedInCents, currencyConfig);
};

export const getGiftCardsWithAmounts = (
  appliedGiftCards: GiftCard[],
  grandTotal: number
) => {
  let stillToPay = grandTotal;
  const giftCardsWithAmounts: GiftCardWithAmount[] = appliedGiftCards?.map(
    (giftCard) => {
      let amount = 0;

      if (giftCard.giftCardBalanceInCents < stillToPay) {
        amount = giftCard.giftCardBalanceInCents;
        stillToPay -= amount;
      } else {
        amount = stillToPay;
        stillToPay = 0;
      }

      return {
        giftCardAmount: amount,
        giftCardNumber: giftCard.giftCardNumber,
        giftCardPin: giftCard.giftCardPin,
        giftCardBalanceInCents: giftCard.giftCardBalanceInCents,
      };
    }
  );

  return giftCardsWithAmounts;
};

export const getFullAddress = (
  addressLineOne?: string,
  addressLineTwo?: string,
  city?: string,
  state?: string,
  postcode?: string
) => {
  let fullAddress = `${addressLineOne}${', '}${
    addressLineTwo && addressLineTwo + ', '
  }${city}${', '}${state && state + ', '}${postcode || ''}`;

  if (fullAddress.endsWith(', ')) {
    fullAddress = fullAddress.substring(0, fullAddress.length - 2);
  }
  return fullAddress;
};

export const setGiftCardConcessions = (
  listConcessionGrouping: ConcessionGrouping[],
  queryString: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: Dispatch<any>
) => {
  const concessionGrouping: ConcessionGrouping = listConcessionGrouping[0];
  const items: Concession[] = concessionGrouping.items;
  const hasDeliveryItems = concessionGrouping.deliveryItems.length > 0;
  const noDeliveryCharge = 0;
  const giftCardDeliveryItemStrategy: number =
    concessionGrouping.giftCardDeliveryItemStrategy || noDeliveryCharge;
  const searchParams = new URLSearchParams(queryString);
  const queryStringId = searchParams.get('id')?.toLowerCase();
  const getIdToCompare = (id: string) => id.substring(id.indexOf('_') + 1);

  if (hasDeliveryItems && giftCardDeliveryItemStrategy !== noDeliveryCharge) {
    const firstDeliveryItem: Concession | undefined =
      concessionGrouping.deliveryItems[0];
    dispatch(actionCreators.changeDeliveryItemId(firstDeliveryItem.id));
  }
  if (queryStringId) {
    const orderedList: Concession[] = [];
    const firstConcession =
      items.find(
        (concession) => getIdToCompare(concession.id) === queryStringId
      ) || null;
    const otherConcessions = items.filter(
      (concession) => getIdToCompare(concession.id) !== queryStringId
    );
    if (firstConcession) {
      orderedList.push(firstConcession);
      if (!firstConcession.isVariablePriceItem) {
        dispatch(actionCreators.changeGiftCardById(firstConcession, 'add'));
      }
    }
    otherConcessions.forEach((concession) => orderedList.push(concession));
    concessionGrouping.items = orderedList;
  } else {
    concessionGrouping.items = items;
  }
  dispatch(actionCreators.setConcessions(listConcessionGrouping));
};

export const splitDeliveryItemFromConcessions = (
  concessions: ConcessionGrouping[] | null,
  selectedConcessions: SelectedConcessions
) => {
  if (concessions === null)
    return {
      listWithNoDeliveryItem: selectedConcessions.list,
      deliveryItem: null,
    };

  // We probabaly dont need to pass deliveryItemId
  const findDeliveryItem = () => {
    if (!concessions || !selectedConcessions || !concessions.length)
      return null;

    const deliveryItem = selectedConcessions.list.find(
      (concession) => concession.isDeliveryItem && concession.quantity > 0
    );
    return deliveryItem ?? null;
  };

  const deliveryItem = findDeliveryItem();

  const listWithNoDeliveryItem = selectedConcessions.list.filter(
    (c: Concession) => !c.isDeliveryItem
  );
  return {
    listWithNoDeliveryItem,
    deliveryItem,
  };
};

export const getValueLimitedToTwoDecimalPlaces = (value: string) => {
  return value
    .toString()
    .split('.')
    .map((el: string, i: number) =>
      i ? el.split('').slice(0, 2).join('') : el
    )
    .join('.');
};

export const getToolTipText = (
  text: string,
  concessionMinVariableCost: number | null,
  concessionMaxVariableCost: number | null,
  currencySymbol: string
) => {
  const minValue = concessionMinVariableCost
    ? concessionMinVariableCost / 100
    : 0;
  const maxValue = concessionMaxVariableCost
    ? concessionMaxVariableCost / 100
    : 0;
  return text
    ? text
        .replace('##minValue##', `${currencySymbol}${String(minValue)}`)
        .replace('##maxValue##', `${currencySymbol}${String(maxValue)}`)
    : text;
};

export const isVariableCostInvalid = (
  c: Concession,
  newValueInCents: number
) => {
  const minCostIsInvalid =
    c.minVariableCost === null || c.minVariableCost > newValueInCents;
  const maxCostIsInvalid =
    c.maxVariableCost === null || c.maxVariableCost < newValueInCents;
  return minCostIsInvalid || maxCostIsInvalid;
};
