/** @jsxImportSource theme-ui */
import React, { useEffect, useState } from 'react';

import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useDispatch, useSelector } from 'react-redux';

import {
  CreateMemberRequestModel,
  SignUpPasswordState,
} from '../../../@types/modelTypes';
import { PEACH_CODES } from '../../../constants';
import { useRecaptcha } from '../../../contextProviders/recaptchaContext';
import { useTurnstile } from '../../../contextProviders/turnstileContext';
import { displayPrice } from '../../../services/Helpers';
import { getContentForError } from '../../../services/PeachErrorResolver';
import backend from '../../../services/RestUtilities';
import { actionCreators } from '../../../store/ActionCreators';
import {
  selectTandCsAccepted,
  selectConfirmationData,
  selectCurrencyConfig,
  selectToken,
  selectContent,
  selectConfig,
  selectCustomer,
} from '../../../store/Selectors';
import ActionButton from '../actionbutton/ActionButton';
import PasswordInput from '../login/PasswordInput';
import RichText from '../richtext/RichText';
import TermsAndConditions from '../termsandconditions/TermsAndConditions';

const min1UppercaseMin1LowercaseMin1NumberMin8Chars =
  '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$';

const SignUpSection: React.FC = () => {
  const dispatch = useDispatch();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const recaptcha = useRecaptcha();
  const turnstile = useTurnstile();

  const isTandCsAccepted = useSelector(selectTandCsAccepted);
  const confirmationData = useSelector(selectConfirmationData);
  const currencyConfig = useSelector(selectCurrencyConfig);
  const dataToken = useSelector(selectToken);
  const content = useSelector(selectContent);
  const config = useSelector(selectConfig);
  const customer = useSelector(selectCustomer);
  const [isLoading, setIsLoading] = useState(false);
  const [isSignUpButtonDisabled, setisSignUpButtonDisabled] = useState(false);

  const [signUpPasswordState, setSignUpPasswordState] =
    useState<SignUpPasswordState>({
      password: '',
      passwordIsValid: false,
      passwordIsValidated: false,
      isValid: false,
    });
  const [validateForm, setValidateForm] = useState(false);

  useEffect(() => {
    if (validateForm) {
      setSignUpPasswordState((prevState) => ({
        ...prevState,
        passwordIsValidated: true,
      }));
    }
  }, [validateForm]);

  const handleSignUpPasswordStateChange = (
    nextSignUpPasswordState: SignUpPasswordState
  ) => {
    setSignUpPasswordState(nextSignUpPasswordState);
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const regex = new RegExp(min1UppercaseMin1LowercaseMin1NumberMin8Chars);
    const password = e.currentTarget.value;
    const passwordIsValid = regex.test(password);
    const isValid = passwordIsValid;
    handleSignUpPasswordStateChange({
      ...signUpPasswordState,
      password,
      passwordIsValid,
      isValid,
    });
  };

  const isFormValid = signUpPasswordState.isValid && isTandCsAccepted;

  const signUpMember = async () => {
    if (!isFormValid || !executeRecaptcha || !customer.name || !customer.email)
      return;

    setIsLoading(true);

    const recaptchaToken = await recaptcha?.getRecaptchaToken(
      'CreateMember',
      executeRecaptcha
    );

    const turnstileToken = await turnstile?.getToken();

    const data: CreateMemberRequestModel = {
      fullName: customer.name,
      email: customer.email,
      password: signUpPasswordState.password,
      circuitId: config.circuitId,
      userSessionId: confirmationData?.userSessionId ?? '',
      dataToken,
      recaptchaToken: recaptchaToken ?? null,
    };

    const response = await backend.post(
      'api/Member/create',
      data,
      turnstileToken
    );

    if (response.ok && response.content.peachCode === PEACH_CODES.noError) {
      window.sessionStorage.setItem('hasUserSignedUpForLoyalty', '1');
    } else if (
      response.content.peachCode === PEACH_CODES.loyaltyMemberAlreadyExists ||
      response.content.peachCode === PEACH_CODES.loyaltyUserNameAlreadyExists
    ) {
      window.sessionStorage.setItem('hasUserSignedUpForLoyalty', '0');
    } else {
      dispatch(
        actionCreators.setError(
          getContentForError(response.content.peachCode, content),
          response.content.peachCode
        )
      );
    }

    turnstile?.resetToken();

    setIsLoading(false);
  };

  const onSignUpClick = () => {
    setisSignUpButtonDisabled(true);
    !validateForm && setValidateForm(true);
    signUpMember();
    setisSignUpButtonDisabled(false);
  };

  const isButtonDisabled = isSignUpButtonDisabled || !isFormValid;

  const Loading = (
    <div sx={{ mt: 5, mb: 2, textAlign: 'center' }}>
      <span
        className='spinner-grow spinner-grow-sm'
        role='status'
        aria-hidden='true'
      />
    </div>
  );

  const formattedSignUpRichText: () => string = () => {
    const orderLoyaltyPointValue =
      confirmationData?.orderLoyaltyPoints?.points ?? 0;

    const orderLoyaltyPointPriceValue = displayPrice(
      confirmationData?.orderLoyaltyPoints.pointsValueInCents ?? 0,
      currencyConfig
    );

    if (!content.signIn?.signUpRichText?.length) {
      return '';
    }

    return content.signIn.signUpRichText
      .replaceAll('##ORDERLOYALTYPOINTS##', orderLoyaltyPointValue.toString())
      .replaceAll('##ORDERLOYALTYPOINTSVALUE##', orderLoyaltyPointPriceValue);
  };

  const SignUpForm = (
    <>
      <RichText text={formattedSignUpRichText()} />
      <div sx={{ my: 4 }}>
        <p sx={{ mt: 0 }}>
          <span sx={{ fontWeight: 'bold' }}>
            {content.signIn.signUpUsernameLabel}
          </span>{' '}
          {customer.email}
        </p>
        <p sx={{ mt: 0 }}>
          <span sx={{ fontWeight: 'bold' }}>
            {content.signIn.signUpNameLabel}
          </span>{' '}
          {customer.name}
        </p>
      </div>
      <div>
        <PasswordInput
          handlePasswordChange={handlePasswordChange}
          value={signUpPasswordState.password}
          isInvalid={
            signUpPasswordState.passwordIsValidated &&
            !signUpPasswordState.passwordIsValid
          }
          isValid={
            signUpPasswordState.passwordIsValidated &&
            signUpPasswordState.passwordIsValid
          }
          onBlur={() =>
            setSignUpPasswordState({
              ...signUpPasswordState,
              passwordIsValidated: true,
            })
          }
        />
        <RichText
          className='tiny'
          text={content.signIn.signUpPasswordRequirementsRichText}
        />
      </div>
      <div className='separator' />
      <TermsAndConditions isSignUp isPageValidated={validateForm} />
      <div sx={{ px: 2 }}>
        <ActionButton
          disabled={isButtonDisabled}
          onClick={() => onSignUpClick()}
          showIcon
          contained
          hasMarginX
          variant='primary'
        >
          {content.signIn.signUpButtonText}
        </ActionButton>
      </div>
    </>
  );

  const hasUserSignedUpForLoyalty = window.sessionStorage.getItem(
    'hasUserSignedUpForLoyalty'
  );

  return (
    <div className='sign-up-section bordered' sx={{ mt: 7 }}>
      <h2 sx={{ textTransform: 'uppercase' }}>
        {content.signIn.signUpHeading}
      </h2>
      <div
        className='sign-up-container'
        sx={{
          borderColor: 'mostReadableOnWebsiteBackground',
          textAlign: 'left',
        }}
      >
        {isLoading ? (
          Loading
        ) : hasUserSignedUpForLoyalty ? (
          <RichText
            text={
              hasUserSignedUpForLoyalty === '1'
                ? content.signIn.signUpCompleteRichText
                : getContentForError(60, content)
            }
          />
        ) : (
          SignUpForm
        )}
      </div>
    </div>
  );
};

export default SignUpSection;
