import React from 'react';

import { useSelector } from 'react-redux';

import { selectAppliedVouchers } from '../../../store/Selectors';
import VoucherBreakDown from '../payment/vouchercomponents/VoucherBreakDown';

interface Props {
  grandTotal: number;
  isConfirmation: boolean;
}

const CartSummaryVouchers: React.FC<Props> = ({
  grandTotal,
  isConfirmation,
}) => {
  const appliedVouchers = useSelector(selectAppliedVouchers);

  if (!appliedVouchers?.length) return null;

  return (
    <div className='cart-summary-row'>
      <VoucherBreakDown
        grandTotal={grandTotal}
        isConfirmation={isConfirmation}
        variant='cartSummary'
      />
    </div>
  );
};

export default CartSummaryVouchers;
