/** @jsxImportSource theme-ui */
import React from 'react';

import classnames from 'classnames';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { getNumberOfItemsToSelect } from '../../../services/Helpers';
import {
  selectContent,
  selectJourneyTypeConfig,
} from '../../../store/Selectors';
import { ReactComponent as RadioSelectedSvg } from '../../../svgs/radioSelected.svg';
import globalMessages from '../../widgets/intl';
import ContainedRow from '../layout/ContainedRow';

interface Props {
  allSelected: boolean;
  numberSelected: number;
  numberToSelect: number;
}

const SelectedHeading: React.FC<Props> = ({
  allSelected,
  numberSelected,
  numberToSelect,
}) => {
  const { formatMessage } = useIntl();

  const content = useSelector(selectContent);
  const journeyTypeConfig = useSelector(selectJourneyTypeConfig);

  return (
    <ContainedRow innerStyles={{ display: 'flex', justifyContent: 'center' }}>
      <h2
        className={classnames('select-heading', allSelected && 'all-selected')}
        sx={{ mt: 3, display: 'flex', alignItems: 'center' }}
      >
        {allSelected && <RadioSelectedSvg className='all-selected-check' />}
        <div>
          {getNumberOfItemsToSelect(
            journeyTypeConfig.type,
            content,
            numberToSelect,
            numberSelected,
            formatMessage(globalMessages.ofLabel),
            formatMessage(globalMessages.selectedLabel)
          )}
        </div>
      </h2>
    </ContainedRow>
  );
};

export default SelectedHeading;
