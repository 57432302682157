import React from 'react';

import { Flex } from 'theme-ui';

import GiftCardImage from '../concessionsonlycommon/GiftCardImage';

interface Props {
  image?: string;
  heading: string;
}

const CartSummaryImage: React.FC<Props> = ({ image, heading }) => {
  return (
    <Flex
      className='cart-summary-row cart-summary-giftcard-image'
      data-testid='cs-giftcard-image'
    >
      <div className='image-container'>
        <GiftCardImage imageUrl={image} />
      </div>
      <div className='text-container'>
        <h2>{heading}</h2>
      </div>
    </Flex>
  );
};

export default CartSummaryImage;
