import React from 'react';

import { useSelector } from 'react-redux';

import ZoomButton from './ZoomButton';

import { selectContentSeats } from '../../../store/Selectors';

interface Props {
  onZoomIn: () => void;
  onZoomOut: () => void;
  zoomInHidden: boolean;
  zoomOutHidden: boolean;
}

const ZoomButtons: React.FC<Props> = ({
  onZoomIn,
  onZoomOut,
  zoomInHidden,
  zoomOutHidden,
}) => {
  const contentSeats = useSelector(selectContentSeats);

  return (
    <div className='zoom-buttons-container'>
      {zoomInHidden || (
        <ZoomButton
          buttonStyle='zoomIn'
          onClick={onZoomIn}
          title={contentSeats.titleZoomInText}
        />
      )}
      {zoomOutHidden || (
        <ZoomButton
          buttonStyle='zoomOut'
          onClick={onZoomOut}
          title={contentSeats.titleZoomOutText}
        />
      )}
    </div>
  );
};

export default ZoomButtons;
