const setContentSecurityPolicy = (
  contentSecurityPolicy,
  enableContentSecurityPolicy
) => {
  const cspElement = document.getElementById('boxoffice-CSP');
  if (cspElement === null && enableContentSecurityPolicy === true) {
    const headTag = document.getElementsByTagName('HEAD')[0];
    const metaTag = document.createElement('meta');
    metaTag.setAttribute('id', 'boxoffice-CSP');
    metaTag.setAttribute('http-equiv', 'Content-Security-Policy');
    metaTag.setAttribute('content', contentSecurityPolicy);
    headTag.appendChild(metaTag);
  }
  return true;
};
export default setContentSecurityPolicy;
