import React, { useState, useCallback, useEffect } from 'react';

import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Cinema } from '../../../@types/configTypes';
import { StartConcessionsJourneyRequestModel } from '../../../@types/modelTypes';
import { forceNewJourneyKey, JOURNEY_TYPES } from '../../../constants';
import loadSentry from '../../../scripts/loadSentry';
import { getCustomer } from '../../../services/Helpers';
import { getRouteFromStep } from '../../../services/JourneyService';
import { journeyTypeConfigs } from '../../../services/journeyTypeConfigs';
import { getContentForError } from '../../../services/PeachErrorResolver';
import backend from '../../../services/RestUtilities';
import { actionCreators } from '../../../store/ActionCreators';
import { selectConfig, selectContent } from '../../../store/Selectors';
import ActionButton from '../../common/actionbutton/ActionButton';
import ContainedRow from '../../common/layout/ContainedRow';
import CinemaSelectorLoyaltyUpgrade from '../../common/loyalty/landingpage/CinemaSelectorLoyaltyUpgrade';
import LoyaltyLandingHeadings from '../../common/loyalty/landingpage/LoyaltyLandingHeadings';

const SignupLanding: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [cookies] = useCookies();
  const [searchParams] = useSearchParams();
  const config = useSelector(selectConfig);
  const content = useSelector(selectContent);

  const [cinemaId, setCinemaId] = useState(
    searchParams.get('cinemaId') ?? undefined
  );

  const journeyType = JOURNEY_TYPES.LOYALTY_SIGNUP_TIERED;
  const journeyTypeConfig = journeyTypeConfigs[journeyType];
  const circuitId = searchParams.get('circuitId');

  const startSignupJourney = useCallback(async () => {
    dispatch(actionCreators.setLoading(true));
    const { search } = location;
    const source = (searchParams.get('source') ?? 'web').toLowerCase();
    const device = (searchParams.get('device') ?? '').toLowerCase();
    const selectedLanguageCulture =
      cookies.preferredLanguageCulture ?? searchParams.get('culture');
    dispatch(actionCreators.setQueryString(search));

    const { requestData } = cookies;

    const data: StartConcessionsJourneyRequestModel = {
      selectedLanguageCulture,
      circuitId,
      requestData,
      journeyType: journeyTypeConfig.type,
    };
    if (cinemaId) {
      data.cinemaId = parseInt(cinemaId);
    }

    const path = 'api/StartConcessions/';
    const response = await backend.post(path, data);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let bookingData, config: any, content, customer, result, reservedSeats;
    if (response.ok) {
      result = response.content;
      bookingData = result.bookingData;
      reservedSeats = result.reservedSeats;
      config = result.circuit.config;
      content = result.circuit.content;
      if (!config.currentCinema && !!config.cinemas) {
        config.currentCinema = config.cinemas.find(
          (x: Cinema) => x.cinemaId === config.loyalty.defaultCinemaId
        );
      }
      customer = getCustomer(
        bookingData,
        config.payment.captureTelephoneNumber,
        config.currentCinema.captureZipCode,
        config.currentCinema.isZipCodeRequired
      );
      const step = 1;
      const session = {
        loading: true,
        bookingData: bookingData,
        token: result.dataToken,
        config: config,
        content: content,
        currency: 'USD',
        cartSummary: result.cartSummaryModel,
        availablePosTickets: result.selectTicketsModel,
        ticketTypes: { ticketTypeModels: [] },
        donation: 0,
        bookingFee: 0,
        seatsModel: result.seatsViewModel,
        error: { show: false, message: '' },
        selectedSeats: reservedSeats,
        giftCard: null,
        countdownEndTime: null,
        countdownExpired: false,
        isUserValidated: false,
        journeyType: journeyTypeConfig.type,
        customer: customer,
        source: source,
        device: device,
        step: step,
        kioskSubStep: 'fab',
        loyaltyRecognitionNumber: null,
        selectedConcessions: { list: [] },
        selectedGiftCards: { list: [] },
        selectedDeliveryWindow: null,
        selectedLanguageCulture: content.culture,
        imageProcessorUrl: result.imageProcessorUrl,
        imageProcessorContainer: result.imageProcessorContainer,
        turnstileConfig: result.turnstileConfig,
        recaptchaConfig: result.recaptchaConfig,
        orderExists: false,
      };

      dispatch(actionCreators.initializeSession(session));
      const route = getRouteFromStep(journeyTypeConfig, step);

      window.sessionStorage.removeItem(forceNewJourneyKey);

      navigate(`/${route}`);
    } else {
      result = response.errorContent;
      config = result.circuit.config;
      content = result.circuit.content;

      delete config.cinemas;
      delete content.cinemas;

      const session = {
        loading: true,
        config: config,
        content: content,
        orderExists: false,
        error: {
          show: true,
          message: getContentForError(response.errorContent.peachCode, content),
        },
        step: -1,
      };
      dispatch(actionCreators.initializeSession(session));

      window.sessionStorage.removeItem(forceNewJourneyKey);
    }
    dispatch(actionCreators.setLoading(false));
  }, [
    cinemaId,
    circuitId,
    cookies,
    dispatch,
    journeyTypeConfig,
    navigate,
    searchParams,
  ]);

  const setConfigAndContent = useCallback(async () => {
    dispatch(actionCreators.clearSession());
    dispatch(actionCreators.setLoading(true));
    const response = await backend.post('api/Config', {
      selectedLanguageCulture:
        cookies.preferredLanguageCulture ?? searchParams.get('culture'),
      circuitId: circuitId ? parseInt(circuitId) : 0,
    });
    if (response.ok) {
      const result = response.content;
      const config = result.config;
      config.currentCinema = config.cinemas.find(
        (x: Cinema) => x.cinemaId === config.loyalty.defaultCinemaId
      );
      const content = result.content;
      dispatch(actionCreators.setCircuitConfig(config));
      dispatch(actionCreators.setCircuitContent(content));
      if (config.sentryDsnKey) {
        loadSentry(config.sentryDsnKey);
      }
    }

    dispatch(actionCreators.setLoading(false));
  }, [circuitId, cookies, dispatch, searchParams]);

  useEffect(() => {
    const cinemaIdFromQuery = searchParams.get('cinemaId');
    if (cinemaIdFromQuery) {
      startSignupJourney();
    } else if (!config?.cinemas) {
      setConfigAndContent();
    }
  }, [config?.cinemas, searchParams, setConfigAndContent, startSignupJourney]);

  useEffect(() => {
    if (config && !config.loyalty.allowCinemaSelection) {
      startSignupJourney();
    }
  }, [config, startSignupJourney]);

  const handleCinemaChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setCinemaId(e.currentTarget.value);
  };

  if (!config || !content) return null;

  return (
    <div className='concessions-landing'>
      <LoyaltyLandingHeadings />

      <CinemaSelectorLoyaltyUpgrade
        handleCinemaChange={handleCinemaChange}
        cinemaId={cinemaId}
      />
      <ContainedRow textAlign='left'>
        <ActionButton
          onClick={startSignupJourney}
          disabled={false}
          stickyMobileDesktop={false}
          showIcon
          contained
          hasMarginX
          mt={6}
          variant='primary'
        >
          {content.loyalty?.landingContinueButtonText}
        </ActionButton>
      </ContainedRow>
    </div>
  );
};

export default SignupLanding;
