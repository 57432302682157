/** @jsxImportSource theme-ui */
import React from 'react';

import { useSelector } from 'react-redux';
import { Box } from 'theme-ui';

import { commonContainerSx } from './Constants';

import { useValidateSeasonPassJourney } from '../../../hooks/useValidateSeasonPassJourney';
import { selectContent, selectSeasonPasses } from '../../../store/Selectors';
import ContainedRow from '../../common/layout/ContainedRow';
import RichText from '../../common/richtext/RichText';
import SeasonPassItem from '../../common/seasonpass/SeasonPassItem';

const SelectSeasonPass: React.FC = () => {
  const seasonPasses = useSelector(selectSeasonPasses);
  const content = useSelector(selectContent);

  const stepRequiredDataAreValid = content?.seasonPass;
  useValidateSeasonPassJourney(!!stepRequiredDataAreValid);
  if (!stepRequiredDataAreValid) return null;
  return (
    <Box className='season-pass-select' sx={commonContainerSx}>
      <ContainedRow>
        <h1 sx={{ mb: 4, textAlign: 'center' }}>
          {content.seasonPass.selectSeasonPassTitle}
        </h1>
        <RichText text={content.seasonPass.selectSeasonPassRichText} />
        {seasonPasses?.map((sP) => (
          <SeasonPassItem key={sP.id} seasonPass={sP} />
        ))}
      </ContainedRow>
    </Box>
  );
};

export default SelectSeasonPass;
