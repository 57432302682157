import {
  CreditCardTypeDetail,
  CreditCardTypeIndex,
} from '../@types/modelTypes';

export const creditCardTypeIndex: CreditCardTypeIndex = {
  americanexpress: {
    creditCardFriendlyName: 'american-express',
    creditCardTypeMatchingNames: ['american_express', 'American Express'],
  },
  mastercard: {
    creditCardFriendlyName: 'mastercard',
    creditCardTypeMatchingNames: ['mastercard', 'Mastercard', 'master'],
  },
  maestro: {
    creditCardFriendlyName: 'maestro',
    creditCardTypeMatchingNames: ['Maestro', 'maestro'],
  },
  visa: {
    creditCardFriendlyName: 'visa',
    creditCardTypeMatchingNames: ['Visa', 'visa'],
  },
  discover: {
    creditCardFriendlyName: 'discover',
    creditCardTypeMatchingNames: ['Discover', 'discover'],
  },
};

const creditCardTypeIndexItems: CreditCardTypeDetail[] =
  Object.values(creditCardTypeIndex);

export const GetPaymentIconDisplayDetailsForCardTypeName = (
  creditCardType: string
) => {
  return creditCardTypeIndexItems.find((x) =>
    x.creditCardTypeMatchingNames.includes(creditCardType)
  );
};

export const CreditCardTypeIsInMatchingNamesOf = (
  creditCardType: string,
  friendlyNameMatch: CreditCardTypeDetail['creditCardFriendlyName']
) => {
  return creditCardTypeIndexItems.some(
    (x) =>
      x.creditCardTypeMatchingNames.includes(creditCardType) &&
      x.creditCardFriendlyName === friendlyNameMatch
  );
};
