const loadSpreedly = (callback) => {
  const existingScript = document.getElementById('spreedly');
  if (!existingScript) {
    const script = document.createElement('script');
    script.src = 'https://core.spreedly.com/iframe/iframe-v1.min.js';
    script.id = 'spreedly';
    document.body.appendChild(script);
    script.onload = () => {
      if (callback) callback();
    };
  }
  if (existingScript && callback) callback();
};
export default loadSpreedly;
