import React from 'react';

import { useSelector } from 'react-redux';

import { selectContentCartSummary } from '../../../store/Selectors';
const CartSummarySubtitle: React.FC = () => {
  const contentCartSummary = useSelector(selectContentCartSummary);
  if (contentCartSummary.yourSummarySubtitle === '') return null;

  return <h2>{contentCartSummary.yourSummarySubtitle}</h2>;
};

export default CartSummarySubtitle;
