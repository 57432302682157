/** @jsxImportSource theme-ui */
import React from 'react';

import { useSelector } from 'react-redux';

import RecipientDetails from './RecipientDetailsEGiftCard';

import { ContentGiftCards } from '../../../../@types/contentTypes';
import {
  selectContent,
  selectGiftCardRecipient,
} from '../../../../store/Selectors';
import CustomerDetails from '../../customerdetails/CustomerDetails';
import ContainedRow from '../../layout/ContainedRow';
import RichText from '../../richtext/RichText';
import TermsAndConditions from '../../termsandconditions/TermsAndConditions';

interface Props {
  isPageValidated: boolean;
}

const DeliveryDetails: React.FC<Props> = ({ isPageValidated }) => {
  const content = useSelector(selectContent);
  const giftCardRecipient = useSelector(selectGiftCardRecipient);
  const giftCardContent: ContentGiftCards = content.giftCards;

  if (!content) {
    return null;
  }

  return (
    <>
      <div className='delivery-details contained'>
        <h2 sx={{ mt: 7, textAlign: 'center' }}>
          2. {giftCardContent.recipientDetailsHeading}
        </h2>
        <RichText
          styles={{ mb: 5 }}
          text={giftCardContent.recipientDetailsRichText}
        />
        <RecipientDetails
          isPageValidated={isPageValidated}
          billingAsRecipient={giftCardRecipient.useSameDetailsForBilling}
        />
        <h2 sx={{ mt: 7, textAlign: 'center' }}>
          3. {giftCardContent.customerDetailsHeading}
        </h2>
        <RichText text={giftCardContent.customerDetailsRichText} />
        <p sx={{ mt: 4, fontWeight: 'bold' }}>
          {giftCardContent.useBillingEmailText}
        </p>
        <CustomerDetails isPageValidated={isPageValidated} />
      </div>

      <ContainedRow>
        <TermsAndConditions isPageValidated={isPageValidated} />
      </ContainedRow>
    </>
  );
};

export default DeliveryDetails;
