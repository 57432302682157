/** @jsxImportSource theme-ui */
import React from 'react';

import { useSelector } from 'react-redux';

import SplitPaymentDesign from './SplitPaymentDesign';
import TabbedFullPaymentDesign from './TabbedFullPaymentDesign';

import {
  selectConfig,
  selectContent,
  selectGrandTotalAfterDiscountsInCents,
  selectGrandTotalWithoutDonationInCents,
  selectHasCardPaymentPromoTicketsInCart,
} from '../../../../store/Selectors';
import ContainedRow from '../../layout/ContainedRow';
import RichText from '../../richtext/RichText';
import LoyaltyRedemption from '../loyaltycomponents/LoyaltyRedemption';
import CardPaymentPromotionPaymentReminder from '../paymentcomponents/common/CardPaymentPromotionPaymentReminder';
import ZeroBankPayment from '../paymentcomponents/providers/ZeroBankPayment';

interface Props {
  giftcardEnabled: boolean;
  loyaltyPaymentEnabled: boolean;
  creditCardType: string;
  showPaymentForm: boolean;
  showGiftCardForm: boolean;
  isPageValidated: boolean;
  handleValidatePage: () => void;
  setPaymentFormVisibility: (visibility: boolean) => void;
  setGiftCardFormVisibility?: (visibility: boolean) => void;
  setCreditCardType: (creditCardType: string) => void;
}

const PaymentDesignWrapper: React.FC<Props> = ({
  giftcardEnabled,
  loyaltyPaymentEnabled,
  creditCardType,
  showPaymentForm,
  showGiftCardForm,
  isPageValidated,
  handleValidatePage,
  setPaymentFormVisibility,
  setGiftCardFormVisibility,
  setCreditCardType,
}) => {
  const config = useSelector(selectConfig);
  const content = useSelector(selectContent);
  const grandTotalWithoutDonation = useSelector(
    selectGrandTotalWithoutDonationInCents
  );
  const grandTotal = useSelector(selectGrandTotalAfterDiscountsInCents);
  const hasCardPaymentPromotionTicketInCart = useSelector(
    selectHasCardPaymentPromoTicketsInCart
  );

  const useTabbedDesign =
    giftcardEnabled &&
    !config.currentCinema.enableSplitPayment &&
    !hasCardPaymentPromotionTicketInCart;

  const showLoyaltyRedemptionFullPayment =
    loyaltyPaymentEnabled &&
    config.currentCinema.enableLoyaltyRedemption &&
    !config.loyalty.allowSplitLoyaltyRedemption &&
    grandTotalWithoutDonation > 0;

  return (
    <>
      <ContainedRow>
        <h2 sx={{ mt: 7, textAlign: 'center' }}>{content.payment.subTitle}</h2>
        <RichText text={content.payment.optionalPaymentRichText} />
      </ContainedRow>

      {grandTotal === 0 && (
        <ContainedRow>
          <ZeroBankPayment
            isPageValidated={isPageValidated}
            handleValidatePage={handleValidatePage}
          />
        </ContainedRow>
      )}

      {grandTotal > 0 && (
        <>
          {showLoyaltyRedemptionFullPayment && (
            <ContainedRow classNameWrapper='loyalty-redemption-wrapper'>
              <LoyaltyRedemption
                isPageValidated={isPageValidated}
                handleValidatePage={handleValidatePage}
                setPaymentFormVisibility={setPaymentFormVisibility}
                setGiftCardFormVisibility={setGiftCardFormVisibility}
              />
            </ContainedRow>
          )}

          {hasCardPaymentPromotionTicketInCart && (
            <CardPaymentPromotionPaymentReminder />
          )}

          {useTabbedDesign ? (
            <TabbedFullPaymentDesign
              creditCardType={creditCardType}
              showGiftCardForm={showGiftCardForm}
              isPageValidated={isPageValidated}
              handleValidatePage={handleValidatePage}
              setPaymentFormVisibility={setPaymentFormVisibility}
              setCreditCardType={setCreditCardType}
            />
          ) : (
            <SplitPaymentDesign
              creditCardType={creditCardType}
              showPaymentForm={showPaymentForm}
              showGiftCardForm={showGiftCardForm && giftcardEnabled}
              isPageValidated={isPageValidated}
              handleValidatePage={handleValidatePage}
              setPaymentFormVisibility={setPaymentFormVisibility}
              setGiftCardFormVisibility={setGiftCardFormVisibility}
              setCreditCardType={setCreditCardType}
            />
          )}
        </>
      )}
    </>
  );
};

export default PaymentDesignWrapper;
