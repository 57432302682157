/** @jsxImportSource theme-ui */
import React from 'react';

import classnames from 'classnames';
import { useSelector } from 'react-redux';

import { GlobalState, TicketTypeModel } from '../../../@types/modelTypes';
import { displayPrice } from '../../../services/Helpers';
import {
  selectContent,
  selectCurrencyConfig,
  selectDisplayOriginalPrice,
  selectHideTax,
  selectOriginalPriceToDisplayInTicketsStep,
  selectTicketBookingFeeInTicketsStep,
  selectTotalPriceToDisplayInTicketsStep,
  shouldDisplayBookingFeeOnTicketsStep,
} from '../../../store/Selectors';

interface Props {
  ticket: TicketTypeModel;
}

const TicketPriceWithBookingFee: React.FC<Props> = ({ ticket }) => {
  const content = useSelector(selectContent);
  const currencyConfig = useSelector(selectCurrencyConfig);
  const hideTaxInSummary = useSelector(selectHideTax);
  const shouldDisplayAdditionalPrices = useSelector(
    shouldDisplayBookingFeeOnTicketsStep
  );
  const bookingFee = useSelector((state: GlobalState) =>
    selectTicketBookingFeeInTicketsStep(state, ticket)
  );
  const totalPriceToDisplayInTicketsStep = useSelector((state: GlobalState) =>
    selectTotalPriceToDisplayInTicketsStep(state, ticket, bookingFee)
  );
  const originalPriceToDisplayInTicketsStep = useSelector(
    (state: GlobalState) =>
      selectOriginalPriceToDisplayInTicketsStep(state, ticket, bookingFee)
  );
  const displayOriginalPrice = useSelector((state: GlobalState) =>
    selectDisplayOriginalPrice(state, ticket)
  );

  const priceWithHideTaxConfigAdjustment = hideTaxInSummary
    ? ticket.price
    : ticket.price - ticket.tax;

  const displayBookingFee = displayPrice(bookingFee, currencyConfig);
  const displayPriceWithHideTaxConfigAdjustment = displayPrice(
    priceWithHideTaxConfigAdjustment,
    currencyConfig
  );
  const additionalPricingText = ` (${displayPriceWithHideTaxConfigAdjustment} + ${displayBookingFee} ${content.cartSummary.serviceChargeLabel})`;

  return (
    <>
      <p>
        {displayOriginalPrice && (
          <span className='original-price'>
            {displayPrice(originalPriceToDisplayInTicketsStep, currencyConfig)}
          </span>
        )}
        <b
          className={classnames(displayOriginalPrice && 'ticket-promo-price')}
          sx={{
            '&.ticket-promo-price': {
              color: 'primary',
            },
          }}
        >
          {displayPrice(totalPriceToDisplayInTicketsStep, currencyConfig)}
        </b>
        {ticket.quantity > 0 && (
          <>
            <span> x </span>
            <span className='quantity'>{ticket.quantity}</span>
          </>
        )}
      </p>
      {shouldDisplayAdditionalPrices && (
        <p className='tiny'>{additionalPricingText}</p>
      )}
    </>
  );
};

export default TicketPriceWithBookingFee;
