import React from 'react';

import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { Box } from 'theme-ui';
import { v4 as uuidv4 } from 'uuid';

import { AppliedDeal } from '../../../@types/modelTypes';
import {
  selectAppliedDeals,
  selectBookingData,
  selectContent,
} from '../../../store/Selectors';
import { ReactComponent as RadioSelectedSvg } from '../../../svgs/radioSelected.svg';

const HeaderDealsApplied: React.FC = () => {
  const appliedDeals = useSelector(selectAppliedDeals);
  const bookingData = useSelector(selectBookingData);
  const content = useSelector(selectContent);

  if (!content || !content.tickets || !appliedDeals || !appliedDeals.length) {
    return null;
  }

  return (
    <Box
      className={classnames(
        'banner',
        bookingData?.isUserValidated && 'extra-margin'
      )}
      data-testid='header-deals_applied'
      sx={{ py: 1, textTransform: 'uppercase', fontWeight: 'bold' }}
    >
      {appliedDeals.map((g: AppliedDeal) => {
        return (
          <Box key={`header_deal_applied_${uuidv4()}`} py={2}>
            {g.title} - {content.tickets.dealAppliedText}
            <RadioSelectedSvg />
          </Box>
        );
      })}
    </Box>
  );
};

export default HeaderDealsApplied;
