import React, { useState } from 'react';

import { useSelector } from 'react-redux';
import { Box } from 'theme-ui';

import TicketSelectorContainer from './TicketSelectorContainer';

import {
  selectContent,
  selectHasCardPaymentPromoTickets,
  selectTicketTypes,
} from '../../../store/Selectors';
import BorderedCollapse from '../borderedcollapse/BorderedCollapse';

interface Props {
  orderHasMaxTickets: boolean;
}

const CardPaymentPromoTicketSelector: React.FC<Props> = ({
  orderHasMaxTickets,
}) => {
  const content = useSelector(selectContent);
  const ticketTypes = useSelector(selectTicketTypes);
  const hasCardPaymentPromoTickets = useSelector(
    selectHasCardPaymentPromoTickets
  );
  const [showCardPromoTicketSection, setShowCardPromoTicketSection] =
    useState(true);

  if (!ticketTypes || !hasCardPaymentPromoTickets) return null;

  return (
    <Box className='contained' sx={{ borderTop: 'bodySeparator', py: 6 }}>
      <BorderedCollapse
        closeButtonText={content.payment.closeButtonText}
        heading={content.tickets.cardPaymentPromoTicketsHeading}
        setShow={setShowCardPromoTicketSection}
        show={showCardPromoTicketSection}
      >
        <div>
          <p>{content.tickets.cardPaymentPromoTicketsInfoText}</p>
          <TicketSelectorContainer
            ticketTypeGroup='card-promo'
            orderHasMaxTickets={orderHasMaxTickets}
          />
        </div>
      </BorderedCollapse>
    </Box>
  );
};

export default CardPaymentPromoTicketSelector;
