import { CommonTrackingEvent } from '../@types/trackingTypes';

export const config = {
  debug: false,
  containerId: null,
  dataLayerName: 'dataLayer',
  dataLayer: undefined,
  preview: undefined,
  auth: undefined,
  execution: 'async',
  // assumesPageview: true,
};

const gtmName = 'google-tag-manager';
let initializedDataLayerName;

export function customGoogleTagManager(options) {
  const defaultScriptSrc = 'https://www.googletagmanager.com/gtm.js';
  const containerId = options.containerId;

  return {
    name: gtmName,
    config: {
      ...config,
      containerId,
    },
    initialize: ({ config }) => {
      const {
        containerId,
        dataLayerName,
        customScriptSrc,
        preview,
        auth,
        execution,
      } = config;
      if (!containerId) {
        throw new Error('No google tag manager containerId defined');
      }
      if (preview && !auth) {
        throw new Error(
          'When enabling preview mode, both preview and auth parameters must be defined'
        );
      }

      const scriptSrc = customScriptSrc || defaultScriptSrc;

      if (!scriptLoaded(containerId, scriptSrc)) {
        /* eslint-disable */
        (function (w, d, s, l, i) {
          w[l] = w[l] || [];
          w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
          var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s),
            dl = l != 'dataLayer' ? '&l=' + l : '',
            p = preview
              ? '&gtm_preview=' +
                preview +
                '&gtm_auth=' +
                auth +
                '&gtm_cookies_win=x'
              : '';
          if (execution) {
            j[execution] = true;
          }
          j.src = `${scriptSrc}?id=` + i + dl + p;
          f.parentNode.insertBefore(j, f);
        })(window, document, 'script', dataLayerName, containerId);
        /* eslint-enable */
        initializedDataLayerName = dataLayerName;
        config.dataLayer = window[dataLayerName];
      }
    },
    track: ({ payload }) => {
      if (Object.values(CommonTrackingEvent).includes(payload?.event)) return;
      safelyPushToDataLayer({
        event: payload.event,
      });
    },
    identify: ({ payload }) => {
      safelyPushToDataLayer({
        event: payload.event,
      });
    },
    page: ({ payload }) => {
      safelyPushToDataLayer({
        event: payload.event,
      });
    },
    loaded: () => {
      const hasDataLayer =
        !!initializedDataLayerName &&
        !!(
          window[initializedDataLayerName] &&
          Array.prototype.push !== window[initializedDataLayerName].push
        );
      return (
        scriptLoaded(
          options.containerId,
          options.customScriptSrc || defaultScriptSrc
        ) && hasDataLayer
      );
    },
  };

  function safelyPushToDataLayer(data) {
    if (window.dataLayer && Array.isArray(window.dataLayer)) {
      window.dataLayer.push(data);
    }
  }

  function scriptLoaded(containerId, scriptSrc) {
    const regexCache = {};
    let regex = regexCache[containerId];
    if (!regex) {
      const scriptSrcEscaped = scriptSrc
        .replace(/^https?:\/\//, '')
        .replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

      regex = new RegExp(scriptSrcEscaped + '.*[?&]id=' + containerId);
      regexCache[containerId] = regex;
    }
    const scripts = document.querySelectorAll('script[src]');
    return !!Object.keys(scripts).filter((key) =>
      (scripts[key].src || '').match(regex)
    ).length;
  }
}
