import React from 'react';

import { useSelector } from 'react-redux';
import { ThemeUIStyleObject } from 'theme-ui';

import { useScreenWidth } from '../../../contextProviders/screenWidthContext';
import { selectConfig, selectContent } from '../../../store/Selectors';
import RichText from '../../common/richtext/RichText';
import ContainedRow from '../layout/ContainedRow';

interface Props {
  styles?: ThemeUIStyleObject;
}

const RecaptchaText: React.FC<Props> = ({ styles = {} }) => {
  const { isMobileScreenWidth } = useScreenWidth();
  const config = useSelector(selectConfig);
  const content = useSelector(selectContent);

  if (!config || !config.enableCaptcha || !content || !isMobileScreenWidth)
    return null;

  return (
    <ContainedRow styles={styles} data-testid='captcha-text'>
      <RichText
        className='rich-text-tiny'
        text={content.googleRecaptchaRichText}
        styles={{ textAlign: 'center' }}
      />
    </ContainedRow>
  );
};

export default RecaptchaText;
