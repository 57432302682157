import 'regenerator-runtime/runtime';
import { all } from 'redux-saga/effects';

import {
  adyenInitiatePaymentWatch,
  adyenSubmitAdditionalDetailsWatch,
} from './sagas/adyen';
import { validateCeaCardWatch } from './sagas/memberRequests';
import {
  adyenDropinHostedPaymentWatch,
  hostedPaymentRequestWatch,
  initializeAmazonPayPaymentWatch,
  submitMakePaymentWatch,
} from './sagas/paymentRequests';
import {
  addBasketTicketsWatch,
  addSingleTicketWatch,
  removeSingleTicketWatch,
  validateDynamicBasketWatch,
} from './sagas/ticketRequests';

export default function* rootSaga() {
  yield all([
    initializeAmazonPayPaymentWatch(),
    submitMakePaymentWatch(),
    adyenInitiatePaymentWatch(),
    adyenSubmitAdditionalDetailsWatch(),
    addSingleTicketWatch(),
    removeSingleTicketWatch(),
    validateCeaCardWatch(),
    addBasketTicketsWatch(),
    validateDynamicBasketWatch(),
    hostedPaymentRequestWatch(),
    adyenDropinHostedPaymentWatch(),
  ]);
}
