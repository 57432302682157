/** @jsxImportSource theme-ui */
import React, { useCallback, useState } from 'react';

import { Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { isLength } from 'validator';
import zipState from 'zip-state';

import { GlobalState } from '../../../../@types/modelTypes';
import { actionCreators } from '../../../../store/ActionCreators';
import {
  selectPhysicalGiftCardRecipient,
  selectContentPhysicalGiftcards,
  selectRecipientStateStrategy,
} from '../../../../store/Selectors';

interface Props {
  isPageValidated: boolean;
}

const PhysicalConcessionRecipientDetails: React.FC<Props> = ({
  isPageValidated,
}) => {
  const dispatch = useDispatch();

  const { showStateInput, lookupState } = useSelector(
    selectRecipientStateStrategy
  );
  const contentPhysicalGiftCards = useSelector(selectContentPhysicalGiftcards);
  const physicalGiftCardRecipient = useSelector(
    selectPhysicalGiftCardRecipient
  );

  const [recipientState, setRecipientState] = useState<
    GlobalState['physicalGiftCardRecipient']
  >({
    name: physicalGiftCardRecipient.name ?? '',
    nameIsValid: !!physicalGiftCardRecipient.nameIsValid,
    nameIsValidated: false,
    message: physicalGiftCardRecipient.message ?? '',
    messageIsValid: !!physicalGiftCardRecipient.messageIsValid,
    messageIsValidated: false,
    addressLineOne: physicalGiftCardRecipient.addressLineOne ?? '',
    addressLineOneIsValid: !!physicalGiftCardRecipient.addressLineOneIsValid,
    addressLineOneIsValidated: false,
    addressLineTwo: physicalGiftCardRecipient.addressLineTwo ?? '',
    addressLineTwoIsValid: !!physicalGiftCardRecipient.addressLineTwoIsValid,
    addressLineTwoIsValidated: false,
    city: physicalGiftCardRecipient.city ?? '',
    cityIsValid: !!physicalGiftCardRecipient.cityIsValid,
    cityIsValidated: false,
    postcode: physicalGiftCardRecipient.postcode ?? '',
    postcodeIsValid: !!physicalGiftCardRecipient.postcodeIsValid,
    postcodeIsValidated: false,
    state: physicalGiftCardRecipient.state ?? '',
    stateIsValid:
      showStateInput || lookupState
        ? !!physicalGiftCardRecipient.stateIsValid
        : false,
    stateIsValidated: false,
    isValid: false,
  });
  useCallback(() => {
    if (isPageValidated) {
      setRecipientState({
        ...recipientState,
        nameIsValidated: true,
        messageIsValidated: true,
        addressLineOneIsValidated: true,
        addressLineTwoIsValidated: true,
        cityIsValidated: true,
        postcodeIsValidated: true,
        stateIsValidated: true,
      });
    }
  }, [recipientState, isPageValidated]);

  const handleRecipientChange = (
    nextRecipientState: GlobalState['physicalGiftCardRecipient']
  ) => {
    setRecipientState(nextRecipientState);
    dispatch(actionCreators.setPhysicalGiftCardRecipient(nextRecipientState));
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.currentTarget.value;
    const nameIsValid = isLength(name, { min: 1, max: 50 });
    const isValid =
      (showStateInput ? recipientState.stateIsValid : true) &&
      nameIsValid &&
      recipientState.messageIsValid &&
      recipientState.addressLineOneIsValid &&
      recipientState.addressLineTwoIsValid &&
      recipientState.cityIsValid &&
      recipientState.postcodeIsValid;
    handleRecipientChange({
      ...recipientState,
      name,
      nameIsValid,
      isValid,
    });
  };

  const handleMessageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const message = e.currentTarget.value;
    const messageIsValid = isLength(message, { min: 0, max: 1000 });
    const isValid =
      (showStateInput ? recipientState.stateIsValid : true) &&
      recipientState.nameIsValid &&
      messageIsValid &&
      recipientState.addressLineOneIsValid &&
      recipientState.addressLineTwoIsValid &&
      recipientState.cityIsValid &&
      recipientState.postcodeIsValid;
    handleRecipientChange({
      ...recipientState,
      message,
      messageIsValid,
      messageIsValidated: true,
      isValid,
    });
  };

  const handleAddressLineOneChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const addressLineOne = e.currentTarget.value;
    const addressLineOneIsValid = isLength(addressLineOne, { min: 1, max: 50 });
    const isValid =
      (showStateInput ? recipientState.stateIsValid : true) &&
      recipientState.nameIsValid &&
      recipientState.messageIsValid &&
      addressLineOneIsValid &&
      recipientState.addressLineTwoIsValid &&
      recipientState.cityIsValid &&
      recipientState.postcodeIsValid;
    handleRecipientChange({
      ...recipientState,
      addressLineOne,
      addressLineOneIsValid,
      isValid,
    });
  };

  const handleAddressLineTwoChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const addressLineTwo = e.currentTarget.value;
    const addressLineTwoIsValid = isLength(addressLineTwo, { min: 0, max: 50 });
    const isValid =
      (showStateInput ? recipientState.stateIsValid : true) &&
      recipientState.nameIsValid &&
      recipientState.messageIsValid &&
      recipientState.addressLineOneIsValid &&
      addressLineTwoIsValid &&
      recipientState.cityIsValid &&
      recipientState.postcodeIsValid;
    handleRecipientChange({
      ...recipientState,
      addressLineTwo,
      addressLineTwoIsValid,
      addressLineTwoIsValidated: true,
      isValid,
    });
  };

  const handleCityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const city = e.currentTarget.value;
    const cityIsValid = isLength(city, { min: 1, max: 50 });
    const isValid =
      (showStateInput ? recipientState.stateIsValid : true) &&
      recipientState.nameIsValid &&
      recipientState.messageIsValid &&
      recipientState.addressLineOneIsValid &&
      recipientState.addressLineTwoIsValid &&
      cityIsValid &&
      recipientState.postcodeIsValid;
    handleRecipientChange({
      ...recipientState,
      city,
      cityIsValid,
      isValid,
    });
  };

  const handleStateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const state = e.currentTarget.value;
    const stateIsValid = isLength(state, { min: 1, max: 50 });
    const isValid =
      recipientState.nameIsValid &&
      recipientState.messageIsValid &&
      recipientState.addressLineOneIsValid &&
      recipientState.addressLineTwoIsValid &&
      recipientState.cityIsValid &&
      stateIsValid &&
      recipientState.postcodeIsValid;
    handleRecipientChange({
      ...recipientState,
      state,
      stateIsValid,
      isValid,
    });
  };

  const handlePostcodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const postcode = e.currentTarget.value;
    const postcodeIsValid = isLength(postcode, { min: 1, max: 50 });

    const isValid =
      (showStateInput ? recipientState.stateIsValid : true) &&
      recipientState.nameIsValid &&
      recipientState.messageIsValid &&
      recipientState.addressLineOneIsValid &&
      recipientState.addressLineTwoIsValid &&
      recipientState.cityIsValid &&
      postcodeIsValid;

    if (lookupState && postcode) {
      const state = zipState(postcode);
      handleRecipientChange({
        ...recipientState,
        state,
        postcode,
        postcodeIsValid,
        isValid,
      });
    } else {
      handleRecipientChange({
        ...recipientState,
        postcode,
        postcodeIsValid,
        isValid,
      });
    }
  };

  if (!contentPhysicalGiftCards) {
    return null;
  }

  return (
    <div className='recipient-details' data-testid='recipient-details'>
      <Form noValidate sx={{ my: 5, textAlign: 'left' }}>
        <Form.Group sx={{ mb: 4 }}>
          <Form.Label htmlFor='recipientName'>
            {contentPhysicalGiftCards.recipientDetailsNameLabel}
          </Form.Label>
          <Form.Control
            id='recipientName'
            name='recipientName'
            type='text'
            placeholder={
              contentPhysicalGiftCards.recipientDetailsNamePlaceholder
            }
            onChange={handleNameChange}
            required
            maxLength={50}
            value={recipientState.name}
            isInvalid={
              recipientState.nameIsValidated && !recipientState.nameIsValid
            }
            isValid={
              recipientState.nameIsValidated && recipientState.nameIsValid
            }
            onBlur={() =>
              setRecipientState({ ...recipientState, nameIsValidated: true })
            }
          />
          <Form.Control.Feedback type='invalid'>
            {contentPhysicalGiftCards.invalidInputText}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group sx={{ mb: 4 }}>
          <Form.Label htmlFor='recipientMessage'>
            {contentPhysicalGiftCards.recipientDetailsOptionalMessageLabel}
          </Form.Label>
          <Form.Control
            id='recipientMessage'
            name='recipientMessage'
            as='textarea'
            rows={6}
            placeholder={
              contentPhysicalGiftCards.recipientDetailsOptionalMessagePlaceholder
            }
            onChange={handleMessageChange}
            maxLength={1000}
            value={recipientState.message}
            isInvalid={
              recipientState.messageIsValidated &&
              !recipientState.messageIsValid
            }
            isValid={
              recipientState.messageIsValidated && recipientState.messageIsValid
            }
            onBlur={() =>
              handleRecipientChange({
                ...recipientState,
                messageIsValidated: true,
              })
            }
          />
          <Form.Control.Feedback type='invalid'>
            {contentPhysicalGiftCards.invalidInputText}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group sx={{ mb: 4 }}>
          <Form.Label htmlFor='recipientAddressLineOne'>
            {contentPhysicalGiftCards.recipientDetailsAddressLineOneLabel}
          </Form.Label>
          <Form.Control
            id='recipientAddressLineOne'
            name='recipientAddressLineOne'
            type='text'
            placeholder={
              contentPhysicalGiftCards.recipientDetailsAddressLineOnePlaceholder
            }
            onChange={handleAddressLineOneChange}
            required
            maxLength={50}
            value={recipientState.addressLineOne}
            isInvalid={
              recipientState.addressLineOneIsValidated &&
              !recipientState.addressLineOneIsValid
            }
            isValid={
              recipientState.addressLineOneIsValidated &&
              recipientState.addressLineOneIsValid
            }
            onBlur={() =>
              setRecipientState({
                ...recipientState,
                addressLineOneIsValidated: true,
              })
            }
          />
          <Form.Control.Feedback type='invalid'>
            {contentPhysicalGiftCards.invalidInputText}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group sx={{ mb: 4 }}>
          <Form.Label htmlFor='recipientAddressLineTwo'>
            {contentPhysicalGiftCards.recipientDetailsAddressLineTwoLabel}
          </Form.Label>
          <Form.Control
            id='recipientAddressLineTwo'
            name='recipientAddressLineTwo'
            type='text'
            placeholder={
              contentPhysicalGiftCards.recipientDetailsAddressLineTwoPlaceholder
            }
            onChange={handleAddressLineTwoChange}
            maxLength={50}
            value={recipientState.addressLineTwo}
            isInvalid={
              recipientState.addressLineTwoIsValidated &&
              !recipientState.addressLineTwoIsValid
            }
            isValid={
              recipientState.addressLineTwoIsValidated &&
              recipientState.addressLineTwoIsValid
            }
            onBlur={() =>
              setRecipientState({
                ...recipientState,
                addressLineTwoIsValidated: true,
              })
            }
          />
          <Form.Control.Feedback type='invalid'>
            {contentPhysicalGiftCards.invalidInputText}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group sx={{ mb: 4 }}>
          <Form.Label htmlFor='recipientCity'>
            {contentPhysicalGiftCards.recipientDetailsCityLabel}
          </Form.Label>
          <Form.Control
            id='recipientCity'
            name='recipientCity'
            type='text'
            placeholder={
              contentPhysicalGiftCards.recipientDetailsCityPlaceholder
            }
            onChange={handleCityChange}
            required
            maxLength={50}
            value={recipientState.city}
            isInvalid={
              recipientState.cityIsValidated && !recipientState.cityIsValid
            }
            isValid={
              recipientState.cityIsValidated && recipientState.cityIsValid
            }
            onBlur={() =>
              setRecipientState({
                ...recipientState,
                cityIsValidated: true,
              })
            }
          />
          <Form.Control.Feedback type='invalid'>
            {contentPhysicalGiftCards.invalidInputText}
          </Form.Control.Feedback>
        </Form.Group>
        {showStateInput && (
          <Form.Group sx={{ mb: 4 }}>
            <Form.Label htmlFor='recipientState'>
              {contentPhysicalGiftCards.recipientDetailsStateLabel}
            </Form.Label>
            <Form.Control
              id='recipientState'
              name='recipientState'
              type='text'
              placeholder={
                contentPhysicalGiftCards.recipientDetailsStatePlaceholder
              }
              onChange={handleStateChange}
              required
              maxLength={50}
              value={recipientState.state ?? ''}
              isInvalid={
                recipientState.stateIsValidated && !recipientState.stateIsValid
              }
              isValid={
                recipientState.stateIsValidated && recipientState.stateIsValid
              }
              onBlur={() =>
                setRecipientState({
                  ...recipientState,
                  stateIsValidated: true,
                })
              }
            />
            <Form.Control.Feedback type='invalid'>
              {contentPhysicalGiftCards.invalidInputText}
            </Form.Control.Feedback>
          </Form.Group>
        )}
        <Form.Group sx={{ mb: 4 }}>
          <Form.Label htmlFor='recipientPostcode'>
            {contentPhysicalGiftCards.recipientDetailsPostcodeLabel}
          </Form.Label>
          <Form.Control
            id='recipientPostcode'
            name='recipientPostcode'
            type='text'
            placeholder={
              contentPhysicalGiftCards.recipientDetailsPostcodePlaceholder
            }
            onChange={handlePostcodeChange}
            required
            maxLength={50}
            value={recipientState.postcode}
            isInvalid={
              recipientState.postcodeIsValidated &&
              !recipientState.postcodeIsValid
            }
            isValid={
              recipientState.postcodeIsValidated &&
              recipientState.postcodeIsValid
            }
            onBlur={() =>
              setRecipientState({
                ...recipientState,
                postcodeIsValidated: true,
              })
            }
          />
          <Form.Control.Feedback type='invalid'>
            {contentPhysicalGiftCards.invalidInputText}
          </Form.Control.Feedback>
        </Form.Group>
      </Form>
    </div>
  );
};

export default PhysicalConcessionRecipientDetails;
