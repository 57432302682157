import React from 'react';

import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';

import { JOURNEY_TYPES } from '../../../constants';
import { useBoostNavigate } from '../../../hooks/useBoostNavigate';
import { buildQueryString } from '../../../services/QueryStringHelper';
import { actionCreators } from '../../../store/ActionCreators';
import {
  selectBookingData,
  selectContent,
  selectJourneyTypeConfig,
  selectSource,
} from '../../../store/Selectors';
import ActionButton from '../actionbutton/ActionButton';
import LoginSection from '../login/LoginSection';

interface Props {
  setIsDropdownOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const ExtraMenuLoginOptions: React.FC<Props> = ({ setIsDropdownOpen }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_cookies, _setCookies, removeCookies] = useCookies();
  const boostNavigate = useBoostNavigate();
  const dispatch = useDispatch();
  const journeyTypeConfig = useSelector(selectJourneyTypeConfig);
  const source = useSelector(selectSource);
  const content = useSelector(selectContent);
  const bookingData = useSelector(selectBookingData);

  const buttonText = bookingData.isUserValidated
    ? content.extraMenuLogoutButtonText
    : content.extraMenuLoginButtonText;

  const handleLogOut = () => {
    setIsDropdownOpen(false);
    removeCookies('requestData', { path: '/' });
    if (journeyTypeConfig.type === JOURNEY_TYPES.LOYALTY_UPGRADE) {
      dispatch(actionCreators.clearAvailableConcessions());
    }
    const qs = buildQueryString({
      circuitId: bookingData.circuitId,
      source: source,
    });

    boostNavigate.navigateToStartJourneyUsingGlobalState(qs);
  };

  return bookingData.isUserValidated ? (
    <ActionButton
      contained
      hasMarginX
      onClick={() => handleLogOut()}
      showIcon
      variant='secondary'
    >
      {buttonText}
    </ActionButton>
  ) : (
    <LoginSection
      loginButtonHasNoMarginX
      setIsDropdownOpen={setIsDropdownOpen}
    />
  );
};

export default ExtraMenuLoginOptions;
