import React, { useContext, useMemo } from 'react';

import { useWindowSize } from '../hooks/useWindowSize';

export interface ProviderProps {
  children: React.ReactNode;
}

export interface Props {
  screenWidth: number;
  isLargeScreenWidth: boolean;
  isMobileScreenWidth: boolean;
  isExtraSmallScreenWidth: boolean;
}

export const ScreenWidthContext = React.createContext<Props>({
  screenWidth: 0,
  isLargeScreenWidth: false,
  isMobileScreenWidth: false,
  isExtraSmallScreenWidth: false,
});

export const ScreenWidthProvider: React.FC<ProviderProps> = ({ children }) => {
  const screenWidth = useWindowSize();
  const mediumWidth = 992;
  const mobileWidth = 768;
  const xsWidth = 576;

  const screenWidthProviderValue = useMemo(() => {
    return {
      screenWidth,
      isLargeScreenWidth: screenWidth >= mediumWidth,
      isMobileScreenWidth: screenWidth < mobileWidth,
      isExtraSmallScreenWidth: screenWidth < xsWidth,
    } as Props;
  }, [screenWidth]);

  return (
    <ScreenWidthContext.Provider value={screenWidthProviderValue}>
      {children}
    </ScreenWidthContext.Provider>
  );
};

export const useScreenWidth = () => {
  return useContext(ScreenWidthContext);
};
