const loadSpreedlyCybersourceDeviceFingerprint = (
  callback,
  orgId,
  sessionId,
  merchantId
) => {
  const scriptId = 'spreedly-cybersource-device-fingerprint';
  const existingScript = document.getElementById(scriptId);
  if (!existingScript) {
    createScriptTag();
  } else {
    handleScriptExists();
  }

  function createScriptTag() {
    const headurl = `https://h.online-metrix.net/fp/tags.js?org_id=${orgId}&session_id=${merchantId}${sessionId}`;
    const bodyUrl = `https://h.online-metrix.net/fp/tags?org_id=${orgId}&session_id=${merchantId}${sessionId}`;

    const script = document.createElement('script');
    script.src = headurl;
    script.id = scriptId;
    document.head.appendChild(script);

    const noscript = document.createElement('noscript');
    noscript.id = 'spreedly-cybersource-device-fingerprint-body';

    const iframe = document.createElement('iframe');
    iframe.style =
      'width: 100px; height: 100px; border: 0; position: absolute; top: -5000px';
    iframe.src = bodyUrl;

    noscript.appendChild(iframe);
    document.body.appendChild(noscript);
    script.onload = () => {
      callback(sessionId);
    };
  }

  function handleScriptExists() {
    const sessionIdRegex = new RegExp(
      `^(.\\S*)(session_id=${merchantId})(\\S*)$`
    );

    const existingScriptSrcAttrValue =
      existingScript.attributes.getNamedItem('src').value;
    const matchesArray = sessionIdRegex.exec(existingScriptSrcAttrValue);
    const sessionId = matchesArray[matchesArray.length - 1];
    callback(sessionId);
  }
};

export default loadSpreedlyCybersourceDeviceFingerprint;
