/** @jsxImportSource theme-ui */
import React from 'react';

import { useSelector } from 'react-redux';

import { DeliveryPreference } from '../../../@types/enums';
import {
  selectContent,
  selectDeliveryPreference,
  selectHasSeatsWithSeatDeliveryAvailable,
  selectOrderToSeatOnly,
  selectSelectedDeliveryWindow,
} from '../../../store/Selectors';

const CartSummaryDeliveryOptions: React.FC = () => {
  const content = useSelector(selectContent);
  const hasSeatsWithSeatDeliveryAvailable = useSelector(
    selectHasSeatsWithSeatDeliveryAvailable
  );
  const selectedDeliveryWindow = useSelector(selectSelectedDeliveryWindow);
  const isOrderToSeatOnly = useSelector(selectOrderToSeatOnly);
  const deliveryPreference = useSelector(selectDeliveryPreference);

  const useDeliveryToSeatLabel =
    deliveryPreference === DeliveryPreference.ORDER_TO_SEAT ||
    isOrderToSeatOnly ||
    hasSeatsWithSeatDeliveryAvailable;

  return (
    <div className='cart-summary-row' data-testid='cs-delivery-options'>
      <div className='cart-summary-row-flex'>
        <span sx={{ textAlign: 'left' }}>
          {content.cartSummary.deliveryOptionLabel}
        </span>
        <span sx={{ textAlign: 'right' }}>
          <span>
            {useDeliveryToSeatLabel
              ? content.concessionsOnlyJourney.deliveryToSeatLabel
              : content.concessionsOnlyJourney.collectFromKioskLabel}

            {selectedDeliveryWindow?.deliveryWindowDescription && (
              <>
                <span className='dot-separator'>·</span>
                {selectedDeliveryWindow.deliveryWindowDescription}
              </>
            )}
          </span>
        </span>
      </div>

      {selectedDeliveryWindow?.comment && (
        <div className='cart-summary-row-flex' sx={{ mt: 2 }}>
          {content.cartSummary.deliveryCommentLabel}
          <span
            sx={{
              ml: 1,
              textAlign: 'right',
              wordWrap: 'break-word',
              wordBreak: 'break-word',
            }}
          >
            {selectedDeliveryWindow.comment}
          </span>
        </div>
      )}
    </div>
  );
};

export default CartSummaryDeliveryOptions;
