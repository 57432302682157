/** @jsxImportSource theme-ui */
import React from 'react';

import { useSelector } from 'react-redux';

import { GlobalState, TicketTypeModel } from '../../../@types/modelTypes';
import { displayPrice } from '../../../services/Helpers';
import {
  selectContent,
  selectCurrencyConfig,
  selectDisplayOriginalPrice,
  selectOriginalPriceToDisplayInTicketsStep,
  selectTicketBookingFeeInTicketsStep,
} from '../../../store/Selectors';

interface Props {
  ticket: TicketTypeModel;
}

const CartSummaryTicketOriginalPriceDisplay: React.FC<Props> = ({ ticket }) => {
  const content = useSelector(selectContent);
  const displayOriginalPrice = useSelector((state: GlobalState) =>
    selectDisplayOriginalPrice(state, ticket)
  );
  const bookingFee = useSelector((state: GlobalState) =>
    selectTicketBookingFeeInTicketsStep(state, ticket)
  );
  const originalPriceToDisplayInTicketsStep = useSelector(
    (state: GlobalState) =>
      selectOriginalPriceToDisplayInTicketsStep(state, ticket, bookingFee)
  );
  const currencyConfig = useSelector(selectCurrencyConfig);

  if (!displayOriginalPrice || !content) {
    return null;
  }

  return (
    <p className='small' sx={{ textTransform: 'uppercase', m: 0 }}>
      {content.cartSummary.wasLabel}{' '}
      {displayPrice(originalPriceToDisplayInTicketsStep, currencyConfig)}
    </p>
  );
};

export default CartSummaryTicketOriginalPriceDisplay;
