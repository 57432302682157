/** @jsxImportSource theme-ui */
import React from 'react';

import { useSelector } from 'react-redux';

import { useBoostNavigate } from '../../../hooks/useBoostNavigate';
import { useValidateSeasonPassJourney } from '../../../hooks/useValidateSeasonPassJourney';
import {
  selectContent,
  selectSelectedSeasonPassItem,
} from '../../../store/Selectors';
import SeasonPassSeatsMoviesContainer from '../../common/seasonpass/SeasonPassSeatsMoviesContainer';
import SelectedSeasonPassStepContainer from '../../common/seasonpass/SelectedSeasonPassStepContainer';

const SeasonPassSeats: React.FC = () => {
  const content = useSelector(selectContent);
  const boostNavigate = useBoostNavigate();
  const selectedSeasonPassItem = useSelector(selectSelectedSeasonPassItem);

  const stepRequiredDataAreValid =
    content?.seasonPass && selectedSeasonPassItem;

  useValidateSeasonPassJourney(!!stepRequiredDataAreValid);

  if (!stepRequiredDataAreValid) {
    return null;
  }

  const handleContinueClick = () => {
    boostNavigate.navigateToNextStep({
      appendCinemaAndSessionIdsFromUrl: true,
    });
  };

  return (
    <SelectedSeasonPassStepContainer
      pageTitle={content.seasonPass.confirmSeatsTitle}
      pageText={content.seasonPass.confirmSeatsRichText}
      continueButtonText={content.continueButtonText}
      onContinueClicked={() => handleContinueClick()}
      disableContinueButton={false}
    >
      <SeasonPassSeatsMoviesContainer />
    </SelectedSeasonPassStepContainer>
  );
};

export default SeasonPassSeats;
