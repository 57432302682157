/** @jsxImportSource theme-ui */
import React, { useState } from 'react';

import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Flex } from 'theme-ui';

import DeliveryWindowsList from './DeliveryWindowsList';

import {
  DeliveryPreference,
  FoodAndBeverageDeliveryWindowsDisplayOptionEnum,
} from '../../../@types/enums';
import { actionCreators } from '../../../store/ActionCreators';
import {
  selectCollectFromKioskOnly,
  selectContent,
  selectDeliveryWindows,
  selectFoodAndBeverageDeliveryWindowsDisplayOption,
  selectHasSeatsWithSeatDeliveryAvailable,
  selectIsDeliveryOptionBasedOnSeatingArea,
  selectOrderToSeatOnly,
} from '../../../store/Selectors';
import { ReactComponent as ChevronLeftSvg } from '../../../svgs/chevronLeft.svg';
import ActionButton from '../actionbutton/ActionButton';
import ContainedRow from '../layout/ContainedRow';

interface Props {
  continueButtonClick: (selectedDeliveryPreference: DeliveryPreference) => void;
  continueButtonText: string;
  hideDeliveryOptions: () => void;
}

const DeliveryOptions: React.FC<Props> = ({
  continueButtonClick,
  continueButtonText,
  hideDeliveryOptions,
}) => {
  const dispatch = useDispatch();

  const content = useSelector(selectContent);
  const deliveryWindows = useSelector(selectDeliveryWindows);
  const isOrderToSeatOnly = useSelector(selectOrderToSeatOnly);
  const isCollectFromKioskOnly = useSelector(selectCollectFromKioskOnly);
  const hasSeatsWithSeatDeliveryAvailable = useSelector(
    selectHasSeatsWithSeatDeliveryAvailable
  );
  const isDeliveryOptionBasedOnSeatingArea = useSelector(
    selectIsDeliveryOptionBasedOnSeatingArea
  );
  const foodAndBeverageDeliveryWindowsDisplayOption = useSelector(
    selectFoodAndBeverageDeliveryWindowsDisplayOption
  );
  const [isOrderToSeatSelected, setIsOrderToSeatSelected] = useState(
    hasSeatsWithSeatDeliveryAvailable || false
  );
  const [isCollectFromKioskSelected, setIsCollectFromKioskSelected] =
    useState(false);

  const displayDeliveryWindowsOnDeliverToMySeat =
    !!deliveryWindows?.length &&
    foodAndBeverageDeliveryWindowsDisplayOption ==
      FoodAndBeverageDeliveryWindowsDisplayOptionEnum.DisplayDeliveryWindowsOnDeliverToMySeat;

  const displayDeliveryWindowsOnPickupFromKiosk =
    !!deliveryWindows?.length &&
    foodAndBeverageDeliveryWindowsDisplayOption ==
      FoodAndBeverageDeliveryWindowsDisplayOptionEnum.DisplayDeliveryWindowsOnPickupFromKiosk;

  const shouldShowDeliveryWindows =
    (isOrderToSeatOnly ||
      isOrderToSeatSelected ||
      isCollectFromKioskOnly ||
      isCollectFromKioskSelected) &&
    (displayDeliveryWindowsOnDeliverToMySeat ||
      displayDeliveryWindowsOnPickupFromKiosk);

  const handleDeliverToSeatButtonClick = () => {
    setIsOrderToSeatSelected(true);
    setIsCollectFromKioskSelected(false);
    dispatch(
      actionCreators.setDeliveryPreference(DeliveryPreference.ORDER_TO_SEAT)
    );
    if (!displayDeliveryWindowsOnDeliverToMySeat) {
      continueButtonClick(DeliveryPreference.ORDER_TO_SEAT);
      hideDeliveryOptions();
    }
  };

  const handleCollectFromKioskButtonClick = () => {
    setIsOrderToSeatSelected(false);
    setIsCollectFromKioskSelected(true);
    dispatch(
      actionCreators.setDeliveryPreference(
        DeliveryPreference.COLLECT_FROM_KIOSK
      )
    );
    if (!displayDeliveryWindowsOnPickupFromKiosk) {
      continueButtonClick(DeliveryPreference.COLLECT_FROM_KIOSK);
      hideDeliveryOptions();
    }
  };

  const handleCloseButtonClick = () => {
    if (!isDeliveryOptionBasedOnSeatingArea) {
      setIsOrderToSeatSelected(false);
      setIsCollectFromKioskSelected(false);
      dispatch(actionCreators.setDeliveryPreference(null));
    }
    hideDeliveryOptions();
  };

  const handleBackButtonClick = () => {
    setIsOrderToSeatSelected(false);
    setIsCollectFromKioskSelected(false);
    dispatch(actionCreators.setDeliveryPreference(null));
  };

  const handleContinueButtonClick = () => {
    let deliveryPreference = DeliveryPreference.ORDER_TO_SEAT;

    if (isCollectFromKioskOnly && displayDeliveryWindowsOnPickupFromKiosk) {
      deliveryPreference = DeliveryPreference.COLLECT_FROM_KIOSK;
    }

    dispatch(actionCreators.setDeliveryPreference(deliveryPreference));

    continueButtonClick(deliveryPreference);
  };

  const showBackButton =
    !hasSeatsWithSeatDeliveryAvailable &&
    (isOrderToSeatSelected || isCollectFromKioskSelected) &&
    (displayDeliveryWindowsOnDeliverToMySeat ||
      displayDeliveryWindowsOnPickupFromKiosk);

  const showActionButtons =
    !isOrderToSeatOnly &&
    !isOrderToSeatSelected &&
    !isCollectFromKioskOnly &&
    !isCollectFromKioskSelected;
  return (
    <ContainedRow
      classNameWrapper='delivery-options'
      styles={{
        backgroundColor: 'boxBackground',
        borderColor: 'primary',
        textAlign: 'center',
        '.top-buttons': {
          button: {
            '.icon': {
              fill: 'primary',
            },

            '&:hover': {
              color: 'primary',
            },
          },
        },

        '.delivery-options-list': {
          borderTop: 'boxSeparatorDashed',

          '.delivery-option': {
            borderTop: 'boxSeparator',

            button: {
              '.unsel': {
                '.a': {
                  fill: 'boxBackground',
                },
                '.b': {
                  fill: 'mostReadableOnWebsiteBackground',
                },
              },

              '.sel': {
                '.a': {
                  fill: 'boxBackground',
                },
                '.b': {
                  fill: 'mostReadableOnWebsiteBackground',
                },
                '.c': {
                  fill: 'mostReadableOnWebsiteBackground',
                },
              },
            },
          },
        },

        '.delivery-comment': {
          input: {
            backgroundColor: 'formInputBackground',
            border: 'mostReadableOnWebsiteBackgroundBorder',
            color: 'mostReadableOnWebsiteBackground',
          },
        },
      }}
    >
      <Flex
        className='top-buttons'
        my={2}
        sx={{
          justifyContent: showBackButton ? 'space-between' : 'flex-end',
          alignItems: 'center',
        }}
      >
        {showBackButton && (
          <Flex sx={{ justifyContent: 'flex-start', alignItems: 'center' }}>
            <Button
              className='tiny'
              onClick={() => handleBackButtonClick()}
              variant='link'
              sx={{ display: 'flex', alignItems: 'center' }}
            >
              <ChevronLeftSvg sx={{ display: 'flex' }} />{' '}
              {content.concessionsOnlyJourney.goBackButtonText}
            </Button>
          </Flex>
        )}
        <Flex sx={{ justifyContent: 'flex-end', alignItems: 'center' }}>
          <Button
            className='tiny'
            onClick={() => handleCloseButtonClick()}
            variant='link'
          >
            {content.concessionsOnlyJourney.closeButtonText}
          </Button>
        </Flex>
      </Flex>

      <Box sx={{ px: [0, 0, 4] }}>
        <h1>
          {shouldShowDeliveryWindows
            ? content.concessionsOnlyJourney.deliveryWindowsListHeading
            : content.concessionsOnlyJourney.deliveryOptionsHeading}
        </h1>

        {shouldShowDeliveryWindows && (
          <DeliveryWindowsList
            continueButtonClick={async () => handleContinueButtonClick()}
            continueButtonText={continueButtonText}
          />
        )}

        {showActionButtons && (
          <div data-testid='delivery-option-buttons'>
            <ActionButton
              onClick={() => handleDeliverToSeatButtonClick()}
              showIcon
              contained
              mb={0}
              mt={3}
              hasMarginX
              variant='secondary'
            >
              {content.concessionsOnlyJourney.deliveryToSeatLabel}
            </ActionButton>

            <ActionButton
              onClick={() => handleCollectFromKioskButtonClick()}
              showIcon
              contained
              mt={3}
              hasMarginX
              variant='primary'
            >
              {content.concessionsOnlyJourney.collectFromKioskLabel}
            </ActionButton>
          </div>
        )}
      </Box>
    </ContainedRow>
  );
};

export default DeliveryOptions;
