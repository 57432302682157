import React from 'react';

import classnames from 'classnames';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Grid } from 'theme-ui';

import messages from './intl';
import LegendItem from './LegendItem';

import { SeatCustomStyle } from '../../../@types/modelTypes';
import { SEAT_TYPE } from '../../../constants';
import { useScreenWidth } from '../../../contextProviders/screenWidthContext';
import {
  selectSeatsModel,
  selectSelectedLanguageCulture,
} from '../../../store/Selectors';
import { WidgetData } from '../types';

interface Props {
  showUnavailableWithSelectedTicketsInLegend: boolean;
  widget: WidgetData<'TicketingCMSJourneySeatSelectionWidget'>;
}

const Legend: React.FC<Props> = ({
  showUnavailableWithSelectedTicketsInLegend,
  widget,
}) => {
  const { isMobileScreenWidth } = useScreenWidth();
  const { formatMessage } = useIntl();

  const selectedLanguageCulture = useSelector(selectSelectedLanguageCulture);
  const availableSeatTypes = useSelector(selectSeatsModel).availableSeatTypes;

  const getUnavailableWithSelectedTicketsIcon = () => {
    const icon = { svgType: 'Custom', css: '', svgSource: '' };

    if (availableSeatTypes.customSeatStyles.length > 0) {
      const customIcons = availableSeatTypes.customSeatStyles.filter(
        (x) => !x.data.hideLegend
      );
      const sofaIcon = customIcons.find(
        (seatCustomStyle: SeatCustomStyle) =>
          seatCustomStyle.data.seatType === SEAT_TYPE.SOFA_SEAT
      );

      if (sofaIcon) {
        icon.css = 'sofa';
        if (sofaIcon.data.siblingId) {
          const sibling = availableSeatTypes.customSeatStyles.find(
            (x) => x.id === sofaIcon.data.siblingId
          );
          if (sibling) {
            icon.svgSource = `${sofaIcon.svg}${sibling.svg}`;
          } else {
            icon.svgSource = customIcons[0].svg;
          }
        }
      } else {
        icon.svgSource = customIcons[0].svg;
      }
    } else {
      icon.svgType = 'SeatSvg';
    }

    return icon;
  };

  const getUnavailableWithSelectedTickets = () => {
    const unavailWithTicket = getUnavailableWithSelectedTicketsIcon();
    return (
      <LegendItem
        label={formatMessage(messages.titleUnavailableWithSelectedTicketsText)}
        svgType={unavailWithTicket.svgType}
        svgSource={unavailWithTicket.svgSource}
        svgClassName={classnames(unavailWithTicket.css, 'available disabled')}
      />
    );
  };
  return (
    <Grid
      className='seating-legend seat'
      sx={{
        p: !isMobileScreenWidth
          ? `0 ${widget.shape?.seatMapXPaddingPx}px`
          : '14px',
        py: 6,
      }}
      columns={[2, 3]}
    >
      <LegendItem
        label={formatMessage(messages.titleAvailableText)}
        svgType='SeatSvg'
        svgClassName='available'
      />
      {availableSeatTypes.hasLuxuryRecliners && (
        <LegendItem
          label={formatMessage(messages.titleLuxuryReclinerText)}
          svgType='SeatLuxurySvg'
          svgClassName='available recliner'
        />
      )}
      <LegendItem
        label={formatMessage(messages.titleSelectedText)}
        svgType='SeatSvg'
        svgClassName='selected'
      />
      <LegendItem
        label={formatMessage(messages.titleUnavailableText)}
        svgType='SeatSvg'
        svgClassName='unavailable'
      />

      {availableSeatTypes.hasWc && (
        <LegendItem
          label={formatMessage(messages.titleAccessibleText)}
          svgType='WheelchairSvg'
          svgClassName='with-icon available'
        />
      )}
      {availableSeatTypes.hasC && (
        <LegendItem
          label={formatMessage(messages.titleCompanionText)}
          svgType='CompanionSvg'
          svgClassName='with-icon available'
        />
      )}
      {availableSeatTypes.hasCouch && (
        <LegendItem
          label={formatMessage(messages.titleSofaText)}
          svgType={`SofaLeftSvgSofaRightSvg`}
          svgClassName='sofa available'
        />
      )}
      {availableSeatTypes.hasObstructedView && (
        <LegendItem
          label={formatMessage(messages.titleObstructedView)}
          svgType='ObstructedSvg'
          svgClassName='with-icon available'
        />
      )}
      {availableSeatTypes.hasBuffer && (
        <LegendItem
          label={formatMessage(messages.titleBufferText)}
          svgType='SeatBufferSvg'
          svgClassName='with-icon buffer'
        />
      )}
      {availableSeatTypes.customSeatStyles.length > 0 &&
        availableSeatTypes.customSeatStyles
          .filter((x) => !x.data.hideLegend)
          .map((seatCustomStyle: SeatCustomStyle) => {
            const cssClass = classnames(
              'available',
              seatCustomStyle.data.seatType === SEAT_TYPE.SOFA_SEAT && 'sofa'
            );
            const legendLabel =
              seatCustomStyle.data.content[selectedLanguageCulture][
                'legendLabel'
              ];
            let svgSource = seatCustomStyle.svg;
            if (seatCustomStyle.data.siblingId) {
              const sibling = availableSeatTypes.customSeatStyles.find(
                (x) => x.id === seatCustomStyle.data.siblingId
              );
              if (sibling) {
                svgSource = `${seatCustomStyle.svg}${sibling.svg}`;
              }
            }
            return (
              <LegendItem
                key={`custom_${seatCustomStyle.id}`}
                label={legendLabel}
                svgType='Custom'
                svgSource={svgSource}
                svgClassName={cssClass}
              />
            );
          })}
      {availableSeatTypes.hasEntrance && (
        <LegendItem
          label={formatMessage(messages.titleEntranceText)}
          svgType='EntranceLeftSvg'
          svgClassName=''
        />
      )}
      {availableSeatTypes.hasEmergencyExit && (
        <LegendItem
          label={formatMessage(messages.titleEmergencyExitText)}
          svgType='EmergencyExitSvg'
          svgClassName=''
        />
      )}
      {availableSeatTypes.hasStairs && (
        <LegendItem
          label={formatMessage(messages.titleStairsText)}
          svgType='StairsUp'
          svgClassName=''
        />
      )}
      {showUnavailableWithSelectedTicketsInLegend &&
        getUnavailableWithSelectedTickets()}
    </Grid>
  );
};

export default Legend;
