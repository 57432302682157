import { buildQueryString } from './QueryStringHelper';

import { GlobalState, JourneyTypeConfig } from '../@types/modelTypes';
import { JOURNEY_TYPES } from '../constants';

export const handleExternalLogInClick = (
  journeyTypeConfig: JourneyTypeConfig,
  bookingData: GlobalState['bookingData'],
  source: GlobalState['source'],
  loginUrl: GlobalState['config']['signIn']['loginUrl']
) => {
  let returnPath: string;
  const queryString = buildQueryString({
    circuitId: bookingData.circuitId,
    source: source,
  });
  if (journeyTypeConfig.type === JOURNEY_TYPES.CONCESSIONS_ONLY) {
    returnPath = `/api/startconcessions/redirect/${bookingData.cinemaId}${queryString}`;
  } else if (journeyTypeConfig.type === JOURNEY_TYPES.GIFTCARDS_ONLY) {
    returnPath = `/api/startgiftcards/redirect/${bookingData.cinemaId}${queryString}`;
  } else if (journeyTypeConfig.type === JOURNEY_TYPES.PHYSICAL_GIFTCARDS_ONLY) {
    returnPath = `/api/startphysicalgiftcards/redirect/${bookingData.cinemaId}${queryString}`;
  } else if (journeyTypeConfig.type === JOURNEY_TYPES.GIFTMEMBERSHIP_ONLY) {
    returnPath = `/api/giftmembership/redirect/${bookingData.cinemaId}${queryString}`;
  } else if (journeyTypeConfig.type === JOURNEY_TYPES.VOUCHERS_ONLY) {
    returnPath = `/api/startconcessions/vouchers/redirect${queryString}`;
  } else if (journeyTypeConfig.type === JOURNEY_TYPES.SEASON_PASS) {
    returnPath = `/api/startseasonpass/redirect?cinemaId=${bookingData.cinemaId}${queryString}`;
  } else {
    returnPath = `/api/startticketing/redirect/${bookingData.externalCinemaId}/${bookingData.externalSessionId}${queryString}`;
  }
  const url = `${loginUrl}?return=${returnPath}`;
  window.location.assign(url);
};

export const handleRegisterClick = (
  registerUrl: GlobalState['config']['signIn']['registerUrl']
) => {
  window.open(registerUrl, '_blank');
};
