export const seatStatuses = {
  AVAILABLE: 0,
  UNAVAILABLE: 1,
  SELECTED: 5,
  BROKEN: 6,
  BUFFER: 7,
  WHITESPACE: 99,
};

export const seatTypes = {
  NORMAL: 1,
  WHEELCHAIR: 2,
  SOFA_SEAT: 3,
  VIP: 4,
  COMPANION: 5,
  LUXURY_RECLINER: 6,
  OBSTRUCTED_VIEW: 7,
  NON_BOOKABLE_ELEMENT: 8,
  CUSTOM_STYLE: 100,
};

export const seatStyles = {
  NORMAL: 0,
  SOFA_LEFT: 1,
  SOFA_MIDDLE: 2,
  SOFA_RIGHT: 3,
  ENTRANCE_LEFT: 4,
  ENTRANCE_RIGHT: 5,
  EMERGENCY_EXIT: 6,
  EMERGENCY_EXIT_LEFT: 7,
  EMERGENCY_EXIT_RIGHT: 8,
  STAIRS_UP: 9,
  STAIRS_DOWN: 10,
};
