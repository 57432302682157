/** @jsxImportSource theme-ui */
import React from 'react';

import { useSelector } from 'react-redux';

import { selectConfig } from '../../../store/Selectors';
import ContainedRow from '../layout/ContainedRow';

const GMAP_BASE_URL = 'https://www.google.com/maps/embed/v1/place';
const GMAP_ZOOM = 17;

interface Props {
  title?: string;
}

const GoogleMap: React.FC<Props> = ({ title }) => {
  const config = useSelector(selectConfig);

  const googleMapSrc =
    config.currentCinema.gmapsPlaceName && config.gmapsApikey
      ? `${GMAP_BASE_URL}?q=${encodeURIComponent(
          config.currentCinema.gmapsPlaceName
        )}&zoom=${GMAP_ZOOM}&key=${config.gmapsApikey}`
      : '';

  if (!googleMapSrc) return null;

  return (
    <ContainedRow dataTestId='gmap'>
      {!!title && <h2 sx={{ mt: 7 }}>{title}</h2>}
      <div sx={{ mt: 5 }}>
        <iframe
          title='Map'
          style={{ height: '280px', width: '100%', border: '0 none' }}
          src={googleMapSrc}
        />
      </div>
    </ContainedRow>
  );
};

export default GoogleMap;
