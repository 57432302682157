import React, { useState } from 'react';

import classNames from 'classnames';
import { Form } from 'react-bootstrap';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from 'theme-ui';

import messages from './intl';

import { QuantitySelectorContext } from '../../../@types/actionTypes';
import {
  TicketTypeModel,
  GroupedTicketTypes,
} from '../../../@types/modelTypes';
import { TrackingEvent } from '../../../@types/trackingTypes';
import { VueEventTrackingStrategy } from '../../../analytics/vueEventTrackingStrategy';
import { PEACH_CODES } from '../../../constants';
import { useRecaptcha } from '../../../contextProviders/recaptchaContext';
import { useTurnstile } from '../../../contextProviders/turnstileContext';
import backend from '../../../services/RestUtilities';
import { actionCreators } from '../../../store/ActionCreators';
import {
  selectConfig,
  selectDazzlerLocale,
  selectIsSeatsFirstJourney,
  selectJourneyTypeConfig,
  selectSelectedSeats,
  selectState,
  selectTicketTypes,
  selectToken,
} from '../../../store/Selectors';
import ActionButton from '../../common/actionbutton/ActionButton';
import ActionButtonSpinner from '../../common/actionbuttonspinner/ActionButtonSpinner';
import BorderedCollapse from '../../common/borderedcollapse/BorderedCollapse';
import ContainedRow from '../../common/layout/ContainedRow';
import VoucherTicketType from '../../common/tickets/VoucherTicketType';
import { resolveLocalisedStringOrDefault } from '../helpers';
import globalMessages from '../intl';
import { WidgetData } from '../types';

const SUCESSFULLYAPPLIED = 'SUCESSFULLYAPPLIED';
const ALREADYAPPLIED = 'ALREADYAPPLIED';
const NOTRECOGNISED = 'NOTRECOGNISED';
const SERVERERROR = 'SERVERERROR';

type Props = {
  widget: WidgetData<'TicketingCMSJourneyVoucherEntryWidget'>;
  orderHasMaxTickets?: boolean;
};

const VoucherEntry: React.FC<Props> = ({ widget, orderHasMaxTickets }) => {
  const dispatch = useDispatch();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { formatMessage } = useIntl();
  const recaptcha = useRecaptcha();
  const turnstile = useTurnstile();

  const config = useSelector(selectConfig);
  const globalState = useSelector(selectState);
  const journeyTypeConfig = useSelector(selectJourneyTypeConfig);
  const locale = useSelector(selectDazzlerLocale);
  const ticketTypes = useSelector(selectTicketTypes);
  const token = useSelector(selectToken);

  const [disableApplyButton, setDisableApplyButton] = useState(false);
  const [feedback, setFeedback] = useState<string | undefined>(undefined);
  const [showVoucherForm, setShowVoucherForm] = useState(
    ticketTypes
      ? ticketTypes.ticketTypeModels.some((t) => t.voucherCode)
      : false
  );
  const [voucherInput, setVoucherInput] = useState('');
  const selectedSeats = useSelector(selectSelectedSeats);
  const isSeatsFirstJourney = useSelector(selectIsSeatsFirstJourney);

  if (!ticketTypes) return null;

  const updateTicketTypes = (ticketTypeModels: TicketTypeModel[]) => {
    dispatch(actionCreators.setTicketTypes({ ticketTypeModels }));
  };

  const trackVoucherEvent = (event: TrackingEvent, discountCode: string) => {
    const vueTracking = new VueEventTrackingStrategy();
    vueTracking.processEvent(event, globalState, {
      pathName: location.pathname,
      dataLayerName: 'adobeDataLayer',
      discountCode,
    });
  };

  const addVoucher = async (voucherCode: string) => {
    if (!executeRecaptcha) return;

    const recaptchaToken = await recaptcha?.getRecaptchaToken(
      'AddVoucherCode',
      executeRecaptcha
    );

    const turnstileToken = await turnstile?.getToken();

    const unlockedRedemptionTicketIds: string[] = ticketTypes.ticketTypeModels
      .filter((x) => x.isRedemptionTicket)
      .map((x) => x.id);
    const data = {
      voucherCode,
      dataToken: token,
      recaptchaToken: recaptchaToken ?? null,
      unlockedRedemptionTicketIds,
    };
    const response = await backend.post(
      'api/Tickets/AddVoucherCode',
      data,
      turnstileToken
    );
    if (response.ok) {
      const responseContent = response.content;
      if (responseContent.peachCode === PEACH_CODES.noError) {
        const voucherTicketGroups: GroupedTicketTypes[] =
          response.content.groupedTicketTypes;
        const ticketTypeModels = ticketTypes.ticketTypeModels;
        voucherTicketGroups.forEach((gpt) => {
          gpt.ticketTypeModels.forEach((t) => {
            t.validatedTopVoucher = { voucherCode: t.voucherCode };
            t.voucherDisplayName = `${t.displayName} (${t.voucherCode.substring(
              0,
              4
            )}...${t.voucherCode.slice(-4)})`;
            ticketTypeModels.push(t);
          });
        });
        updateTicketTypes(ticketTypeModels);
        setFeedback(SUCESSFULLYAPPLIED);
        setVoucherInput('');
        trackVoucherEvent(TrackingEvent.VOUCHER_CODE_VALID, voucherCode);
      } else {
        setFeedback(NOTRECOGNISED);
        trackVoucherEvent(TrackingEvent.VOUCHER_CODE_INVALID, voucherCode);
      }
    } else {
      setFeedback(SERVERERROR);
    }

    turnstile?.resetToken();

    setDisableApplyButton(false);
  };

  const handleVoucherCheck = async () => {
    if (!voucherInput) return;
    setDisableApplyButton(true);
    const exists = ticketTypes.ticketTypeModels.some(
      (item) => item.voucherCode === voucherInput
    );
    if (!exists) {
      addVoucher(voucherInput);
    } else {
      setDisableApplyButton(false);
      setFeedback(ALREADYAPPLIED);
    }
  };

  const handleVoucherClick = (
    ticketTypeId: TicketTypeModel['id'],
    voucherCode: TicketTypeModel['voucherCode'],
    context: QuantitySelectorContext
  ) => {
    const { ticketTypeModels } = ticketTypes;
    const ticketTypeModel = ticketTypeModels.find(
      (x) => x.id === ticketTypeId && x.voucherCode === voucherCode
    );

    if (!ticketTypeModel) {
      return;
    }

    if (context === 'add') {
      ticketTypeModel.quantity += 1;
    } else {
      ticketTypeModel.quantity += -1;

      if (!isSeatsFirstJourney) {
        dispatch(actionCreators.removeAllSeats());
      }
    }

    updateTicketTypes(ticketTypeModels);
  };

  const hasTicketsWithTheSameVoucherAlreadyAdded = (
    voucherCode: TicketTypeModel['voucherCode'],
    ticketMaxQuantity: TicketTypeModel['maxQuantity']
  ) => {
    const ticketsWithTheSameVoucher = ticketTypes.ticketTypeModels.filter(
      (t) => t.voucherCode === voucherCode
    );

    if (!ticketsWithTheSameVoucher.length) {
      return false;
    }

    const ticketsWithTheSameVoucherSelectedQuantity =
      ticketsWithTheSameVoucher.reduce((a, b) => a + (b.quantity || 0), 0);

    return ticketMaxQuantity === ticketsWithTheSameVoucherSelectedQuantity;
  };

  const shouldDisplayTicket = (t: TicketTypeModel) => {
    return (
      t.voucherCode &&
      !t.isThirdPartyMemberVoucher &&
      (!journeyTypeConfig.isSeatsFirst ||
        selectedSeats.some((x) => x.areaCategoryCode === t.areaCategoryCode))
    );
  };

  return (
    <ContainedRow>
      <BorderedCollapse
        closeButtonText={resolveLocalisedStringOrDefault(
          formatMessage(globalMessages.closeButtonText),
          locale,
          widget.shape?.voucherEntryHeading
        )}
        heading={resolveLocalisedStringOrDefault(
          formatMessage(messages.voucherEntryHeading),
          locale,
          widget.shape?.voucherEntryHeading
        )}
        setShow={setShowVoucherForm}
        show={showVoucherForm}
      >
        <div className='voucher-container' data-testid='voucher-selector'>
          <div className='voucher-selector'>
            <Form>
              <Form.Label
                className={classNames(disableApplyButton && 'disabled')}
              >
                {resolveLocalisedStringOrDefault(
                  formatMessage(messages.voucherEntryLabel),
                  locale,
                  widget.shape?.voucherEntryLabel
                )}
              </Form.Label>
              <Box
                sx={{
                  justifyContent: 'space-between',
                  display: ['block', 'flex'],
                }}
              >
                <Box sx={{ pr: [0, 3], flexGrow: 1 }}>
                  <Form.Control
                    id='voucherNumber'
                    name='voucherNumber'
                    type='text'
                    placeholder={resolveLocalisedStringOrDefault(
                      formatMessage(messages.voucherEntryPlaceholder),
                      locale,
                      widget.shape?.voucherEntryPlaceholder
                    )}
                    onChange={(e) => {
                      setVoucherInput(e.target.value);
                    }}
                    value={voucherInput}
                    disabled={disableApplyButton}
                  />
                </Box>
                <Box sx={{ flexShrink: 0, mt: [4, 0] }}>
                  <ActionButton
                    onClick={handleVoucherCheck}
                    disabled={disableApplyButton}
                    variant='secondary'
                    mb={0}
                    mt={0}
                  >
                    {disableApplyButton ? (
                      <ActionButtonSpinner />
                    ) : (
                      resolveLocalisedStringOrDefault(
                        formatMessage(globalMessages.checkButtonText),
                        locale,
                        widget.shape?.voucherEntryHeading
                      )
                    )}
                  </ActionButton>
                </Box>
              </Box>
            </Form>
            {feedback && feedback !== SUCESSFULLYAPPLIED && (
              <Box className='warning-container' sx={{ mt: 5, p: 5 }}>
                <p>
                  {feedback === ALREADYAPPLIED
                    ? resolveLocalisedStringOrDefault(
                        formatMessage(
                          messages.voucherEntryAlreadyAppliedMessage
                        ),
                        locale,
                        widget.shape?.voucherEntryAlreadyAppliedMessage
                      )
                    : resolveLocalisedStringOrDefault(
                        formatMessage(messages.voucherEntryErrorMessage),
                        locale,
                        widget.shape?.voucherEntryErrorMessage
                      )}
                </p>
              </Box>
            )}
            <div className='voucher-list'>
              {ticketTypes?.ticketTypeModels
                ?.filter((t) => shouldDisplayTicket(t))
                ?.map((t) => (
                  <VoucherTicketType
                    key={`${t.id}${t.voucherCode}`}
                    ticket={t}
                    onClick={handleVoucherClick}
                    disableAdd={
                      orderHasMaxTickets ||
                      t.quantity === t.maxQuantity ||
                      hasTicketsWithTheSameVoucherAlreadyAdded(
                        t.voucherCode,
                        t.maxQuantity
                      )
                    }
                    packageTicketsIncludesLabel={resolveLocalisedStringOrDefault(
                      formatMessage(globalMessages.includesLabel),
                      locale,
                      widget.shape?.voucherEntryHeading
                    )}
                    hideTax={config.currentCinema.hideTax}
                    disableRemove={t.quantity === 0}
                  />
                ))}
            </div>
          </div>
        </div>
      </BorderedCollapse>
    </ContainedRow>
  );
};

export default VoucherEntry;
