/** @jsxImportSource theme-ui */
import React from 'react';

import classnames from 'classnames';
import { Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Box, Grid } from 'theme-ui';

import { PayrixPaymentState } from './PayrixPaymentTypes';

import {
  selectContent,
  selectPaymentProviders,
} from '../../../../../../store/Selectors';
import LabelWithTooltip from '../../common/LabelWithTooltip';

interface Props {
  handleZipCodeChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  paymentState: PayrixPaymentState;
  setPaymentState: React.Dispatch<React.SetStateAction<PayrixPaymentState>>;
}

const PayrixPaymentForm: React.FC<Props> = ({
  handleZipCodeChange,
  paymentState,
  setPaymentState,
}) => {
  const content = useSelector(selectContent);
  const paymentProviders = useSelector(selectPaymentProviders);
  const payFieldBackgroundClass =
    paymentProviders.length > 1 ? 'multi' : 'single';

  return (
    <div
      id='payrixPayment'
      data-testid='payrix-payment'
      sx={{
        '.form-control': {
          '&.payfield': {
            '&.multi': {
              backgroundColor: 'websiteBackground',
            },

            '&.single': {
              backgroundColor: 'boxBackground',
            },
          },
        },
      }}
    >
      <Form noValidate sx={{ mt: 0 }}>
        <Form.Group sx={{ mb: 2 }}>
          <LabelWithTooltip
            labelFor='payment_number'
            helpText={content.payment.cardNumberHelpText}
          >
            {content.payment.cardNumberLabel}
          </LabelWithTooltip>
          <div
            id='numberDiv'
            className={classnames(
              'form-control payfield',
              payFieldBackgroundClass,
              paymentState.cardNumber.isValidated &&
                (paymentState.cardNumber.isValid ? 'is-valid' : 'is-invalid')
            )}
          />
        </Form.Group>
        <Form.Group sx={{ mb: 2 }}>
          <LabelWithTooltip
            labelFor='name'
            helpText={content.payment.nameOnCardHelpText}
          >
            {content.payment.nameOnCardLabel}
          </LabelWithTooltip>
          <div
            id='nameDiv'
            className={classnames(
              'form-control payfield',
              payFieldBackgroundClass,
              paymentState.nameOnCard.isValidated &&
                (paymentState.nameOnCard.isValid ? 'is-valid' : 'is-invalid')
            )}
          />
        </Form.Group>
        <Box>
          <Form.Group sx={{ mb: 2 }}>
            <LabelWithTooltip
              labelFor='expiration'
              helpText={content.payment.expiryDateHelpText}
            >
              {content.payment.expiryDateLabel}
            </LabelWithTooltip>
            <div
              id='expirationDiv'
              className={classnames(
                'form-control payfield',
                payFieldBackgroundClass,
                paymentState.expiryDate.isValidated &&
                  (paymentState.expiryDate.isValid ? 'is-valid' : 'is-invalid')
              )}
            />
          </Form.Group>
        </Box>
        <Grid sx={{ mb: 2 }} columns={2} gap={0}>
          <Form.Group as={Box}>
            <LabelWithTooltip
              labelFor='payment_cvv'
              helpText={content.payment.cvvHelpText}
            >
              {content.payment.cvvLabel}
            </LabelWithTooltip>
            <div
              id='cvvDiv'
              className={classnames(
                'form-control payfield',
                payFieldBackgroundClass,
                paymentState.cvvCode.isValidated &&
                  (paymentState.cvvCode.isValid ? 'is-valid' : 'is-invalid')
              )}
            />
          </Form.Group>
          <Form.Group as={Box}>
            <LabelWithTooltip
              labelFor='payrixZipcodeInput'
              helpText={content.payment.zipCodeHelpText}
            >
              {content.payment.zipCodeLabel}
            </LabelWithTooltip>
            <div
              id='payrixZipcodeDiv'
              className={classnames(
                'form-control payfield',
                payFieldBackgroundClass,
                paymentState.zipCode.isValidated &&
                  `${paymentState.zipCode.isValid ? 'is-valid' : 'is-invalid'}`
              )}
            >
              <input
                id='payrixZipcodeInput'
                data-testid='payrix-zipcode-input'
                value={paymentState.zipCode.value}
                type='text'
                maxLength={7}
                placeholder={content.payment.zipCodePlaceholder}
                required
                onChange={handleZipCodeChange}
                onBlur={() =>
                  setPaymentState((prevPaymentState) => ({
                    ...prevPaymentState,
                    zipCode: {
                      ...prevPaymentState.zipCode,
                      isValidated: true,
                    },
                  }))
                }
                className={classnames(
                  'form-control',
                  payFieldBackgroundClass,
                  paymentState.zipCode.isValidated &&
                    (paymentState.zipCode.isValid
                      ? 'zipcode-is-valid'
                      : 'zipcode-is-invalid')
                )}
              />
              {paymentState.zipCode.isValidated &&
                !paymentState.zipCode.isValid && (
                  <span className='form-error payrix-zipcode-error'>
                    {content.payment.zipCodeValidationText}
                  </span>
                )}
            </div>
          </Form.Group>
        </Grid>
      </Form>
    </div>
  );
};

export default PayrixPaymentForm;
