import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { cinemaHomeUrlKey } from '../../../constants';
import { getContentForError } from '../../../services/PeachErrorResolver';
import { selectContent } from '../../../store/Selectors';
import ActionButton from '../../common/actionbutton/ActionButton';
import ContainedRow from '../../common/layout/ContainedRow';
import RichText from '../../common/richtext/RichText';

const ErrorPage: React.FC = () => {
  const [searchParams] = useSearchParams();

  const content = useSelector(selectContent);

  const [errorMessage, setErrorMessage] = useState<string>('');

  useEffect(() => {
    const errorCode = parseInt(searchParams.get('code') ?? '', 10);

    const message =
      content && !isNaN(errorCode)
        ? getContentForError(errorCode, content)
        : '';

    setErrorMessage(message);
  }, [content, searchParams]);

  const title = content?.error?.title ?? 'Ooops, there was a problem.';

  const cinemaHomeUrl = window.sessionStorage.getItem(cinemaHomeUrlKey);

  const handleGoToCinemaWebsiteClick = () => {
    if (cinemaHomeUrl) window.location.replace(cinemaHomeUrl);
  };

  return (
    <div className='error' data-testid='error'>
      <ContainedRow>
        <h2>{title}</h2>
        {!!content && <RichText text={errorMessage} />}
        {!!cinemaHomeUrl && (
          <ActionButton
            onClick={handleGoToCinemaWebsiteClick}
            showIcon
            contained
            variant='primary'
          >
            Go to Cinema website
          </ActionButton>
        )}
      </ContainedRow>
    </div>
  );
};

export default ErrorPage;
