import React from 'react';

export interface Props {
  setupApplePay: () => void;
  language: string;
}

const SetupApplePayButton: React.FC<Props> = ({ setupApplePay, language }) => {
  const className =
    'apple-pay apple-pay-set-up-button apple-pay-set-up-button-black';
  return (
    <button
      onClick={() => setupApplePay()}
      style={{ width: '100%', height: '50px' }}
      className={className}
      lang={language}
    />
  );
};

export default SetupApplePayButton;
