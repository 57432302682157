/** @jsxImportSource theme-ui */
import React from 'react';

import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { Box, Flex } from 'theme-ui';

import { Concession } from '../../../@types/modelTypes';
import { displayPrice } from '../../../services/Helpers';
import {
  selectContentPhysicalGiftcards,
  selectCurrencyConfig,
} from '../../../store/Selectors';
import CheckBoxButton from '../checkboxbutton/CheckBoxButton';

interface Props {
  concession: Concession;
  selected: boolean;
  onClick: (e: Concession) => void;
}
const DeliveryChargeOption: React.FC<Props> = ({
  concession,
  selected,
  onClick,
}) => {
  const contentPhysicalGiftCards = useSelector(selectContentPhysicalGiftcards);
  const currencyConfig = useSelector(selectCurrencyConfig);
  if (!contentPhysicalGiftCards) {
    return null;
  }

  return (
    <Flex
      className={classnames('delivery-charge-option', selected && 'selected')}
      onClick={() => onClick(concession)}
      sx={{
        mt: 2,
        p: 2,
        border: 'mostReadableOnWebsiteBackgroundRgbaBorder',
        backgroundColor: 'websiteBackground',
        color: 'mostReadableOnWebsiteBackground',
        alignItems: 'center',
        '&.selected': {
          border: 'mostReadableOnWebsiteBackgroundBorder',
          boxShadow: 'mostReadableOnWebsiteBackgroundShadow',
        },
      }}
    >
      <div sx={{ flexgrow: 1 }}>
        <div sx={{ fontWeight: 'bold' }}>{concession.description}</div>
        <div className='tiny'>{concession.extendedDescription}</div>
      </div>
      <Box sx={{ px: 4, fontWeight: selected ? 'bold' : 'normal' }}>
        {displayPrice(concession.cost, currencyConfig)}
      </Box>
      <div>
        <CheckBoxButton checked={selected} />
      </div>
    </Flex>
  );
};

export default DeliveryChargeOption;
