import { defineMessages } from 'react-intl';

export default defineMessages({
  memberTicketsHeading: {
    id: 'ticket-selection.member-tickets-heading',
    defaultMessage: 'Member Tickets',
  },
  packageTicketsHeading: {
    id: 'ticket-selection.package-tickets-heading',
    defaultMessage: 'Package Tickets',
  },
  standardTicketsHeading: {
    id: 'ticket-selection.standard-tickets-heading',
    defaultMessage: 'Standard Tickets',
  },
  memberTicketsInfoText: {
    id: 'ticket-selection.member-tickets-info-text',
    defaultMessage: 'Members can select a free or discounted ticket',
  },
  memberTicketsLoginOrRegisterText: {
    id: 'ticket-selection.member-tickets-login-or-register-text',
    defaultMessage:
      'In order to add a member ticket, you need to login or register',
  },
  memberTicketsLoginButtonText: {
    id: 'ticket-selection.member-tickets-login-button-text',
    defaultMessage: 'Login',
  },
  memberTicketsRegisterButtonText: {
    id: 'ticket-selection.member-tickets-register-button-text',
    defaultMessage: 'Register',
  },
  cardPaymentPromoTicketsHeading: {
    id: 'ticket-selection.card-payment-promo-tickets-heading',
    defaultMessage: 'Card Payment Promo Tickets',
  },
});
