/** @jsxImportSource theme-ui */
import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Flex } from 'theme-ui';

import { actionCreators } from '../../../../store/ActionCreators';
import {
  selectConfig,
  selectContent,
  selectGuestNewsletterSignUp,
} from '../../../../store/Selectors';
import CheckBoxButton from '../../checkboxbutton/CheckBoxButton';
import ContainedRow from '../../layout/ContainedRow';

const GuestNewsletterSignUpCheckbox: React.FC = () => {
  const dispatch = useDispatch();

  const guestNewsletterSignUp = useSelector(selectGuestNewsletterSignUp);
  const configPayment = useSelector(selectConfig).payment;
  const contentPayment = useSelector(selectContent).payment;

  const handleNewsLetterClick = () => {
    dispatch(actionCreators.setGuestNewsletterSignUp(!guestNewsletterSignUp));
  };

  if (!configPayment || !contentPayment) return null;

  return (
    <ContainedRow classNameWrapper='guest-marketing-wrapper'>
      <Flex
        className='checkbox-with-text'
        sx={{
          justifyContent: 'space-between',
          alignItems: 'center',
          mt: 4,
          mx: 2,
        }}
      >
        <Flex sx={{ alignItems: 'center', mr: 2 }}>
          <CheckBoxButton
            checked={guestNewsletterSignUp}
            onClick={handleNewsLetterClick}
          />
        </Flex>
        <Flex sx={{ alignItems: 'center', flexGrow: 1 }}>
          <p
            className='tiny'
            data-testid='crm-newsletter'
            sx={{
              textAlign: 'left',
              m: 0,
              fontWeight: guestNewsletterSignUp ? 'bold' : 'normal',
            }}
          >
            {contentPayment.guestNewsletterSignUpCheckboxText}
          </p>
        </Flex>
      </Flex>
    </ContainedRow>
  );
};

export default GuestNewsletterSignUpCheckbox;
