import { takeLeading, call, select, put } from 'redux-saga/effects';

import { Content } from '../../@types/contentTypes';
import { ConfirmationRequestModel, Customer } from '../../@types/modelTypes';
import { PEACH_CODES } from '../../constants';
import { createConfirmation } from '../../services/Helpers';
import { getContentForError } from '../../services/PeachErrorResolver';
import backend from '../../services/RestUtilities';
import { actionCreators } from '../ActionCreators';
import {
  ADYEN_INITIATE_PAYMENT,
  ADYEN_SUBMIT_ADDITIONAL_DETAILS,
} from '../Actions';
import {
  selectBankCardAmount,
  selectContent,
  selectCustomer,
  selectToken,
  selectGuestSessionToken,
  selectJourneyType,
} from '../Selectors';

/* eslint-disable @typescript-eslint/no-explicit-any */

function* handleServerResponse(
  response: any,
  component: any,
  setAdyenConfig: any
): any {
  const content: Content = yield select(selectContent);

  const guestSessionToken = yield select(selectGuestSessionToken);
  const token = yield select(selectToken);
  const journeyType = yield select(selectJourneyType);

  if (
    typeof response.content.adyenPaymentResponseJson != 'undefined' &&
    response.content.adyenPaymentResponseJson
  ) {
    const adyenResp = JSON.parse(response.content.adyenPaymentResponseJson);
    component.handleAction(adyenResp.action);
  } else if (response.ok) {
    yield put(actionCreators.setLoading(true));
    const responseContent = response.content;
    if (responseContent.peachCode === PEACH_CODES.noError) {
      let body: ConfirmationRequestModel | undefined;

      if (guestSessionToken) {
        body = {
          DataToken: token,
          GuestSessionToken: guestSessionToken,
        };
      }

      const response = yield call(
        backend.post,
        `api/Confirmation/${responseContent.externalOrderId}?circuitId=${responseContent.circuitId}`,
        body
      );
      if (response.ok) {
        const data = response.content;

        const customer: Customer = yield select(selectCustomer);

        const confirmation = createConfirmation(data, customer, journeyType);
        yield put(actionCreators.setSendAnalytics(true));
        yield put(actionCreators.setConfirmation(confirmation));
      }
      yield put(actionCreators.setGuestSessionToken(undefined));
    } else {
      if (responseContent.peachCode !== PEACH_CODES.userCancelled)
        yield put(
          actionCreators.setError(
            getContentForError(responseContent.peachCode, content),
            responseContent.peachCode
          )
        );

      yield call(setAdyenConfig, undefined);
    }
    yield put(actionCreators.setLoading(false));
  } else if (response.status === 400) {
    yield put(actionCreators.setError(content.error.paymentModelErrorRichText));

    yield call(setAdyenConfig, undefined);
  }
}

function* adyenInitiatePaymentHandler(action: any): any {
  const { adyenStateData, adyenComponent, setAdyenConfig } = action.payload;
  const customer: Customer = yield select(selectCustomer);
  const bankCardAmount: number = yield select(selectBankCardAmount);
  const token: string = yield select(selectToken);
  const completePaymentDataRequest = {
    ...adyenStateData,
    CustomerEmailAddress: customer?.email,
    PaymentRequest: JSON.stringify(adyenStateData),
    paymentAmount: bankCardAmount,
    DataToken: token,
  };

  try {
    const response = yield call(
      backend.post,
      '/api/payment/AdyenInitiatePayment',
      completePaymentDataRequest
    );

    yield call(handleServerResponse, response, adyenComponent, setAdyenConfig);
  } catch (error) {
    const { error: errorContent } = yield select(selectContent);
    yield put(actionCreators.setError(errorContent.paymentModelErrorRichText));
  }
}

function* adyenSubmitAdditionalDetailsHandler(action: any): any {
  const { adyenStateData, adyenComponent, setAdyenConfig } = action.payload;

  const token: string = yield select(selectToken);

  yield put(actionCreators.setLoading(true));

  const adyenSubmitAdditionalDetailsRequest = {
    ...adyenStateData,
    paymentDetailsRequest: JSON.stringify(adyenStateData),
    DataToken: token,
  };

  try {
    const response = yield call(
      backend.post,
      '/api/payment/AdyenSubmitAdditionalDetails',
      adyenSubmitAdditionalDetailsRequest
    );

    yield call(handleServerResponse, response, adyenComponent, setAdyenConfig);
  } catch (error) {
    const { error: errorContent } = yield select(selectContent);
    yield put(actionCreators.setError(errorContent.paymentModelErrorRichText));
  }

  yield put(actionCreators.setLoading(false));
}

export function* adyenInitiatePaymentWatch() {
  yield takeLeading([ADYEN_INITIATE_PAYMENT], adyenInitiatePaymentHandler);
}

export function* adyenSubmitAdditionalDetailsWatch() {
  yield takeLeading(
    [ADYEN_SUBMIT_ADDITIONAL_DETAILS],
    adyenSubmitAdditionalDetailsHandler
  );
}
