/* eslint-disable @typescript-eslint/no-explicit-any */
/** @jsxImportSource theme-ui */
import React, { useCallback, useEffect, useState } from 'react';

import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useDispatch, useSelector } from 'react-redux';
import { Flex } from 'theme-ui';

import { PaymentProvidersEnum } from '../../../../../@types/enums';
import { useRecaptcha } from '../../../../../contextProviders/recaptchaContext';
import { useTurnstile } from '../../../../../contextProviders/turnstileContext';
import { actionCreators } from '../../../../../store/ActionCreators';
import {
  selectBankCardAmount,
  selectBankCardAmountDisplayPrice,
  selectContent,
  selectGrandTotalAfterDiscountsInCents,
  selectHostedPayments,
  selectSelectedLanguageCulture,
  selectSelectedPaymentProvider,
} from '../../../../../store/Selectors';
import ActionButton from '../../../actionbutton/ActionButton';

interface Props {
  isPageValidated?: boolean;
  handleValidatePage: () => void;
}

const AmazonPay: React.FC<Props> = ({
  isPageValidated = false,
  handleValidatePage,
}) => {
  const dispatch = useDispatch();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const recaptcha = useRecaptcha();
  const turnstile = useTurnstile();

  const bankCardAmount = useSelector(selectBankCardAmount);
  const content = useSelector(selectContent);
  const selectedLanguageCulture = useSelector(selectSelectedLanguageCulture);
  const hostedPayments = useSelector(selectHostedPayments);
  const selectedPaymentProvider = useSelector(selectSelectedPaymentProvider);
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const [showAmazonWallet, setShowAmazonWallet] = useState(false);
  const [orderReferenceId, setOrderReferenceId] = useState('');
  const priceToDisplay = useSelector(selectBankCardAmountDisplayPrice);
  const grandTotal = useSelector(selectGrandTotalAfterDiscountsInCents);

  const hostedPayment = hostedPayments[PaymentProvidersEnum.AMAZONPAY];
  const isAmazonPaySelected =
    selectedPaymentProvider === PaymentProvidersEnum.AMAZONPAY;

  const widgetsUrl = hostedPayment?.widgetsUrl;
  const clientId = hostedPayment?.clientId;
  const merchantId = hostedPayment?.merchantId;
  const _window = window as any;
  const showAmazonPayButton = !showAmazonWallet;

  const showButton = useCallback(() => {
    if (!hostedPayment || !_window.amazon) {
      return;
    }
    _window.OffAmazonPayments.Button(
      'AmazonPayButton',
      hostedPayment.merchantId,
      {
        type: 'LwA',
        color: 'Gold',
        size: 'x-large',
        language: selectedLanguageCulture,
        authorization: function () {
          _window.amazon.Login.authorize(
            {
              scope: 'profile payments:widget',
              popup: true,
            },
            (t: any) => {
              if (
                typeof t === 'undefined' ||
                t === null ||
                typeof t.access_token === 'undefined' ||
                t.access_token === null
              ) {
                return;
              }

              setShowAmazonWallet(true);
            }
          );
        },
      }
    );
  }, [
    _window.OffAmazonPayments,
    _window.amazon,
    hostedPayment,
    selectedLanguageCulture,
  ]);

  const getPaymentProviderAsync = useCallback(async () => {
    if (!executeRecaptcha) {
      return;
    }

    dispatch(
      actionCreators.initializeAmazonPayPayment({
        executeRecaptcha,
        turnstile,
        recaptcha,
      })
    );
  }, [dispatch, executeRecaptcha, turnstile, recaptcha]);

  // reacts to bankCardAmount Changing and sets hostedpayment to null
  useEffect(() => {
    if (
      hostedPayment &&
      (bankCardAmount !== hostedPayment.bankCardAmount ||
        grandTotal !== hostedPayment?.grandTotal)
    ) {
      dispatch(
        actionCreators.setHostedPayment(PaymentProvidersEnum.AMAZONPAY, null)
      );
    }
  }, [bankCardAmount, dispatch, grandTotal, hostedPayment]);

  // Initialise Wallet after initial login
  useEffect(() => {
    if (!showAmazonWallet || !_window.OffAmazonPayments) {
      return;
    }
    new _window.OffAmazonPayments.Widgets.Wallet({
      sellerId: merchantId,
      amazonOrderReferenceId: null,
      design: {
        designMode: 'responsive',
      },
      onOrderReferenceCreate: (orderReference: any) => {
        setOrderReferenceId(orderReference.getAmazonOrderReferenceId());
      },
    }).bind('AmazonWalletWidget');
  }, [_window.OffAmazonPayments, merchantId, showAmazonWallet]);

  // Loads Amazon script
  useEffect(() => {
    if (scriptLoaded || !showAmazonPayButton || !widgetsUrl || !clientId) {
      return;
    }
    _window.onAmazonLoginReady = () => {
      _window.amazon.Login.setClientId(clientId);
    };
    _window.onAmazonPaymentsReady = () => {
      showButton();
    };
    const script = document.createElement('script');
    script.src = widgetsUrl;
    document.head.appendChild(script);
    setScriptLoaded(true);
  }, [
    _window,
    hostedPayment,
    clientId,
    scriptLoaded,
    selectedLanguageCulture,
    showAmazonPayButton,
    showButton,
    widgetsUrl,
  ]);

  // Calls GetPaymentProvider
  useEffect(() => {
    if (isAmazonPaySelected && !hostedPayment) {
      getPaymentProviderAsync();
    }
  }, [hostedPayment, getPaymentProviderAsync, isAmazonPaySelected]);

  const handlePaymentClick = async () => {
    if (!isPageValidated) {
      handleValidatePage();
      return;
    }

    dispatch(
      actionCreators.submitMakePayment({
        makePaymentModelOverrideProps: {
          amazonPayOrderReferenceId: orderReferenceId,
          paymentProvider: PaymentProvidersEnum.AMAZONPAY,
        },
        executeRecaptcha,
        turnstile,
        recaptcha,
      })
    );
  };

  return (
    <>
      {showAmazonPayButton && (
        <Flex sx={{ justifyContent: 'center' }}>
          <div id='AmazonPayButton' />
        </Flex>
      )}
      {showAmazonWallet && (
        <>
          <div id='AmazonWalletWidget' />

          <ActionButton
            onClick={handlePaymentClick}
            disabled={!orderReferenceId}
            showIcon
            mb={0}
            mt={0}
            variant='primary'
          >
            {`${content.payment.submitText} ${priceToDisplay}`}
          </ActionButton>
        </>
      )}
    </>
  );
};

export default AmazonPay;
