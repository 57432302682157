import { Dispatch } from 'react';

import {
  Concession,
  ConcessionGrouping,
  Voucher,
  SelectedConcessions,
} from '../@types/modelTypes';
import { actionCreators } from '../store/ActionCreators';

export const getGrandTotalDiscountFromVouchers = (
  appliedVouchers: Voucher[]
) => {
  let totalDiscount = 0;
  appliedVouchers.forEach((voucher) => {
    totalDiscount += voucher.totalDiscount;
  });
  return totalDiscount;
};

export const getGrandTotalFeeFromVouchers = (appliedVouchers: Voucher[]) => {
  let totalFee = 0;
  appliedVouchers.forEach((voucher) => {
    totalFee += voucher.totalFee;
  });
  return totalFee;
};
export const getFullAddress = (
  addressLineOne?: string,
  addressLineTwo?: string,
  city?: string,
  postcode?: string
) => {
  const fullAddress = `${addressLineOne}${', '}${
    addressLineTwo && addressLineTwo
  }${addressLineTwo && ', '}${city}${', '}${postcode}`;
  return fullAddress;
};

export const setGiftCardConcessions = (
  listConcessionGrouping: ConcessionGrouping[],
  queryString: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: Dispatch<any>
  //deals: Deal[]
) => {
  const concessionGrouping: ConcessionGrouping = listConcessionGrouping[0];
  const items: Concession[] = concessionGrouping.items;
  const hasDeliveryItems = concessionGrouping.deliveryItems.length > 0;
  const noDeliveryCharge = 0;
  const giftCardDeliveryItemStrategy: number =
    concessionGrouping.giftCardDeliveryItemStrategy || noDeliveryCharge;
  const searchParams = new URLSearchParams(queryString);
  const queryStringId = searchParams.get('id')?.toLowerCase();

  if (hasDeliveryItems && giftCardDeliveryItemStrategy !== noDeliveryCharge) {
    const firstDeliveryItem: Concession | undefined =
      concessionGrouping.deliveryItems[0];
    dispatch(actionCreators.changeDeliveryItemId(firstDeliveryItem.id));
  }

  const getIdToCompare = (id: string) => id.substring(id.indexOf('_') + 1);
  if (queryStringId) {
    const orderedList: Concession[] = [];
    const firstConcession =
      items.find(
        (concession) => getIdToCompare(concession.id) === queryStringId
      ) || null;
    const otherConcessions = items.filter(
      (concession) => getIdToCompare(concession.id) !== queryStringId
    );
    if (firstConcession) {
      orderedList.push(firstConcession);
      if (!firstConcession.isVariablePriceItem) {
        dispatch(actionCreators.changeGiftCardById(firstConcession, 'add'));
      }
    }
    otherConcessions.forEach((concession) => orderedList.push(concession));
    concessionGrouping.items = orderedList;
  } else {
    concessionGrouping.items = items;
  }

  dispatch(actionCreators.setConcessions(listConcessionGrouping));
};

export const splitDeliveryItemFromConcessions = (
  concessions: ConcessionGrouping[] | null,
  selectedConcessions: SelectedConcessions
) => {
  if (concessions === null)
    return {
      listWithNoDeliveryItem: selectedConcessions.list,
      deliveryItem: null,
    };

  const findDeliveryItem = (
    concessions: ConcessionGrouping[],
    selectedConcessions: SelectedConcessions
  ) => {
    if (!concessions || !selectedConcessions) return null;
    const concessionGrouping: ConcessionGrouping = concessions[0];
    const deliveryItem: Concession = concessionGrouping.deliveryItems[0];
    const getIdToCompare = (id: string) => id.substring(id.indexOf('_') + 1);

    return (
      selectedConcessions.list.find((concession) => {
        return (
          concession.isDeliveryItem ||
          (deliveryItem && getIdToCompare(concession.id) === deliveryItem.id)
        );
      }) || null
    );
  };

  const deliveryItem =
    concessions &&
    selectedConcessions &&
    findDeliveryItem(concessions, selectedConcessions);

  const listWithNoDeliveryItem = selectedConcessions.list.filter(
    (c: Concession) => c.id !== deliveryItem?.id
  );
  return {
    listWithNoDeliveryItem,
    deliveryItem,
  };
};

export const getValueLimitedToTwoDecimalPlaces = (value: string) => {
  return value
    .toString()
    .split('.')
    .map((el: string, i: number) =>
      i ? el.split('').slice(0, 2).join('') : el
    )
    .join('.');
};

export const getToolTipText = (
  text: string,
  concessionMinVariableCost: number | null,
  concessionMaxVariableCost: number | null,
  currencySymbol: string
) => {
  const minValue = concessionMinVariableCost
    ? concessionMinVariableCost / 100
    : 0;
  const maxValue = concessionMaxVariableCost
    ? concessionMaxVariableCost / 100
    : 0;
  return text
    ? text
        .replace('##minValue##', `${currencySymbol}${String(minValue)}`)
        .replace('##maxValue##', `${currencySymbol}${String(maxValue)}`)
    : text;
};
