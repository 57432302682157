import React from 'react';

import { useSelector } from 'react-redux';
import { Box } from 'theme-ui';

import SingleItemRow from './SingleItemRow';

import { Concession } from '../../../../../@types/modelTypes';
import {
  selectEGiftCardGroup,
  selectEGiftCardImage,
} from '../../../../../store/Selectors';
import GiftCardImage from '../../GiftCardImage';

const SingleItemLayout: React.FC = () => {
  const eGiftCardImage = useSelector(selectEGiftCardImage);
  const eGiftCardGroup = useSelector(selectEGiftCardGroup);

  return (
    <Box
      className='giftcards-row'
      sx={{
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        px: [0, 4],
        display: ['block', 'flex'],
      }}
    >
      <Box className='image-container' sx={{ minWidth: 'unset', mr: 4, pt: 5 }}>
        <GiftCardImage imageUrl={eGiftCardImage} />
      </Box>
      <Box
        className='single-item-row-container'
        sx={{ textAlign: 'left', width: '100%' }}
      >
        {eGiftCardGroup?.items.map((concession: Concession) => (
          <SingleItemRow key={`${concession.id}`} concession={concession} />
        ))}
      </Box>
    </Box>
  );
};

export default SingleItemLayout;
