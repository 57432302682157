/** @jsxImportSource theme-ui */
import React, { useCallback } from 'react';

import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Flex } from 'theme-ui';

import messages from './intl';

import { GuestMarketing } from '../../../@types/modelTypes';
import { actionCreators } from '../../../store/ActionCreators';
import {
  selectConfig,
  selectDazzlerLocale,
  selectGuestMarketing,
} from '../../../store/Selectors';
import CheckBoxButton from '../../common/checkboxbutton/CheckBoxButton';
import { resolveLocalisedStringOrDefault } from '../helpers';
import { WidgetData } from '../types';

interface Props {
  widget: WidgetData<'TicketingCMSJourneyPurchaserDetailsWidget'>;
  onEdit?: () => void;
}

const NewsletterCheckbox: React.FC<Props> = ({ widget, onEdit }) => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const configPayment = useSelector(selectConfig)?.payment;
  const guestMarketing = useSelector(selectGuestMarketing);
  const locale = useSelector(selectDazzlerLocale);

  const handleClick = useCallback(() => {
    onEdit?.();
    dispatch(
      actionCreators.setGuestMarketing({
        ...guestMarketing,
        sendNewsletter: !guestMarketing?.sendNewsletter,
      } as GuestMarketing)
    );
  }, [dispatch, guestMarketing, onEdit]);

  if (!configPayment) return null;

  return (
    <Flex sx={{ mt: 4, mx: 2, alignItems: 'center' }}>
      <Flex sx={{ alignItems: 'center', mr: 2 }}>
        <CheckBoxButton
          checked={!!guestMarketing?.sendNewsletter}
          onClick={handleClick}
        />
      </Flex>

      <Flex sx={{ alignItems: 'center' }}>
        <p
          data-testid='newsletter'
          className='tiny'
          sx={{
            textAlign: 'left',
            m: 0,
            fontWeight: guestMarketing?.sendNewsletter ? 'bold' : 'normal',
          }}
        >
          {resolveLocalisedStringOrDefault(
            formatMessage(messages.guestMarketingNewsletterCheckboxText),
            locale,
            widget.shape?.guestMarketingNewsletterCheckboxText
          )}
        </p>
      </Flex>
    </Flex>
  );
};

export default NewsletterCheckbox;
