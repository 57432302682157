import React from 'react';

import { useSelector } from 'react-redux';

import {
  selectContent,
  selectCurrentCinemaHomeUrl,
  selectSource,
} from '../../../store/Selectors';

interface Props {
  footer?: boolean;
}

const Logo: React.FC<Props> = ({ footer }) => {
  const content = useSelector(selectContent);
  const source = useSelector(selectSource);
  const currentCinemaHomeUrl = useSelector(selectCurrentCinemaHomeUrl);

  if (!content) return null;

  const logoSrc = footer
    ? content.logoFooterUrl || content.logoUrl
    : content.logoUrl;

  const imageTag = <img src={logoSrc} alt={content.title} />;

  let imageHtml;

  if (source === 'app' || !currentCinemaHomeUrl) {
    imageHtml = imageTag;
  } else if (currentCinemaHomeUrl) {
    imageHtml = <a href={currentCinemaHomeUrl}>{imageTag}</a>;
  }

  return (
    <div data-testid='logo-container' className='logo-container'>
      {imageHtml}
    </div>
  );
};

export default Logo;
