import React from 'react';

import classnames from 'classnames';

import { CreditCardTypeDetail } from '../../../../../../@types/modelTypes';
import { GetPaymentIconDisplayDetailsForCardTypeName } from '../../../../../../services/PaymentIconsHelper';
import { ReactComponent as AmexSvg } from '../../../../../../svgs/payment/amex.svg';
import { ReactComponent as DiscoverSvg } from '../../../../../../svgs/payment/discover.svg';
import { ReactComponent as MaestroSvg } from '../../../../../../svgs/payment/maestro.svg';
import { ReactComponent as MastercardSvg } from '../../../../../../svgs/payment/mastercard.svg';
import { ReactComponent as VisaSvg } from '../../../../../../svgs/payment/visa.svg';

interface Props {
  creditCardType: string;
  isActive: boolean;
}

const GetSvgElementForCard = (
  creditCardFriendlyName: CreditCardTypeDetail['creditCardFriendlyName']
) => {
  switch (creditCardFriendlyName) {
    case 'american-express':
      return AmexSvg;
    case 'discover':
      return DiscoverSvg;
    case 'maestro':
      return MaestroSvg;
    case 'mastercard':
      return MastercardSvg;
    case 'visa':
      return VisaSvg;
    default:
      return undefined;
  }
};

const PaymentIconSingle: React.FC<Props> = ({ creditCardType, isActive }) => {
  const creditCardDetail =
    GetPaymentIconDisplayDetailsForCardTypeName(creditCardType);

  if (!creditCardDetail) return null;

  const SvgElement = GetSvgElementForCard(
    creditCardDetail.creditCardFriendlyName
  );

  if (!SvgElement) return null;

  return (
    <SvgElement
      className={classnames(isActive && 'payment-icon-active')}
      data-testid={`payment-icon-${creditCardDetail.creditCardFriendlyName}`}
    />
  );
};

export default PaymentIconSingle;
