import React from 'react';

import classnames from 'classnames';
import { useSelector } from 'react-redux';

import {
  CreditCardTypeIsInMatchingNamesOf,
  creditCardTypeIndex,
} from '../../../../../../services/PaymentIconsHelper';
import { selectConfig } from '../../../../../../store/Selectors';
import { ReactComponent as AmexSvg } from '../../../../../../svgs/payment/amex.svg';
import { ReactComponent as ApplePaySvg } from '../../../../../../svgs/payment/applePay.svg';
import { ReactComponent as DiscoverSvg } from '../../../../../../svgs/payment/discover.svg';
import { ReactComponent as GooglePaySvg } from '../../../../../../svgs/payment/googlePay.svg';
import { ReactComponent as MaestroSvg } from '../../../../../../svgs/payment/maestro.svg';
import { ReactComponent as MastercardSvg } from '../../../../../../svgs/payment/mastercard.svg';
import { ReactComponent as VisaSvg } from '../../../../../../svgs/payment/visa.svg';

interface Props {
  cardType: string;
}

const PaymentIcons: React.FC<Props> = ({ cardType }) => {
  const config = useSelector(selectConfig);

  return (
    <div
      className='payment-icons'
      data-testid='payment-icons'
      sx={{ textAlign: 'center' }}
    >
      {config.payment.showApplePay && (
        <ApplePaySvg
          className={classnames(!cardType && 'payment-icon-active')}
          data-testid='payment-icon-apple-pay'
        />
      )}
      {config.payment.showGooglePay && (
        <GooglePaySvg
          className={classnames(!cardType && 'payment-icon-active')}
          data-testid='payment-icon-google-pay'
        />
      )}
      {config.payment.hideAmex || (
        <AmexSvg
          className={classnames(
            (!cardType ||
              CreditCardTypeIsInMatchingNamesOf(
                cardType,
                'american-express'
              )) &&
              'payment-icon-active'
          )}
          data-testid={`payment-icon-${creditCardTypeIndex.americanexpress.creditCardFriendlyName}`}
        />
      )}
      {config.payment.hideMastercard || (
        <MastercardSvg
          className={classnames(
            (!cardType ||
              CreditCardTypeIsInMatchingNamesOf(cardType, 'mastercard')) &&
              'payment-icon-active'
          )}
          data-testid={`payment-icon-${creditCardTypeIndex.mastercard.creditCardFriendlyName}`}
        />
      )}
      {config.payment.hideMaestro || (
        <MaestroSvg
          className={classnames(
            (!cardType ||
              CreditCardTypeIsInMatchingNamesOf(cardType, 'maestro')) &&
              'payment-icon-active'
          )}
          data-testid={`payment-icon-${creditCardTypeIndex.maestro.creditCardFriendlyName}`}
        />
      )}
      {config.payment.hideVisa || (
        <VisaSvg
          className={classnames(
            (!cardType ||
              CreditCardTypeIsInMatchingNamesOf(cardType, 'visa')) &&
              'payment-icon-active'
          )}
          data-testid={`payment-icon-${creditCardTypeIndex.visa.creditCardFriendlyName}`}
        />
      )}
      {config.payment.hideDiscover || (
        <DiscoverSvg
          className={classnames(
            (!cardType ||
              CreditCardTypeIsInMatchingNamesOf(cardType, 'discover')) &&
              'payment-icon-active'
          )}
          data-testid={`payment-icon-${creditCardTypeIndex.discover.creditCardFriendlyName}`}
        />
      )}
    </div>
  );
};

export default PaymentIcons;
