/** @jsxImportSource theme-ui */
import React from 'react';

import { useSelector } from 'react-redux';

import { selectDeals } from '../../../store/Selectors';
import ContainedRow from '../layout/ContainedRow';
import RichText from '../richtext/RichText';

const DealsIntroduction: React.FC = () => {
  const deals = useSelector(selectDeals);

  if (!deals || !deals.length) {
    return null;
  }

  return (
    <div className='header' sx={{ mt: 6, textAlign: 'center' }}>
      {deals.map((deal, index) => {
        return (
          <ContainedRow
            key={`deal_introduction_${index}`}
            classNameWrapper='banner'
            styles={{ py: 4, textAlign: 'center' }}
          >
            <h2>{deal.title}</h2>
            <RichText
              text={deal.description}
              styles={{ mt: 2, textAlign: 'center' }}
            />
          </ContainedRow>
        );
      })}
    </div>
  );
};

export default DealsIntroduction;
