/** @jsxImportSource theme-ui */
import React from 'react';

import { useSelector } from 'react-redux';
import { Flex, Image } from 'theme-ui';

import { Concession } from '../../../@types/modelTypes';
import { findTotalQuantityOfSelectedConcessionById } from '../../../services/KioskHelpers';
import { selectSelectedFaBConcessions } from '../../../store/Selectors';
import { ReactComponent as PlusSvg } from '../../../svgs/plus.svg';

interface Props {
  concession?: Concession;
  imageUrl: string;
  showQuantityIcon?: boolean;
}

const FandBItemImage: React.FC<Props> = ({
  concession,
  imageUrl,
  showQuantityIcon,
}) => {
  const selectedConcessions = useSelector(selectSelectedFaBConcessions);

  const quantity: number | null =
    (showQuantityIcon &&
      concession &&
      selectedConcessions &&
      findTotalQuantityOfSelectedConcessionById(
        concession,
        selectedConcessions
      )) ||
    null;

  return (
    <Flex
      sx={{
        flexShrink: 0,
        margin: showQuantityIcon && '8px',
        maxWidth: '250px',
        position: 'relative',
      }}
    >
      <Image src={imageUrl} variant='concessionItem' />
      {showQuantityIcon && (
        <Flex
          sx={{
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: quantity ? 'websiteBackground' : 'primary',
            border: quantity
              ? 'mostReadableOnWebsiteBackgroundBorder'
              : 'primaryBorder',
            borderRadius: '15px',
            color: 'mostReadableOnWebsiteBackground',
            fontSize: 'small',
            height: '26px',
            width: '26px',
            position: 'absolute',
            top: '-8px',
            right: '-8px',

            svg: {
              fill: 'mostReadableOnPrimaryBackground',
              height: '12px',
              width: '12px',
            },
          }}
        >
          {quantity ?? <PlusSvg />}
        </Flex>
      )}
    </Flex>
  );
};

export default FandBItemImage;
