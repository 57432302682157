/** @jsxImportSource theme-ui */
import React, { useEffect, useCallback } from 'react';

import { useCookies } from 'react-cookie';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import { Box } from 'theme-ui';

import { ConfirmationRequestModel } from '../../../@types/modelTypes';
import { TrackingEvent } from '../../../@types/trackingTypes';
import useAnalyticsTrackOnce from '../../../hooks/useAnalyticsTrackOnce';
import { useConfirmationTracking } from '../../../hooks/useConfirmationTracking';
import { createConfirmation } from '../../../services/Helpers';
import { buildQueryString } from '../../../services/QueryStringHelper';
import backend from '../../../services/RestUtilities';
import TokenReplacer from '../../../services/TokenReplacer';
import { actionCreators } from '../../../store/ActionCreators';
import {
  selectConfig,
  selectConfirmationData,
  selectContent,
  selectCustomer,
  selectGuestSessionToken,
  selectGiftCardRecipient,
  selectToken,
  selectJourneyType,
  selectCurrentCinemaHomeUrl,
} from '../../../store/Selectors';
import ActionButton from '../../common/actionbutton/ActionButton';
import ContainedRow from '../../common/layout/ContainedRow';
import RichText from '../../common/richtext/RichText';

const GiftCardConfirmation: React.FC = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const { orderId } = useParams();
  const [cookies] = useCookies();

  const config = useSelector(selectConfig);
  const token = useSelector(selectToken);
  const content = useSelector(selectContent);
  const confirmationData = useSelector(selectConfirmationData);
  const giftCardRecipient = useSelector(selectGiftCardRecipient);
  const guestSessionToken = useSelector(selectGuestSessionToken);
  const recipientEmail: string = giftCardRecipient.email
    ? giftCardRecipient.email
    : '';

  const customer = useSelector(selectCustomer);
  const journeyType = useSelector(selectJourneyType);
  const currentCinemaHomeUrl = useSelector(selectCurrentCinemaHomeUrl);

  const fetchData = useCallback(async () => {
    dispatch(actionCreators.setLoading(true));
    const qs = buildQueryString({
      circuitId: searchParams.get('circuitId') ?? undefined,
      languageCulture: cookies.preferredLanguageCulture,
    });
    let body: ConfirmationRequestModel | undefined;

    if (guestSessionToken) {
      body = {
        DataToken: token,
        GuestSessionToken: guestSessionToken,
      };
    }

    const response = await backend.post(
      `api/Confirmation/${orderId}${qs}`,
      body
    );

    if (response.ok) {
      const data = response.content;
      const confirmation = createConfirmation(data, customer, journeyType);
      dispatch(actionCreators.setConfirmation(confirmation));
    }

    dispatch(actionCreators.setLoading(false));
  }, [
    dispatch,
    searchParams,
    cookies.preferredLanguageCulture,
    guestSessionToken,
    orderId,
    token,
    customer,
    journeyType,
  ]);

  useAnalyticsTrackOnce(TrackingEvent.GIFTCARD_CHECKOUT);
  useConfirmationTracking('GiftCard');

  // fetch data
  useEffect(() => {
    if (!confirmationData || confirmationData.externalOrderId !== orderId) {
      fetchData();
    }
  }, [confirmationData, orderId, fetchData]);

  const processMode = process.env.NODE_ENV.toLowerCase();
  const isDevelopment = processMode === 'development';
  const buyAnotherGiftCardButtonUrl = isDevelopment
    ? `${'/giftcards/?circuitId='}${config.circuitId}`
    : '/giftcards/';

  if (!content || !config) return null;

  return (
    <div className='payment' data-testid='payment' sx={{ textAlign: 'center' }}>
      <ContainedRow textAlign='center'>
        <h1>{content.giftCards.confirmationTitle}</h1>
        <h2 sx={{ mt: 5 }}>{content.giftCards.confirmationSubheading}</h2>
        {recipientEmail && (
          <p sx={{ mt: 4 }}>
            {TokenReplacer(content.giftCards.confirmationText)}
          </p>
        )}
        <h2 sx={{ mt: 6 }}>
          {content.giftCards.confirmationAdditionalHeading}
        </h2>
        <RichText
          text={content.giftCards.confirmationAdditionalRichText}
          styles={{ mt: 4 }}
        />
        {confirmationData?.codeUsedInBarcode && (
          <Box mt={5}>
            <h2>
              {content.concessionsOnlyJourney.confirmationOrderNumberLabel}
            </h2>
            <p>{confirmationData.codeUsedInBarcode}</p>
          </Box>
        )}
      </ContainedRow>
      <ActionButton
        contained
        disabled={false}
        hasMarginX
        onClick={() =>
          (window.location.href = `${
            content.giftCards.confirmationButtonUrl
              ? content.giftCards.confirmationButtonUrl
              : currentCinemaHomeUrl
          }`)
        }
        showIcon={true}
        sticky={false}
        variant='primary'
      >
        {content.giftCards.confirmationButtonText}
      </ActionButton>

      {config.giftCardsPurchase.showBuyAnotherGiftCardButton && (
        <ActionButton
          contained
          disabled={false}
          hasMarginX
          onClick={() =>
            (window.location.href = `${buyAnotherGiftCardButtonUrl}`)
          }
          showIcon={true}
          sticky={false}
          variant='secondary'
        >
          {content.giftCards.buyAnotherGiftCardButtonText}
        </ActionButton>
      )}
    </div>
  );
};

export default GiftCardConfirmation;
