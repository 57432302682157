import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { actionCreators } from '../store/ActionCreators';
import {
  selectTicketTypes,
  selectUseCalculatedBookingFeeStrategy,
} from '../store/Selectors';

export const useUpdateExpectedBookingFeesAndTax = () => {
  const dispatch = useDispatch();
  const ticketTypes = useSelector(selectTicketTypes);
  const {
    useBookingFeeStrategy,
    calculatedBookingFee,
    calculatedBookingFeeTax,
  } = useSelector(selectUseCalculatedBookingFeeStrategy);

  // Change to ticketTypes triggers a recalc
  useEffect(() => {
    if (useBookingFeeStrategy) {
      dispatch(
        actionCreators.setBookingFeeAndTax(
          calculatedBookingFee,
          calculatedBookingFeeTax
        )
      );
    }
  }, [
    calculatedBookingFee,
    calculatedBookingFeeTax,
    dispatch,
    useBookingFeeStrategy,
    ticketTypes,
  ]);
};
