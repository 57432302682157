/** @jsxImportSource theme-ui */
import React from 'react';

import { Button } from 'react-bootstrap';
import { Flex } from 'theme-ui';

import { ReactComponent as RadioSelectedSvg } from '../../../svgs/radioSelected.svg';
import { ReactComponent as RadioUnselectedSvg } from '../../../svgs/radioUnselected.svg';

interface Props {
  checked: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
}

const CheckBoxButton: React.FC<Props> = ({ checked, onClick, disabled }) => {
  return (
    <Flex className='checkbox-button-container' sx={{ alignItems: 'center' }}>
      <Button
        className='checkbox-button'
        aria-checked={checked}
        onClick={onClick}
        role='checkbox'
        variant='link'
        disabled={!!disabled}
        sx={{
          '& .selectable-button-svg': {
            '& .a': { fill: 'websiteBackground' },
            '& .b': { fill: 'mostReadableOnWebsiteBackground' },
            '& .c': { fill: 'mostReadableOnWebsiteBackground' },
          },
        }}
      >
        {checked ? (
          <RadioSelectedSvg className='selectable-button-svg' />
        ) : (
          <RadioUnselectedSvg className='selectable-button-svg' />
        )}
      </Button>
    </Flex>
  );
};

export default CheckBoxButton;
