import React from 'react';

import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap';

import { ReactComponent as InfoSvg } from '../../../../../svgs/info.svg';

interface Props {
  children: React.ReactNode;
  labelFor: string;
  helpText: string;
}

const LabelWithTooltip: React.FC<Props> = ({
  children,
  labelFor,
  helpText,
}) => {
  return (
    <Form.Label
      htmlFor={labelFor}
      onClick={(e: React.MouseEvent) => e.preventDefault()}
      data-testid={'label-with-tooltip'}
    >
      <span>
        {children}
        {helpText && <span className='visually-hidden'> {helpText}</span>}
      </span>
      <OverlayTrigger
        placement={'right'}
        overlay={<Tooltip id={`${labelFor}-tooltip`}>{helpText}</Tooltip>}
      >
        <InfoSvg className='svg-info' tabIndex={0} />
      </OverlayTrigger>
    </Form.Label>
  );
};

export default LabelWithTooltip;
